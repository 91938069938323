import {
  ModalContainer,
  PaginatorContainer,
  DocumentContainer,
  Paginator,
  MobilePaginator,
  TopContainer,
  PageIndicator,
  TopContainerLeft,
} from "./styles";

import { Document, Page, pdfjs } from "react-pdf";
import { useEffect, useState } from "react";
import { useToast } from "../../hooks/toast";
import { FormattedMessage, useIntl } from "react-intl";
import { MdClose, MdFileDownload, MdRemoveRedEye } from "react-icons/md";

import { BsBoxArrowInUpRight } from "react-icons/bs";

import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    zIndex: 999999,
  },
};

function PDFModal({ pdfLocation, modalIsOpen, setIsOpen, children }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [width, setWidth] = useState(0);
  const { addToast } = useToast();
  const intl = useIntl();

  function closeModal() {
    setIsOpen(false);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function onDocumentLoadError(error) {
    addToast({
      type: "error",
      title: intl.formatMessage({
        id: "error",
      }),
      description: intl.formatMessage({
        id: "error.loading_pdf",
      }),
    });
  }

  return (
    <ModalContainer
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      className="Modal"
    >
      <TopContainer>
        <TopContainerLeft>
          <BsBoxArrowInUpRight
            size={24}
            onClick={() => window.open(pdfLocation, "_blank").focus()}
          />
        </TopContainerLeft>
        <MobilePaginator>
          {pageNumber !== 1 && (
            <FaArrowAltCircleLeft
              size={24}
              onClick={() => setPageNumber(pageNumber - 1)}
            />
          )}
          <PageIndicator>
            <FormattedMessage id="page" /> {pageNumber}{" "}
            <FormattedMessage id="of" /> {numPages}
          </PageIndicator>
          {numPages !== pageNumber && (
            <FaArrowAltCircleRight
              size={24}
              onClick={() => setPageNumber(pageNumber + 1)}
            />
          )}
        </MobilePaginator>
        <MdClose size={24} onClick={() => closeModal()} />
      </TopContainer>
      <DocumentContainer>
        <Document
          file={pdfLocation}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onDocumentLoadError}
          className={"PDFDocument"}
        >
          <Page className={"PDFPage"} pageNumber={pageNumber} />
        </Document>

        <PaginatorContainer>
          <Paginator>
            {pageNumber !== 1 && (
              <FaArrowAltCircleLeft
                size={24}
                onClick={() => setPageNumber(pageNumber - 1)}
              />
            )}
            <PageIndicator>
              <FormattedMessage id="page" /> {pageNumber}{" "}
              <FormattedMessage id="of" /> {numPages}
            </PageIndicator>
            {numPages !== pageNumber && (
              <FaArrowAltCircleRight
                size={24}
                onClick={() => setPageNumber(pageNumber + 1)}
              />
            )}
          </Paginator>
        </PaginatorContainer>
      </DocumentContainer>
    </ModalContainer>
  );
}

export default PDFModal;
