import styled, { keyframes } from "styled-components";
import { shade } from "polished";

import backgroundImg from "../../../assets/sign-in-background.jpg";

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
  @media (max-width: 1100px) {
    margin-top: 32px;
    height: auto;
  }
  @media (max-height: 790px) {
    height: auto;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 700px;
  @media (max-width: 1100px) {
    max-width: none;
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${backgroundImg}) no-repeat center;
  background-size: cover;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimatedContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: ${appearFromLeft} 1s;
  form {
    margin: 24px 0 16px 0;
    width: 360px;
    @media (max-width: 360px) {
      width: 95vw;
    }
    text-align: center;
    h1 {
      margin-bottom: 24px;
    }
    a {
      color: ${shade(0.2, "#002244")};
      display: block;
      margin-top: 12px;
      text-decoration: none;
      transition: color 0.2s;
      &:hover {
        color: ${shade(0.2, "#f4ede8")};
      }
    }
  }
  > a {
    color: #ff9000;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;
    display: flex;
    align-items: center;
    &:hover {
      color: ${shade(0.2, "#ff9000")};
    }
    svg {
      margin-right: 16px;
    }
  }
`;

export const CreateAccountContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  > a {
    color: #002244;
    text-decoration: none;
    transition: color 0.2s;
    align-items: center;
    font-weight: 600;
    &:hover {
      color: ${shade(0.2, "#f4ede8")};
    }
  }
`;

export const CreateAccountText = styled.h4`
  color: #314c68;
`;

export const Logo = styled.img`
  width: 360px;
  @media (max-width: 360px) {
    width: 100%;
  }
`;

export const Divider = styled.span`
  display: flex;
  width: 100%;
  margin-top: 48px;
  hr {
    padding: 0px 20%;
    font-size: 20px;
    margin: auto;
  }
`;

export const AlternativeSignInContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 32px;
`;

export const AlternativeSignInLogo = styled.img`
  width: 48px;
  margin: 0px 12px;
`;
