import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import * as Yup from "yup";
import FormButton from "../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";
import getValidationErrors from "../../utils/getValidationErrors";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  LinkButton,
  Session,
  ContainerDivider,
  ItemGroup,
  ProgressBarContainer,
  TimelineContainer,
  HorizontalJourney,
  HorizontalMarketParticipation,
  GraphicContainer,
  Graphic,
  GraphicRounded,
  CollapsableContainerContains,
  KnowledgeBaseAccessContainer,
  KnowledgeBaseAccessContainerTitle,
  KnowledgeBaseAccessInformationContainer,
  KnowledgeBaseAccessInformationYearContainer,
  KnowledgeBaseAccessInformationTitle,
  KnowledgeBaseAccessInformationValue,
  KnowledgeBaseAccessContainerHeader,
  CustomerHealthContainer,
} from "./styles";
import SelectWithLabel from "../../components/SelectWithLabel";
import Badge from "../../components/Badge";
import { GoBackContainer } from "../Contracts/styles";
import { MdRepeat, MdTrendingUp, MdLoyalty } from "react-icons/md";

import { TiTicket } from "react-icons/ti";

import ContractGrowthCard from "./Components/ContractGrowthCard";
import { CircularProgressbar } from "react-circular-progressbar";
import Timeline from "./Components/Timeline";
import { useToast } from "../../hooks/toast";
import api from "../../service";
import { useLoading } from "../../hooks/loading";
import { useCallback } from "react";
import ItemCollapse from "./Components/ItemCollapse/MarketingParticipation";
import SessionDivider from "../../components/SessionDivider";
import { convertToYearOnly } from "../../utils/date";
import YearPickerWithRange from "../../components/YearPickerWithRange";
import GrowthComponent from "./Components/GrowthComponent";
import ItemCollapseSelfSufficiency from "./Components/ItemCollapse/SelfSufficiency";
import { FaCheckSquare } from "react-icons/fa";
import { BsSquare } from "react-icons/bs";
import { ImCoinDollar } from "react-icons/im";
import { BsBoundingBox } from "react-icons/bs";
import { BiRepeat } from "react-icons/bi";

import { Bar, Doughnut, Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { removeDuplicatedFieldsFromArray } from "../../utils/arraySanitize";
import InfoTooltip from "../../components/InfoTooltip";
import { range } from "pdf-lib";

const Cockpit = () => {
  const { token } = useSelector((state) => state.auth);
  const formRef = useRef(null);
  const formUpsellingRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const [marketingParticipationData, setMarketingParticipationData] = useState(
    []
  );
  const [contractGrowthData, setContractGrowthData] = useState([]);
  const { handleLoading } = useLoading();
  const [timelimeInfo, setTimelineInfo] = useState();
  const [currentTimeline, setCurrentTimeline] = useState(0);
  const [totalOfDones, setTotalOfDones] = useState(0);
  const [totalOfTimelineItems, setTotalOfTimelineItems] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [filtered, setFiltered] = useState(false);
  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState();
  const [accounts, setAccounts] = useState();
  const [account, setAccount] = useState();
  const [minPeriod, setMinPeriod] = useState(new Date());
  const [maxPeriod, setMaxPeriod] = useState(new Date());
  const [selfSufficiencyData, setSelfSufficiencyData] = useState();
  const [helpdesk, setHelpdesk] = useState([]);
  const [upsellingInformation, setUpsellingInformation] = useState();
  const [upsellingFiltered, setUpsellingFiltered] = useState(false);
  const [periods, setPeriods] = useState([]);
  const [dateRange, setDateRange] = useState();
  const [customerHealthData, setCustomerHealthData] = useState();
  const [mktParticipation, setMktParticipation] = useState();
  const [productAdoption, setProductAdoption] = useState();
  const [sponsor, setSponsor] = useState();
  const [contentProducer, setContentProducer] = useState();
  const [brandExhibitor, setBrandExhibitor] = useState();
  const [digitalExhibitor, setDigitalExhibitor] = useState();
  const [employeeRegistration, setEmployeeRegistration] = useState();
  const [credentialsFill, setCredentialsFill] = useState();
  const [scheduleWithdrawal, setScheduleWithdrawal] = useState();
  const [invitationRegistration, setInvitationRegistration] = useState();
  const [associationOfServiceProviders, setAssociationOfServiceProviders] =
    useState();
  const [maxValueMarketingParticipation, setMaxValueMarketingParticipation] =
    useState(0);
  const [maxValueProductAdoption, setMaxValueProductAdoption] = useState(0);
  const [productTypeList, setProductTypeList] = useState([]);
  const [productAdoptionList, setProductAdoptionList] = useState([]);

  const schema = Yup.object().shape({
    account: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    event: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    period: Yup.array()
      .test(
        "testing_period",
        intl.formatMessage({ id: "error.field_required" }),
        (value) => {
          return value[0] && value[1];
        }
      )
      .min(2, intl.formatMessage({ id: "error.field_required" }))
      .max(2, intl.formatMessage({ id: "error.field_required" })),
  });

  const schemaUpselling = Yup.object().shape({
    year: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
  });

  const digitalList = ["digital", "virtual", "digital_advertising"];

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const validationUpsellingBlur = (name) => {
    let errors = formUpsellingRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schemaUpselling, name, formUpsellingRef);
  };

  useEffect(() => {
    setPercentage(((totalOfDones / totalOfTimelineItems) * 100).toFixed(2));
  }, [totalOfDones, totalOfTimelineItems]);

  const sanitizeTimelineArray = useCallback(
    (array) => {
      return array.map((value, index) => {
        return {
          id: index + 1,
          text: intl.formatMessage({ id: `${value.name}` }),
          done: value.done,
        };
      });
    },
    [intl]
  );

  const sanitizePeriods = useCallback((array) => {
    if (array.length === 0) {
      return [];
    }

    let min = 9999;
    let max = 1;
    let yearsInPeriods = [];
    array.forEach((eventSetup) => {
      eventSetup.setupEventAssociationOutputs.forEach((value) => {
        if (min > convertToYearOnly(value.eventStartDate)) {
          min = convertToYearOnly(value.eventStartDate);
        }
        if (max < convertToYearOnly(value.eventStartDate)) {
          max = convertToYearOnly(value.eventStartDate);
        }
        yearsInPeriods.push({
          label: convertToYearOnly(value.eventStartDate),
          value: convertToYearOnly(value.eventStartDate),
        });
      });
    });

    setMinPeriod(min);
    setMaxPeriod(max);
    setPeriods(removeDuplicatedFieldsFromArray(yearsInPeriods, JSON.stringify));
  }, []);

  useEffect(() => {
    let result = 0;
    if (
      sponsor &&
      contentProducer &&
      brandExhibitor &&
      digitalExhibitor &&
      productTypeList
    ) {
      let maxValueForMktParticipation =
        sponsor + contentProducer + brandExhibitor + digitalExhibitor;
      setMaxValueMarketingParticipation(maxValueForMktParticipation);
      if (productTypeList) {
        productTypeList.forEach((item) => {
          if (item.name === "sponsorship" && item.productsList.length > 0) {
            result += 1 * sponsor;
          }

          if (item.name === "publishing" && item.productsList.length > 0) {
            result += 1 * contentProducer;
          }

          if (item.name === "merchandize" && item.productsList.length > 0) {
            result += 1 * brandExhibitor;
          }

          if (
            (item.name === "digital" && item.productsList.length > 0) ||
            (item.name === "virtual" && item.productsList.length > 0) ||
            (item.name === "digital_advertising" &&
              item.productsList.length > 0)
          ) {
            result += 1 * digitalExhibitor;
          }
        });
      }
      setMktParticipation(result);
    }
  }, [
    brandExhibitor,
    contentProducer,
    digitalExhibitor,
    sponsor,
    productTypeList,
  ]);

  useEffect(() => {
    let result = 0;
    if (
      employeeRegistration &&
      credentialsFill &&
      scheduleWithdrawal &&
      invitationRegistration &&
      associationOfServiceProviders &&
      productAdoptionList
    ) {
      let maxValueForProductAdoption =
        employeeRegistration +
        credentialsFill +
        scheduleWithdrawal +
        invitationRegistration +
        associationOfServiceProviders;
      setMaxValueProductAdoption(maxValueForProductAdoption);
      productAdoptionList?.productAdoptions?.forEach((item) => {
        if (item.name === "CREATE_ASSOCIATE_COLLABORATOR" && item.done) {
          result += 1 * employeeRegistration;
        }

        if (item.name === "CREATE_CREDENTIAL" && item.done) {
          result += 1 * credentialsFill;
        }

        if (item.name === "CREATE_CREDENTIAL_SCHEDULE" && item.done) {
          result += 1 * scheduleWithdrawal;
        }

        if (item.name === "REGISTERED_INVITES" && item.done) {
          result += 1 * invitationRegistration;
        }

        if (item.name === "ASSOCIATE_SERVICE_PROVIDER" && item.done) {
          result += 1 * associationOfServiceProviders;
        }
      });
      setProductAdoption(result);
    }
  }, [
    associationOfServiceProviders,
    credentialsFill,
    employeeRegistration,
    invitationRegistration,
    productAdoptionList,
    scheduleWithdrawal,
  ]);

  useEffect(() => {
    handleLoading(true);
    api
      .patch(
        "/admin/setup-event",
        { eventSetupName: null, eventsId: null },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        setEvents(result.data.setupEventOutputList);
        sanitizePeriods(result.data.setupEventOutputList);
      })
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => handleLoading(false));
  }, [
    addToast,
    handleLoading,
    intl,
    token,
    sanitizeTimelineArray,
    sanitizePeriods,
  ]);

  useEffect(() => {
    if (event) {
      handleLoading(true);
      formRef.current.setFieldValue("account", null);
      api
        .patch(
          "/admin/account/by-setup-id",
          {},
          {
            params: {
              setupId: event.value,
            },
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          setLoading(false);
          setAccounts(result.data.output);
        })
        .catch((err) => {
          setLoading(false);
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
        })
        .finally(() => {
          handleLoading(false);
        });
    }
  }, [event, addToast, handleLoading, intl, token]);

  const handleSubmit = async (data) => {
    try {
      formRef.current?.setErrors({});

      if (loading) {
        return;
      }
      setLoading(true);

      await schema.validate(data, { abortEarly: false });
      api
        .patch(
          "/admin/cockpit",
          {
            setupEventId: data.event,
            accountId: data.account,
            initialPeriodDate: data.period[0].getFullYear(),
            endPeriodDate: data.period[1].getFullYear(),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          setFiltered(true);
          const {
            marketingParticipationOutput,
            contractGrowthOutput,
            selfSufficiencyOutput,
            productAdoptionOutput,
            customerHealthOutput,
          } = result.data.cockpitOutput;
          const { period, event } = data;

          setDateRange({
            initial: period[0].getFullYear(),
            end: period[1].getFullYear(),
          });
          getParametersValues(event);

          setMarketingParticipationData(marketingParticipationOutput);
          setProductTypeList(marketingParticipationOutput.productTypeList);
          setProductAdoptionList(productAdoptionOutput);
          setContractGrowthData(contractGrowthOutput);
          setSelfSufficiencyData(selfSufficiencyOutput);
          handleProductAdoption(productAdoptionOutput);
          setUpsellingFiltered(false);
          setCustomerHealthData(customerHealthOutput);
        })
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => setLoading(false));
    } catch (err) {
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        return;
      }
    }
  };

  const handleUpsellingSubmit = async (data) => {
    try {
      formUpsellingRef.current?.setErrors({});

      if (loading) {
        return;
      }
      setLoading(true);

      await schemaUpselling.validate(data, { abortEarly: false });

      api
        .patch(
          "/admin/cockpit/upselling",
          {
            setupEventId: formRef.current.getFieldValue("event"),
            accountId: formRef.current.getFieldValue("account"),
            year: data.year,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          setUpsellingInformation(result.data.upsellingOutput);
          setUpsellingFiltered(true);
        })
        .catch((err) => {
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
        })
        .finally(() => {
          setLoading(false);
          handleLoading(false);
        });
    } catch (err) {
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        return;
      }
    }
  };

  useEffect(() => {
    if (event) {
      handleLoading(true);
      formRef.current.setFieldValue("account", null);
      api
        .patch(
          `/admin/setup-event/helpdesk/${event.value}`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          setLoading(false);
          setHelpdesk(result.data.output);
        })
        .catch((err) => {
          setLoading(false);
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
        })
        .finally(() => {
          handleLoading(false);
        });
    }
  }, [event, addToast, handleLoading, intl, token]);

  const getParametersValues = useCallback(
    (eventSelected) => {
      handleLoading(true);
      api
        .get(`/admin/setup-event/parameters/${eventSelected}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const parameters = response.data.eventsAdminParametersOutput;
          setSponsor(parameters.sponsor || 0);
          setContentProducer(parameters.contentProducer || 0);
          setBrandExhibitor(parameters.brandExhibitor || 0);
          setDigitalExhibitor(parameters.digitalExhibitor || 0);
          setEmployeeRegistration(parameters.employeeRegistration || 0);
          setCredentialsFill(parameters.credentialsFill || 0);
          setScheduleWithdrawal(parameters.scheduleWithdrawal || 0);
          setInvitationRegistration(parameters.invitationRegistration || 0);
          setAssociationOfServiceProviders(
            parameters.associationOfServiceProviders || 0
          );
        })
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({ id: "error" }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => {
          handleLoading(false);
        });
    },
    [addToast, handleLoading, intl, token]
  );

  useEffect(() => {
    if (event) {
      getParametersValues(event.value);
    }
  }, [event, getParametersValues]);

  const monthNames = [
    "JAN",
    "FEV",
    "MAR",
    "ABR",
    "MAI",
    "JUN",
    "JUL",
    "AGO",
    "SET",
    "OUT",
    "NOV",
    "DEZ",
  ];

  const generateRandomColors = (array) => {
    const colorPallete = [
      "#1D5B56",
      "#40896E",
      "#152D3B",
      "#446653",
      "#445671",
      "#36ac64",
      "#68c18b",
    ];
    let colors = [];
    array.map((item) => {
      let color = Math.floor(Math.random() * colorPallete.length);
      colors.push(colorPallete[color]);
      return colors;
    });
    return colors;
  };

  const ticketDates = helpdesk.map(({ entryDate }) => entryDate);

  const groupsTicketsToBarChart = helpdesk.reduce((groups, ticket) => {
    let date = ticket.entryDate;
    let dateParts = date.split("/");
    let dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    let formatedDate = `${monthNames[dateObject.getMonth()]}/${dateParts[2]}`;
    if (!groups[formatedDate]) {
      groups[formatedDate] = [];
    }
    groups[formatedDate].push(ticket);
    return groups;
  }, {});

  const groupsTicketsBarChartToArrays = Object.keys(groupsTicketsToBarChart)
    .sort((a, b) => {
      const [aMonth, aYear] = a.split("/");
      const [bMonth, bYear] = b.split("/");

      const aMonthIndex = monthNames.indexOf(aMonth);
      const bMonthIndex = monthNames.indexOf(bMonth);

      if (aYear > bYear) return 1;
      else if (aYear < bYear) return -1;
      else if (aMonthIndex > bMonthIndex) return 1;
      else if (aMonthIndex < bMonthIndex) return -1;
      return 0;
    })
    .map((date) => {
      return {
        date,
        tickets: groupsTicketsToBarChart[date],
      };
    });

  const labelsBarChart = ticketDates
    .map((item) => {
      let date = item;
      let dateParts = date.split("/");
      let dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      let dates = `${monthNames[dateObject.getMonth()]}/${dateParts[2]}`;
      return dates;
    })
    .sort((a, b) => {
      const [aMonth, aYear] = a.split("/");
      const [bMonth, bYear] = b.split("/");

      const aMonthIndex = monthNames.indexOf(aMonth);
      const bMonthIndex = monthNames.indexOf(bMonth);

      if (aYear > bYear) return 1;
      else if (aYear < bYear) return -1;
      else if (aMonthIndex > bMonthIndex) return 1;
      else if (aMonthIndex < bMonthIndex) return -1;
      return 0;
    });

  const ticketsVerticalBarChart = {
    labels: labelsBarChart.filter(
      (item, i) => labelsBarChart.indexOf(item) === i
    ),
    datasets: [
      {
        label: "Tickets",
        data: groupsTicketsBarChartToArrays.map((item) => item.tickets.length),
        backgroundColor: generateRandomColors(groupsTicketsBarChartToArrays),
      },
    ],
  };

  const optionsTicketsVerticalBarChart = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      title: {
        display: true,
        text: "Tickets (Mês)",
      },
      legend: {
        display: false,
      },
    },
  };

  const labelsDonutChart = helpdesk.map((item) => {
    return item.ticketCategory;
  });

  const groupsTicketsToDonutChart = helpdesk.reduce((groups, ticket) => {
    let category = ticket.ticketCategory;
    if (!groups[category]) {
      groups[category] = [];
    }
    groups[category].push(ticket);
    return groups;
  }, {});

  const groupsTicketsDonutChartToArrays = Object.keys(
    groupsTicketsToDonutChart
  ).map((category) => {
    return {
      category,
      tickets: groupsTicketsToDonutChart[category],
    };
  });

  const donutChart = {
    labels: labelsDonutChart.filter(
      (item, i) => labelsDonutChart.indexOf(item) === i
    ),
    datasets: [
      {
        label: "Tickets (Categoria)",
        data: groupsTicketsDonutChartToArrays.map(
          (item) => item.tickets.length
        ),
        backgroundColor: generateRandomColors(groupsTicketsDonutChartToArrays),
      },
    ],
  };

  const optionsDonutChart = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      title: {
        display: true,
        text: "Tickets (Categoria)",
      },
    },
  };

  const labelsHorizontalBarChart = helpdesk.map((item) => {
    return item.ticketSubject;
  });

  const groupsTicketsToHorizontalBarChart = helpdesk.reduce(
    (groups, ticket) => {
      let subject = ticket.ticketSubject;
      if (!groups[subject]) {
        groups[subject] = [];
      }
      groups[subject].push(ticket);
      return groups;
    },
    {}
  );

  const groupsTicketsHorizontalBarChartToArrays = Object.keys(
    groupsTicketsToHorizontalBarChart
  ).map((subject) => {
    return {
      subject,
      tickets: groupsTicketsToHorizontalBarChart[subject],
    };
  });

  const categoryHorizontalBarChart = {
    labels: labelsHorizontalBarChart.filter(
      (item, i) => labelsHorizontalBarChart.indexOf(item) === i
    ),
    datasets: [
      {
        label: "Tickets",
        data: groupsTicketsHorizontalBarChartToArrays.map(
          (item) => item.tickets.length
        ),
        backgroundColor: generateRandomColors(
          groupsTicketsHorizontalBarChartToArrays
        ),
        borderWidth: 1,
      },
    ],
  };

  const optionsCategoryHorizontalBarChart = {
    indexAxis: "y",
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      y: {
        ticks: {
          callback: (index) => {
            const { subject } = groupsTicketsHorizontalBarChartToArrays[index];
            return subject.length > 10
              ? `${subject.substring(0, 10)}...`
              : subject;
          },
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: "Tickets (Assunto)",
      },
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
  };

  const labelsPieBarChart = helpdesk.map((item) => {
    return item.satisfactionSurvey;
  });

  const groupsTicketsToPieChart = helpdesk.reduce((groups, ticket) => {
    let satisfactionSurvey = ticket.satisfactionSurvey;
    if (!groups[satisfactionSurvey]) {
      groups[satisfactionSurvey] = [];
    }
    groups[satisfactionSurvey].push(ticket);
    return groups;
  }, {});

  const groupsTicketsPieChartToArrays = Object.keys(
    groupsTicketsToPieChart
  ).map((satisfactionSurvey) => {
    return {
      satisfactionSurvey,
      tickets: groupsTicketsToPieChart[satisfactionSurvey],
    };
  });

  const satisfactionSurveyPieChart = {
    labels: labelsPieBarChart.filter(
      (item, i) => labelsPieBarChart.indexOf(item) === i
    ),
    datasets: [
      {
        label: "Pesquisa de Satisfação",
        data: groupsTicketsPieChartToArrays.map((item) => item.tickets.length),
        backgroundColor: generateRandomColors(groupsTicketsPieChartToArrays),
      },
    ],
  };

  const optionsSatisfactionSurveyPieChart = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      title: {
        display: true,
        text: "Pesquisa de Satisfação",
      },
    },
  };

  const handleProductAdoption = useCallback(
    (output) => {
      let productAdoptionList = output.productAdoptions;
      let currentTimelineCounter = -1;
      let totalOfDonesCounter = 0;

      productAdoptionList.forEach((element) => {
        if (element.done) {
          currentTimelineCounter++;
          totalOfDonesCounter++;
        }
      });

      setTimelineInfo(sanitizeTimelineArray(productAdoptionList));
      setCurrentTimeline(currentTimelineCounter);
      setTotalOfDones(totalOfDonesCounter);
      setTotalOfTimelineItems(productAdoptionList.length);
    },
    [sanitizeTimelineArray]
  );

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("period", "");
    formRef.current.setFieldValue("account", "");
    formRef.current.setFieldValue("event", "");
    setFiltered(false);
    setHelpdesk([]);
  };

  const optionsMarketingParticipationBarChart = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      title: {
        display: true,
        text: "Marketing Participation",
      },
      legend: {
        display: false,
      },
    },
  };

  const marketingParticipationBarChart = {
    labels: ["Marketing Participation"],
    datasets: [
      {
        label: "Marketing Participation",
        data: [mktParticipation, maxValueMarketingParticipation],
        backgroundColor: generateRandomColors([1, 2, 3, 4, 5, 6]),
      },
    ],
  };

  const optionsProductionAdoptionBarChart = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      title: {
        display: true,
        text: "Product Adoption",
      },
      legend: {
        display: false,
      },
    },
  };

  const productAdoptionBarChart = {
    labels: ["Product Adoption"],
    datasets: [
      {
        label: "Product Adoption",
        data: [productAdoption, maxValueProductAdoption],
        backgroundColor: generateRandomColors([1, 2, 3, 4, 5, 6]),
      },
    ],
  };

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "breadcrumb.cockpit" })}
    >
      <Container>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <TitleContainer>
              <Title>
                <FormattedMessage id="breadcrumb.cockpit" />
              </Title>
              <GoBackContainer>
                <LinkButton onClick={() => history.goBack()}>
                  <Badge
                    color="#002244"
                    text={intl.formatMessage({ id: "goback" })}
                    fontSize="12px"
                  />
                </LinkButton>
              </GoBackContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal style={{ alignItems: "flex-start" }}>
                <SelectWithLabel
                  name="event"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "event" })}
                  placeholder={intl.formatMessage({ id: "event" })}
                  options={events?.map((event) => {
                    return {
                      label: event.eventSetupName,
                      value: event.id,
                    };
                  })}
                  onChange={(element) => {
                    setFiltered(false);
                    return element != null ? setEvent(element) : null;
                  }}
                  clearable
                  required
                />
                <SelectWithLabel
                  name="account"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "exhibitor" })}
                  placeholder={intl.formatMessage({ id: "exhibitor" })}
                  options={accounts?.map((account) => {
                    return {
                      label: account.accountName,
                      value: account.idNumber,
                      document: account.accountGovCompanyCode,
                    };
                  })}
                  onChange={(element) => {
                    setAccount(element);
                    setFiltered(false);
                  }}
                  disabled={!event}
                  clearable
                  required
                />
                <YearPickerWithRange
                  name="period"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "period" })}
                  placeholder={intl.formatMessage({ id: "period" })}
                  disabled={!event}
                  labelOrientation="vertical"
                  minRange={minPeriod}
                  maxRange={maxPeriod}
                  required
                />
              </Horizontal>
              <Horizontal>
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="reset"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="search" />
                    </FormButton>
                  </SubmitContainer>
                </FilterActionsContainer>
              </Horizontal>
            </FieldsContainer>
          </Form>
        </FormContainer>
      </Container>
      <ContainerDivider />
      {event && helpdesk.length > 0 && (
        <>
          <Container>
            <Session>
              <SessionDivider
                icon={<TiTicket size="16px" color="#002244" />}
                title={`Survey Scores (${helpdesk.length})`}
              />
            </Session>
            <GraphicContainer>
              <Graphic>
                <Bar
                  data={ticketsVerticalBarChart}
                  options={optionsTicketsVerticalBarChart}
                />
              </Graphic>
              <Graphic>
                <Bar
                  data={categoryHorizontalBarChart}
                  options={optionsCategoryHorizontalBarChart}
                  plugins={[ChartDataLabels]}
                />
              </Graphic>
              <GraphicRounded>
                <Doughnut data={donutChart} options={optionsDonutChart} />
              </GraphicRounded>
              <GraphicRounded>
                <Pie
                  data={satisfactionSurveyPieChart}
                  options={optionsSatisfactionSurveyPieChart}
                />
              </GraphicRounded>
            </GraphicContainer>
          </Container>
        </>
      )}
      <ContainerDivider />
      {filtered && (
        <>
          <Container>
            <Session>
              <SessionDivider
                icon={<MdTrendingUp size="16px" color="#002244" />}
                title="Customer Health"
              />
              <FieldsContainer>
                <CustomerHealthContainer>
                  <ContractGrowthCard
                    title={intl.formatMessage({ id: "financial_growth" })}
                    value={customerHealthData?.financialGrowth || 0}
                    icon={<ImCoinDollar size="72" color="#dadada" />}
                  />
                  <ContractGrowthCard
                    title={intl.formatMessage({ id: "area_growth" })}
                    value={customerHealthData?.areaGrowth || 0}
                    icon={<BsBoundingBox size="72" color="#dadada" />}
                  />
                  <ContractGrowthCard
                    title={intl.formatMessage({ id: "recurrence" })}
                    value={customerHealthData?.recurrence || 0}
                    icon={<BiRepeat size="72" color="#dadada" />}
                  />
                  <ContractGrowthCard
                    title="Upselling"
                    value={customerHealthData?.upselling || 0}
                    icon={<MdTrendingUp size="72" color="#dadada" />}
                  />
                  <ContractGrowthCard
                    title="Loyalty Index"
                    value={customerHealthData?.loyaltyIndex || 0}
                    icon={<MdLoyalty size="72" color="#dadada" />}
                  />
                </CustomerHealthContainer>
              </FieldsContainer>
              <GraphicContainer>
                <Graphic>
                  <Bar
                    data={marketingParticipationBarChart}
                    options={optionsMarketingParticipationBarChart}
                  />
                </Graphic>
                <Graphic>
                  <Bar
                    data={productAdoptionBarChart}
                    options={optionsProductionAdoptionBarChart}
                  />
                </Graphic>
              </GraphicContainer>
            </Session>
          </Container>
          <ContainerDivider />
          <Container>
            <Session>
              <SessionDivider
                icon={<MdTrendingUp size="16px" color="#002244" />}
                title="Contract Growth"
                infoIcon={
                  <InfoTooltip
                    name="Contract Growth"
                    text={intl.formatMessage({ id: "contract_growth_tooltip" })}
                    type="info"
                  />
                }
              />

              <FieldsContainer>
                <CollapsableContainerContains>
                  <HorizontalMarketParticipation>
                    <GrowthComponent
                      growthItems={contractGrowthData?.growthItems}
                    />
                  </HorizontalMarketParticipation>
                  <ContractGrowthCard
                    title={intl.formatMessage({ id: "recurrence" })}
                    value={intl.formatMessage(
                      { id: "recurrence_text" },
                      { value: contractGrowthData?.recurrence }
                    )}
                    icon={<MdRepeat size="72" color="#dadada" />}
                    tooltip={intl.formatMessage({ id: "recurrence_tooltip" })}
                    hasTooltip
                  />
                  <ContractGrowthCard
                    title="Loyalty"
                    value={contractGrowthData?.loyalty}
                    icon={<MdLoyalty size="72" color="#dadada" />}
                    tooltip={intl.formatMessage({ id: "loyalty_tooltip" })}
                    hasTooltip
                  />
                </CollapsableContainerContains>
                {account?.document && (
                  <div style={{ padding: "16px" }}>
                    <SessionDivider
                      icon={<MdTrendingUp size="16px" color="#002244" />}
                      title="Upselling"
                      infoIcon={
                        <InfoTooltip
                          name="Upselling"
                          text={intl.formatMessage({
                            id: "upselling_tooltip",
                          })}
                          type="info"
                        />
                      }
                    />
                    <FieldsContainer>
                      <Form
                        ref={formUpsellingRef}
                        onSubmit={handleUpsellingSubmit}
                      >
                        <Horizontal>
                          <SelectWithLabel
                            name="year"
                            validationBlur={validationUpsellingBlur}
                            label={intl.formatMessage({ id: "year" })}
                            placeholder={intl.formatMessage({ id: "year" })}
                            options={periods}
                            disabled={!event || !account}
                            clearable
                          />

                          <SubmitContainer>
                            <FormButton type="submit" loading={loading}>
                              <FormattedMessage id="search" />
                            </FormButton>
                          </SubmitContainer>
                        </Horizontal>
                      </Form>
                    </FieldsContainer>
                  </div>
                )}

                {upsellingFiltered && (
                  <CollapsableContainerContains>
                    <ContractGrowthCard
                      title="Upselling"
                      value={`${upsellingInformation?.percentage.toFixed(2)}%`}
                      icon={<MdTrendingUp size="72" color="#dadada" />}
                    />
                  </CollapsableContainerContains>
                )}
              </FieldsContainer>
            </Session>
          </Container>
          <ContainerDivider />
          <Container>
            <Session>
              <SessionDivider
                icon={<MdTrendingUp size="16px" color="#002244" />}
                title="Marketing Participation"
              />
              <FieldsContainer>
                <HorizontalMarketParticipation
                  style={{ alignItems: "flex-start" }}
                >
                  {marketingParticipationData?.productTypeList?.map(
                    (element, index) => {
                      if (!digitalList.includes(element.name)) {
                        return (
                          <ItemGroup key={index}>
                            <SessionDivider
                              title={
                                element.name === "sponsorship"
                                  ? intl.formatMessage({
                                      id: "sponsorship_cockpit",
                                    })
                                  : element.name === "publishing"
                                  ? intl.formatMessage({
                                      id: "publishing_cockpit",
                                    })
                                  : element.name === "merchandize"
                                  ? intl.formatMessage({
                                      id: "merchandize_cockpit",
                                    })
                                  : ""
                              }
                            />

                            <ItemCollapse element={element} />
                          </ItemGroup>
                        );
                      }
                      return null;
                    }
                  )}
                </HorizontalMarketParticipation>

                <SessionDivider
                  title={intl.formatMessage({ id: "digital_cockpit" })}
                />
                <HorizontalMarketParticipation>
                  {marketingParticipationData?.productTypeList?.map(
                    (element, index) => {
                      if (digitalList.includes(element.name)) {
                        return (
                          <ItemGroup key={index}>
                            <ItemCollapse element={element} />
                          </ItemGroup>
                        );
                      }
                      return null;
                    }
                  )}
                </HorizontalMarketParticipation>
              </FieldsContainer>
            </Session>
          </Container>
          <ContainerDivider />
          <Container>
            <Session>
              <SessionDivider
                icon={<MdTrendingUp size="16px" color="#002244" />}
                title="self-sufficiency"
                infoIcon={
                  <InfoTooltip
                    name="self sufficiency"
                    text={intl.formatMessage({
                      id: "self_sufficiency_tooltip",
                    })}
                    type="info"
                  />
                }
              />
              <FieldsContainer>
                <KnowledgeBaseAccessContainer>
                  <KnowledgeBaseAccessContainerHeader>
                    {selfSufficiencyData?.faqAccesses &&
                    Object.keys(selfSufficiencyData?.faqAccesses).length > 0 ? (
                      <FaCheckSquare
                        size={20}
                        color="#002244"
                        style={{ minWidth: 20 }}
                      />
                    ) : (
                      <BsSquare
                        size={20}
                        color="#002244"
                        style={{ minWidth: 20 }}
                      />
                    )}
                    <KnowledgeBaseAccessContainerTitle>
                      {intl.formatMessage({ id: "knowledge_base_accesses" })}
                    </KnowledgeBaseAccessContainerTitle>
                  </KnowledgeBaseAccessContainerHeader>
                  <KnowledgeBaseAccessInformationContainer>
                    {!!dateRange &&
                      range(dateRange?.initial, dateRange?.end + 1).map(
                        (year, index) => (
                          <KnowledgeBaseAccessInformationYearContainer
                            key={index}
                          >
                            <KnowledgeBaseAccessInformationTitle>
                              {year}
                            </KnowledgeBaseAccessInformationTitle>
                            <KnowledgeBaseAccessInformationValue>
                              {selfSufficiencyData?.faqAccesses[year] || 0}
                            </KnowledgeBaseAccessInformationValue>
                          </KnowledgeBaseAccessInformationYearContainer>
                        )
                      )}
                  </KnowledgeBaseAccessInformationContainer>
                </KnowledgeBaseAccessContainer>

                <HorizontalMarketParticipation>
                  {selfSufficiencyData?.eventAccessOutputList?.map(
                    (element, index) => (
                      <ItemGroup key={index}>
                        <SessionDivider />
                        <ItemCollapseSelfSufficiency element={element} />
                      </ItemGroup>
                    )
                  )}
                </HorizontalMarketParticipation>
              </FieldsContainer>
            </Session>
          </Container>
          <ContainerDivider />
          <Container>
            <Session>
              <SessionDivider
                icon={<MdTrendingUp size="16px" color="#002244" />}
                title="Product Adoption"
                infoIcon={
                  <InfoTooltip
                    name="Product Adoption"
                    text={intl.formatMessage({
                      id: "product_adoption_tooltip",
                    })}
                    type="info"
                  />
                }
              />
              <FieldsContainer>
                <HorizontalJourney>
                  <ProgressBarContainer>
                    <CircularProgressbar
                      value={percentage}
                      text={`${percentage}%`}
                      styles={{
                        root: {
                          justifyContent: "center",
                        },
                        trail: {
                          stroke: "#d6d6d6",
                          strokeLinecap: "butt",
                          transform: "rotate(0.25turn)",
                          transformOrigin: "center center",
                        },
                        text: {
                          fill: "#002244",
                          fontSize: "16px",
                        },
                        background: {
                          fill: "#002244",
                        },
                        path: {
                          stroke: `#002244`,
                        },
                      }}
                    />
                  </ProgressBarContainer>

                  <TimelineContainer>
                    <Timeline
                      data={timelimeInfo}
                      timelineInfo={timelimeInfo}
                      currentStep={currentTimeline}
                    />
                  </TimelineContainer>
                </HorizontalJourney>
              </FieldsContainer>
            </Session>
          </Container>
        </>
      )}
    </AnimatedMainContainer>
  );
};

export default Cockpit;
