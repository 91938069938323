import produce from "immer";

const INITIAL_STATE = {
  token: "",
  user: null,
  customerCenterToken: "",
  permissionCategories: [],
  accountCreated: null,
  loading: true,
  executeLogout: false,
  serviceProviderLoggingAsAnExhibitor: false,
};

const auth = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case "AUTH_SUCCESS": {
        const {
          user,
          token,
          customerCenterToken,
          keepSignedIn,
          accountCreated,
          serviceProviderLoggingAsAnExhibitor,
        } = action.payload;

        draft.user = user;
        draft.token = token;
        draft.customerCenterToken = customerCenterToken;
        draft.permissionCategories = user.permissionCategories;
        draft.accountCreated = accountCreated;
        draft.executeLogout = false;
        draft.serviceProviderLoggingAsAnExhibitor =
          serviceProviderLoggingAsAnExhibitor;

        localStorage.setItem(
          "InformaMarket:KeepSignedIn",
          keepSignedIn ? true : ""
        );

        if (user.language) {
          localStorage.setItem("InformaMarkets:Language", user.language);
        }

        if (!!keepSignedIn) {
          localStorage.setItem("InformaMarket:User", JSON.stringify(user));
          localStorage.setItem("InformaMarket:Token", token);
          localStorage.setItem(
            "InformaMarket:CustomerCenterToken",
            customerCenterToken
          );
          localStorage.setItem("InformaMarket:AccountCreated", accountCreated);
          localStorage.setItem(
            "InformaMarket:ServiceProviderLoggingAsAnExhibitor",
            serviceProviderLoggingAsAnExhibitor
          );
        } else {
          sessionStorage.setItem("InformaMarket:User", JSON.stringify(user));
          sessionStorage.setItem("InformaMarket:Token", token);
          sessionStorage.setItem(
            "InformaMarket:CustomerCenterToken",
            customerCenterToken
          );
          sessionStorage.setItem(
            "InformaMarket:AccountCreated",
            accountCreated
          );
          sessionStorage.setItem(
            "InformaMarket:ServiceProviderLoggingAsAnExhibitor",
            serviceProviderLoggingAsAnExhibitor
          );
        }

        break;
      }

      case "LOGOUT_SUCCESS": {
        draft.user = null;
        draft.token = "";
        draft.permissionCategories = [];
        draft.accountCreated = null;

        const keepSignedIn = localStorage.getItem("InformaMarket:KeepSignedIn");
        if (!!keepSignedIn) {
          localStorage.removeItem("InformaMarket:User");
          localStorage.removeItem("InformaMarket:Token");
          localStorage.removeItem("InformaMarket:KeepSignedIn");
          localStorage.removeItem("InformaMarket:CustomerCenterToken");
          localStorage.removeItem("InformaMarket:AccountCreated");
          localStorage.removeItem("InformaMarkets:Language");
          localStorage.removeItem(
            "InformaMarket:ServiceProviderLoggingAsAnExhibitor"
          );
        } else {
          sessionStorage.removeItem("InformaMarket:User");
          sessionStorage.removeItem("InformaMarket:Token");
          sessionStorage.removeItem("InformaMarket:CustomerCenterToken");
          sessionStorage.removeItem("InformaMarket:AccountCreated");
          sessionStorage.removeItem(
            "InformaMarket:ServiceProviderLoggingAsAnExhibitor"
          );
        }

        break;
      }

      case "LOAD_USER": {
        const keepSignedIn = localStorage.getItem("InformaMarket:KeepSignedIn");

        let storagedUser;
        let storagedToken;
        let storagedCustomerCenterToken;
        let storagedAccountCreated;
        let storagedServiceProviderLoggingAsAnExhibitor;

        if (!!keepSignedIn) {
          storagedUser = localStorage.getItem("InformaMarket:User");
          storagedToken = localStorage.getItem("InformaMarket:Token");
          storagedCustomerCenterToken = localStorage.getItem(
            "InformaMarket:CustomerCenterToken"
          );
          storagedAccountCreated = localStorage.getItem(
            "InformaMarket:AccountCreated"
          );
          storagedServiceProviderLoggingAsAnExhibitor = localStorage.getItem(
            "InformaMarket:ServiceProviderLoggingAsAnExhibitor"
          );
        } else {
          storagedUser = sessionStorage.getItem("InformaMarket:User");
          storagedToken = sessionStorage.getItem("InformaMarket:Token");
          storagedCustomerCenterToken = sessionStorage.getItem(
            "InformaMarket:CustomerCenterToken"
          );
          storagedAccountCreated = sessionStorage.getItem(
            "InformaMarket:AccountCreated"
          );
          storagedServiceProviderLoggingAsAnExhibitor = sessionStorage.getItem(
            "InformaMarket:ServiceProviderLoggingAsAnExhibitor"
          );
        }

        if (storagedUser) {
          const user = JSON.parse(storagedUser);

          draft.user = user;
          draft.permissionCategories = user.permissionCategories;
        }

        if (storagedToken) {
          const token = storagedToken;

          draft.token = token;
        }

        if (storagedCustomerCenterToken) {
          const customerCenterToken = storagedCustomerCenterToken;

          draft.customerCenterToken = customerCenterToken;
        }

        if (storagedAccountCreated) {
          const accountCreated = storagedAccountCreated;

          draft.accountCreated = accountCreated === "true";
        }

        if (storagedServiceProviderLoggingAsAnExhibitor) {
          const serviceProviderLoggingAsAnExhibitor =
            storagedServiceProviderLoggingAsAnExhibitor;

          draft.serviceProviderLoggingAsAnExhibitor =
            serviceProviderLoggingAsAnExhibitor === "true";
        }

        draft.loading = false;

        break;
      }

      case "PRE_LOGOUT_REQUEST": {
        draft.executeLogout = true;

        break;
      }

      case "UPDATE_USER": {
        const { user } = action.payload;

        draft.user = user;
        draft.accountCreated = true;

        const keepSignedIn = localStorage.getItem("InformaMarket:KeepSignedIn");

        if (!!keepSignedIn) {
          localStorage.setItem("InformaMarket:User", JSON.stringify(user));
          localStorage.setItem("InformaMarket:AccountCreated", true);
        } else {
          sessionStorage.setItem("InformaMarket:User", JSON.stringify(user));
          sessionStorage.setItem("InformaMarket:AccountCreated", true);
        }

        break;
      }

      default: {
        return draft;
      }
    }
  });
};

export default auth;
