import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { useToast } from "../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";
import api from "../../service";
import { useCallback } from "react";
import FormButton from "../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  LinkButton,
  GoBackContainer,
  ActionContainer,
  BadgeContainer,
  NotificationContainer,
} from "./styles";
import InputWithLabel from "../../components/InputWithLabel";
import Table from "../../components/Table";
import Badge from "../../components/Badge";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import SelectWithLabel from "../../components/SelectWithLabel";

const AdditionalProduct = () => {
  const { token } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [filtered, setFiltered] = useState(false);

  const [formWithSearchedValues, setFormWithSearchedValues] = useState();

  const [key, setKey] = useState();

  const schema = Yup.object().shape({
    title: Yup.string().nullable(),
    credential: Yup.string().nullable(),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        clearSessionStorage();

        await schema.validate(data, { abortEarly: false });

        setFiltered(true);

        api
          .patch(`/admin/ecommerce/chleba/category`, data, {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((result) => {
            setData(result.data.ecommerceChlebaCategoryFilterOutput);
            sessionStorage.setItem(
              "@informacp/categoryNavigation",
              JSON.stringify({
                title: data.title !== "" ? data.title : null,
                credential: data.credential,
                categorySearchResult:
                  result.data.ecommerceChlebaCategoryFilterOutput,
              })
            );
          })
          .catch((err) =>
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            })
          )
          .finally(() => setLoading(false));
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [addToast, intl, schema, token]
  );

  const callCategoryApi = async (data) => {
    try {
      formRef.current?.setErrors({});

      setLoading(true);
      clearSessionStorage();

      await schema.validate(data, { abortEarly: false });

      setFiltered(true);

      api
        .patch(`/admin/ecommerce/chleba/category`, data, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          setData(result.data.ecommerceChlebaCategoryFilterOutput);
          sessionStorage.setItem(
            "@informacp/categoryNavigation",
            JSON.stringify({
              title: data.title !== "" ? data.title : null,
              credential: data.credential,
              categorySearchResult:
                result.data.ecommerceChlebaCategoryFilterOutput,
            })
          );
        })
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => setLoading(false));
    } catch (err) {
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    if (
      history.location.state != null &&
      history.location.state.prevPath.pathname.includes("/admin/categories/")
    ) {
      if (sessionStorage.getItem("@informacp/categoryNavigation") != null) {
        setFiltered(true);
        setData(
          JSON.parse(sessionStorage.getItem("@informacp/categoryNavigation"))
            .categorySearchResult
        );
      }
    } else {
      clearSessionStorage();
      clearFields();
      callCategoryApi({ title: "", credential: null });
    }
    setLoading(false);
  }, [history]);

  const statusOptions = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: "yes" }),
        value: true,
      },
      {
        label: intl.formatMessage({ id: "no" }),
        value: false,
      },
      {
        label: intl.formatMessage({ id: "both" }),
        value: null,
      },
    ],
    [intl]
  );

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "title" }),
        selector: (row) => row.title,
      },
      {
        name: intl.formatMessage({ id: "credential_type" }),
        selector: (row) =>
          row.credential
            ? intl.formatMessage({ id: "yes" })
            : intl.formatMessage({ id: "no" }),
      },
      {
        cell: (row) => (
          <LinkButton to={`/admin/categories/${row.id}`}>
            <Badge color="#002244" text={intl.formatMessage({ id: "edit" })} />
          </LinkButton>
        ),
        right: true,
      },
    ],
    [intl]
  );

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("title", "");
    formRef.current.setFieldValue("credential", {
      label: intl.formatMessage({ id: "both" }),
      value: null,
    });
    callCategoryApi({ title: "", credential: null });
  };

  const clearSessionStorage = () => {
    sessionStorage.removeItem("@informacp/categoryNavigation");
  };

  useEffect(() => {
    const categoryNavigationFromSessionStorage = sessionStorage.getItem(
      "@informacp/categoryNavigation"
    );

    if (categoryNavigationFromSessionStorage) {
      const { title, credential } = JSON.parse(
        categoryNavigationFromSessionStorage
      );
      const typeItem = statusOptions.find((item) => item.value === credential);
      setFormWithSearchedValues({ title: title, credential: typeItem });
      setKey("session-filter");
    } else {
      const typeItem = statusOptions.find((item) => item.value === null);
      setFormWithSearchedValues({ title: "", credential: typeItem });
      setKey("session-filter");
    }
  }, [intl, statusOptions]);

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({
        id: "breadcrumb.categories",
      })}
    >
      <Container>
        <FormContainer>
          <Form
            ref={formRef}
            key={key}
            onSubmit={handleSubmit}
            initialData={formWithSearchedValues}
          >
            <TitleContainer>
              <Title>
                <FormattedMessage id="categories" />
              </Title>
              <GoBackContainer>
                <ActionContainer>
                  <BadgeContainer onClick={() => history.push("/dashboard")}>
                    <Badge
                      color="#C9C9C9"
                      text={intl.formatMessage({ id: "goback" })}
                      fontSize="12px"
                    />
                  </BadgeContainer>
                  <BadgeContainer
                    onClick={() => history.push("/admin/categories/new")}
                  >
                    <Badge
                      color="#002244"
                      text={intl.formatMessage({
                        id: "add_categories",
                      })}
                      fontSize="12px"
                    />
                  </BadgeContainer>
                </ActionContainer>
              </GoBackContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal>
                <InputWithLabel
                  name="title"
                  placeholder={intl.formatMessage({ id: "title" })}
                  label={intl.formatMessage({ id: "title" })}
                  validationBlur={validationBlur}
                  labelWidth="150px"
                />
                <SelectWithLabel
                  name="credential"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "credential_type" })}
                  placeholder={intl.formatMessage({ id: "credential_type" })}
                  labelOrientation="vertical"
                  options={statusOptions}
                />
              </Horizontal>
              <Horizontal>
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="search" />
                    </FormButton>
                  </SubmitContainer>
                </FilterActionsContainer>
              </Horizontal>
            </FieldsContainer>
          </Form>
        </FormContainer>
      </Container>
      {filtered ? (
        <>
          <Table
            columns={columns}
            data={data}
            title={intl.formatMessage({ id: "results" })}
            progressPending={loading}
            progressComponent={
              <Loader type="Oval" color="#00BFFF" height={100} width={100} />
            }
          />
        </>
      ) : (
        <NotificationContainer>
          <FormattedMessage id="need_to_filter_first" />
        </NotificationContainer>
      )}
    </AnimatedMainContainer>
  );
};

export default AdditionalProduct;
