import React, { useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useToast } from "../../../hooks/toast";
import * as Yup from "yup";
import api from "../../../service";
import { useCallback } from "react";
import InputWithLabel from "../../../components/InputWithLabel";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";

import {
  FormContainer,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
} from "./styles";
import Modal from "../../../components/Modal";
import FormButton from "../../../components/FormButton";
import DatePickerWithHorizontalLabel from "../../../components/DatePickerWithLabel";
import getValidationErrors from "../../../utils/getValidationErrors";
import { useLoading } from "../../../hooks/loading";
import printJS from "print-js";

const PrintModal = ({
  setIsOpen,
  modalIsOpen,
  credentialsId,
  updateCallback,
}) => {
  const { token } = useSelector((state) => state.auth);
  const formRef = useRef(null);
  const intl = useIntl();
  const { addToast } = useToast();
  const { handleLoading } = useLoading();

  const schema = Yup.object().shape({
    signed_by: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    receptionist: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    date: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
  });

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const handlePrint = useCallback(
    async (data) => {
      try {
        let printIds = credentialsId;
        formRef.current?.setErrors({});

        await schema.validate(data, { abortEarly: false });

        handleLoading(true);

        api
          .patch(
            "/credential-reports/protocol",
            {
              nameOfSignature: data.signed_by,
              nameOfStaff: data.receptionist,
              collectDate: data.date,
              credentialsId: printIds,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(async (result) => {
            const output = result.data.reportOutput;

            updateCallback();

            printJS({
              printable: output.file,
              type: "pdf",
              base64: true,
            });

            setIsOpen(false);
          })
          .catch((err) => {
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          })
          .finally(() => {
            handleLoading(false);
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [
      credentialsId,
      addToast,
      intl,
      token,
      handleLoading,
      schema,
      setIsOpen,
      updateCallback,
    ]
  );

  return (
    <Modal setIsOpen={setIsOpen} modalIsOpen={modalIsOpen} modalWidth="50%">
      <FormContainer>
        <Form ref={formRef} onSubmit={handlePrint}>
          <FieldsContainer>
            <InputWithLabel
              name="signed_by"
              validationBlur={validationBlur}
              label={intl.formatMessage({ id: "signed_by" })}
              required
            />

            <InputWithLabel
              name="receptionist"
              validationBlur={validationBlur}
              label={intl.formatMessage({ id: "receptionist" })}
              required
            />

            <DatePickerWithHorizontalLabel
              name="date"
              validationBlur={validationBlur}
              label={intl.formatMessage({ id: "date" })}
              labelOrientation="vertical"
              required
            />
            <Horizontal>
              <FilterActionsContainer>
                <SubmitContainer>
                  <FormButton
                    color="#C9C9C9"
                    type="button"
                    onClick={() => setIsOpen(false)}
                  >
                    <FormattedMessage id="close" />
                  </FormButton>
                </SubmitContainer>
                <SubmitContainer>
                  <FormButton type="submit">
                    <FormattedMessage id="save" />
                  </FormButton>
                </SubmitContainer>
              </FilterActionsContainer>
            </Horizontal>
          </FieldsContainer>
        </Form>
      </FormContainer>
    </Modal>
  );
};

export default PrintModal;
