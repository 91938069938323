import React from "react";

import { Container } from "./styles";
import Loader from "react-loader-spinner";

const FormButton = ({
  children,
  loading,
  height,
  roundedButton,
  color,
  ...rest
}) => {
  return (
    <Container
      height={height}
      roundedButton={roundedButton}
      color={color}
      {...rest}
    >
      {loading ? <Loader type="Oval" color="white" height="20px" /> : children}
    </Container>
  );
};

export default FormButton;
