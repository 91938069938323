import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "../../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationErrors";
import api from "../../../service";
import { useCallback } from "react";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";

import {
  FormContainer,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
} from "./styles";
import SelectWithLabel from "../../../components/SelectWithLabel";
import Modal from "../../../components/Modal";
import FormButton from "../../../components/FormButton";
import { useLoading } from "../../../hooks/loading";
import { authSuccess } from "../../../redux/modules/auth/actions";
import { changeApplicationLanguage } from "../../../redux/modules/language/actions";

const SelectYourAccountModal = ({ setIsOpen, modalIsOpen }) => {
  const { token } = useSelector((state) => state.auth);
  const [key, setKey] = useState();
  const formRef = useRef(null);
  const intl = useIntl();
  const { addToast } = useToast();
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const dispatch = useDispatch();

  const schema = Yup.object().shape({
    account: Yup.string().nullable(),
  });

  useEffect(() => {
    handleLoading(true);
    if (loading) {
      return;
    }
    api
      .get("/provider-association/service-provider-accounts", {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setKey(new Date());
        setAccounts(result.data.serviceProviderAccountsAssociated);
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, intl, token, handleLoading, loading]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        handleLoading(true);
        setLoading(true);
        if (loading) {
          return;
        }

        await schema.validate(data, { abortEarly: false });

        api
          .post(
            "/auth/signin-as-an-exhibitor",
            { accountIdNumber: data.account },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            dispatch(
              authSuccess({
                user: result.data.user,
                token: result.data.token,
                customerCenterToken: result.data.customerCenterToken,
                accountCreated: result.data.accountCreated,
                keepSignedIn: true,
                serviceProviderLoggingAsAnExhibitor:
                  result.data.serviceProviderLoggingAsAnExhibitor,
              })
            );
            dispatch(
              changeApplicationLanguage({
                code: result.data.user.language,
              })
            );
          })
          .catch((err) => {
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          })
          .finally(() => {
            handleLoading(false);
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, intl, schema, token, loading, handleLoading, dispatch]
  );

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  return (
    <Modal
      setIsOpen={setIsOpen}
      modalIsOpen={modalIsOpen}
      modalWidth="50%"
      dontCloseByClick={true}
    >
      <FormContainer>
        <Form ref={formRef} onSubmit={handleSubmit} key={key}>
          <FieldsContainer>
            <SelectWithLabel
              name="account"
              options={accounts.map((account) => {
                return {
                  value: account.idNumber,
                  label: account.accountName,
                };
              })}
              validationBlur={validationBlur}
              label={intl.formatMessage({ id: "account" })}
              placeholder={intl.formatMessage({
                id: "select_which_account_to_login",
              })}
            />
            <Horizontal>
              <FilterActionsContainer>
                <SubmitContainer>
                  <FormButton type="submit" loading={loading}>
                    <FormattedMessage id="login" />
                  </FormButton>
                </SubmitContainer>
              </FilterActionsContainer>
            </Horizontal>
          </FieldsContainer>
        </Form>
      </FormContainer>
    </Modal>
  );
};

export default SelectYourAccountModal;
