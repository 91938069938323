import * as Yup from "yup";

export function validateSingleFieldOnBlur(errors, schema, name, formRef) {
  schema
    .validateAt(name, formRef.current.getData())
    .then(() => {
      formRef.current?.setErrors({
        ...errors,
        [name]: undefined,
      });
    })
    .catch((err) => {
      if (err instanceof Yup.ValidationError) {
        formRef.current?.setErrors({
          ...errors,
          [name]: err.errors[0],
        });
      }
    });
}
