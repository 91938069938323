import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const NavContainer = styled.nav`
  display: flex;
  justify-content: flex-end;
`;

export const Container = styled.div`
  display: flex;
`;

export const Content = styled.div`
  display: flex;
  margin-top: 72px;
  flex: 1;
  background-color: #dadada;
`;

export const BarsContainer = styled.div`
  /* display: flex;
  width: 100%;
  flex-direction: row; */
`;

export const DivFlex = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

export const Card = styled.div`
  width: 200px;
  height: 100px;
  margin: 20px 0 40px 20px;
  padding: 16px 47px 47px 0px;
  border-radius: 8px;
  ${(props) =>
    props.color
      ? css`
          background: ${props.color};
        `
      : css`
          background: #002244;
        `}
`;

export const TitleCard = styled.div`
  width: 200px;
  height: 30px;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  text-transform: uppercase;
  line-height: 0.67;
  letter-spacing: normal;
  text-align: center;
  color: white;
`;

export const TotalQuantityOfCard = styled.div`
  width: 200px;
  height: 20px;
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.67;
  letter-spacing: normal;
  text-align: center;
  color: white;
`;

export const ButtonContainer = styled.div`
  width: 15%;
  @media (max-width: 1140px) {
    width: 40%;
  }
  @media (max-width: 750px) {
    width: 95%;
    padding-bottom: 10px;
  }
`;

export const LinkButton = styled(Link)`
  text-decoration: none;
`;

export const TableContainer = styled.div`
  width: 95%;
  padding-left: 5%;
  padding-bottom: 20px;
  @media (max-width: 900px) {
    padding-left: 0;
  }
`;

export const TitleContainer = styled.div`
  height: 40%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  align-items: center;
  padding-right: 5%;
  padding-left: 5%;
  padding-bottom: 20px;
  @media (max-width: 900px) {
    width: 95%;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 20px;
  }
  @media (max-width: 750px) {
    width: 100%;
  }
`;

export const TitleTable = styled.div`
  width: 30%;
  @media (max-width: 900px) {
    width: 40%;
  }
  @media (max-width: 750px) {
    width: 100%;
  }
`;

export const LinkButtonSalesForce = styled.a`
  text-decoration: none;
`;
