import React, { createContext, useContext, useCallback, useState } from "react";

const LoadingContext = createContext({});

const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleLoading = useCallback((loading) => {
    setIsLoading(loading);
  }, []);

  return (
    <LoadingContext.Provider value={{ isLoading, handleLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};

const useLoading = () => {
  const context = useContext(LoadingContext);

  if (!context) {
    throw new Error("useLoading must be used within a LoadingProvider");
  }

  return context;
};

export { LoadingProvider, useLoading };
