import styled, { css } from "styled-components";
import { shade } from "polished";

export const Container = styled.button`
  ${(props) =>
    props.color
      ? css`
          background: ${props.color};
        `
      : css`
          background: #002244;
        `}

  color: #fff;

  ${(props) =>
    props.roundedButton
      ? css`
          border-radius: 24px;
        `
      : css`
          border-radius: 5px;
        `}

  border: 0;
  padding: 0 16px;
  ${(props) =>
    props.marginTop
      ? css`
          margin-top: ${props.marginTop};
        `
      : css`
          margin-top: 24px;
        `}
  width: 100%;

  ${(props) =>
    props.height
      ? css`
          height: ${props.height};
        `
      : css`
          height: 32px;
        `}
  ${(props) =>
    props.fontSize
      ? css`
          font-size: ${props.fontSize};
        `
      : css`
          font-size: 16px;
        `}

  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  transition: background-color 0.2s;
  text-transform: uppercase;
  &:hover {
    cursor: pointer;
    ${(props) =>
      props.color
        ? css`
            background: ${shade(0.2, props.color)};
          `
        : css`
            background: ${shade(0.2, "#002244")};
          `}
  }
`;
