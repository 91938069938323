import React, { createContext, useContext, useCallback, useState } from "react";

const SidebarContext = createContext({});

const SidebarProvider = ({ children }) => {
  const [sidebarIsVisible, setSidebarIsVisible] = useState(true);

  const toogleSidebar = useCallback(() => {
    setSidebarIsVisible(!sidebarIsVisible);
  }, [sidebarIsVisible]);

  return (
    <SidebarContext.Provider value={{ sidebarIsVisible, toogleSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};

const useSidebar = () => {
  const context = useContext(SidebarContext);

  if (!context) {
    throw new Error("useSidebar must be used within a SidebarProvider");
  }

  return context;
};

export { SidebarProvider, useSidebar };
