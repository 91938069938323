import { useState } from "react";
import { Collapse } from "react-collapse";
import { BsSquare } from "react-icons/bs";
import { FaCheckSquare } from "react-icons/fa";
import { BsChevronUp, BsChevronDown } from "react-icons/bs";
import { useIntl } from "react-intl";
import { SubitemCheckbox, SubitemsContainer } from "../../../styles";
import {
  CollapseContainer,
  CollapseToggle,
  ItemContainer,
  PrincipalItem,
  ItemCheckbox,
  ItemTitle,
  ItemArrow,
} from "./styles";

const ItemCollapse = ({ element }) => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const intl = useIntl();

  const hasElements = element.productsList.length > 0;

  const toggleCollapse = () => {
    setCollapseOpen(!collapseOpen);
  };

  return (
    <CollapseContainer>
      <CollapseToggle onClick={toggleCollapse} hasElements={hasElements}>
        <ItemContainer>
          <PrincipalItem>
            <ItemCheckbox>
              {hasElements ? (
                <FaCheckSquare size={20} />
              ) : (
                <BsSquare size={20} />
              )}
            </ItemCheckbox>
            <ItemTitle>{intl.formatMessage({ id: element.name })}</ItemTitle>
            {hasElements && (
              <ItemArrow>
                {collapseOpen ? <BsChevronDown /> : <BsChevronUp />}
              </ItemArrow>
            )}
          </PrincipalItem>
        </ItemContainer>
      </CollapseToggle>
      <Collapse isOpened={collapseOpen}>
        <div
          style={{
            display: "flex",
            backgroundColor: "#fff",
            flex: 1,
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <SubitemsContainer>
            {element.productsList.map((product, index) => {
              return (
                <SubitemCheckbox key={index}>
                  <p style={{ marginLeft: "8px" }}>
                    {product.portugueseName
                      ? product.portugueseName
                      : product.name}
                  </p>
                </SubitemCheckbox>
              );
            })}
          </SubitemsContainer>
        </div>
      </Collapse>
    </CollapseContainer>
  );
};

export default ItemCollapse;
