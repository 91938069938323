import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import { useToast } from "../../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationErrors";
import api from "../../../service";
import { useCallback } from "react";
import InputWithLabel from "../../../components/InputWithLabel";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";
import ConfirmationModal from "../../../components/ConfirmationModal";
import FormButton from "../../../components/FormButton";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  LinkButton,
  BadgeContainer,
  ActionContainer,
  SubmitContainer,
  FilterActionsContainer,
  NotificationContainer,
} from "./styles";
import SelectWithLabel from "../../../components/SelectWithLabel";
import Table from "../../../components/Table";
import Badge from "../../../components/Badge";
import { useLoading } from "../../../hooks/loading";
import provisionTypesConstants from "../../../constants/provisionTypes";

const ServiceProviderAssociation = () => {
  const { token } = useSelector((state) => state.auth);
  const [allEvents, setAllEvents] = useState([]);
  const [data, setData] = useState([]);
  const [associationToDelete, setAssociationToDelete] = useState();
  const [associationToUpdate, setAssociationToUpdate] = useState();
  const [deleteAssociationModalOpen, setDeleteAssociationModalOpen] =
    useState(false);
  const [associationToUpdateModalOpen, setAssociationToUpdateModalOpen] =
    useState(false);
  const [opportunities, setOpportunities] = useState([]);
  const [event, setEvent] = useState();
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);
  const [filtered, setFiltered] = useState(false);

  const provisionTypes = provisionTypesConstants();

  const schema = Yup.object().shape({
    event: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    contract: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    service_type: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    service_provider: Yup.string().nullable(),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        await schema.validate(data, { abortEarly: false });

        setLoading(true);
        if (loading) {
          return;
        }

        api
          .patch(
            "/provider-association",
            {
              eventId: data.event,
              opportunityId: data.contract,
              serviceTypeId: data.service_type,
              serviceProviderName: data.service_provider,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            setData(result.data.output);
          })
          .catch((err) => {
            setData([]);
          })
          .finally(() => {
            setLoading(false);
            setFiltered(true);
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [loading, token, schema]
  );

  const deleteAssociation = () => {
    try {
      handleLoading(true);
      api
        .delete(`/provider-association/${associationToDelete}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((_) => {
          setDeleteAssociationModalOpen(false);
          setData([...data.filter((x) => x.id !== associationToDelete)]);
          addToast({
            type: "success",
            title: intl.formatMessage({
              id: "success",
            }),
            description: intl.formatMessage({
              id: "success.delete_association",
            }),
          });
        })
        .catch((err) => {
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
          setDeleteAssociationModalOpen(false);
        })
        .finally(() => {
          handleLoading(false);
        });
    } catch (err) {
      handleLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        setDeleteAssociationModalOpen(false);

        formRef.current?.setErrors(errors);

        return;
      }
    }
  };

  const updatePermissionOfAssociation = () => {
    try {
      handleLoading(true);
      api
        .put(
          `/provider-association/${associationToUpdate.id}/update-manage-permission`,
          { allowManageAccount: !associationToUpdate.currentStatus },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((_) => {
          addToast({
            type: "success",
            title: intl.formatMessage({
              id: "success",
            }),
            description: intl.formatMessage({
              id: "success.update_association_permission",
            }),
          });
          formRef.current.submitForm();
        })
        .catch((err) => {
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
        })
        .finally(() => {
          handleLoading(false);
          setAssociationToUpdateModalOpen(false);
        });
    } catch (err) {
      handleLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        setDeleteAssociationModalOpen(false);

        formRef.current?.setErrors(errors);

        return;
      }
    }
  };

  const getEvents = useCallback(() => {
    handleLoading(true);
    api
      .get("/provider-association/events", {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        const events = result.data.output.map((event) => ({
          label: event.eventEditionName,
          value: event.idNumber,
        }));
        let eventsSort = [...events].sort((a, b) =>
          a.label < b.label ? -1 : a.label > b.label ? 1 : 0
        );
        setAllEvents(eventsSort);
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, intl, token, handleLoading]);

  const getOpportunities = useCallback(
    (event) => {
      handleLoading(true);
      api
        .get(`/provider-association/opportunities/${event}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          setOpportunities(
            result.data.output.map((x) => ({ label: x.name, value: x.id }))
          );
        })
        .catch((err) => {
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
        })
        .finally(() => {
          handleLoading(false);
        });
    },
    [addToast, intl, token, handleLoading]
  );

  useEffect(() => {
    getEvents();
  }, [getEvents]);

  useEffect(() => {
    if (event) getOpportunities(event);
  }, [event, getOpportunities]);

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const openDeleteModal = (id) => {
    setAssociationToDelete(id);
    setDeleteAssociationModalOpen(true);
  };

  const openUpdatePermissionModal = (id, currentStatus) => {
    setAssociationToUpdate({ id, currentStatus });
    setAssociationToUpdateModalOpen(true);
  };

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("event", {
      label: intl.formatMessage({ id: "event_edition" }),
      value: null,
    });
    formRef.current.setFieldValue("contract", {
      label: intl.formatMessage({ id: "contract" }),
      value: null,
    });
    formRef.current.setFieldValue("service_type", {
      label: intl.formatMessage({ id: "service_type" }),
      value: null,
    });
    setData([]);
    setFiltered(false);
  };

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "event_edition" }),
        selector: (row) => row.event,
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "contract" }),
        selector: (row) => row.opportunity,
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "type" }),
        selector: (row) => row.type,
        cell: (row) =>
          provisionTypes.filter((pt) => pt.value === row.type)[0].label,
      },
      {
        name: intl.formatMessage({ id: "service_provider" }),
        selector: (row) => row.serviceProviderName,
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "document" }),
        selector: (row) => row.document,
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "allow_login" }),
        cell: (row) =>
          row.allowManageAccount
            ? intl.formatMessage({ id: "yes" })
            : intl.formatMessage({ id: "no" }),
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "action" }),
        cell: (row) => (
          <>
            <Badge
              onClick={() => openDeleteModal(row.id)}
              color="#cc0000"
              text={intl.formatMessage({ id: "delete" })}
              fontSize="12px"
            />
            <Badge
              onClick={() => {
                openUpdatePermissionModal(row.id, row.allowManageAccount);
              }}
              color="#cc0000"
              text={intl.formatMessage({ id: "change_login_permission" })}
            />
          </>
        ),
        minWidth: "410px",
      },
    ],
    [intl, provisionTypes]
  );

  return (
    <>
      <AnimatedMainContainer
        breadcrumb={intl.formatMessage({ id: "breadcrumb.association" })}
      >
        <Container>
          <FormContainer>
            <TitleContainer>
              <Title>
                <FormattedMessage id="search_for_association" />
              </Title>
              <ActionContainer>
                <LinkButton onClick={() => history.push("/dashboard")}>
                  <Badge
                    color="#C9C9C9"
                    text={intl.formatMessage({ id: "goback" })}
                    fontSize="12px"
                  />
                </LinkButton>
                <BadgeContainer
                  onClick={() =>
                    history.push("/serviceprovider-association/new")
                  }
                >
                  <Badge
                    color="#002244"
                    text={intl.formatMessage({ id: "create_association" })}
                    fontSize="12px"
                  />
                </BadgeContainer>
              </ActionContainer>
            </TitleContainer>
            <FieldsContainer>
              <Form
                ref={formRef}
                onSubmit={handleSubmit}
                initialData={{
                  type: {
                    label: intl.formatMessage({ id: "all" }),
                    value: "ALL",
                  },
                  status: {
                    label: intl.formatMessage({ id: "both" }),
                    value: null,
                  },
                }}
              >
                <Horizontal>
                  <SelectWithLabel
                    name="event"
                    options={allEvents}
                    validationBlur={validationBlur}
                    label={intl.formatMessage({ id: "event_edition" })}
                    placeholder={intl.formatMessage({ id: "event_edition" })}
                    onChange={(e) => setEvent(e.value)}
                    required
                  />

                  <SelectWithLabel
                    name="contract"
                    options={opportunities}
                    validationBlur={validationBlur}
                    label={intl.formatMessage({ id: "contract" })}
                    placeholder={intl.formatMessage({ id: "contract" })}
                    required
                  />
                </Horizontal>
                <Horizontal>
                  <SelectWithLabel
                    name="service_type"
                    options={provisionTypes}
                    validationBlur={validationBlur}
                    label={intl.formatMessage({ id: "service_type" })}
                    placeholder={intl.formatMessage({ id: "service_type" })}
                    required
                  />
                  <InputWithLabel
                    name="service_provider"
                    validationBlur={validationBlur}
                    label={intl.formatMessage({ id: "service_provider" })}
                  />
                </Horizontal>
                <Horizontal>
                  <FilterActionsContainer>
                    <SubmitContainer>
                      <FormButton
                        color="#C9C9C9"
                        type="button"
                        onClick={clearFields}
                      >
                        <FormattedMessage id="clear_fields" />
                      </FormButton>
                    </SubmitContainer>
                    <SubmitContainer>
                      <FormButton type="submit" loading={loading}>
                        <FormattedMessage id="search" />
                      </FormButton>
                    </SubmitContainer>
                  </FilterActionsContainer>
                </Horizontal>
              </Form>
            </FieldsContainer>
          </FormContainer>
        </Container>
        {filtered ? (
          <Table
            columns={columns}
            data={data}
            title={intl.formatMessage({ id: "results" })}
            hasPagination
          />
        ) : (
          <NotificationContainer>
            <FormattedMessage id="need_to_filter_first" />
          </NotificationContainer>
        )}
      </AnimatedMainContainer>
      <ConfirmationModal
        modalIsOpen={deleteAssociationModalOpen}
        setIsOpen={setDeleteAssociationModalOpen}
        confirmationFunction={deleteAssociation}
        description={intl.formatMessage({ id: "delete_association" })}
        confirmationText={intl.formatMessage({ id: "confirm" })}
        cancelText={intl.formatMessage({ id: "cancel" })}
      />
      <ConfirmationModal
        modalIsOpen={associationToUpdateModalOpen}
        setIsOpen={setAssociationToUpdateModalOpen}
        confirmationFunction={updatePermissionOfAssociation}
        description={intl.formatMessage({
          id: "update_association_permission",
        })}
        confirmationText={intl.formatMessage({ id: "confirm" })}
        cancelText={intl.formatMessage({ id: "cancel" })}
      />
    </>
  );
};

export default ServiceProviderAssociation;
