import React, { useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";

import { MdPerson, MdLock } from "react-icons/md";

import { Form } from "@unform/web";
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationErrors";

import { useDispatch } from "react-redux";
import { authRequest } from "../../../redux/modules/auth/actions";

import { FormattedMessage, useIntl } from "react-intl";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";

import { Container, Content, AnimatedContainer, Logo } from "./styles";

import Input from "../../../components/Input";
import FormButton from "../../../components/FormButton";

import logoImg from "../../../assets/images/logo.png";

import CheckboxInput from "../../../components/CheckboxInput";
import BackgroundSlogan from "../../../components/BackgroundSlogan";
import { useLoading } from "../../../hooks/loading";

const SignInForm = () => {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const intl = useIntl();

  const history = useHistory();
  const { handleLoading } = useLoading();

  const checkboxOptions = [
    {
      id: "keep_signed_in",
      value: "1",
      label: intl.formatMessage({
        defaultMessage: "Manter-me conectado",
        id: "keep_signed_in",
      }),
    },
  ];

  const schema = Yup.object().shape({
    email: Yup.string()
      .required(
        intl.formatMessage({
          defaultMessage: "O e-mail é obrigatório",
          id: "error.email_required",
        })
      )
      .email(
        intl.formatMessage({
          defaultMessage: "Digite um e-mail válido",
          id: "error.valid_email",
        })
      ),
    password: Yup.string()
      .required(
        intl.formatMessage({
          defaultMessage: "A senha é obrigatória",
          id: "error.password_required",
        })
      )
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>"'`~´]){1})(?=.*\d)((?=.*[a-zA-Z]){1}).*$/,
        intl.formatMessage({
          defaultMessage:
            "A senha precisa ter pelo menos 8 caracteres, sendo um caractere especial, letras e números.",
          id: "error.password_match",
        })
      ),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        handleLoading(true);
        formRef.current?.setErrors({});

        await schema.validate(data, { abortEarly: false });
        dispatch(
          authRequest({
            email: data.email,
            password: data.password,
            keepSignedIn: !!data?.checkbox[0],
            callbackLoading: handleLoading,
          })
        );
      } catch (err) {
        handleLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [dispatch, schema, handleLoading]
  );

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  return (
    <Container>
      <BackgroundSlogan />
      <Content>
        <AnimatedContainer>
          <Logo src={logoImg} alt="InformaMarket" />
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input
              name="email"
              icon={MdPerson}
              validationBlur={validationBlur}
              placeholder="E-mail"
            />
            <Input
              name="password"
              icon={MdLock}
              placeholder={intl.formatMessage({
                defaultMessage: "Senha",
                id: "password",
              })}
              validationBlur={validationBlur}
              type="password"
            />
            <CheckboxInput name="checkbox" options={checkboxOptions} />

            <FormButton type="submit" height="52px" roundedButton>
              <FormattedMessage
                id="signin"
                defaultMessage="Entrar"
                description="Entrar"
              />
            </FormButton>

            <FormButton
              type="button"
              onClick={() => history.push("/")}
              color="#C9C9C9"
              height="52px"
              roundedButton
            >
              <FormattedMessage id="goback" />
            </FormButton>

            {/* <Link to="/forgot-password">
              <FormattedMessage
                id="login.forgot_password"
                defaultMessage="Esqueceu a senha?"
                description="Link de esquecimento de senha"
              />
            </Link> */}
          </Form>

          {/* <CreateAccountContainer>
            <CreateAccountText>
              <FormattedMessage
                id="login.not_a_member"
                defaultMessage="Não é cadastrado ainda?"
                description="Link de criação de conta"
              />
              &nbsp;
            </CreateAccountText>
            <Link to="/register">
              <FormattedMessage
                id="login.create_account"
                defaultMessage="Crie uma conta"
                description="Link de criação de conta"
              />
            </Link>
          </CreateAccountContainer>
          <Divider>
            <hr />
            <FormattedMessage id="or" defaultMessage="Ou" description="Ou" />
            <hr />
          </Divider>
          <AlternativeSignInContainer>
            <AlternativeSignInLogo src={facebookLogo} />
            <AlternativeSignInLogo src={linkedinLogo} />
          </AlternativeSignInContainer> */}
        </AnimatedContainer>
      </Content>
    </Container>
  );
};

export default SignInForm;
