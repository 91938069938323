import { combineReducers } from "redux";
import auth from "./auth/reducer";
import alerts from "./alerts/reducer";
import language from "./language/reducer";
import notification from "./notification/reducer";

export default combineReducers({
  auth,
  alerts,
  language,
  notification,
});
