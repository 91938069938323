import styled from "styled-components";

export const CollapseToggle = styled.div`
  font-size: 14px;
  font-weight: normal;
  padding: 4px 16px;
  display: flex;
  align-items: center;
  cursor: ${({ hasElements }) => (hasElements ? "pointer" : "auto")};
  font-size: 13px;
`;

export const CollapseContainer = styled.div`
  display: flex;
  border: 1px solid #ccc;
  padding: 8px;
  margin-bottom: 16px;
  flex-direction: column;
  flex-wrap: wrap;
`;

export const ItemContainer = styled.div`
  flex: 1;
  display: flex;
  margin: 16px 0px;
`;

export const PrincipalItem = styled.div`
  width: 100%;
  display: flex;
  margin-left: 8px;
  padding: 8px 0px;
  justify-content: space-between;
  > * {
    display: flex;
    align-items: center;
  }
  > div {
    justify-content: center;
    > svg {
      min-width: 20px;
      color: #002244;
    }
  }
`;

export const ItemCheckbox = styled.div`
  flex: 0 0 20px;
`;

export const ItemTitle = styled.p`
  margin-left: 8px;
  flex: 1;
  white-space: nowrap;
`;

export const ItemArrow = styled.div`
  flex: 0 0 20px;
`;
