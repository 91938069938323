import styled, { css } from "styled-components";

export const TableTitleContainer = styled.div`
  font-size: 12px;
  font-weight: 600;
  padding: 16px 0px 16px 24px;

  ${(props) =>
    props.titleMarginLeft &&
    css`
      padding-left: ${props.titleMarginLeft};
    `}
`;

export const TableTitle = styled.p`
  font-size: 14px;
`;

export const TableContainer = styled.div`
  width: 100%;
  background-color: #fff;
  ${(props) =>
    props.marginTop &&
    css`
      margin-top: ${props.marginTop};
    `}

  ${(props) =>
    props.paddingAround &&
    css`
      padding: ${props.paddingAround};
    `}
`;

export const NoDataText = styled.p`
  margin: 16px 0px 32px 0px;
`;
