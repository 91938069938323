import React, { useCallback, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Form } from "@unform/web";
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationErrors";

import { useSelector } from "react-redux";

import { FormattedMessage, useIntl } from "react-intl";

import {
  Container,
  TitleContainer,
  Title,
  Session,
  FieldsContainer,
  Horizontal,
  ActionContainer,
  BadgeContainer,
} from "./styles";

import { useToast } from "../../../hooks/toast";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";
import api from "../../../service";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import InputWithHorizontalLabel from "../../../components/InputWithHorizontalLabel";
import DatePickerWithLabel from "../../../components/DatePickerWithLabel";
import Badge from "../../../components/Badge";
import { useEffect } from "react";
import { useLoading } from "../../../hooks/loading";
import { parse } from "date-fns";

const EditStaffAccess = () => {
  const formRef = useRef(null);
  const intl = useIntl();
  const { addToast } = useToast();
  const { token } = useSelector((state) => state.auth);
  const { id } = useParams();

  const [data, setData] = useState();
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const schema = Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    email: Yup.string()
      .required(intl.formatMessage({ id: "error.field_required" }))
      .email(
        intl.formatMessage({
          defaultMessage: "Digite um e-mail válido",
          id: "error.valid_email",
        })
      ),
    startDate: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    finalDate: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
  });

  useEffect(() => {
    handleLoading(true);
    api
      .get(`/staff/access/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const { startDate, finalDate, ...output } =
          response.data.staffAccessOutput;

        setData({
          ...output,
          startDate: startDate
            ? parse(startDate, "yyyy-MM-dd", new Date())
            : "",
          finalDate: finalDate
            ? parse(finalDate, "yyyy-MM-dd", new Date())
            : "",
        });
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => handleLoading(false));
  }, [addToast, id, intl, token, handleLoading]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) {
          return;
        }

        await schema.validate(data, { abortEarly: false });

        api
          .put(
            `/staff/access/${id}`,
            {
              name: data.name,
              email: data.email,
              startDate: data.startDate,
              finalDate: data.finalDate,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.staff_access_update",
              }),
            });
            history.push("/staff-access");
          })
          .catch((err) =>
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            })
          )
          .finally(() => setLoading(false));
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [addToast, history, id, intl, loading, schema, token]
  );

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  return (
    <AnimatedMainContainer>
      <Container>
        <Form
          ref={formRef}
          initialData={{
            name: data?.name,
            email: data?.email,
            startDate: data?.startDate,
            finalDate: data?.finalDate,
          }}
          onSubmit={handleSubmit}
        >
          <TitleContainer>
            <Title>
              <FormattedMessage id="editing_staff_access" />
            </Title>
            <ActionContainer>
              <BadgeContainer onClick={() => history.push("/staff-access")}>
                <Badge
                  color="#C9C9C9"
                  text={intl.formatMessage({ id: "goback" })}
                  fontSize="12px"
                />
              </BadgeContainer>
              <BadgeContainer onClick={() => formRef.current.submitForm()}>
                <Badge
                  loading={loading}
                  color="#002244"
                  text={intl.formatMessage({ id: "save" })}
                  fontSize="12px"
                />
              </BadgeContainer>
            </ActionContainer>
          </TitleContainer>
          <Session>
            <FieldsContainer>
              <Horizontal>
                <InputWithHorizontalLabel
                  name="name"
                  placeholder={intl.formatMessage({ id: "name" })}
                  label={intl.formatMessage({ id: "name" })}
                  validationBlur={validationBlur}
                  labelWidth="100px"
                  disabled
                  required
                />
                <InputWithHorizontalLabel
                  name="email"
                  placeholder="E-mail"
                  label="E-mail"
                  validationBlur={validationBlur}
                  labelWidth="100px"
                  disabled
                  required
                />
              </Horizontal>
              <Horizontal>
                <DatePickerWithLabel
                  name="startDate"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "start_date" })}
                  labelWidth="100px"
                  required
                />
                <DatePickerWithLabel
                  name="finalDate"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "final_date" })}
                  labelWidth="100px"
                  required
                />
              </Horizontal>
            </FieldsContainer>
          </Session>
        </Form>
      </Container>
    </AnimatedMainContainer>
  );
};

export default EditStaffAccess;
