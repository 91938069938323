import React from "react";

import { Background, BackgroundText } from "./styles";

import { FormattedMessage } from "react-intl";

const BackgroundSlogan = ({ ...rest }) => (
  <Background {...rest}>
    <BackgroundText>
      <FormattedMessage
        id="slogan"
        defaultMessage="Criando plataformas globais para indústrias, mercados especializados e clientes para se conectar, fazer negócios, inovar e crescer."
        description="Slogan"
      />
    </BackgroundText>
  </Background>
);

export default BackgroundSlogan;
