import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 16px;
  background-color: #fff;
`;

export const TableContainer = styled.div`
  width: 100%;
  background-color: #fff;
  margin-top: 32px;
`;

export const FormContainer = styled.div`
  form {
    display: flex;
    flex-direction: column;
  }
`;

export const Title = styled.h1`
  font-size: 14px;
  font-weight: normal;
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 21px;
  padding-left: 8px;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
`;

export const FieldsContainer = styled.div`
  margin-top: 16px;
`;

export const Horizontal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  & > div {
    display: flex;
    flex: 1 0 100px;
    margin-left: 16px;
    @media (max-width: 500px) {
      margin-left: 0px;
      flex: 1 1 auto;
      width: 100%;
    }
  }

  & > div:first-child {
    margin-left: 0px;
  }
`;

export const HorizontalMarketParticipation = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  & > div {
    display: flex;
    margin: 4px 8px;
    flex: 1;
    @media (max-width: 500px) {
      margin-left: 0px;
      flex: 1 1 auto;
      width: 100%;
    }
  }

  & > div:first-child {
    margin: 4px 8px;
  }
`;

export const HorizontalJourney = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  & > div {
    display: flex;
    flex: 1 0 100px;
    margin-left: 16px;
    @media (max-width: 500px) {
      margin-left: 0px;
      flex: 1 1 auto;
      width: 100%;
    }
  }

  & > div:first-child {
    flex: 0.2 0 100px;
    margin-left: 0px;
  }
`;

export const FilterActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  flex-wrap: wrap;

  & > div:first-child {
    margin-left: 0px;
  }

  @media (max-width: 500px) {
    width: 100%;
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 16px;
    @media (max-width: 500px) {
      margin-left: 0px;
    }
  }
`;

export const SubmitContainer = styled.div`
  width: 100%;
  max-width: 150px;

  @media (max-width: 500px) {
    margin-left: 0px;
    max-width: none;
    width: 100%;
  }
`;

export const TableTitleContainer = styled.div`
  padding: 16px 0px 16px 24px;
`;

export const TableTitle = styled.p``;

export const LinkButton = styled(Link)`
  text-decoration: none;
`;

export const Session = styled.div``;

export const SessionDivider = styled.div`
  display: flex;
  @media (max-width: 720px) {
    flex-direction: column;
  }
  ${(props) =>
    props.nr_items === "2" &&
    css`
      & > div {
        flex: 0.5;
        @media (max-width: 720px) {
          flex: 1 1 auto;
        }
      }
      & > div:nth-child(2) {
        margin-left: 32px;
        @media (max-width: 720px) {
          margin-left: 0px;
        }
      }
    `}
`;

export const SessionContainer = styled.div`
  border-bottom: 1px solid #dadada;
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  flex: 1;
  margin: 0px 8px;
`;

export const SessionTitle = styled.h3`
  font-size: 14px;
  font-weight: bold;
  color: #002244;
  text-transform: uppercase;
`;

export const SessionIconContainer = styled.div`
  display: flex;
`;

export const ContainerDivider = styled.div`
  margin: 8px 0px;
`;

export const PrincipalDoneItem = styled.div`
  display: flex;
  margin-left: 8px;
  padding: 8px 0px;
`;

export const SubitemsContainer = styled.div`
  display: flex;
  margin-left: 40px;
  flex-direction: column;
`;

export const ItemGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0px;
`;

export const SubitemCheckbox = styled.div`
  padding: 8px 0px;
  display: flex;
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  /* justify-content: center; */
  width: 200px;
  height: 200px;
  /* flex-direction: column; */
`;

export const TimelineContainer = styled.div`
  display: flex;
`;

export const GraphicContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  overflow-x: auto;
  justify-content: space-around;
  & > div {
    padding: 0px 10px;
  }
`;

export const Graphic = styled.div`
  flex: 0.5;

  & > canvas {
    overflow-x: scroll;
  }
`;

export const GraphicRounded = styled.div`
  flex: 0 0 300px;
`;

export const GraphicContainerT = styled.div`
  width: 50%;
  display: grid;
  grid-column-gap: 1em;
  grid-template-columns: repeat(2, 1fr);
`;

export const GraphicContainerT2 = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 400px;
  flex-direction: row;
  flex-flow: row wrap;
  align-items: flex-start;
`;

export const CollapsableContainerContains = styled.div`
  display: flex;
  background-color: #dadada;
  flex: 1;
  flex-wrap: wrap;
  width: "100%";
  justify-content: center;
  align-items: space-between;
`;

export const CustomerHealthContainer = styled.div`
  display: flex;
  background-color: #dadada;
  flex: 1;
  flex-wrap: wrap;
  width: "100%";
  justify-content: center;
  align-items: center;
  overflow: hidden;
  > div {
    flex: 0.5 0 200px;
    max-width: 200px;
  }
`;

export const KnowledgeBaseAccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
`;

export const KnowledgeBaseAccessContainerHeader = styled.p`
  width: 100%;
  margin-left: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
`;

export const KnowledgeBaseAccessContainerTitle = styled.div`
  margin-left: 8px;
`;

export const KnowledgeBaseAccessInformationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  justify-content: center;
  margin: 10px !important;
`;

export const KnowledgeBaseAccessInformationYearContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100px;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 85px;
  margin: 8px 12px 0px 12px;
  border-radius: 16px;
  background-color: #bcbcbc;
`;

export const KnowledgeBaseAccessInformationTitle = styled.div`
  font-weight: bold;
  text-align: center;
`;

export const KnowledgeBaseAccessInformationValue = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
