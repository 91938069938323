import React from "react";
import {
  Container,
  IconsContainer,
  IconCenter,
  TitleContainer,
  ValueContainer,
  CardInformation,
  IconCenterContainer,
} from "./styles";

import InfoTooltip from "../../../../components/InfoTooltip";

const ContractGrowthCard = ({
  title,
  icon,
  value,
  tooltip,
  hasTooltip = false,
}) => {
  return (
    <Container>
      {hasTooltip && (
        <IconsContainer>
          <InfoTooltip name={title} text={tooltip} type="info" />
        </IconsContainer>
      )}

      <IconCenterContainer>
        <IconCenter>{icon}</IconCenter>
      </IconCenterContainer>
      <CardInformation>
        <TitleContainer>{title}</TitleContainer>
        <ValueContainer>{value}</ValueContainer>
      </CardInformation>
    </Container>
  );
};

export default ContractGrowthCard;
