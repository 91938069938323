import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import { useField } from "@unform/core";
import { MdError } from "react-icons/md";
import { Error } from "./styles";

import ReCAPTCHA from "react-google-recaptcha";
import { CaptchaContainer } from "./styles";

const ReCaptcha = ({ name, children, description, ...rest }) => {
  const inputRef = useRef(null);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  const handleCaptcha = useCallback(() => {
    inputRef.current.value = true;
  }, []);

  return (
    <>
      <CaptchaContainer>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_CAPTCHA_KEY}
          onChange={handleCaptcha}
          onExpired={() => inputRef.current.reset()}
          ref={inputRef}
        />
      </CaptchaContainer>
      {error && (
        <Error title={error}>
          <MdError color="#e00000" size={20} />
          {error}
        </Error>
      )}
    </>
  );
};

export default ReCaptcha;
