import React, { useCallback, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { MdInfo, MdPermContactCalendar } from "react-icons/md";

import { Form } from "@unform/web";
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationErrors";

import { useDispatch, useSelector } from "react-redux";

import { FormattedMessage, useIntl } from "react-intl";

import {
  Container,
  TitleContainer,
  Title,
  SessionDivider,
  Session,
  SessionContainer,
  SessionIconContainer,
  SessionTitle,
  FieldsContainer,
  ActionContainer,
  BadgeContainer,
} from "./styles";

import Input from "../../../components/Input";

import { useToast } from "../../../hooks/toast";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";
import { updateUser } from "../../../redux/modules/auth/actions";
import api from "../../../service";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import Badge from "../../../components/Badge";

const UpdateUser = () => {
  const formRef = useRef(null);
  const intl = useIntl();
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const { user, token } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const schema = Yup.object().shape({
    credential_name: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) {
          return;
        }

        await schema.validate(data, { abortEarly: false });

        api
          .put(
            `/customer/account`,
            {
              companyNameCredential: data.credential_name,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            setLoading(false);
            dispatch(
              updateUser({
                user: result.data.user,
              })
            );
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.user_update",
              }),
            });
            history.push("/dashboard");
          })
          .catch((err) => {
            setLoading(false);
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, dispatch, history, intl, schema, token, loading]
  );

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const pushBack = () => {
    history.push("/dashboard");
  };

  return (
    <AnimatedMainContainer>
      <Container>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            name: user.name,
            commercial_name: user.accountAlias,
            cnpj: user.cnpj,
            classification: user.nationalClassification,
            responsible_for_the_invoice: user.billingContact,
            operational_manager: user.operationsContact,
            fiscal_email: user.billingContactEmail,
            operational_email: user.operationsContactEmail,
            address: user.avenue,
            address_number: user.number,
            city: user.city,
            state: user.state,
            address_complement: user.address_complement,
            telephone: user.telephone,
            mobile: user.mobile,
            credential_name: user.credentialName,
          }}
        >
          <TitleContainer>
            <Title>
              <FormattedMessage id="my_account" />
            </Title>
            <ActionContainer>
              <BadgeContainer onClick={() => pushBack()}>
                <Badge
                  color="#C9C9C9"
                  text={intl.formatMessage({ id: "goback" })}
                  fontSize="12px"
                />
              </BadgeContainer>
              <BadgeContainer onClick={() => formRef.current.submitForm()}>
                <Badge
                  color="#002244"
                  text={intl.formatMessage({ id: "save" })}
                  fontSize="12px"
                />
              </BadgeContainer>
            </ActionContainer>
          </TitleContainer>
          <SessionDivider nr_items="2">
            <Session>
              <SessionContainer>
                <SessionIconContainer>
                  <MdInfo size="16px" color="#002244" />
                </SessionIconContainer>
                <SessionTitle>
                  <FormattedMessage id="informations" />
                </SessionTitle>
              </SessionContainer>
              <FieldsContainer>
                <Input
                  name="name"
                  placeholder={intl.formatMessage({
                    id: "company_name",
                  })}
                  hasLabel
                  disabled
                  validationBlur={validationBlur}
                />
                <Input
                  name="commercial_name"
                  placeholder={intl.formatMessage({
                    id: "commercial_name",
                  })}
                  hasLabel
                  disabled
                />

                <Input
                  name="cnpj"
                  placeholder="CNPJ"
                  hasLabel
                  validationBlur={validationBlur}
                  disabled
                />

                <Input
                  name="classification"
                  placeholder={intl.formatMessage({
                    id: "classification",
                  })}
                  hasLabel
                  validationBlur={validationBlur}
                  disabled
                />

                <Input
                  name="responsible_for_the_invoice"
                  placeholder={intl.formatMessage({
                    id: "responsible_for_the_invoice",
                  })}
                  hasLabel
                  validationBlur={validationBlur}
                  disabled
                />

                <Input
                  name="operational_manager"
                  placeholder={intl.formatMessage({
                    id: "operational_manager",
                  })}
                  hasLabel
                  validationBlur={validationBlur}
                  disabled
                />

                <Input
                  name="fiscal_email"
                  placeholder={intl.formatMessage({
                    id: "fiscal_email",
                  })}
                  hasLabel
                  validationBlur={validationBlur}
                  disabled
                />

                <Input
                  name="operational_email"
                  placeholder={intl.formatMessage({
                    id: "operational_email",
                  })}
                  hasLabel
                  validationBlur={validationBlur}
                  disabled
                />
              </FieldsContainer>
            </Session>
            <Session>
              <SessionContainer>
                <SessionIconContainer>
                  <MdPermContactCalendar size="16px" color="#002244" />
                </SessionIconContainer>
                <SessionTitle>
                  <FormattedMessage id="contact_data" />
                </SessionTitle>
              </SessionContainer>
              <FieldsContainer>
                <Input
                  name="address"
                  placeholder={intl.formatMessage({
                    id: "address",
                  })}
                  hasLabel
                  disabled
                />

                <Input
                  name="address_number"
                  placeholder={intl.formatMessage({
                    id: "address_number",
                  })}
                  hasLabel
                  validationBlur={validationBlur}
                  disabled
                  maxLength="80"
                />

                <Input
                  name="city"
                  placeholder={intl.formatMessage({
                    id: "city",
                  })}
                  hasLabel
                  validationBlur={validationBlur}
                  disabled
                />

                <Input
                  name="state"
                  placeholder={intl.formatMessage({
                    id: "state",
                  })}
                  hasLabel
                  validationBlur={validationBlur}
                  disabled
                />

                <Input
                  name="address_complement"
                  placeholder={intl.formatMessage({
                    id: "address_complement",
                  })}
                  hasLabel
                  validationBlur={validationBlur}
                  disabled
                />

                <Input
                  name="telephone"
                  placeholder={intl.formatMessage({
                    id: "telephone",
                  })}
                  hasLabel
                  validationBlur={validationBlur}
                  disabled
                />
                <Input
                  name="mobile"
                  placeholder={intl.formatMessage({
                    id: "mobile",
                  })}
                  hasLabel
                  validationBlur={validationBlur}
                  disabled
                />
                <Input
                  name="credential_name"
                  placeholder={intl.formatMessage({
                    id: "credential_name",
                  })}
                  hasLabel
                  validationBlur={validationBlur}
                  required
                />
              </FieldsContainer>
            </Session>
          </SessionDivider>
        </Form>
      </Container>
    </AnimatedMainContainer>
  );
};

export default UpdateUser;
