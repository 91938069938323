import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { useToast } from "../../hooks/toast";
import * as Yup from "yup";
import { useCallback } from "react";
import FormButton from "../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  LinkButton,
  ActionContainer,
  BadgeContainer,
} from "./styles";

import Table from "../../components/Table";
import Badge from "../../components/Badge";
import { useLoading } from "../../hooks/loading";
import api from "../../service";
import getValidationErrors from "../../utils/getValidationErrors";
import SelectWithLabel from "../../components/SelectWithLabel";
import InputWithLabel from "../../components/InputWithLabel";
import ConfirmationModal from "../../components/ConfirmationModal";
import axios from "axios";

const EventSetup = () => {
  const { token } = useSelector((state) => state.auth);
  const [eventsSetup, setEventsSetup] = useState([]);
  const [eventsEditionSetup, setEventsEdition] = useState([]);

  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [eventSetupId, setEventSetupId] = useState(null);
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();
  const { handleLoading } = useLoading();

  const schema = Yup.object().shape({
    setup_name: Yup.string().nullable(),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        await schema.validate(data, { abortEarly: false });

        if (loading) {
          return;
        }

        setLoading(true);

        api
          .patch(
            "/admin/setup-event",
            {
              eventSetupName: data.setup_name === "" ? null : data.setup_name,
              eventsId:
                data.event_editions.length > 0 ? data.event_editions : null,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => setEventsSetup(result.data.setupEventOutputList))
          .catch((err) =>
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            })
          )
          .finally(() => setLoading(false));
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [addToast, intl, schema, token, loading]
  );

  useEffect(() => {
    handleLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .all([
        api.get(`admin/events`, config),
        api.patch(
          `/admin/setup-event`,
          { eventSetupName: null, eventsId: null },
          config
        ),
      ])
      .then(
        axios.spread((eventsResponse, setupEventsResponse) => {
          const eventsEditions = eventsResponse.data.output;
          let eventsEditionsSort = [...eventsEditions].sort((a, b) =>
            a.eventEditionName < b.eventEditionName
              ? -1
              : a.eventEditionName > b.eventEditionName
              ? 1
              : 0
          );
          setEventsEdition(eventsEditionsSort);
          setEventsSetup(setupEventsResponse.data.setupEventOutputList);
        })
      )
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => handleLoading(false));
  }, [addToast, handleLoading, intl, token]);

  const getAllSetups = useCallback(() => {
    handleLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .all([
        api.get(`admin/events`, config),
        api.patch(
          `/admin/setup-event`,
          { eventSetupName: null, eventsId: null },
          config
        ),
      ])
      .then(
        axios.spread((eventsResponse, setupEventsResponse) => {
          const eventsEditions = eventsResponse.data.output;
          let eventsEditionsSort = [...eventsEditions].sort((a, b) =>
            a.eventEditionName < b.eventEditionName
              ? -1
              : a.eventEditionName > b.eventEditionName
              ? 1
              : 0
          );
          setEventsEdition(eventsEditionsSort);
          setEventsSetup(setupEventsResponse.data.setupEventOutputList);
        })
      )
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => handleLoading(false));
  }, [addToast, handleLoading, intl, token]);

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("event", {
      label: intl.formatMessage({ id: "event_edition" }),
      value: null,
    });
    formRef.current.setFieldValue("event_editions", []);
    getAllSetups();
  };

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const handleDeletion = () => {
    handleLoading(true);
    api
      .delete(`/admin/setup-event/${eventSetupId}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        addToast({
          type: "success",
          title: intl.formatMessage({
            id: "success",
          }),
          description: intl.formatMessage({
            id: "success.unassociation",
          }),
        });
        setEventSetupId(null);
        formRef.current.submitForm();
      })
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => {
        setModalIsOpen(false);
        handleLoading(false);
      });
  };

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "event" }),
        selector: (row) => row.eventSetupName,
        width: "200px",
      },
      {
        name: intl.formatMessage({ id: "editions" }),
        selector: (row) => row.eventSetupName,
        allowOverflow: true,
        wrap: true,
        format: (row) => {
          return row.setupEventAssociationOutputs.map((value, index) => {
            if (index + 1 === row.setupEventAssociationOutputs.length) {
              return `${value.eventEditionCode}`;
            }
            return `${value.eventEditionCode}, `;
          });
        },
      },
      {
        width: "125px",
        cell: (row) => (
          <LinkButton to={`/admin/event-setup/${row.id}`}>
            <Badge color="#002244" text={intl.formatMessage({ id: "edit" })} />
          </LinkButton>
        ),
        right: true,
      },
      {
        width: "125px",
        cell: (row) => (
          <LinkButton to={`/admin/event-setup/parameters/${row.id}`}>
            <Badge
              color="#002244"
              text={intl.formatMessage({ id: "parameters" })}
            />
          </LinkButton>
        ),
        right: true,
      },
      {
        width: "125px",
        cell: (row) => (
          <Badge
            onClick={() => {
              setEventSetupId(row.id);
              setModalIsOpen(true);
            }}
            color="#cc0000"
            text={intl.formatMessage({ id: "delete" })}
          />
        ),
        right: true,
      },
    ],
    [intl]
  );

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "breadcrumb.event_setup" })}
    >
      <Container>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <TitleContainer>
              <Title>
                <FormattedMessage id="search_for_events" />
              </Title>
              <ActionContainer>
                <BadgeContainer onClick={() => history.push("/dashboard")}>
                  <Badge
                    color="#C9C9C9"
                    text={intl.formatMessage({ id: "goback" })}
                    fontSize="12px"
                  />
                </BadgeContainer>
                <BadgeContainer
                  onClick={() => history.push("/admin/event-setup/new")}
                >
                  <Badge
                    color="#002244"
                    text={intl.formatMessage({ id: "new" })}
                    fontSize="12px"
                  />
                </BadgeContainer>
              </ActionContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal>
                <InputWithLabel
                  name="setup_name"
                  labelWidth="150px"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "event" })}
                  placeholder={intl.formatMessage({ id: "event" })}
                />
                <SelectWithLabel
                  label={intl.formatMessage({
                    id: "event_editions",
                  })}
                  placeholder={intl.formatMessage({
                    id: "event_editions",
                  })}
                  labelOrientation="vertical"
                  isMulti
                  options={eventsEditionSetup.map((eventEdition) => {
                    return {
                      label: eventEdition.eventEditionName,
                      value: eventEdition.idNumber,
                    };
                  })}
                  name="event_editions"
                />
              </Horizontal>
              <Horizontal>
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="search" />
                    </FormButton>
                  </SubmitContainer>
                </FilterActionsContainer>
              </Horizontal>
            </FieldsContainer>
          </Form>
        </FormContainer>
        <Table
          data={eventsSetup}
          columns={columns}
          hasPagination
          progressPending={loading}
        />
      </Container>
      <ConfirmationModal
        setIsOpen={setModalIsOpen}
        modalIsOpen={modalIsOpen}
        confirmationFunction={handleDeletion}
        description={intl.formatMessage({ id: "exclusion_confirmation" })}
        confirmationText={intl.formatMessage({ id: "yes" })}
        cancelText={intl.formatMessage({ id: "no" })}
      />
    </AnimatedMainContainer>
  );
};

export default EventSetup;
