import styled, { css } from "styled-components";

export const Container = styled.div``;

export const BadgeContainer = styled.div`
  background-color: transparent;
  border-radius: 2px;
  text-transform: uppercase;
  text-align: center;
  min-width: 120px;
  border-radius: 5px;
  word-wrap: break-word;
  /* margin-left: 0px !important; */
  cursor: pointer;
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0px !important;
  }
  ${(props) =>
    props.color &&
    css`
      background-color: ${props.color};
      color: #fff;
    `}

  ${(props) =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize};
    `}

    ${(props) =>
    props.disabled &&
    css`
      opacity: 0.4;
      cursor: default;
    `}


  padding: 4px 8px 4px 8px;
  ${(props) =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize};
    `}
`;

export const Text = styled.div`
  /* width: 10px;
  word-wrap: break-word; */
`;
