import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import { useToast } from "../../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationErrors";
import api from "../../../service";
import { useCallback } from "react";
import InputWithLabel from "../../../components/InputWithLabel";
import FormButton from "../../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";
import { format } from "date-fns";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  LinkButton,
  GoBackContainer,
} from "./styles";
import SelectWithLabel from "../../../components/SelectWithLabel";
import Table from "../../../components/Table";
import Badge from "../../../components/Badge";
import { useLoading } from "../../../hooks/loading";

const Events = () => {
  const { token } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);

  const schema = Yup.object().shape({
    prefix: Yup.string(),
    name: Yup.string(),
    status: Yup.bool().nullable(),
  });

  const getAllCustomerEvents =   useCallback(() => {
    handleLoading(true);
    api
      .patch(
        "/customer/events",
        {
          eventEditionCode: null,
          eventEditionName: null,
          active: null,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        let finalData = [];
        result.data.output.forEach((value) => {
          let date = new Date(value.eventStartDate);

          finalData.push({
            ...value,
            eventStartDate: date,
          });
        });
        setData(finalData);
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, intl, token, handleLoading]);

  useEffect(() => {
    getAllCustomerEvents();
  }, [getAllCustomerEvents]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        setLoading(true);
        if (loading) {
          return;
        }
        formRef.current?.setErrors({});

        await schema.validate(data, { abortEarly: false });

        api
          .patch(
            "/customer/events",
            {
              eventEditionCode: data.prefix,
              eventEditionName: data.name,
              active: data.status,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            let finalData = [];
            result.data.output.forEach((value) => {
              let date = new Date(value.eventStartDate);
              finalData.push({
                ...value,
                eventStartDate: date,
              });
            });
            setData(finalData);
          })
          .catch((err) => {
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, intl, schema, token, loading]
  );

  const pushBack = () => {
    history.goBack();
  };

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("event", {
      label: intl.formatMessage({ id: "event_edition" }),
      value: null,
    });
    formRef.current.setFieldValue("status", {
      label: intl.formatMessage({ id: "both" }),
      value: null,
    });
    getAllCustomerEvents();
  };

  const columns = [
    {
      name: intl.formatMessage({ id: "date" }),
      selector: (row) => row.eventStartDate,
      sortable: true,
      maxWidth: "400px",
      format: (row) => format(row.eventStartDate, "dd/MM/yyyy"),
    },
    {
      name: intl.formatMessage({ id: "prefix" }),
      selector: (row) => row.eventEditionCode,
      sortable: true,
      maxWidth: "400px",
    },
    {
      name: intl.formatMessage({ id: "name" }),
      selector: (row) => row.eventEditionName,
      sortable: true,
      maxWidth: "400px",
    },
    {
      name: "Status",
      selector: (row) => row.active,
      cell: (row) => {
        if (row.active) {
          return (
            <Badge
              color="#33d27e"
              text={intl.formatMessage({ id: "active" })}
            />
          );
        }
        return (
          <Badge
            color="#C9C9C9"
            text={intl.formatMessage({ id: "inactive" })}
          />
        );
      },
      maxWidth: "200px",
    },
    {
      cell: (row) => (
        <LinkButton to={`/events-client-visualization/${row.eventEditionCode}`}>
          <Badge
            color="#002244"
            text={intl.formatMessage({ id: "see_details" })}
          />
        </LinkButton>
      ),
      // maxWidth: "calc(100% - 800px)",
      right: true,
    },
  ];

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "breadcrumb.events" })}
    >
      <Container>
        <FormContainer>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{
              status: {
                label: intl.formatMessage({ id: "both" }),
                value: null,
              },
            }}
          >
            <TitleContainer>
              <Title>
                <FormattedMessage id="search_for_event_edition" />
              </Title>
              <GoBackContainer>
                <LinkButton onClick={pushBack}>
                  <Badge
                    color="#002244"
                    text={intl.formatMessage({ id: "goback" })}
                    fontSize="12px"
                  />
                </LinkButton>
              </GoBackContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal>
                <InputWithLabel
                  name="name"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "name" })}
                />
                <InputWithLabel
                  name="prefix"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "prefix" })}
                />

                <SelectWithLabel
                  name="status"
                  options={[
                    {
                      label: intl.formatMessage({ id: "active" }),
                      value: true,
                    },
                    {
                      label: intl.formatMessage({ id: "inactive" }),
                      value: false,
                    },
                    {
                      label: intl.formatMessage({ id: "both" }),
                      value: null,
                    },
                  ]}
                  validationBlur={validationBlur}
                  label="Status"
                />
              </Horizontal>
              <Horizontal>
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="search" />
                    </FormButton>
                  </SubmitContainer>
                </FilterActionsContainer>
              </Horizontal>
            </FieldsContainer>
          </Form>
        </FormContainer>
      </Container>
      <Table
        columns={columns}
        data={data}
        title={intl.formatMessage({ id: "results" })}
      />
    </AnimatedMainContainer>
  );
};

export default Events;
