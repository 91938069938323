import React from "react";
import { BadgeContainer, Text, Container } from "./styles";
import Loader from "react-loader-spinner";

const Badge = ({
  color,
  text,
  fontSize,
  disabled = false,
  loading,
  ...rest
}) => {
  return (
    <Container>
      <BadgeContainer
        color={color}
        fontSize={fontSize}
        disabled={disabled}
        {...rest}
      >
        {loading ? (
          <Loader type="Oval" color="white" height="16px" />
        ) : (
          <Text>{text}</Text>
        )}
      </BadgeContainer>
    </Container>
  );
};

export default Badge;
