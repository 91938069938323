import React, { useEffect, useMemo, useRef, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router";
import AnimatedMainContainer from "../../../../components/AnimatedMainContainer";
import {
  Container,
  TitleContainer,
  Title,
  FieldsContainer,
  Session,
  ActionContainer,
  BadgeContainer,
  ProgressBarContainer,
  CentralizedContainer,
  ProgressBarTitle,
} from "./styles";

import api from "../../../../service";
import { Form } from "@unform/web";
import Badge from "../../../../components/Badge";
import { useToast } from "../../../../hooks/toast";

import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Table from "../../../../components/Table";
import format from "date-fns/format";
import { FaCheckSquare } from "react-icons/fa";
import { BsSquare } from "react-icons/bs";
import {} from "../../../../hooks";
import { useLoading } from "../../../../hooks/loading";

const Journey = () => {
  const { token } = useSelector((state) => state.auth);
  const { opportunityId } = useParams();
  const { state } = useLocation();
  const [data, setData] = useState([]);
  const [totalJourneyItems, setTotalJourneyItems] = useState(0);
  const [completedJourneyItems, setCompletedJourneyItems] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);

  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();

  useEffect(() => {
    handleLoading(true);
    api
      .patch(
        "/route-log/",
        {
          opportunityId: opportunityId,
          eventEditionCode: state.eventEditionCode,
          accountId: state.accountId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const output = response.data.routeLogsList;
        let finalTotal = 0;
        setLoading(false);

        output.forEach((value) => {
          if (value.done) {
            finalTotal++;
          }
        });

        setCompletedJourneyItems(finalTotal);
        setTotalJourneyItems(response.data.routeLogsList.length);

        setData(response.data.routeLogsList);
      })
      .catch((err) => {
        setLoading(false);
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [
    opportunityId,
    state.email,
    token,
    addToast,
    intl,
    handleLoading,
    state.accountId,
    state.eventEditionCode,
  ]);

  useEffect(() => {
    setPercentage(
      ((completedJourneyItems / totalJourneyItems) * 100).toFixed(2)
    );
  }, [completedJourneyItems, totalJourneyItems]);

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "task" }),
        cell: (row) => (
          <>
            {row.done ? (
              <FaCheckSquare size={20} color="#002244" />
            ) : (
              <BsSquare size={20} color="#002244" />
            )}
            <p style={{ marginLeft: "8px" }}>
              {row.name && <FormattedMessage id={row.name} />}
            </p>
          </>
        ),
        minWidth: "300px",
      },
      {
        name: "E-mail",
        selector: (row) => row.email,
        minWidth: "300px",
      },
      {
        name: intl.formatMessage({ id: "action_date" }),
        selector: (row) => row.lastDate,
        format: (row) => {
          return (
            row.lastDate && format(new Date(row.lastDate), "dd/MM/yyyy HH:mm")
          );
        },
        minWidth: "150px",
      },
    ],
    [intl]
  );

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "journey_information" })}
    >
      <Container>
        <Form ref={formRef}>
          <TitleContainer>
            <Title>
              <FormattedMessage id="user_journey" />
            </Title>
            <ActionContainer>
              <BadgeContainer onClick={() => history.goBack()}>
                <Badge
                  color="#C9C9C9"
                  text={intl.formatMessage({ id: "goback" })}
                  fontSize="12px"
                />
              </BadgeContainer>
            </ActionContainer>
          </TitleContainer>

          <Session>
            <FieldsContainer>
              <CentralizedContainer>
                <ProgressBarContainer>
                  <ProgressBarTitle>
                    <FormattedMessage id="user_completed_percent" />:
                  </ProgressBarTitle>

                  <CircularProgressbar
                    value={percentage}
                    text={`${!loading ? percentage : 0}%`}
                    styles={{
                      root: {},
                      trail: {
                        stroke: "#d6d6d6",
                        strokeLinecap: "butt",
                        transform: "rotate(0.25turn)",
                        transformOrigin: "center center",
                      },
                      text: {
                        fill: "#002244",
                        fontSize: "16px",
                      },
                      background: {
                        fill: "#002244",
                      },
                      path: {
                        stroke: `#002244`,
                      },
                    }}
                  />
                </ProgressBarContainer>
              </CentralizedContainer>
              <Table
                data={data}
                columns={columns}
                progressPending={loading}
                hasPagination={false}
              />
            </FieldsContainer>
          </Session>
        </Form>
      </Container>
    </AnimatedMainContainer>
  );
};

export default Journey;
