import styled from "styled-components";

export const Input = styled.input`
  margin-right: 8px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px !important;
  color: #001b36;
`;
