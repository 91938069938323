import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
`;

export const Input = styled.input``;

export const List = styled.ul``;

export const Item = styled.ul``;

export const Error = styled.div`
  margin: 5px 0px !important;
  display: flex;
  font-size: 10px;
  align-items: center;
  justify-content: flex-start;
  color: #e00000;
  text-align: left;
  svg {
    margin-right: 4px;
    /* margin-left: 16px; */
    min-width: 32px;
  }
  span {
    background: #c53030;
    color: #fff;
    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
