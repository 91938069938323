import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { Form } from "@unform/web";
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationErrors";

import { useSelector } from "react-redux";

import { FormattedMessage, useIntl } from "react-intl";

import {
  Container,
  TitleContainer,
  Title,
  Session,
  FieldsContainer,
  ActionContainer,
  BadgeContainer,
  InfoContainer,
  NumericalInfoContainer,
  NumericalInfoText,
} from "./styles";

import { useToast } from "../../../hooks/toast";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";
import api from "../../../service";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import InputWithHorizontalLabel from "../../../components/InputWithHorizontalLabel";
import SelectWithLabel from "../../../components/SelectWithLabel";
import Badge from "../../../components/Badge";
import axios from "axios";
import { useLoading } from "../../../hooks/loading";

const CreateAssociation = () => {
  const formRef = useRef(null);
  const intl = useIntl();
  const { addToast } = useToast();
  const { user, token } = useSelector((state) => state.auth);
  const [events, setEvents] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [selectedContract, setSelectedContract] = useState();
  const [selectedEvent, setSelectedEvent] = useState();
  const [collaborators, setCollaborators] = useState([]);
  const [selectedCollaborator, setSelectedCollaborator] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoryInformation, setCategoryInformation] = useState();
  const { handleLoading } = useLoading();

  const history = useHistory();

  const schema = Yup.object().shape({
    event: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    contract: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    collaborator: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    category: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    assignment: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    credential_name: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        setLoading(true);
        if (loading) {
          return;
        }

        formRef.current?.setErrors({});

        await schema.validate(data, { abortEarly: false });

        api
          .post(
            `/accreditation`,
            {
              eventId: data.event,
              opportunityId: data.contract,
              associateCollaboratorId: data.collaborator,
              categoryId: data.category,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            setLoading(false);
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.collaborator_creation",
              }),
            });
            history.push("/credentials");
          })
          .catch((err) => {
            setLoading(false);
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, history, intl, loading, schema, token]
  );

  useEffect(() => {
    handleLoading(true);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .all([
        api.get(`/customer/events/by-user`, config),
        api.get(`/associate-collaborators/by-user`, config),
      ])
      .then(
        axios.spread((eventsResponse, collaboratorsResponse) => {
          setEvents(eventsResponse.data.output);
          setCollaborators(collaboratorsResponse.data.output);
        })
      )
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, intl, token, handleLoading]);

  useEffect(() => {
    formRef.current.setFieldValue("contract");
    if (selectedEvent) {
      handleLoading(true);
      api
        .get(`customer/opportunity/event/${selectedEvent}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          setContracts(result.data.output);
        })
        .catch((err) => {
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
        })
        .finally(() => {
          handleLoading(false);
        });
    }
  }, [selectedEvent, addToast, intl, token, handleLoading]);

  useEffect(() => {
    if (selectedCollaborator) {
      formRef.current.setFieldValue(
        "assignment",
        selectedCollaborator.jobTitle
      );
      formRef.current.setFieldValue(
        "credential_name",
        selectedCollaborator.credentialName
      );
    }
  }, [selectedCollaborator]);

  useEffect(() => {
    formRef.current.setFieldValue("category");
    if (selectedEvent && selectedContract) {
      handleLoading(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .all([
          api.patch(
            `accreditation/quota/`,
            { eventId: selectedEvent, opportunityId: selectedContract },
            config
          ),
          api.get(
            `ecommerce/category/available/opportunity/${selectedContract}`,
            config
          ),
        ])
        .then(
          axios.spread((quotaResponse, categoryResponse) => {
            const { total, used } = quotaResponse.data.credentialQuotesOutput;
            const credentialCategories =
              categoryResponse.data.ecommerceChlebaCategoryCredentialAvailableOutputs.reduce(
                (categories, category) => [
                  ...categories,
                  {
                    value: category.id,
                    label: category.title,
                    information: {
                      total: category.total || 0,
                      used: category.used || 0,
                    },
                  },
                ],
                user?.role?.type === "CLIENT"
                  ? [
                      {
                        label: intl.formatMessage({ id: "contract" }),
                        value: 0,
                        information: {
                          total: total || 0,
                          used: used || 0,
                        },
                      },
                    ]
                  : []
              );
            setCategories(credentialCategories);
          })
        )
        .catch((error) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + error.response.data.code,
            }),
          })
        )
        .finally(handleLoading(false));
    }
  }, [addToast, handleLoading, intl, selectedContract, selectedEvent, token]);

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  return (
    <AnimatedMainContainer>
      <Container>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <TitleContainer>
            <Title>
              <FormattedMessage id="create_association" />
            </Title>
            <ActionContainer>
              <BadgeContainer onClick={() => history.push("/credentials")}>
                <Badge
                  color="#C9C9C9"
                  text={intl.formatMessage({ id: "goback" })}
                  fontSize="12px"
                />
              </BadgeContainer>
              <BadgeContainer onClick={() => formRef.current.submitForm()}>
                <Badge
                  color="#002244"
                  text={intl.formatMessage({ id: "save" })}
                  fontSize="12px"
                  loading={loading}
                />
              </BadgeContainer>
            </ActionContainer>
          </TitleContainer>
          <Session>
            <FieldsContainer>
              <SelectWithLabel
                name="event"
                label={intl.formatMessage({ id: "event_edition" })}
                placeholder={intl.formatMessage({ id: "event_edition" })}
                validationBlur={validationBlur}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={events}
                onChange={(e) => setSelectedEvent(e?.value)}
                required
              />
              <SelectWithLabel
                name="contract"
                placeholder={intl.formatMessage({
                  id: "contract",
                })}
                label={intl.formatMessage({
                  id: "contract",
                })}
                validationBlur={validationBlur}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={contracts}
                onChange={(e) => setSelectedContract(e?.value)}
                required
              />
              <SelectWithLabel
                name="collaborator"
                placeholder={intl.formatMessage({
                  id: "collaborator",
                })}
                label={intl.formatMessage({
                  id: "collaborator",
                })}
                validationBlur={validationBlur}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={collaborators}
                onChange={(collab) => setSelectedCollaborator(collab)}
                required
              />
              <SelectWithLabel
                name="category"
                validationBlur={validationBlur}
                label={intl.formatMessage({ id: "credential_types" })}
                placeholder={intl.formatMessage({ id: "credential_types" })}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={categories}
                onChange={(category) =>
                  setCategoryInformation(category?.information)
                }
                required
              />
              <InputWithHorizontalLabel
                name="assignment"
                placeholder={intl.formatMessage({
                  id: "assignment",
                })}
                label={intl.formatMessage({
                  id: "assignment",
                })}
                validationBlur={validationBlur}
                disabled={true}
                labelWidth="150px"
                required
              />
              <InputWithHorizontalLabel
                name="credential_name"
                placeholder={intl.formatMessage({
                  id: "credential_name",
                })}
                label={intl.formatMessage({
                  id: "credential_name",
                })}
                validationBlur={validationBlur}
                disabled={true}
                labelWidth="150px"
                required
              />
            </FieldsContainer>
            {categoryInformation && (
              <InfoContainer>
                <NumericalInfoContainer>
                  <NumericalInfoText>
                    {`${intl.formatMessage({
                      id: "utilized",
                    })}: ${categoryInformation.used}/${
                      categoryInformation.total
                    }`}
                  </NumericalInfoText>
                </NumericalInfoContainer>
              </InfoContainer>
            )}
          </Session>
        </Form>
      </Container>
    </AnimatedMainContainer>
  );
};

export default CreateAssociation;
