const Strings = {};

Strings.orEmpty = function (entity) {
  return entity || "";
};

Strings.fullname = function (first, lastName) {
  return `${this.orEmpty(first)} ${this.orEmpty(lastName)}`;
};

export { Strings };
