import { useField } from "@unform/core";
import { useEffect, useRef, useState } from "react";
import {
  Container,
  Label,
  CheckBox,
  CheckBoxLabel,
  CheckBoxWrapper,
} from "./styles";

const Toggle = ({ name, label, labelWidth, className, ...rest }) => {
  const toggleRef = useRef();

  const { fieldName, registerField, defaultValue } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: toggleRef.current,
      getValue: (ref) => {
        return ref.checked;
      },
      setValue: (ref, value) => {
        ref.checked = value;
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container className={className}>
      <Label labelWidth={labelWidth}>{label}</Label>
      <CheckBoxWrapper>
        <CheckBox
          id={`checkbox-${fieldName}`}
          type="checkbox"
          ref={toggleRef}
          defaultChecked={defaultValue}
          {...rest}
        />
        <CheckBoxLabel htmlFor={`checkbox-${fieldName}`} />
      </CheckBoxWrapper>
    </Container>
  );
};

export default Toggle;
