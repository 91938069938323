import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useToast } from "../../../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../../../utils/getValidationErrors";
import api from "../../../../service";
import { useCallback } from "react";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../../../utils/formValidations";

import {
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
} from "./styles";
import SelectWithLabel from "../../../../components/SelectWithLabel";
import Modal from "../../../../components/Modal";
import FormButton from "../../../../components/FormButton";
import { useLoading } from "../../../../hooks/loading";

const ProfileModal = ({
  users,
  currentRoleId,
  setIsOpen,
  modalIsOpen,
  updateTable,
  closeCallback,
}) => {
  const { token } = useSelector((state) => state.auth);
  const [profilesSelect, setProfilesSelect] = useState([]);
  const [key, setKey] = useState();
  const formRef = useRef(null);
  const intl = useIntl();
  const { addToast } = useToast();
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);

  const schema = Yup.object().shape({
    profile: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
  });

  useEffect(() => {
    handleLoading(true);
    if (loading) {
      return;
    }

    api
      .patch(
        "/role/replace-options",
        {
          currentRoleId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        let filteredRoles = result.data.roles.filter(
          (role) => role.id !== Number(currentRoleId)
        );

        let sanitizedRoles = filteredRoles.map((role) => {
          return { label: role.name, value: role.id, type: role.type };
        });

        setProfilesSelect(sanitizedRoles);

        setKey(new Date());
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [users, addToast, intl, token, handleLoading, loading, currentRoleId]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) {
          return;
        }

        await schema.validate(data, { abortEarly: false });
        api
          .put(
            "/role/replace-current",
            {
              newRoleId: data.profile,
              currentRoleId,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((_) => {
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.user_updated",
              }),
            });
            setIsOpen(false);
            updateTable();
            setLoading(false);
            closeCallback();
          })
          .catch((err) => {
            setLoading(false);
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [
      loading,
      schema,
      currentRoleId,
      token,
      addToast,
      intl,
      setIsOpen,
      updateTable,
      closeCallback,
    ]
  );

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  return (
    <Modal
      setIsOpen={setIsOpen}
      modalIsOpen={modalIsOpen}
      closeCallback={closeCallback}
      modalWidth="50%"
    >
      <FormContainer>
        <Form ref={formRef} onSubmit={handleSubmit} key={key}>
          <TitleContainer>
            <Title>
              <FormattedMessage id="change_profile" />
            </Title>
          </TitleContainer>
          <FieldsContainer>
            <SelectWithLabel
              name="profile"
              options={profilesSelect}
              validationBlur={validationBlur}
              label={intl.formatMessage({ id: "profile" })}
              placeholder={intl.formatMessage({ id: "select_an_option" })}
              required
            />
            <Horizontal>
              <FilterActionsContainer>
                <SubmitContainer>
                  <FormButton
                    color="#C9C9C9"
                    type="button"
                    onClick={() => {
                      closeCallback();
                      setIsOpen(false);
                    }}
                  >
                    <FormattedMessage id="close" />
                  </FormButton>
                </SubmitContainer>
                <SubmitContainer>
                  <FormButton type="submit" loading={loading}>
                    <FormattedMessage id="save" />
                  </FormButton>
                </SubmitContainer>
              </FilterActionsContainer>
            </Horizontal>
          </FieldsContainer>
        </Form>
      </FormContainer>
    </Modal>
  );
};

export default ProfileModal;
