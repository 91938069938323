import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 16px;
  background-color: #fff;
`;

export const TableContainer = styled.div`
  width: 100%;
  background-color: #fff;
  margin-top: 32px;
`;

export const FormContainer = styled.div`
  form {
    display: flex;
    flex-direction: column;
  }
`;

export const Title = styled.h1`
  font-size: 14px;
  font-weight: normal;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 21px;
  padding-left: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);

  @media (max-width: 500px) {
    flex-direction: column;
    justify-content: flex-start;

    & > div {
      margin-top: 16px;
    }
    & > div:first-child {
      margin-top: 0px;
    }
  }
`;

export const FieldsContainer = styled.div`
  margin-top: 16px;
`;

export const Horizontal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & > div {
    display: flex;
    flex: 1 0 100px;
    margin-left: 16px;
    @media (max-width: 768px) {
      margin-left: 0px;
      flex: 1 1 auto;
      width: 100%;
    }
  }

  & > div:first-child {
    margin-left: 0px;
  }
`;

export const FilterActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  flex-wrap: wrap;

  margin-bottom: 16px;

  & > div:first-child {
    margin-left: 0px;
  }

  @media (max-width: 500px) {
    width: 100%;
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 16px;
    @media (max-width: 500px) {
      margin-left: 0px;
    }
  }
`;

export const SubmitContainer = styled.div`
  width: 100%;
  max-width: 150px;

  @media (max-width: 500px) {
    margin-left: 0px;
    max-width: none;
    width: 100%;
  }
`;

export const TableTitleContainer = styled.div`
  padding: 16px 0px 16px 24px;
`;

export const TableTitle = styled.p``;

export const LinkButton = styled(Link)`
  text-decoration: none;
`;

export const GoBackContainer = styled.div``;

export const BadgeContainer = styled.div`
  align-items: center;
`;

export const UserNameContainer = styled.div`
  margin-left: 16px;
`;

export const DocumentDragAndDropContainer = styled.div`
  display: flex;
  background-color: rgba(218, 218, 218, 0.3);
  padding: 8px;
  width: 100%;
  margin: 16px;
  margin-top: 8px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const DocumentDragAndDropText = styled.span`
  font-size: 11px;
  font-weight: normal;
  color: #525a5c;
  margin-left: 8px;
  text-align: center;
`;

export const DocumentsUploadContainer = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
`;

export const DocumentUpload = styled.div`
  display: flex;
  flex: 0.5 1 100px;
  border: 1px solid #dadada;
  border-top: 8px solid #002244;
  border-radius: 8px;
  margin: 16px 0px 16px 0px;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;

  &:first-child {
    margin-left: 0px;
  }

  @media (max-width: 500px) {
    margin-left: 0px;
  }

  padding: 0px 16px 0px 16px;
`;

export const DocumentUploadTitle = styled.span`
  margin: 8px 0px 8px 0px;
  font-size: 11px;
  font-weight: 600;
  color: #002244;
  justify-content: center;
`;

export const DocumentsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FileIconsContainer = styled.div`
  margin-top: 8px;
  svg {
    margin: 0px 8px;
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;

  div {
    margin-left: 16px;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    div {
      margin-left: 0px;
    }
  }
`;

export const NotificationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 32px;

  div {
    margin-left: 16px;
  }

  div:first-child {
    margin-left: 0px;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    div {
      margin-left: 0px;
    }
  }
`;
