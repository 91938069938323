import React, { useCallback, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Form } from "@unform/web";
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationErrors";

import { useSelector } from "react-redux";

import { FormattedMessage, useIntl } from "react-intl";

import {
  Container,
  TitleContainer,
  Title,
  Session,
  FieldsContainer,
  ActionContainer,
  BadgeContainer,
} from "./styles";

import { useToast } from "../../../hooks/toast";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";
import api from "../../../service";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import InputWithHorizontalLabel from "../../../components/InputWithHorizontalLabel";
import Badge from "../../../components/Badge";
import Toggle from "../../../components/Toggle";

import { useEffect } from "react";
import { useLoading } from "../../../hooks/loading";

const EditCategory = () => {
  const formRef = useRef(null);
  const intl = useIntl();
  const { addToast } = useToast();
  const { token } = useSelector((state) => state.auth);
  const { id } = useParams();

  const [data, setData] = useState();

  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const schema = Yup.object().shape({
    title: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
  });

  useEffect(() => {
    handleLoading(true);
    api
      .get(`/admin/ecommerce/chleba/category/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => setData(response.data.ecommerceChlebaCategoryOutput))
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, id, intl, token, handleLoading]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        setLoading(true);
        if (loading) {
          return;
        }
        await schema.validate(data, { abortEarly: false });

        api
          .put(
            `/admin/ecommerce/chleba/category/${id}`,
            {
              title: data.title,
              credential: data.credential,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.category_update",
              }),
            });
            history.push("/admin/categories");
          })
          .catch((err) =>
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            })
          )
          .finally(setLoading(false));
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [addToast, history, id, intl, loading, schema, token]
  );

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  return (
    <AnimatedMainContainer>
      <Container>
        <Form
          ref={formRef}
          initialData={{ title: data?.title, credential: data?.credential }}
          onSubmit={handleSubmit}
        >
          <TitleContainer>
            <Title>
              <FormattedMessage id="editing_category" />
            </Title>
            <ActionContainer>
              <BadgeContainer
                onClick={() =>
                  history.push("/admin/categories", {
                    prevPath: history.location,
                  })
                }
              >
                <Badge
                  color="#C9C9C9"
                  text={intl.formatMessage({ id: "goback" })}
                  fontSize="12px"
                />
              </BadgeContainer>
              <BadgeContainer onClick={() => formRef.current.submitForm()}>
                <Badge
                  loading={loading}
                  color="#002244"
                  text={intl.formatMessage({ id: "save" })}
                  fontSize="12px"
                />
              </BadgeContainer>
            </ActionContainer>
          </TitleContainer>
          <Session>
            <FieldsContainer>
              <InputWithHorizontalLabel
                name="title"
                placeholder={intl.formatMessage({
                  id: "title",
                })}
                label={intl.formatMessage({
                  id: "title",
                })}
                validationBlur={validationBlur}
                labelWidth="80px"
                required
              />
              <Toggle
                name="credential"
                label={intl.formatMessage({ id: "credential_type" })}
              />
            </FieldsContainer>
          </Session>
        </Form>
      </Container>
    </AnimatedMainContainer>
  );
};

export default EditCategory;
