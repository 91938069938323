export function changeApplicationLanguage(languageState) {
  return {
    type: "CHANGE_APPLICATION_LANGUAGE",
    payload: {
      code: languageState.code,
    },
  };
}

export function changeLanguagePreference(languageState) {
  return {
    type: "CHANGE_LANGUAGE_PREFERENCE",
    payload: {
      code: languageState.code,
      token: languageState.token,
    },
  };
}

export function loadLanguagePreference(languageState) {
  return {
    type: "LOAD_APPLICATION_LANGUAGE",
  };
}
