import styled, { css } from "styled-components";

export const NavContainer = styled.nav`
  display: flex;
  justify-content: flex-end;
`;

export const Container = styled.div`
  display: flex;
  margin-left: 72px;
  ${(props) =>
    !props.sidebarIsVisible &&
    css`
      margin-left: 0px;
    `}
`;

export const Content = styled.div`
  display: flex;
  width: calc(100vw - 90px);

  ${(props) =>
    !props.sidebarIsVisible &&
    css`
      width: 100vw;
    `}

  /* @media (min-height: 940px) {
    margin-top: 72px;
  } */

  flex: 1;
  background-color: #dadada;
`;

export const BarsContainer = styled.div`
  /* display: flex;
  width: 100%;
  flex-direction: row; */
`;

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

export const Title = styled.h1`
  font-size: 14px;
  font-weight: normal;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 21px;
  padding-left: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  & > div {
    margin-left: 16px;
  }
`;

export const FieldsContainer = styled.div`
  margin-top: 16px;
`;

export const Horizontal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & > div {
    display: flex;
    flex: 1 0 100px;
    margin-left: 16px;
    @media (max-width: 768px) {
      margin-left: 0px;
      flex: 1 1 auto;
      width: 100%;
    }
  }

  & > div:first-child {
    margin-left: 0px;
  }
`;

export const FilterActionsContainer = styled.div`
  display: flex;
  width: 100%;
  font-size: 14px;
  flex-wrap: wrap;

  & > div:first-child {
    margin-left: 0px;
  }

  & > div {
    flex-direction: column;
    margin-left: 16px;
    @media (max-width: 500px) {
      margin-left: 0px;
    }
  }
`;

export const SubmitContainer = styled.div`
  flex: 1;
  display: flex;

  @media (max-width: 500px) {
    margin-left: 0px;
    max-width: none;
    width: 100%;
  }
`;

export const AdditionalInformationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const InformationContainer = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 8px 8px;
`;

export const InformationLabel = styled.p`
  flex: 0.5;
`;

export const InformationValue = styled.p`
  flex: 0.5;
  text-align: right;
`;

export const InformationNotification = styled.p`
  margin-top: 12px;
  text-align: center;
`;
