import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Form } from "@unform/web";
import * as Yup from "yup";
import getValidationErrors from "../../../../utils/getValidationErrors";

import { useSelector } from "react-redux";

import { FormattedMessage, useIntl } from "react-intl";

import {
  Container,
  TitleContainer,
  Title,
  Session,
  FieldsContainer,
  ActionContainer,
  BadgeContainer,
} from "./styles";

import CpfCnpj from "../../../../components/CpfCnpj";

import { useToast } from "../../../../hooks/toast";
import { validateSingleFieldOnBlur } from "../../../../utils/formValidations";
import api from "../../../../service";
import AnimatedMainContainer from "../../../../components/AnimatedMainContainer";
import InputWithHorizontalLabel from "../../../../components/InputWithHorizontalLabel";
import SelectWithLabel from "../../../../components/SelectWithLabel";
import { cpf } from "cpf-cnpj-validator";
import Badge from "../../../../components/Badge";
import { useLoading } from "../../../../hooks/loading";

const CollaboratorsAssociateDetail = () => {
  const formRef = useRef(null);
  const intl = useIntl();
  const { id } = useParams();
  const { addToast } = useToast();
  const { token } = useSelector((state) => state.auth);
  const [data, setData] = useState(null);
  const [key, setKey] = useState(null);
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);

  const [personType, setPersonType] = useState("F");

  const [documentLabel, setDocumentLabel] = useState(
    `${intl.formatMessage({ id: "document" })} (CPF)`
  );

  const nationalityOptions = [
    {
      label: intl.formatMessage({ id: "national" }),
      value: false,
    },
    {
      label: intl.formatMessage({ id: "international" }),
      value: true,
    },
  ];

  const history = useHistory();

  const schema = Yup.object().shape({
    nationality: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    name_lastname: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    document: Yup.string()
      .required(intl.formatMessage({ id: "error.field_required" }))
      .test(
        "is-incorrect",
        intl.formatMessage({
          id: "error.invalid_cpf",
        }),
        function (value) {
          let result;
          const nationality = JSON.parse(this.resolve(Yup.ref("nationality")));
          if (nationality === true) {
            return true;
          } else if (nationality === false) {
            result = cpf.isValid(value);
          }
          return result;
        }
      ),
    assignment: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    credential_name: Yup.string()
      .max(
        23,
        intl.formatMessage(
          { id: "error.field_required" },
          {
            length: 23,
          }
        )
      )
      .required(intl.formatMessage({ id: "error.field_required" })),
    email: Yup.string().email(intl.formatMessage({ id: "error.valid_email" })),
    address: Yup.string(),
    telephone: Yup.string().matches(
      /^$|^(?:\+)[0-9]{1,3}\s?(?:\()[0-9]{1,3}(?:\))\s?[0-9]{3,13}$/,
      intl.formatMessage({
        id: "error.telephone_match",
      })
    ),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) {
          return;
        }

        await schema.validate(data, { abortEarly: false });

        api
          .put(
            `/associate-collaborators/${id}`,
            {
              international: data.nationality,
              name: data.name_lastname,
              credentialName: data.credential_name,
              document: data.document,
              jobTitle: data.assignment,
              email: data.email,
              address: data.address,
              telephone: data.telephone,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            setLoading(false);
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.collaborator_update",
              }),
            });
            history.push("/associate-collaborators");
          })
          .catch((err) => {
            setLoading(false);
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, history, id, intl, schema, token, loading]
  );

  useEffect(() => {
    handleLoading(true);
    api
      .get(`/associate-collaborators/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setData(result.data.associateCollaboratorOutput);
        setKey(new Date());
        switch (result.data.associateCollaboratorOutput.international) {
          case false:
            setPersonType("F");
            setDocumentLabel(`${intl.formatMessage({ id: "document" })} (CPF)`);
            break;
          case true:
            setPersonType("I");
            setDocumentLabel(
              `${intl.formatMessage({ id: "document" })} (${intl.formatMessage({
                id: "passport",
              })})`
            );
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, id, intl, token, handleLoading]);

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const handleTypeChanges = (value) => {
    formRef.current.setFieldValue("document", "");
    switch (value) {
      case false:
        setPersonType("F");
        setDocumentLabel(`${intl.formatMessage({ id: "document" })} (CPF)`);
        break;
      case true:
        setPersonType("I");
        setDocumentLabel(
          `${intl.formatMessage({ id: "document" })} (${intl.formatMessage({
            id: "passport",
          })})`
        );
        break;
      default:
        break;
    }
  };

  return (
    <AnimatedMainContainer>
      <Container>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          key={key}
          initialData={{
            nationality: {
              label: nationalityOptions.filter(
                (v) => v.value === data?.international
              )[0]?.label,
              value: data?.international,
            },
            credential_name: data?.credentialName,
            name_lastname: data?.name,
            document: data?.document,
            assignment: data?.jobTitle,
            email: data?.email,
            address: data?.address,
            telephone: data?.telephone,
          }}
        >
          <TitleContainer>
            <Title>
              <FormattedMessage id="editing_collaborator" />
            </Title>
            <ActionContainer>
              <BadgeContainer
                onClick={() => history.push("/associate-collaborators")}
              >
                <Badge
                  color="#C9C9C9"
                  text={intl.formatMessage({ id: "goback" })}
                  fontSize="12px"
                />
              </BadgeContainer>
              <BadgeContainer onClick={() => formRef.current.submitForm()}>
                <Badge
                  color="#002244"
                  text={intl.formatMessage({ id: "save" })}
                  fontSize="12px"
                  loading={loading}
                />
              </BadgeContainer>
            </ActionContainer>
          </TitleContainer>
          <Session>
            <FieldsContainer>
              <SelectWithLabel
                name="nationality"
                label={intl.formatMessage({ id: "nationality" })}
                placeholder={intl.formatMessage({ id: "nationality" })}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={nationalityOptions}
                onChange={(e) => handleTypeChanges(e.value)}
                required
              />
              <InputWithHorizontalLabel
                name="name_lastname"
                placeholder={intl.formatMessage({
                  id: "name_lastname",
                })}
                label={intl.formatMessage({
                  id: "name_lastname",
                })}
                validationBlur={validationBlur}
                labelWidth="150px"
                required
              />
              <InputWithHorizontalLabel
                name="credential_name"
                placeholder={intl.formatMessage({
                  id: "credential_name",
                })}
                label={intl.formatMessage({
                  id: "credential_name",
                })}
                validationBlur={validationBlur}
                labelWidth="150px"
                required
              />
              <CpfCnpj
                name="document"
                placeholder={documentLabel}
                label={documentLabel}
                hasLabel
                labelOrientation="horizontal"
                personType={personType}
                validationBlur={validationBlur}
                labelWidth="150px"
                required
              />
              <InputWithHorizontalLabel
                name="assignment"
                placeholder={intl.formatMessage({
                  id: "assignment",
                })}
                label={intl.formatMessage({
                  id: "assignment",
                })}
                validationBlur={validationBlur}
                labelWidth="150px"
                required
              />
              <InputWithHorizontalLabel
                name="email"
                placeholder="E-mail"
                label="E-mail"
                validationBlur={validationBlur}
                labelWidth="150px"
              />
              <InputWithHorizontalLabel
                name="address"
                placeholder={intl.formatMessage({
                  id: "address",
                })}
                label={intl.formatMessage({
                  id: "address",
                })}
                validationBlur={validationBlur}
                labelWidth="150px"
              />
              <InputWithHorizontalLabel
                name="telephone"
                placeholder={intl.formatMessage({
                  id: "telephone",
                })}
                label={intl.formatMessage({
                  id: "telephone",
                })}
                validationBlur={validationBlur}
                labelWidth="150px"
                maxLength="20"
              />
            </FieldsContainer>
          </Session>
        </Form>
      </Container>
    </AnimatedMainContainer>
  );
};

export default CollaboratorsAssociateDetail;
