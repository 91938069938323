import { all, takeLatest, put } from "redux-saga/effects";

import { alertSuccess, alertFailure, alertReset } from "./actions";

function* request({ payload }) {
  const { message, isDialog, messageType, title } = payload;

  if (messageType === "success") {
    yield put(
      alertSuccess({
        message,
        title,
        isDialog,
        messageType,
      })
    );
    yield put(alertReset());
  } else {
    yield put(
      alertFailure({
        message,
        title,
        isDialog,
        messageType,
      })
    );
    yield put(alertReset());
  }
}

export default all([takeLatest("ALERT_REQUEST", request)]);
