import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 1500px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  align-items: center;
  /* justify-content: center; */
  margin-bottom: 16px;
`;

export const Label = styled.span`
  ${(props) =>
    props.labelWidth &&
    css`
      width: ${props.labelWidth};
    `}
  margin-right: 8px;
  font-size: 12px;
  font-weight: 600;
  word-wrap: break-word;
`;

export const CheckBoxWrapper = styled.div`
  position: relative;
`;
export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 4px;
  left: 0;
  width: 42px;
  height: 13px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: -5px;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 3px 3px 3px 0px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 13px;
  &:checked + ${CheckBoxLabel} {
    background: rgba(0, 34, 68, 0.4);
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: -5px;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      margin-left: 21px;
      transition: 0.2s;
      background-color: #002244;
    }
  }
`;
