import React, { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useField } from "@unform/core";
import { FormattedMessage, useIntl } from "react-intl";
import { Container, Input, List, Item, Error } from "./styles";
import { MdError } from "react-icons/md";
import { useToast } from "../../hooks/toast";

const Dropzone = ({ name, accept, multiple, type }) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue = [], error, registerField } = useField(name);
  const { addToast } = useToast();
  const intl = useIntl();

  const [k, setK] = useState();
  const [acceptedFiles, setAcceptedFiles] = useState(defaultValue);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: accept,
    multiple: multiple,
    maxSize: 20971520,
    minSize: 1,
    onDropRejected: (e) => {
      addToast({
        type: "error",
        title: intl.formatMessage({
          id: "error",
        }),
        description: intl.formatMessage({
          id: `${e[0].errors[0].code}-pdf`,
        }),
      });
    },
    onDrop: (onDropAcceptedFiles) => {
      if (inputRef.current) {
        inputRef.current.acceptedFiles = onDropAcceptedFiles;
        setAcceptedFiles(onDropAcceptedFiles);
      }
    },
  });

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref) => {
        return ref.acceptedFiles || [];
      },
      clearValue: (ref) => {
        inputRef.current.value = null;
        ref.acceptedFiles = [];
        setAcceptedFiles([]);
      },
      setValue: (ref, value) => {
        ref.acceptedFiles = value;
        setAcceptedFiles(value);
        setK(value);
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container
      {...getRootProps()}
      onClick={() => inputRef.current?.click()}
      key={k}
    >
      <Input {...getInputProps()} ref={inputRef} />

      {acceptedFiles.length !== 0 && (
        <List>
          {acceptedFiles.map((file, index) => (
            <Item key={index}>{file.name}</Item>
          ))}
        </List>
      )}

      {(isDragActive || (!multiple && acceptedFiles.length < 1)) &&
        intl.formatMessage(
          { id: "drag_and_drog_sheet_message" },
          { filetype: type }
        )}
      {error && (
        <Error title={error}>
          <MdError color="#e00000" size={20} />
          {error}
        </Error>
      )}
    </Container>
  );
};

export default Dropzone;
