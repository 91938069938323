import React from "react";

import LoadingComponent from "react-loading-overlay";

import { useLoading } from "../../hooks/loading";
import { useIntl } from "react-intl";
import { LoadingSpinnerLogo } from "./styles";
import LogoCircle from "../../assets/images/Informa_Logo_Circle.png";

const LoadingOverlay = ({ children }) => {
  const { isLoading } = useLoading();
  const intl = useIntl();

  return (
    <LoadingComponent
      active={isLoading}
      spinner={
        <div>
          <LoadingSpinnerLogo src={LogoCircle} />
        </div>
      }
      styles={{
        overlay: (base) => ({
          ...base,
          zIndex: 9999999999999,
        }),
      }}
      text={intl.formatMessage({ id: "loading..." })}
    >
      {children}
    </LoadingComponent>
  );
};

export default LoadingOverlay;
