import React, { useEffect, useRef, useState, useCallback } from "react";
import { useField } from "@unform/core";
import { MdError } from "react-icons/md";

import {
  Container,
  Error,
  Label,
  LabelContainer,
  RootContainer,
  AsteriskText
} from "./styles";

const Input = ({
  name,
  containerStyle = {},
  icon: Icon,
  validationBlur,
  disabled,
  hasLabel,
  placeholder,
  required,
  ...rest
}) => {
  const inputRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(async () => {
    let fieldHasValue = !!inputRef.current?.value;
    setIsFocused(false);

    setIsFilled(fieldHasValue);

    if (validationBlur) {
      validationBlur(fieldName, inputRef.current?.value);
    }
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <RootContainer>
      {hasLabel && (
        <LabelContainer>
          <Label>{placeholder}</Label>
          {required && <AsteriskText>*</AsteriskText>}
        </LabelContainer>
      )}
      <Container
        style={containerStyle}
        isFocused={isFocused}
        isFilled={isFilled}
        isErrored={!!error}
        disabled={disabled}
      >
        {Icon && <Icon size={24} />}
        <input
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          ref={inputRef}
          disabled={disabled}
          placeholder={placeholder}
          {...rest}
        />
      </Container>
      {error && (
        <Error title={error}>
          <MdError color="#e00000" size={20} />
          {error}
        </Error>
      )}
    </RootContainer>
  );
};

export default Input;
