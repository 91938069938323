import React, { useEffect, useRef, useState, useCallback } from "react";
import { useField } from "@unform/core";
import { MdError } from "react-icons/md";

import {
  Container,
  Error,
  RootContainer,
  Label,
  InputRow,
  AsteriskText,
} from "./styles";

const InputWithHorizontalLabel = ({
  name,
  containerStyle = {},
  icon: Icon,
  validationBlur,
  disabled,
  label,
  labelWidth,
  className,
  required,
  ...rest
}) => {
  const inputRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(async () => {
    let fieldHasValue = !!inputRef.current?.value;
    setIsFocused(false);

    setIsFilled(fieldHasValue);

    if (validationBlur) {
      validationBlur(fieldName, inputRef.current?.value);
    }
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <RootContainer className={className}>
      <InputRow>
        <Label labelWidth={labelWidth}>
          {label}
          {required && <AsteriskText>*</AsteriskText>}
        </Label>
        <Container
          style={containerStyle}
          isFocused={isFocused}
          isFilled={isFilled}
          isErrored={!!error}
          disabled={disabled}
        >
          <input
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            defaultValue={defaultValue}
            ref={inputRef}
            disabled={disabled}
            {...rest}
          />
        </Container>
      </InputRow>
      {error && (
        <Error title={error} labelWidth={labelWidth}>
          <MdError color="#e00000" size={20} />
          {error}
        </Error>
      )}
    </RootContainer>
  );
};

export default InputWithHorizontalLabel;
