import React, { useCallback, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { Form } from "@unform/web";
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationErrors";

import { useSelector } from "react-redux";

import { FormattedMessage, useIntl } from "react-intl";
import axios from "axios";

import {
  Container,
  TitleContainer,
  Title,
  Session,
  FieldsContainer,
  ActionContainer,
  BadgeContainer,
  Horizontal,
} from "./styles";

import { useToast } from "../../../hooks/toast";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";
import api from "../../../service";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import InputWithHorizontalLabel from "../../../components/InputWithHorizontalLabel";
import SelectWithLabel from "../../../components/SelectWithLabel";
import DatePickerWithLabel from "../../../components/DatePickerWithLabel";
import Badge from "../../../components/Badge";
import Toggle from "../../../components/Toggle";
import { useEffect } from "react";
import { useLoading } from "../../../hooks/loading";

const NewProductEventAssociation = () => {
  const formRef = useRef(null);
  const intl = useIntl();
  const { addToast } = useToast();
  const { token } = useSelector((state) => state.auth);
  var language = useSelector((state) => state.language).code;

  const [events, setEvents] = useState();
  const [products, setProducts] = useState();
  const [unities, setUnities] = useState();
  const [periods, setPeriods] = useState();
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const schema = Yup.object().shape({
    event: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    product: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    unity: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    realValue: Yup.string()
      .matches(
        /^[0-9]+(\.[0-9]+)?$/g,
        intl.formatMessage({ id: "error.need_to_be_decimal" })
      )
      .required(intl.formatMessage({ id: "error.field_required" })),
    dollarValue: Yup.string()
      .matches(
        /^[0-9]+(\.[0-9]+)?$/g,
        intl.formatMessage({ id: "error.need_to_be_decimal" })
      )
      .required(intl.formatMessage({ id: "error.field_required" })),
    euroValue: Yup.string()
      .matches(
        /^[0-9]+(\.[0-9]+)?$/g,
        intl.formatMessage({ id: "error.need_to_be_decimal" })
      )
      .required(intl.formatMessage({ id: "error.field_required" })),
    deadline: Yup.date().nullable(),
    maxDate: Yup.date().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    startDateAssembly: Yup.date().nullable(),
    finalDateAssembly: Yup.date().nullable(),
    period: Yup.string().nullable(),
    active: Yup.bool().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
  });

  useEffect(() => {
    handleLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .all([
        api.get(`admin/events`, config),
        api.patch(`/admin/ecommerce/chleba/product`, {}, config),
        api.get(`/admin/ecommerce/chleba/unity`, config),
      ])
      .then(
        axios.spread((eventsResponse, productsResponse, unityResponse) => {
          const events = eventsResponse.data.output.map((event) => ({
            label: event.eventEditionName,
            value: event.eventEditionCode,
          }));
          let eventsSort = [...events].sort((a, b) =>
            a.label < b.label ? -1 : a.label > b.label ? 1 : 0
          );
          setEvents(eventsSort);

          const products =
            productsResponse.data.ecommerceChlebaProductFilterOutput.map(
              (product) => ({
                label: language === "pt" ? product.title : product.englishTitle,
                value: product.id,
              })
            );
          setProducts(products);

          const unities = unityResponse.data.ecommerceChlebaUnityOutput.map(
            (unity) => ({
              label: intl.formatMessage({ id: unity.title }),
              value: unity.id,
            })
          );
          setUnities(unities);

          setPeriods([
            {
              label: intl.formatMessage({ id: "daytime" }),
              value: "DAYTIME",
            },
            {
              label: intl.formatMessage({ id: "nighttime" }),
              value: "NIGHTTIME",
            },
            {
              label: intl.formatMessage({ id: "both" }),
              value: "BOTH",
            },
          ]);
        })
      )
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => handleLoading(false));
  }, [addToast, intl, token, handleLoading, language]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        await schema.validate(data, { abortEarly: false });

        setLoading(true);
        if (loading) {
          return;
        }

        api
          .post(
            `/admin/ecommerce/chleba/product-event`,
            {
              eventEditionCode: data.event,
              productId: data.product,
              unityId: data.unity,
              realValue: data.realValue,
              dollarValue: data.dollarValue,
              euroValue: data.euroValue,
              deadline: data.deadline,
              maxDate: data.maxDate,
              startDateAssembly: data.startDateAssembly,
              finalDateAssembly: data.finalDateAssembly,
              period: data.period,
              active: data.active,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.product_event_association_creation",
              }),
            });
            history.push("/admin/product-event-association");
          })
          .catch((err) => {
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
            if ([-18000, -18003].includes(err.response.data.code)) {
              history.push("/admin/product-event-association");
            }
          })
          .finally(() => setLoading(false));
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [addToast, history, intl, loading, schema, token]
  );

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  return (
    <AnimatedMainContainer>
      <Container>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <TitleContainer>
            <Title>
              <FormattedMessage id="new_product_event_association" />
            </Title>
            <ActionContainer>
              <BadgeContainer
                onClick={() => history.push("/admin/product-event-association")}
              >
                <Badge
                  color="#C9C9C9"
                  text={intl.formatMessage({ id: "goback" })}
                  fontSize="12px"
                />
              </BadgeContainer>
              <BadgeContainer onClick={() => formRef.current.submitForm()}>
                <Badge
                  color="#002244"
                  text={intl.formatMessage({ id: "save" })}
                  fontSize="12px"
                  loading={loading}
                />
              </BadgeContainer>
            </ActionContainer>
          </TitleContainer>
          <Session>
            <FieldsContainer>
              <Horizontal>
                <SelectWithLabel
                  name="event"
                  label={intl.formatMessage({ id: "event_edition" })}
                  placeholder={intl.formatMessage({ id: "event_edition" })}
                  validationBlur={validationBlur}
                  labelOrientation="horizontal"
                  labelWidth="120px"
                  options={events}
                  required
                />
                <SelectWithLabel
                  name="product"
                  label={intl.formatMessage({ id: "product" })}
                  placeholder={intl.formatMessage({ id: "product" })}
                  validationBlur={validationBlur}
                  labelOrientation="horizontal"
                  labelWidth="120px"
                  options={products}
                  required
                />
              </Horizontal>
              <Horizontal>
                <SelectWithLabel
                  name="unity"
                  label={intl.formatMessage({ id: "unity" })}
                  placeholder={intl.formatMessage({ id: "unity" })}
                  validationBlur={validationBlur}
                  labelOrientation="horizontal"
                  labelWidth="120px"
                  options={unities}
                  required
                />
                <SelectWithLabel
                  name="period"
                  label={intl.formatMessage({ id: "period" })}
                  placeholder={intl.formatMessage({ id: "period" })}
                  validationBlur={validationBlur}
                  labelOrientation="horizontal"
                  labelWidth="120px"
                  options={periods}
                  clearable
                />
              </Horizontal>
              <Horizontal>
                <DatePickerWithLabel
                  loading={loading}
                  name="deadline"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "deadline" })}
                  labelWidth="120px"
                  tooltip={{
                    name: "Deadline",
                    text: intl.formatMessage({ id: "deadline_tooltip" }),
                    type: "info",
                  }}
                />
                <DatePickerWithLabel
                  loading={loading}
                  name="maxDate"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "maximum_payment_date" })}
                  labelWidth="120px"
                  required
                  tooltip={{
                    name: "MaxDate",
                    text: intl.formatMessage({ id: "max_date_tooltip" }),
                    type: "info",
                  }}
                />
              </Horizontal>
              <Horizontal>
                <DatePickerWithLabel
                  loading={loading}
                  name="startDateAssembly"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "start_date_assembly" })}
                  labelWidth="120px"
                  tooltip={{
                    name: "StartDateAssembly",
                    text: intl.formatMessage({ id: "assembly_date_tooltip" }),
                    type: "info",
                  }}
                />
                <DatePickerWithLabel
                  loading={loading}
                  name="finalDateAssembly"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "final_date_assembly" })}
                  labelWidth="120px"
                  tooltip={{
                    name: "FinalDateAssembly",
                    text: intl.formatMessage({ id: "assembly_date_tooltip" }),
                    type: "info",
                  }}
                />
              </Horizontal>
              <Horizontal>
                <InputWithHorizontalLabel
                  name="realValue"
                  placeholder="R$"
                  label="R$"
                  validationBlur={validationBlur}
                  labelWidth="120px"
                  required
                />
                <InputWithHorizontalLabel
                  name="dollarValue"
                  placeholder="US$"
                  label="US$"
                  validationBlur={validationBlur}
                  labelWidth="120px"
                  required
                />
                <InputWithHorizontalLabel
                  name="euroValue"
                  placeholder="EU$"
                  label="EU$"
                  validationBlur={validationBlur}
                  labelWidth="120px"
                  required
                />
              </Horizontal>
              <Toggle
                name="active"
                label={intl.formatMessage({ id: "active" })}
              />
            </FieldsContainer>
          </Session>
        </Form>
      </Container>
    </AnimatedMainContainer>
  );
};

export default NewProductEventAssociation;
