import React from "react";

import {
  Container,
  Content,
  AnimatedContainer,
  RouteBreadcrumbs,
} from "./styles";

const AnimatedMainContainer = ({ breadcrumb, children }) => {
  return (
    <Container>
      <Content>
        {breadcrumb && <RouteBreadcrumbs>{breadcrumb}</RouteBreadcrumbs>}

        <AnimatedContainer className="animated-container">
          {children}
        </AnimatedContainer>
      </Content>
    </Container>
  );
};

export default AnimatedMainContainer;
