import React, { useEffect, useState, useRef, useCallback } from "react";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import InputWithLabel from "../../../components/InputWithLabel";
import { useSelector } from "react-redux";
import { useToast } from "../../../hooks/toast";
import { useIntl, FormattedMessage } from "react-intl";
import { MdDescription } from "react-icons/md";
import { Form } from "@unform/web";
import Badge from "../../../components/Badge";
import api from "../../../service";
import * as Yup from "yup";

import {
  Container,
  TitleContainer,
  DocumentsContainer,
  DocumentsUploadContainer,
  DocumentUpload,
  DocumentUploadTitle,
  DocumentDragAndDropContainer,
  Horizontal,
  SubmitContainer,
} from "./styles";
import { useLocation } from "react-router-dom";
import { convertToDateOnly } from "../../../utils/date";
import {
  DocumentOpenText,
  DocumentVisualization,
  FieldsContainer,
  Session,
  SessionContainer,
  SessionIconContainer,
  SessionTitle,
} from "../../Events/Detail/styles";
import PDFModal from "../../../components/PDFModal";
import FormButton from "../../../components/FormButton";
import { useLoading } from "../../../hooks/loading";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";
import getValidationErrors from "../../../utils/getValidationErrors";

const Detail = () => {
  const { token } = useSelector((state) => state.auth);
  const { state } = useLocation();

  const intl = useIntl();
  const { addToast } = useToast();
  const [exhibitorsManualOpen, setExhibitorsManualOpen] = useState(false);
  const [pricingTableOpen, setPricingTableOpen] = useState(false);
  const history = useHistory();
  const { handleLoading } = useLoading();

  const [data, setData] = useState({});
  const [key, setKey] = useState({});

  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleLoading(true);
    api
      .post(
        `/serviceprovider/find`,
        {
          provisionType: state.provisionType,
          opportunityIdNumber: state.opportunityId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        setData(result.data.output);
        setKey(new Date());
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [
    addToast,
    handleLoading,
    intl,
    state.provisionType,
    state.opportunityIdNumber,
    token,
    state.opportunityId,
  ]);

  const handleLogFileDownload = (eventFileType) => {
    api
      .post(
        `/users/events/log-file-download/${data.eventEditionCode}`,
        {
          fileType: eventFileType,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {})
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      });
  };

  const schemaHeadband = Yup.object().shape({
    headband: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "headband_error" })),
  });

  const validationBlurHeadband = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schemaHeadband, name, formRef);
  };

  const saveHeadbandOpportunity = useCallback(
    (contractId, headband) => {
      try {
        setLoading(true);
        if (loading) {
          return;
        }

        api
          .post(
            `/serviceprovider/opportunity/headband`,
            {
              opportunityId: contractId,
              headband: headband,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.headband_opportunity_creation",
              }),
            });
          })
          .catch((err) => {
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, intl, loading, token]
  );

  const saveHeadband = () => {
    validationBlurHeadband("headband");
    const headband = formRef.current.getFieldValue("headband");
    const contractId = data.opportunityId;
    if (headband == null || headband === "") {
      return;
    }
    saveHeadbandOpportunity(contractId, headband);
  };

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "breadcrumb.events" })}
    >
      <Form ref={formRef} key={key} initialData={{ headband: data?.headband }}>
        <Container>
          <TitleContainer>
            <div>{intl.formatMessage({ id: "event_edition_info" })}</div>
            <Badge
              color="#002244"
              text={intl.formatMessage({ id: "goback" })}
              onClick={() =>
                history.push("/serviceprovider/association-provider-view")
              }
              style={{ cursor: "pointer" }}
            />
          </TitleContainer>

          <Horizontal>
            <InputWithLabel
              disabled
              name="active"
              value={data?.eventEditionName}
              label={intl.formatMessage({ id: "event_name" })}
            />
            <InputWithLabel
              disabled
              name="active"
              value={intl.formatMessage({
                id: data?.status ? "active" : "inactive",
              })}
              maxWidth="250px"
              label={intl.formatMessage({ id: "status" })}
            />
          </Horizontal>
          <Horizontal>
            <InputWithLabel
              disabled
              name="active"
              value={
                data?.startEventDate
                  ? convertToDateOnly(data?.startEventDate)
                  : null
              }
              label={intl.formatMessage({ id: "start_date" })}
            />
            <InputWithLabel
              disabled
              name="active"
              value={
                data?.finishEventDate
                  ? convertToDateOnly(data.finishEventDate)
                  : null
              }
              label={intl.formatMessage({ id: "end_date" })}
            />
          </Horizontal>
          <Horizontal>
            <InputWithLabel
              disabled
              name="active"
              value={data?.address}
              label={intl.formatMessage({ id: "venue" })}
            />
            <InputWithLabel
              disabled
              name="active"
              value={data?.pavillion}
              label={intl.formatMessage({ id: "pavillion" })}
            />
          </Horizontal>
          <TitleContainer>
            <div>{intl.formatMessage({ id: "download_area" })}</div>
          </TitleContainer>
          <Session>
            <SessionContainer>
              <SessionIconContainer>
                <MdDescription size="16px" color="#002244" />
              </SessionIconContainer>
              <SessionTitle>
                <FormattedMessage id="documentation" />
              </SessionTitle>
            </SessionContainer>
            <FieldsContainer>
              <DocumentsContainer>
                <DocumentsUploadContainer>
                  <DocumentUpload>
                    <DocumentUploadTitle>
                      Manual do expositor
                    </DocumentUploadTitle>
                    <DocumentDragAndDropContainer>
                      <DocumentVisualization>
                        <DocumentOpenText
                          onClick={() => {
                            if (data.exhibitorManualUrl) {
                              if (data?.contractCurrency === "BRL") {
                                handleLogFileDownload("EXHIBITOR_MANUAL_PT");
                              } else {
                                handleLogFileDownload("EXHIBITOR_MANUAL_EN");
                              }
                              setExhibitorsManualOpen(true);
                            }
                          }}
                        >
                          {}
                          {data.exhibitorManualUrl ? (
                            <FormattedMessage id="view_file" />
                          ) : (
                            <FormattedMessage id="file_is_not_configured" />
                          )}
                        </DocumentOpenText>
                      </DocumentVisualization>
                    </DocumentDragAndDropContainer>
                    <PDFModal
                      setIsOpen={setExhibitorsManualOpen}
                      modalIsOpen={exhibitorsManualOpen}
                      pdfLocation={data.exhibitorManualUrl}
                    />
                  </DocumentUpload>
                  <DocumentUpload>
                    <DocumentUploadTitle>Tabela de preços</DocumentUploadTitle>
                    <DocumentDragAndDropContainer>
                      <DocumentVisualization>
                        <DocumentOpenText
                          onClick={() => {
                            if (data.pricingUrl) {
                              if (data?.contractCurrency === "BRL") {
                                handleLogFileDownload("PRICING_PT");
                              } else {
                                handleLogFileDownload("PRICING_EN");
                              }
                              setPricingTableOpen(true);
                            }
                          }}
                        >
                          {data.pricingUrl ? (
                            <FormattedMessage id="view_file" />
                          ) : (
                            <FormattedMessage id="file_is_not_configured" />
                          )}
                        </DocumentOpenText>
                      </DocumentVisualization>
                    </DocumentDragAndDropContainer>
                    <PDFModal
                      setIsOpen={setPricingTableOpen}
                      modalIsOpen={pricingTableOpen}
                      pdfLocation={data.pricingUrl}
                    />
                  </DocumentUpload>
                </DocumentsUploadContainer>
              </DocumentsContainer>
            </FieldsContainer>
          </Session>
          <TitleContainer>
            <div>{intl.formatMessage({ id: "exhibitor_info" })}</div>
          </TitleContainer>
          <Horizontal>
            <InputWithLabel
              disabled
              name="active"
              value={data?.accountName}
              label={intl.formatMessage({ id: "name" })}
            />
          </Horizontal>
          <Horizontal>
            <InputWithLabel
              disabled
              name="active"
              value={data?.accountAlias}
              label={intl.formatMessage({ id: "commercial_name" })}
            />
          </Horizontal>
          <Horizontal>
            <InputWithLabel
              disabled
              name="active"
              value={data?.accountGovCompanyCode}
              label={intl.formatMessage({ id: "document" })}
            />
          </Horizontal>
          <TitleContainer>
            <div>{intl.formatMessage({ id: "booth" })}</div>
          </TitleContainer>
          <Horizontal>
            <InputWithLabel
              disabled
              name="active"
              value={data?.boothNumber}
              label={intl.formatMessage({ id: "booth_number" })}
            />
            <InputWithLabel
              disabled
              name="active"
              value={data?.area}
              label={intl.formatMessage({ id: "area" })}
            />
            <InputWithLabel
              disabled
              name="active"
              value={data?.contractNumber}
              label={intl.formatMessage({ id: "contract_number" })}
            />
          </Horizontal>
          <Horizontal>
            <InputWithLabel
              disabled
              name="active"
              value={data?.boothInfo?.boothType}
              label={intl.formatMessage({ id: "booth_type" })}
            />
          </Horizontal>
          <Horizontal>
            <InputWithLabel
              name="headband"
              validationBlur={validationBlurHeadband}
              label={intl.formatMessage({ id: "headband" })}
              maxLength="500"
            />
            <SubmitContainer style={{ marginTop: "13px", display: "flex" }}>
              <FormButton
                type="button"
                loading={loading}
                onClick={saveHeadband}
              >
                <FormattedMessage id="save" />
              </FormButton>
            </SubmitContainer>
          </Horizontal>
        </Container>
      </Form>
    </AnimatedMainContainer>
  );
};

export default Detail;
