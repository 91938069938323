import React from "react";

import ReactTooltip from "react-tooltip";
import { MdInfo } from "react-icons/md";

const InfoTooltip = ({ type, name, text }) => (
  <>
    <a data-tip data-for={`${name.split(" ").join("_")}`}>
      <MdInfo size="16px" color="#dadada" />
    </a>
    <ReactTooltip
      id={`${name.split(" ").join("_")}`}
      type={type}
      effect="solid"
      overridePosition={({ left, top }, currentEvent, currentTarget, node) => {
        const d = document.documentElement;
        left = Math.min(d.clientWidth - node.clientWidth, left);
        top = Math.min(d.clientHeight - node.clientHeight, top);
        left = Math.max(74, left);
        top = Math.max(0, top);
        return { top, left };
      }}
    >
      {text}
    </ReactTooltip>
  </>
);

export default InfoTooltip;
