import produce from "immer";

const INITIAL_STATE = {
  message: "",
  isDialog: false,
  messageType: undefined,
};

const auth = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case "ALERT_SUCCESS": {
        const { message, isDialog, messageType, title } = action.payload;

        draft.message = message;
        draft.title = title;
        draft.isDialog = isDialog;
        draft.messageType = messageType;

        break;
      }

      case "ALERT_FAILURE": {
        const { message, isDialog, messageType, title } = action.payload;

        draft.message = message;
        draft.title = title;
        draft.isDialog = isDialog;
        draft.messageType = messageType;

        break;
      }

      case "ALERT_RESET": {
        draft.message = "";
        draft.title = "";
        draft.isDialog = false;
        draft.messageType = undefined;

        break;
      }

      default: {
        return draft;
      }
    }
  });
};

export default auth;
