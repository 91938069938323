import styled from "styled-components";

export const LoadingSpinnerLogo = styled.img`
  width: "42px";

  animation: rotation 2s infinite linear;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;
