import styled from "styled-components";
import Modal from "react-modal";

Modal.setAppElement(document.getElementById("root"));

export const ModalContainer = styled(Modal)`
  position: absolute;
  margin: 0 16px !important;
  padding: 24px;
  background-color: #fff;
  overflow: auto;
  max-height: 100vh;
  z-index: 999999;

  h1 {
    color: #002b45;
    font-family: "Aleo", serif;
    margin-bottom: 24px;
  }

  p {
    margin-bottom: 20px;
  }

  span {
    margin-left: 5em;
  }

  @media (max-width: 1100px) {
    margin: unset;
    margin-right: -50% !important;
  }
`;
