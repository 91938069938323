import React, { useEffect, useMemo, useRef, useState } from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import api from "../../service";
import { Container, LoaderContainer } from "./styles";
import { authSuccess } from "../../redux/modules/auth/actions";
import { changeApplicationLanguage } from "../../redux/modules/language/actions";
import { useToast } from "../../hooks/toast";
import { FormattedMessage, useIntl } from "react-intl";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const AuthSSO = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const { addToast } = useToast();
  const intl = useIntl();
  const history = useHistory();

  const [token, setToken] = useState(query.get("token"));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get("/auth/authenticate-sso-signin", {
        params: {
          token,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
      })
      .then((result) => {
        setLoading(false);
        dispatch(
          authSuccess({
            user: result.data.user,
            customerCenterToken: result.data.customerCenterToken,
            token: result.data.token,
            accountCreated: result.data.accountCreated,
            keepSignedIn: true,
          })
        );
        dispatch(
          changeApplicationLanguage({
            code: result.data.user.language,
          })
        );
      })
      .catch((err) => {
        setLoading(false);
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
        history.push("/");
      });
  }, [token]);

  return (
    <Container>
      {loading && (
        <LoaderContainer>
          <Loader type="Oval" color="#00BFFF" height={100} width={100} />
          <FormattedMessage id="loading..." />
        </LoaderContainer>
      )}
    </Container>
  );
};

export default AuthSSO;
