import produce from "immer";

const INITIAL_STATE = {
  notifications: [],
  unread: 0,
};

const notification = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case "SET_APP_NOTIFICATIONS": {
        const { notifications, unread } = action.payload;
        draft.notifications = notifications || [];
        draft.unread = unread || 0;
        break;
      }
      case "DEDUCT_AN_UNREAD_NOTIFICATION": {
        draft.unread = draft.unread - 1;
        break;
      }
      default: {
        return draft;
      }
    }
  });
};

export default notification;
