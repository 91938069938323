import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import Loader from "react-loader-spinner";
import * as XLSX from "xlsx";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { useToast } from "../../hooks/toast";
import * as Yup from "yup";
import { useCallback } from "react";
import FormButton from "../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";
import FileSaver from "file-saver";
import Dropzone from "../../components/Dropzone";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  ActionContainer,
  BadgeContainer,
  GuestsActionContainer,
  FileDropzone,
} from "./styles";

import Table from "../../components/Table";
import Badge from "../../components/Badge";
import { useLoading } from "../../hooks/loading";
import api from "../../service";
import getValidationErrors from "../../utils/getValidationErrors";
import SelectWithLabel from "../../components/SelectWithLabel";
import ConfirmationModal from "../../components/ConfirmationModal";

const EventSetupHelpdesk = () => {
  const { token } = useSelector((state) => state.auth);
  const [setups, setSetups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [eventSetupId, setEventSetupId] = useState(null);
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();
  const { handleLoading } = useLoading();
  const [filtered, setFiltered] = useState(false);
  const [xlsx, setXlsx] = useState([]);
  const [customersSurvey, setCustomerSurvey] = useState([]);

  const schema = Yup.object().shape({
    setups: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    file: Yup.array()
      .nullable()
      .min(1, intl.formatMessage({ id: "error.field_required" })),
  });

  const readExcel = useCallback(
    (file) => {
      handleLoading(true);
      new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onloadend = (e) => {
          const bufferArray = e.target.result;
          const wb = XLSX.read(bufferArray, {
            type: "buffer",
            cellDates: true,
          });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws);
          resolve(data);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      })
        .then((content) => {
          setXlsx(content);
        })
        .finally(() => handleLoading(false));
    },
    [handleLoading]
  );

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        await schema.validate(data, { abortEarly: false });
        setLoading(true);
        if (loading) {
          return;
        }
        await readExcel(data.file[0]);
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [schema, loading, readExcel]
  );

  useEffect(() => {
    if (xlsx.length > 0 && formRef.current.getFieldValue("setups") != null) {
      let inputList = [];
      const setups = formRef.current.getFieldValue("setups");

      xlsx.map((cellValue) => {
        let temp = {
          ticketNumber: cellValue.numero_ticket,
          entryDate: new Date(cellValue.data_entrada),
          ticketCategory: cellValue.categoria_assunto_ticket,
          ticketSubject: cellValue.assunto_ticket,
          satisfactionSurvey: cellValue.pesquisa_satisfacao,
        };
        inputList.push(temp);
        return inputList;
      });

      api
        .put(
          `/admin/setup-event/helpdesk/${setups}`,
          {
            inputList,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          addToast({
            type: "success",
            title: intl.formatMessage({
              id: "success",
            }),
            description: intl.formatMessage({
              id: "success.setup_event_helpdesk_creation",
            }),
          });
          formRef.current.setFieldValue("setups", {
            label: intl.formatMessage({ id: "event" }),
            value: null,
          });
          formRef.current.clearField("file");
          setFiltered(false);
        })
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => {
          setLoading(false);
        });
    }
  }, [addToast, intl, token, xlsx]);

  useEffect(() => {
    handleLoading(true);
    api
      .patch(
        `/admin/setup-event`,
        { eventSetupName: null, eventsId: null },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        const events = result.data.setupEventOutputList.map((event) => ({
          label: event.eventSetupName,
          value: event.id,
        }));
        let eventsSort = [...events].sort((a, b) =>
          a.label < b.label ? -1 : a.label > b.label ? 1 : 0
        );
        setSetups(eventsSort);
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, intl, token, handleLoading]);

  const getSetupsEventHelpdesk = useCallback((setups) => {
    handleLoading(true);
    api
      .patch(
        `/admin/setup-event/helpdesk/${setups}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        setCustomerSurvey(result.data.output);
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, intl, token, handleLoading]);

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("setups", {
      label: intl.formatMessage({ id: "event" }),
      value: null,
    });
    formRef.current.clearField("file");
    setFiltered(false);
  };

  const onChangeEvent = (setup) => {
    if (setup.value != null) {
      getSetupsEventHelpdesk(setup.value);
      setFiltered(true);
    } else {
      setFiltered(false);
    }
  };

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const handleDeletion = () => {
    handleLoading(true);
    api
      .delete(`/admin/setup-event/${eventSetupId}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        addToast({
          type: "success",
          title: intl.formatMessage({
            id: "success",
          }),
          description: intl.formatMessage({
            id: "success.unassociation",
          }),
        });
        setEventSetupId(null);
        formRef.current.submitForm();
      })
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => {
        setModalIsOpen(false);
        handleLoading(false);
      });
  };

  const handleDownloadTemplate = () => {
    FileSaver.saveAs(
      `${process.env.PUBLIC_URL}/survey_template.xlsx`,
      "Survey_Template.xlsx"
    );
  };

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "ticket_number" }),
        selector: (row) => row.ticketNumber,
        allowOverflow: true,
        wrap: true,
        maxWidth: "145px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "entry_date" }),
        selector: (row) => row.entryDate,
        allowOverflow: true,
        wrap: true,
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "ticket_category" }),
        selector: (row) => row.ticketCategory,
        allowOverflow: true,
        wrap: true,
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "ticket_subject" }),
        selector: (row) => row.ticketSubject,
        allowOverflow: true,
        wrap: true,
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "satisfaction_survey" }),
        selector: (row) => row.satisfactionSurvey,
        allowOverflow: true,
        wrap: true,
        sortable: true,
      },
    ],
    [intl]
  );

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "breadcrumb.event_setup_helpdesk" })}
    >
      <Container>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <TitleContainer>
              <Title>
                <FormattedMessage id="search_for_events" />
              </Title>
              <ActionContainer>
                <BadgeContainer onClick={() => history.push("/dashboard")}>
                  <Badge
                    color="#C9C9C9"
                    text={intl.formatMessage({ id: "goback" })}
                    fontSize="12px"
                  />
                </BadgeContainer>
              </ActionContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal>
                <SelectWithLabel
                  label={intl.formatMessage({
                    id: "event",
                  })}
                  placeholder={intl.formatMessage({
                    id: "event",
                  })}
                  labelOrientation="vertical"
                  options={setups}
                  validationBlur={validationBlur}
                  name="setups"
                  onChange={(option) => onChangeEvent(option)}
                  required
                />
              </Horizontal>
              <Horizontal>
                <FileDropzone>
                  <Dropzone
                    name="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    type="XLSX"
                    multiple={false}
                  />
                </FileDropzone>
              </Horizontal>
              <Horizontal>
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit">
                      <FormattedMessage id="save" />
                    </FormButton>
                  </SubmitContainer>
                </FilterActionsContainer>
              </Horizontal>
            </FieldsContainer>
          </Form>
        </FormContainer>
      </Container>
      <GuestsActionContainer>
        <BadgeContainer onClick={() => handleDownloadTemplate()}>
          <Badge
            color="#33d27e"
            text={intl.formatMessage({ id: "download_template" })}
            fontSize="16px"
          />
        </BadgeContainer>
      </GuestsActionContainer>
      {filtered ? (
        <>
          <GuestsActionContainer />
          <Table
            columns={columns}
            data={customersSurvey}
            title={intl.formatMessage({ id: "results" })}
            progressPending={loading}
            progressComponent={
              <Loader type="Oval" color="#00BFFF" height={100} width={100} />
            }
          />
        </>
      ) : (
        <GuestsActionContainer>
          <FormattedMessage id="need_to_filter_first" />
        </GuestsActionContainer>
      )}
      <ConfirmationModal
        setIsOpen={setModalIsOpen}
        modalIsOpen={modalIsOpen}
        confirmationFunction={handleDeletion}
        description={intl.formatMessage({ id: "exclusion_confirmation" })}
        confirmationText={intl.formatMessage({ id: "yes" })}
        cancelText={intl.formatMessage({ id: "no" })}
      />
    </AnimatedMainContainer>
  );
};

export default EventSetupHelpdesk;
