import styled from "styled-components";

export const CollapseToggle = styled.div`
  font-size: 14px;
  font-weight: normal;
  padding: 4px 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  flex-direction: column;
  margin: 16px 0px;
  cursor: ${({ hasElements }) => (hasElements ? "pointer" : "auto")};
`;

export const CollapseContainer = styled.div`
  display: flex;
  border: 1px solid #ccc;
  padding: 8px;
  margin-bottom: 16px;
  flex-direction: column;
  flex-wrap: wrap;
`;

export const PrincipalContainer = styled.div`
  display: flex;
  width: 100%;
  margin-left: 8px;
  padding: 8px 0px;
`;

export const ItemContainer = styled.div`
  padding: 8px 0px;
  display: flex;
`;

export const ItemCheckbox = styled.div`
  flex: 0 0 20px;
  > svg {
    min-width: 20px;
    color: #002244;
  }
`;

export const ItemTitle = styled.p`
  flex: 1;
  margin-left: 8px;
`;

export const ItemArrow = styled.div`
  flex: 0 0 20px;
`;

export const SubItemsContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  justify-content: center;
  margin: 10px 0 10px 40px;

  @media (max-width: 500px) {
    margin-left: 0px !important;
  }
`;

export const SubItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100px;
  justify-content: center;
  align-items: center;
  padding: 8px;
  margin: 8px 12px 0px 12px;
  border-radius: 16px;
  background-color: #bcbcbc;
`;

export const SubItemContainerTitle = styled.div`
  flex: 1;
  font-weight: bold;
  text-align: center;
`;

export const SubItemContainerValue = styled.div`
  flex: 0 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
