import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useToast } from "../../hooks/toast";
import api from "../../service";
import { useSelector } from "react-redux";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { useCallback } from "react";
import FormButton from "../../components/FormButton";
import Table from "../../components/Table";
import { useLoading } from "../../hooks/loading";
import Badge from "../../components/Badge";
import axios from "axios";
import { format, parseISO } from "date-fns";
import {
  DivFlex,
  Card,
  TitleCard,
  TotalQuantityOfCard,
  ButtonContainer,
  LinkButton,
  TableContainer,
  TitleContainer,
  TitleTable,
  LinkButtonSalesForce,
} from "./styles";

const Dashboard = () => {
  const { user, token } = useSelector((state) => state.auth);
  const intl = useIntl();
  const { addToast } = useToast();
  const [quantityActiveEvents, setQuantityActiveEvents] = useState(0);
  const [
    quantityOpportunitiesWithActiveEvents,
    setQuantitytOpportunitiesWithActiveEvents,
  ] = useState(0);
  const [quantityOrders, setQuantityOrders] = useState(0);
  const [events, setEvents] = useState([]);
  const [orders, setOrders] = useState([]);
  const { isLoading, handleLoading } = useLoading();
  const [urlSalesForce, setUrlSalesForce] = useState("");
  const dashboardCategoryName = "DASHBOARD";
  const showDashboardPermissionName = "SHOW_DASHBOARD";

  const hasShowDashboardPermission = useCallback(
    (user) => {
      let dashboardCategory = user?.permissionCategories.find(
        (permission) => permission.code === dashboardCategoryName
      );
      if (dashboardCategory?.permissions) {
        return dashboardCategory.permissions.some(
          (infoPermission) =>
            infoPermission.code === showDashboardPermissionName
        );
      }
      return false;
    },
    [dashboardCategoryName, showDashboardPermissionName]
  );

  useEffect(() => {
    if (hasShowDashboardPermission(user)) {
      handleLoading(true);

      if (process.env.REACT_APP_ENVIROMENT === "prd") {
        setUrlSalesForce(`https://informage.lightning.force.com/`);
      } else {
        setUrlSalesForce(`https://informage--uat.lightning.force.com/`);
      }

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .all([
          api.get(
            "/admin/dashboard/find-number-active-events-and-opportunities",
            config
          ),
          api.patch(
            "/admin/opportunity",
            { event: null, exhibitor: null, contract: null },
            config
          ),
        ])
        .then(
          axios.spread((informationsCards, informationsTableOpportunities) => {
            setQuantityActiveEvents(
              informationsCards.data.output.numberOfActiveEvents
            );
            setQuantitytOpportunitiesWithActiveEvents(
              informationsCards.data.output
                .numberOfOpportunitiesWithActiveEvents
            );
            setEvents(
              informationsTableOpportunities.data.opportunityInformation
            );
          })
        )
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    }
  }, [intl, handleLoading, user, token, addToast, hasShowDashboardPermission]);

  useEffect(() => {
    if (hasShowDashboardPermission(user)) {
      handleLoading(true);

      if (process.env.REACT_APP_ENVIROMENT === "prd") {
        setUrlSalesForce(`https://informage.lightning.force.com/`);
      } else {
        setUrlSalesForce(`https://informage--uat.lightning.force.com/`);
      }

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      };

      api
        .patch(
          "/admin/ecommerce/chleba/last-order-imported",
          {
            orderId: null,
            description: null,
            paid: null,
            total: null,
            saleDate: null,
          },
          config
        )
        .then((informationsTableOrders) => {
          const orders =
            informationsTableOrders.data.ecommerceChlebaLastOrderImportedOutput;
          setQuantityOrders(orders.length);
          setOrders(orders);
        })
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    }
  }, [intl, handleLoading, user, token, addToast, hasShowDashboardPermission]);

  const updateCard = useCallback(
    (data) => {
      if (hasShowDashboardPermission(user)) {
        handleLoading(true);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        };
        axios
          .all([
            api.get(
              "/admin/dashboard/find-number-active-events-and-opportunities",
              config
            ),
            api.patch(
              "/admin/opportunity",
              { event: null, exhibitor: null, contract: null },
              config
            ),
          ])
          .then(
            axios.spread(
              (
                informationsCards,
                informationsTableOpportunities,
              ) => {
                setQuantityActiveEvents(
                  informationsCards.data.output.numberOfActiveEvents
                );
                setQuantitytOpportunitiesWithActiveEvents(
                  informationsCards.data.output
                    .numberOfOpportunitiesWithActiveEvents
                );
                setEvents(
                  informationsTableOpportunities.data.opportunityInformation
                );
              }
            )
          )
          .catch((err) =>
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            })
          )
          .finally(() => handleLoading(false));
      }
    },
    [intl, handleLoading, user, token, addToast, hasShowDashboardPermission]
  );

  const updateOrdersCard = useCallback(
    (data) => {
      if (hasShowDashboardPermission(user)) {
        handleLoading(true);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        };
        api
          .patch(
            "/admin/ecommerce/chleba/last-order-imported",
            {
              orderId: null,
              description: null,
              paid: null,
              total: null,
              saleDate: null,
            },
            config
          )
          .then((informationsTableOrders) => {
            const orders =
              informationsTableOrders.data
                .ecommerceChlebaLastOrderImportedOutput;
            setQuantityOrders(orders.length);
            setOrders(orders);
          })
          .catch((err) =>
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            })
          )
          .finally(() => handleLoading(false));
      }
    },
    [intl, handleLoading, user, token, addToast, hasShowDashboardPermission]
  );

  const updateAllCards = () => {
    updateCard();
    updateOrdersCard();
  }

  const columnsTableOpportunities = [
    {
      name: intl.formatMessage({ id: "event_edition" }),
      selector: (row) => row.eventEditionName,
      sortable: true,
      maxWidth: "400px",
      minWidth: "250px",
      allowOverflow: true,
      wrap: true,
    },
    {
      name: intl.formatMessage({ id: "exhibitor" }),
      selector: (row) => row.accountName,
      sortable: true,
      maxWidth: "300px",
      minWidth: "250px",
      allowOverflow: true,
      wrap: true,
    },
    {
      name: intl.formatMessage({ id: "contract" }),
      selector: (row) => row.contractNumber,
      sortable: true,
      maxWidth: "50px",
    },
    {
      cell: (row) => (
        <LinkButton to={`/contract/${row.opportunityId}`}>
          <Badge
            color="#002244"
            text={intl.formatMessage({ id: "see_details" })}
          />
        </LinkButton>
      ),
      right: true,
      minWidth: "125px",
    },
    {
      cell: (row) => (
        <LinkButtonSalesForce
          href={`${urlSalesForce}lightning/r/Opportunity/${row.opportunityId}/view`}
          target="_blank"
        >
          <Badge
            color="#002244"
            text={intl.formatMessage({ id: "salesforce" })}
          />
        </LinkButtonSalesForce>
      ),
      right: true,
      maxWidth: "125px",
    },
  ];

  const columnsTableOrders = [
    {
      name: intl.formatMessage({ id: "orderId" }),
      selector: (row) => row.orderId,
    },
    {
      name: intl.formatMessage({ id: "description" }),
      selector: (row) => row.description,
    },
    {
      name: intl.formatMessage({ id: "paid" }),
      selector: (row) => row.paid,
      format: (row) =>
        row.paid
          ? intl.formatMessage({ id: "yes" })
          : intl.formatMessage({ id: "no" }),
    },
    {
      name: intl.formatMessage({ id: "total" }),
      selector: (row) => row.total,
    },
    {
      name: intl.formatMessage({ id: "sale_date" }),
      selector: (row) => row.saleDate,
      sortable: false,
      format: (row) => format(parseISO(row.saleDate), "dd/MM/yyyy HH:mm"),
      wrap: true,
    },
  ];

  if (hasShowDashboardPermission(user)) {
    return (
      <AnimatedMainContainer>
        {user.name}

        <DivFlex>
          <Card color=" rgba(0, 34, 68, 0.7);">
            <TitleCard>
              <FormattedMessage id="active_events_editions" />
            </TitleCard>
            <TotalQuantityOfCard>{quantityActiveEvents}</TotalQuantityOfCard>
          </Card>

          <Card color="rgba(17, 167, 217, 0.7)">
            <TitleCard>
              <FormattedMessage id="contracts" />
            </TitleCard>
            <TotalQuantityOfCard>
              {quantityOpportunitiesWithActiveEvents}
            </TotalQuantityOfCard>
          </Card>

          <Card color="rgba(51, 210, 126, 0.7)">
            <TitleCard>
              <FormattedMessage id="additional_orders" />
            </TitleCard>
            <TotalQuantityOfCard>{quantityOrders}</TotalQuantityOfCard>
          </Card>
        </DivFlex>
        <TitleContainer>
          <TitleTable>
            <FormattedMessage id="last_imported_opportunities" />
          </TitleTable>
          <ButtonContainer>
            <FormButton onClick={() => updateAllCards()} marginTop={"0px"}>
              <FormattedMessage id="update" />
            </FormButton>
          </ButtonContainer>
        </TitleContainer>
        <TableContainer>
          <Table
            columns={columnsTableOpportunities}
            data={events}
            marginTop={"0px"}
          />
        </TableContainer>
        <TitleContainer>
          <TitleTable>
            <FormattedMessage id="last_orders_placed" />
          </TitleTable>
        </TitleContainer>
        <TableContainer>
          <Table columns={columnsTableOrders} data={orders} marginTop={"0px"} />
        </TableContainer>
      </AnimatedMainContainer>
    );
  } else {
    return <AnimatedMainContainer>{user.name}</AnimatedMainContainer>;
  }
};

export default Dashboard;
