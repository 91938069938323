import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useSelector } from "react-redux";

import { FormattedMessage, useIntl } from "react-intl";

import { useToast } from "../../hooks/toast";
import api from "../../service";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import ConfirmationModal from "../../components/ConfirmationModal";

import { useLoading } from "../../hooks/loading";
import {
  Container,
  FormContainer,
  LinkButton,
  Title,
  TitleContainer,
  BadgeContainer,
} from "./styles";

import Badge from "../../components/Badge";

import Table from "../../components/Table";

import { ActionContainer } from "./Edit/styles";

const Faq = () => {
  const intl = useIntl();
  const { addToast } = useToast();
  const { token } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const showConfirmation = (id) => {
    setModalIsOpen(true);
    setDeleteId(id);
  };

  useEffect(() => {
    handleLoading(true);
    api
      .get("faq/list/false", {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setData(result.data.faqListOutput);
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
        setModalIsOpen(false);
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, intl, token, handleLoading]);

  const handleDeleteFaq = () => {
    setLoading(true);
    if (loading) {
      return;
    }
    api
      .delete(`faq/${deleteId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setLoading(false);
        addToast({
          type: "success",
          title: intl.formatMessage({
            id: "success",
          }),
          description: intl.formatMessage({
            id: "success.faq_delete",
          }),
        });
        const dataRemaining = data.filter((d) => d.id !== deleteId);

        setData(dataRemaining);
        setModalIsOpen(false);
      })
      .catch((err) => {
        setLoading(false);
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
        setModalIsOpen(false);
      });
    setDeleteId(0);
  };

  const columns = [
    {
      name: intl.formatMessage({ id: "question_pt" }),
      selector: (row) => row.questionPT,
      sortable: true,
      maxWidth: "250px",
    },
    {
      name: intl.formatMessage({ id: "answer_pt" }),
      selector: (row) => row.answerPT,
      sortable: true,
      maxWidth: "400px",
    },
    {
      name: intl.formatMessage({ id: "question_en" }),
      selector: (row) => row.questionEN,
      sortable: true,
      maxWidth: "250px",
    },
    {
      name: intl.formatMessage({ id: "answer_en" }),
      selector: (row) => row.answerEN,
      sortable: true,
      maxWidth: "400px",
    },
    {
      cell: (row) => (
        <LinkButton to={`/faq/edit/${row.id}`}>
          <Badge color="#002244" text={intl.formatMessage({ id: "edit" })} />
        </LinkButton>
      ),
      right: true,
      maxWidth: "250px",
      minWidth: "170px",
    },
    {
      cell: (row) => (
        <Badge
          color="#cc0000"
          text={intl.formatMessage({ id: "delete" })}
          onClick={() => showConfirmation(row.id)}
        />
      ),
      right: true,
      maxWidth: "250px",
      minWidth: "170px",
    },
  ];

  const pushBack = () => {
    history.push("/dashboard");
  };

  return (
    <>
      <AnimatedMainContainer breadcrumb="FAQ">
        <Container>
          <FormContainer>
            <TitleContainer>
              <ActionContainer>
                <Title>
                  <FormattedMessage id="edit_knowledge_base" />
                </Title>

                <BadgeContainer onClick={pushBack}>
                  <Badge
                    color="#002244"
                    text={intl.formatMessage({ id: "goback" })}
                    fontSize="12px"
                  />
                </BadgeContainer>
                <BadgeContainer onClick={() => history.push("/faq/create")}>
                  <Badge
                    color="#002244"
                    text={intl.formatMessage({ id: "new" })}
                    fontSize="12px"
                  />
                </BadgeContainer>
              </ActionContainer>
            </TitleContainer>
          </FormContainer>
        </Container>
        <Table
          columns={columns}
          data={data}
          title={intl.formatMessage({ id: "results" })}
        />
      </AnimatedMainContainer>

      <ConfirmationModal
        setIsOpen={setModalIsOpen}
        modalIsOpen={modalIsOpen}
        confirmationFunction={() => handleDeleteFaq()}
        description={intl.formatMessage({ id: "exclusion_confirmation" })}
        confirmationText={intl.formatMessage({ id: "yes" })}
        cancelText={intl.formatMessage({ id: "no" })}
        isLoading={loading}
      />
    </>
  );
};

export default Faq;
