import React, { useCallback, useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Form } from "@unform/web";
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationErrors";

import { useSelector } from "react-redux";

import { FormattedMessage, useIntl } from "react-intl";
import Table from "../../../components/Table";

import {
  Container,
  TitleContainer,
  Title,
  Session,
  TableContainer,
  ActionContainer,
  BadgeContainer,
  ValuesContainer,
  ParameterContainer,
  ParameterTitle,
  ParameterValue,
} from "./styles";

import { useToast } from "../../../hooks/toast";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";
import api from "../../../service";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import Badge from "../../../components/Badge";
import { useEffect } from "react";
import { useLoading } from "../../../hooks/loading";
import InputWithLabel from "../../../components/InputWithLabel";

const EditCategory = () => {
  const formRef = useRef(null);
  const intl = useIntl();
  const { addToast } = useToast();
  const { token } = useSelector((state) => state.auth);
  const { id } = useParams();

  const [data, setData] = useState();
  const [key, setKey] = useState();

  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const schema = Yup.object().shape({
    projectDelivery: Yup.string()
      .matches(
        /^(^$|([0-9]*))$/g,
        intl.formatMessage({ id: "error.need_to_be_number" })
      )
      .required(intl.formatMessage({ id: "error.field_required" }))
      .max(
        2,
        intl.formatMessage(
          { id: "error.number_value_range" },
          { start: 0, end: 99 }
        )
      ),
    paymentMade: Yup.string()
      .matches(
        /^(^$|([0-9]*))$/g,
        intl.formatMessage({ id: "error.need_to_be_number" })
      )
      .required(intl.formatMessage({ id: "error.field_required" }))
      .max(
        2,
        intl.formatMessage(
          { id: "error.number_value_range" },
          { start: 0, end: 99 }
        )
      ),
    contractGrowthArea: Yup.string()
      .matches(
        /^(^$|([0-9]*))$/g,
        intl.formatMessage({ id: "error.need_to_be_number" })
      )
      .required(intl.formatMessage({ id: "error.field_required" }))
      .max(
        2,
        intl.formatMessage(
          { id: "error.number_value_range" },
          { start: 0, end: 99 }
        )
      ),
    contractGrowthValue: Yup.string()
      .matches(
        /^(^$|([0-9]*))$/g,
        intl.formatMessage({ id: "error.need_to_be_number" })
      )
      .required(intl.formatMessage({ id: "error.field_required" }))
      .max(
        2,
        intl.formatMessage(
          { id: "error.number_value_range" },
          { start: 0, end: 99 }
        )
      ),
    recurrence: Yup.string()
      .matches(
        /^(^$|([0-9]*))$/g,
        intl.formatMessage({ id: "error.need_to_be_number" })
      )
      .required(intl.formatMessage({ id: "error.field_required" }))
      .max(
        2,
        intl.formatMessage(
          { id: "error.number_value_range" },
          { start: 0, end: 99 }
        )
      ),
    upselling: Yup.string()
      .matches(
        /^(^$|([0-9]*))$/g,
        intl.formatMessage({ id: "error.need_to_be_number" })
      )
      .required(intl.formatMessage({ id: "error.field_required" }))
      .max(
        2,
        intl.formatMessage(
          { id: "error.number_value_range" },
          { start: 0, end: 99 }
        )
      ),
    loyaltyIndex: Yup.string()
      .matches(
        /^(^$|([0-9]*))$/g,
        intl.formatMessage({ id: "error.need_to_be_number" })
      )
      .required(intl.formatMessage({ id: "error.field_required" }))
      .max(
        2,
        intl.formatMessage(
          { id: "error.number_value_range" },
          { start: 0, end: 99 }
        )
      ),
    sponsor: Yup.string()
      .matches(
        /^(^$|([0-9]*))$/g,
        intl.formatMessage({ id: "error.need_to_be_number" })
      )
      .required(intl.formatMessage({ id: "error.field_required" }))
      .max(
        2,
        intl.formatMessage(
          { id: "error.number_value_range" },
          { start: 0, end: 99 }
        )
      ),
    contentProducer: Yup.string()
      .matches(
        /^(^$|([0-9]*))$/g,
        intl.formatMessage({ id: "error.need_to_be_number" })
      )
      .required(intl.formatMessage({ id: "error.field_required" }))
      .max(
        2,
        intl.formatMessage(
          { id: "error.number_value_range" },
          { start: 0, end: 99 }
        )
      ),
    brandExhibitor: Yup.string()
      .matches(
        /^(^$|([0-9]*))$/g,
        intl.formatMessage({ id: "error.need_to_be_number" })
      )
      .required(intl.formatMessage({ id: "error.field_required" }))
      .max(
        2,
        intl.formatMessage(
          { id: "error.number_value_range" },
          { start: 0, end: 99 }
        )
      ),
    digitalExhibitor: Yup.string()
      .matches(
        /^(^$|([0-9]*))$/g,
        intl.formatMessage({ id: "error.need_to_be_number" })
      )
      .required(intl.formatMessage({ id: "error.field_required" }))
      .max(
        2,
        intl.formatMessage(
          { id: "error.number_value_range" },
          { start: 0, end: 99 }
        )
      ),
    employeeRegistration: Yup.string()
      .matches(
        /^(^$|([0-9]*))$/g,
        intl.formatMessage({ id: "error.need_to_be_number" })
      )
      .required(intl.formatMessage({ id: "error.field_required" }))
      .max(
        2,
        intl.formatMessage(
          { id: "error.number_value_range" },
          { start: 0, end: 99 }
        )
      ),
    credentialsFill: Yup.string()
      .matches(
        /^(^$|([0-9]*))$/g,
        intl.formatMessage({ id: "error.need_to_be_number" })
      )
      .required(intl.formatMessage({ id: "error.field_required" }))
      .max(
        2,
        intl.formatMessage(
          { id: "error.number_value_range" },
          { start: 0, end: 99 }
        )
      ),
    scheduleWithdrawal: Yup.string()
      .matches(
        /^(^$|([0-9]*))$/g,
        intl.formatMessage({ id: "error.need_to_be_number" })
      )
      .required(intl.formatMessage({ id: "error.field_required" }))
      .max(
        2,
        intl.formatMessage(
          { id: "error.number_value_range" },
          { start: 0, end: 99 }
        )
      ),
    invitationRegistration: Yup.string()
      .matches(
        /^(^$|([0-9]*))$/g,
        intl.formatMessage({ id: "error.need_to_be_number" })
      )
      .required(intl.formatMessage({ id: "error.field_required" }))
      .max(
        2,
        intl.formatMessage(
          { id: "error.number_value_range" },
          { start: 0, end: 99 }
        )
      ),
    associationOfServiceProviders: Yup.string()
      .matches(
        /^(^$|([0-9]*))$/g,
        intl.formatMessage({ id: "error.need_to_be_number" })
      )
      .required(intl.formatMessage({ id: "error.field_required" }))
      .max(
        2,
        intl.formatMessage(
          { id: "error.number_value_range" },
          { start: 0, end: 99 }
        )
      ),
    accessToKnowledgeBase: Yup.string()
      .matches(
        /^(^$|([0-9]*))$/g,
        intl.formatMessage({ id: "error.need_to_be_number" })
      )
      .required(intl.formatMessage({ id: "error.field_required" }))
      .max(
        2,
        intl.formatMessage(
          { id: "error.number_value_range" },
          { start: 0, end: 99 }
        )
      ),
    eventMaterials: Yup.string()
      .matches(
        /^(^$|([0-9]*))$/g,
        intl.formatMessage({ id: "error.need_to_be_number" })
      )
      .required(intl.formatMessage({ id: "error.field_required" }))
      .max(
        2,
        intl.formatMessage(
          { id: "error.number_value_range" },
          { start: 0, end: 99 }
        )
      ),
    exhibitorManual: Yup.string()
      .matches(
        /^(^$|([0-9]*))$/g,
        intl.formatMessage({ id: "error.need_to_be_number" })
      )
      .required(intl.formatMessage({ id: "error.field_required" }))
      .max(
        2,
        intl.formatMessage(
          { id: "error.number_value_range" },
          { start: 0, end: 99 }
        )
      ),
    priceList: Yup.string()
      .matches(
        /^(^$|([0-9]*))$/g,
        intl.formatMessage({ id: "error.need_to_be_number" })
      )
      .required(intl.formatMessage({ id: "error.field_required" }))
      .max(
        2,
        intl.formatMessage(
          { id: "error.number_value_range" },
          { start: 0, end: 99 }
        )
      ),
  });

  useEffect(() => {
    handleLoading(true);
    api
      .get(`/admin/setup-event/parameters/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setData(response.data.eventsAdminParametersOutput);
        setKey(new Date());
      })
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, id, intl, token, handleLoading]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        setLoading(true);
        if (loading) {
          return;
        }
        await schema.validate(data, { abortEarly: false });

        api
          .put(
            `/admin/setup-event/parameters/${id}`,
            {
              projectDelivery: data.projectDelivery,
              paymentMade: data.paymentMade,
              contractGrowthArea: data.contractGrowthArea,
              contractGrowthValue: data.contractGrowthValue,
              recurrence: data.recurrence,
              upselling: data.upselling,
              loyaltyIndex: data.loyaltyIndex,
              sponsor: data.sponsor,
              contentProducer: data.contentProducer,
              brandExhibitor: data.brandExhibitor,
              digitalExhibitor: data.digitalExhibitor,
              employeeRegistration: data.employeeRegistration,
              credentialsFill: data.credentialsFill,
              scheduleWithdrawal: data.scheduleWithdrawal,
              invitationRegistration: data.invitationRegistration,
              associationOfServiceProviders: data.associationOfServiceProviders,
              accessToKnowledgeBase: data.accessToKnowledgeBase,
              eventMaterials: data.eventMaterials,
              exhibitorManual: data.exhibitorManual,
              priceList: data.priceList,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.event_parameters_defined",
              }),
            });
            history.push("/admin/event-setup");
          })
          .catch((err) =>
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            })
          )
          .finally(setLoading(false));
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [addToast, history, id, intl, loading, schema, token]
  );

  const timeParameters = [
    intl.formatMessage({ id: "overdue" }),
    intl.formatMessage({ id: "on_time" }),
    intl.formatMessage({ id: "before_the_deadline" }),
  ];

  const affirmativeParameters = [
    intl.formatMessage({ id: "no" }),
    intl.formatMessage({ id: "yes" }),
  ];

  const isFilledParameters = [
    intl.formatMessage({ id: "not_filled" }),
    intl.formatMessage({ id: "filled" }),
  ];

  const percentageParameters = [
    "0%",
    intl.formatMessage({ id: "to_percent" }, { percent: 10 }),
    "11% - 30%",
    "31% - 50%",
    "50% - 80%",
    "> 81%",
  ];

  const generateValuesContainer = useCallback(
    (elements) =>
      elements ? (
        elements.map((element, index) => {
          return (
            <ParameterContainer key={index}>
              <ParameterTitle>{element}</ParameterTitle>
              <ParameterValue>{index}</ParameterValue>
            </ParameterContainer>
          );
        })
      ) : (
        <ParameterContainer>
          {intl.formatMessage({ id: "counter" })}
        </ParameterContainer>
      ),
    [intl]
  );

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "parameter" }),
        selector: (row) => row.parameter,
        cell: (row) => row.parameter,
        width: "200px",
      },
      {
        name: intl.formatMessage({ id: "values" }),
        selector: (row) => row.values,
        cell: (row) => (
          <ValuesContainer>
            {generateValuesContainer(row.values)}
          </ValuesContainer>
        ),
        minWidth: "600px",
        center: true,
      },
      {
        cell: (row) => (
          <InputWithLabel
            name={row.name}
            validationBlur={(name) => {
              let errors = formRef.current.getErrors();
              validateSingleFieldOnBlur(errors, schema, name, formRef);
            }}
            disabled={
              row.name === "projectDelivery" || row.name === "paymentMade"
            }
          />
        ),
        width: "300px",
      },
    ],
    [generateValuesContainer, intl, schema]
  );

  const rows = [
    {
      parameter: intl.formatMessage({ id: "project_delivery" }),
      values: timeParameters,
      name: "projectDelivery",
    },
    {
      parameter: intl.formatMessage({ id: "payment_made" }),
      values: timeParameters,
      name: "paymentMade",
    },
    {
      parameter: intl.formatMessage({ id: "contract_growth_area" }),
      values: percentageParameters,
      name: "contractGrowthArea",
    },
    {
      parameter: intl.formatMessage({ id: "contract_growth_value" }),
      values: percentageParameters,
      name: "contractGrowthValue",
    },
    {
      parameter: intl.formatMessage({ id: "recurrence" }),
      name: "recurrence",
    },
    {
      parameter: intl.formatMessage({ id: "upselling" }),
      values: percentageParameters,
      name: "upselling",
    },
    {
      parameter: intl.formatMessage({ id: "loyalty_index" }),
      name: "loyaltyIndex",
    },
    {
      parameter: intl.formatMessage({ id: "sponsor" }),
      values: affirmativeParameters,
      name: "sponsor",
    },
    {
      parameter: intl.formatMessage({ id: "content_producer" }),
      values: affirmativeParameters,
      name: "contentProducer",
    },
    {
      parameter: intl.formatMessage({ id: "brand_exhibitor" }),
      values: affirmativeParameters,
      name: "brandExhibitor",
    },
    {
      parameter: intl.formatMessage({ id: "digital_exhibitor" }),
      values: affirmativeParameters,
      name: "digitalExhibitor",
    },
    {
      parameter: intl.formatMessage({ id: "employee_registration" }),
      values: isFilledParameters,
      name: "employeeRegistration",
    },
    {
      parameter: intl.formatMessage({ id: "credentials_fill" }),
      values: isFilledParameters,
      name: "credentialsFill",
    },
    {
      parameter: intl.formatMessage({ id: "schedule_withdrawal" }),
      values: affirmativeParameters,
      name: "scheduleWithdrawal",
    },
    {
      parameter: intl.formatMessage({ id: "invitation_registration" }),
      values: isFilledParameters,
      name: "invitationRegistration",
    },
    {
      parameter: intl.formatMessage({ id: "association_of_service_providers" }),
      values: isFilledParameters,
      name: "associationOfServiceProviders",
    },
    {
      parameter: intl.formatMessage({ id: "access_to_knowledge_base" }),
      name: "accessToKnowledgeBase",
    },
    {
      parameter: intl.formatMessage({ id: "event_materials" }),
      name: "eventMaterials",
    },
    {
      parameter: intl.formatMessage({ id: "exhibitor_manual" }),
      name: "exhibitorManual",
    },
    {
      parameter: intl.formatMessage({ id: "price_list" }),
      name: "priceList",
    },
  ];

  return (
    <AnimatedMainContainer>
      <Container>
        <Form
          ref={formRef}
          key={key}
          initialData={{
            projectDelivery: data?.projectDelivery || 0,
            paymentMade: data?.paymentMade || 0,
            contractGrowthArea: data?.contractGrowthArea || 0,
            contractGrowthValue: data?.contractGrowthValue || 0,
            recurrence: data?.recurrence || 0,
            upselling: data?.upselling || 0,
            loyaltyIndex: data?.loyaltyIndex || 0,
            sponsor: data?.sponsor || 0,
            contentProducer: data?.contentProducer || 0,
            brandExhibitor: data?.brandExhibitor || 0,
            digitalExhibitor: data?.digitalExhibitor || 0,
            employeeRegistration: data?.employeeRegistration || 0,
            credentialsFill: data?.credentialsFill || 0,
            scheduleWithdrawal: data?.scheduleWithdrawal || 0,
            invitationRegistration: data?.invitationRegistration || 0,
            associationOfServiceProviders:
              data?.associationOfServiceProviders || 0,
            accessToKnowledgeBase: data?.accessToKnowledgeBase || 0,
            eventMaterials: data?.eventMaterials || 0,
            exhibitorManual: data?.exhibitorManual || 0,
            priceList: data?.priceList || 0,
          }}
          onSubmit={handleSubmit}
        >
          <TitleContainer>
            <Title>
              <FormattedMessage id="editing_event_parameters" />
            </Title>
            <ActionContainer>
              <BadgeContainer
                onClick={() => history.push("/admin/event-setup")}
              >
                <Badge
                  color="#C9C9C9"
                  text={intl.formatMessage({ id: "goback" })}
                  fontSize="12px"
                />
              </BadgeContainer>
              <BadgeContainer onClick={() => formRef.current.submitForm()}>
                <Badge
                  loading={loading}
                  color="#002244"
                  text={intl.formatMessage({ id: "save" })}
                  fontSize="12px"
                />
              </BadgeContainer>
            </ActionContainer>
          </TitleContainer>
          <Session>
            <TableContainer>
              <Table columns={columns} data={rows} hasPagination={false} />
            </TableContainer>
          </Session>
        </Form>
      </Container>
    </AnimatedMainContainer>
  );
};

export default EditCategory;
