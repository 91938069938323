import styled, { css } from "styled-components";

export const Container = styled.div`
  background: transparent;
  border-radius: 5px;
  border: 1px solid #dadada;
  padding: 8px 16px;
  /* width: 100%; */
  color: #dadada;
  display: flex;
  align-items: center;
  & + div {
    margin-top: 24px;
  }

  opacity: 0.7;
  background-color: #f2f2f2;

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}
`;

export const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.span`
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 600;
`;

export const HtmlContent = styled.div`
  min-height: 165px;
  color: #000;
`;
