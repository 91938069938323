import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { Form } from "@unform/web";
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationErrors";

import { useSelector } from "react-redux";

import { FormattedMessage, useIntl } from "react-intl";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";

import {
  ActionContainer,
  BadgeContainer,
  Container,
  FieldsContainer,
  Session,
  Title,
  TitleContainer,
} from "./styles";

import { useToast } from "../../../hooks/toast";
import { useLoading } from "../../../hooks/loading";
import api from "../../../service";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import InputWithLabel from "../../../components/InputWithLabel";
import Badge from "../../../components/Badge";
import SelectWithLabel from "../../../components/SelectWithLabel";

const EventSetupCreate = () => {
  const formRef = useRef(null);
  const intl = useIntl();
  const { addToast } = useToast();
  const { token } = useSelector((state) => state.auth);
  const [eventOptions, setEventOptions] = useState([]);

  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const schema = Yup.object().shape({
    setup_name: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    editions_list: Yup.array().min(
      1,
      intl.formatMessage({ id: "select_at_least_one_of_the_options" })
    ),
  });

  useEffect(() => {
    handleLoading(true);
    api
      .get("/admin/events", {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        const eventsEditions = result.data.output;
        let eventsEditionsSort = [...eventsEditions].sort((a, b) =>
          a.eventEditionName < b.eventEditionName
            ? -1
            : a.eventEditionName > b.eventEditionName
            ? 1
            : 0
        );
        setEventOptions(eventsEditionsSort);
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, handleLoading, intl, token]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) {
          return;
        }

        await schema.validate(data, { abortEarly: false });

        api
          .post(
            "/admin/setup-event",
            {
              eventSetupName: data.setup_name,
              eventsId: data.editions_list,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            setLoading(false);
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.create_association",
              }),
            });
            history.push("/admin/event-setup");
          })
          .catch((err) => {
            setLoading(false);
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, history, intl, schema, token, loading]
  );

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const pushBack = () => {
    history.push("/admin/event-setup");
  };

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "breadcrumb.event_setup_creation" })}
    >
      <Container>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <TitleContainer>
            <Title>
              <FormattedMessage id="new" />
            </Title>
            <ActionContainer>
              <BadgeContainer onClick={() => pushBack()}>
                <Badge
                  color="#C9C9C9"
                  text={intl.formatMessage({ id: "goback" })}
                  fontSize="12px"
                />
              </BadgeContainer>
              <BadgeContainer onClick={() => formRef.current.submitForm()}>
                <Badge
                  color="#002244"
                  text={intl.formatMessage({ id: "save" })}
                  fontSize="12px"
                  loading={loading}
                />
              </BadgeContainer>
            </ActionContainer>
          </TitleContainer>
          <Session>
            <FieldsContainer>
              <InputWithLabel
                name="setup_name"
                validationBlur={validationBlur}
                placeholder={`${intl.formatMessage({
                  id: "event_name",
                })} / ${intl.formatMessage({
                  id: "fair",
                })}`}
                label={`${intl.formatMessage({
                  id: "event_name",
                })} / ${intl.formatMessage({
                  id: "fair",
                })}`}
                required
              />
              <SelectWithLabel
                label={intl.formatMessage({
                  id: "editions",
                })}
                validationBlur={validationBlur}
                placeholder={intl.formatMessage({
                  id: "select_at_least_one_of_the_options",
                })}
                isMulti
                options={eventOptions.map((event) => {
                  return {
                    label: `${event.eventEditionName} - ${event.eventEditionCode}`,
                    value: event.idNumber,
                  };
                })}
                name="editions_list"
              />
            </FieldsContainer>
          </Session>
        </Form>
      </Container>
    </AnimatedMainContainer>
  );
};

export default EventSetupCreate;
