import React, { useEffect, useMemo, useRef, useState } from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useHistory, useLocation } from "react-router-dom";
import { Container, LoaderContainer } from "./styles";
import { useToast } from "../../hooks/toast";
import { FormattedMessage, useIntl } from "react-intl";

const AuthSSOError = () => {
  const { addToast } = useToast();
  const intl = useIntl();
  const history = useHistory();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
    addToast({
      type: "error",
      title: intl.formatMessage({
        id: "error",
      }),
      description: intl.formatMessage({
        id: "api.error.-2016",
      }),
    });
    history.push("/");
  });

  return (
    <Container>
      {loading && (
        <LoaderContainer>
          <Loader type="Oval" color="#00BFFF" height={100} width={100} />
          <FormattedMessage id="loading..." />
        </LoaderContainer>
      )}
    </Container>
  );
};

export default AuthSSOError;
