import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import { useToast } from "../../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationErrors";
import api from "../../../service";
import { useCallback } from "react";
import CpfCnpj from "../../../components/CpfCnpj";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";
import ConfirmationModal from "../../../components/ConfirmationModal";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  LinkButton,
  GoBackContainer,
  BadgeContainer,
  UserNameContainer,
} from "./styles";
import SelectWithLabel from "../../../components/SelectWithLabel";
import Badge from "../../../components/Badge";
import { useLoading } from "../../../hooks/loading";
import Toggle from "../../../components/Toggle";

const CreateAssociation = () => {
  const { token } = useSelector((state) => state.auth);
  const [allEvents, setAllEvents] = useState([]);
  const [data, setData] = useState([]);
  const [service, setService] = useState();
  const [associateModalOpen, setAssociateModalOpen] = useState(false);
  const [exhibitors, setExhibitors] = useState([]);
  const [selectedExhibitor, setSelectedExhibitor] = useState();
  const [opportunities, setOpportunities] = useState([]);
  const [serviceProvider, setServiceProvider] = useState(null);
  const [event, setEvent] = useState();
  const [contract, setContract] = useState();
  const formRef = useRef(null);
  const associationFormRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);

  const schema = Yup.object().shape({
    exhibitor: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    event: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    contract: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    document: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
  });

  const [provisionTypes] = useState([
    { label: intl.formatMessage({ id: "landscaping" }), value: 1 },
    { label: intl.formatMessage({ id: "safety" }), value: 2 },
    { label: intl.formatMessage({ id: "receptive" }), value: 3 },
    { label: intl.formatMessage({ id: "cleaning" }), value: 4 },
    { label: intl.formatMessage({ id: "custom_broker" }), value: 5 },
    { label: intl.formatMessage({ id: "catering" }), value: 6 },
    { label: intl.formatMessage({ id: "furniture_and_decoration" }), value: 7 },
    { label: intl.formatMessage({ id: "translation" }), value: 8 },
    { label: intl.formatMessage({ id: "compressed_air" }), value: 9 },
    { label: intl.formatMessage({ id: "travel_agency" }), value: 10 },
    { label: intl.formatMessage({ id: "visual_communication" }), value: 11 },
    {
      label: intl.formatMessage({ id: "electrical_and_hydraulic" }),
      value: 12,
    },
    { label: intl.formatMessage({ id: "data_collectors" }), value: 13 },
    {
      label: intl.formatMessage({ id: "transport_import_and_export" }),
      value: 14,
    },
    {
      label: intl.formatMessage({ id: "transportation_equipment_leasing" }),
      value: 15,
    },
    { label: intl.formatMessage({ id: "telephone_and_internet" }), value: 16 },
    { label: intl.formatMessage({ id: "press_office" }), value: 17 },
    { label: intl.formatMessage({ id: "equipment_leasing" }), value: 18 },
    { label: intl.formatMessage({ id: "carmaker" }), value: 19 },
    { label: intl.formatMessage({ id: "carpet" }), value: 20 },
    { label: intl.formatMessage({ id: "cargo_handling" }), value: 21 },
    { label: intl.formatMessage({ id: "reception" }), value: 22 },
    { label: intl.formatMessage({ id: "insurance" }), value: 23 },
    { label: intl.formatMessage({ id: "agency" }), value: 24 },
    { label: intl.formatMessage({ id: "coexhibitor" }), value: 25 },
    { label: intl.formatMessage({ id: "others" }), value: 26 },
  ]);

  const schemaAssociationForm = Yup.object().shape({
    service_type: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        await schema.validate(data, { abortEarly: false });

        setLoading(true);
        if (loading) {
          return;
        }

        setServiceProvider(null);
        clearFormErrors(associationFormRef);

        associationFormRef.current?.setFieldValue("service_type", []);

        api
          .get(
            `/admin/provider-association/service-provider/${data.document}`,
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            let translatedServices = [];

            result.data.output.services.forEach((item) => {
              provisionTypes.forEach((s) => {
                if (s.value === item.value) {
                  translatedServices.push({
                    value: item.id,
                    label: s.label,
                  });
                }
              });
            });
            setServiceProvider({
              ...result.data.output,
              services: translatedServices,
            });
            setLoading(false);
          })
          .catch((err) => {
            setServiceProvider(null);
            setLoading(false);
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
              time: 7000,
            });
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, intl, loading, provisionTypes, schema, token]
  );

  const clearFormErrors = (ref) => {
    ref.current.setErrors({});
  };

  const handleSubmitAssociationForm = useCallback(
    async (data) => {
      schemaAssociationForm.current?.setErrors({});

      await schemaAssociationForm.validate(data, { abortEarly: false });
      setAssociateModalOpen(true);
    },
    [schemaAssociationForm]
  );

  const createAssociation = () => {
    try {
      handleLoading(true);
      api
        .post(
          "/admin/provider-association",
          {
            eventId: event,
            opportunityId: contract,
            serviceProviderId: serviceProvider.id,
            serviceTypeId: service,
            exhibitorId: selectedExhibitor,
            allowManageAccount:
              formRef.current.getFieldValue("allowManageAccount"),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          setAssociateModalOpen(false);
          setData([...data, result.data.output]);
          addToast({
            type: "success",
            title: intl.formatMessage({
              id: "success",
            }),
            description: intl.formatMessage({
              id: "success.create_association",
            }),
          });
          history.push("/admin/serviceprovider-association");
        })
        .finally(() => {
          handleLoading(false);
        })
        .catch((err) => {
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
          setAssociateModalOpen(false);
        });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
        setAssociateModalOpen(false);

        return;
      }
    }
  };

  const getEvents = useCallback(() => {
    handleLoading(true);
    api
      .patch(
        "/admin/provider-association/events",
        { accountExhibitorId: selectedExhibitor },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        setAllEvents(
          result.data.output.map((x) => ({
            label: x.eventEditionName,
            value: x.idNumber,
          }))
        );
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, intl, token, handleLoading, selectedExhibitor]);

  const getOpportunities = useCallback(
    (event) => {
      handleLoading(true);
      api
        .patch(
          `/admin/provider-association/opportunities/${event}`,
          { accountExhibitorId: selectedExhibitor },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          setOpportunities(
            result.data.output.map((x) => ({ label: x.name, value: x.id }))
          );
        })
        .catch((err) => {
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
        })
        .finally(() => {
          handleLoading(false);
        });
    },
    [addToast, intl, token, handleLoading, selectedExhibitor]
  );

  const getExhibitors = useCallback(() => {
    handleLoading(true);
    api
      .get("/admin/account", {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setExhibitors(
          result.data.output.map((x) => ({
            label: x.accountName,
            value: x.idNumber,
          }))
        );
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, intl, token, handleLoading]);

  useEffect(() => {
    getExhibitors();
  }, [getExhibitors]);

  useEffect(() => {
    if (selectedExhibitor) {
      getEvents();
    }
  }, [getEvents, selectedExhibitor]);

  useEffect(() => {
    if (event) getOpportunities(event);
  }, [event, getOpportunities]);

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const validationBlurAssociationForm = (name) => {
    let errors = associationFormRef.current.getErrors();

    validateSingleFieldOnBlur(
      errors,
      schemaAssociationForm,
      name,
      associationFormRef
    );
  };

  return (
    <>
      <AnimatedMainContainer
        breadcrumb={intl.formatMessage({ id: "breadcrumb.association" })}
      >
        <Container>
          <FormContainer>
            <TitleContainer>
              <Title>
                <FormattedMessage id="service_provider_association" />
              </Title>
              <GoBackContainer>
                <LinkButton
                  onClick={() =>
                    history.push("/admin/serviceprovider-association")
                  }
                >
                  <Badge
                    color="#002244"
                    text={intl.formatMessage({ id: "goback" })}
                    fontSize="12px"
                  />
                </LinkButton>
              </GoBackContainer>
            </TitleContainer>
            <FieldsContainer>
              <Form
                ref={formRef}
                onSubmit={handleSubmit}
                initialData={{
                  type: {
                    label: intl.formatMessage({ id: "all" }),
                    value: "ALL",
                  },
                  status: {
                    label: intl.formatMessage({ id: "both" }),
                    value: null,
                  },
                }}
              >
                <Toggle
                  name="allowManageAccount"
                  label={intl.formatMessage({ id: "allow_manage_account" })}
                />
                <Horizontal>
                  <SelectWithLabel
                    name="exhibitor"
                    options={exhibitors}
                    validationBlur={validationBlur}
                    label={intl.formatMessage({ id: "exhibitor" })}
                    placeholder={intl.formatMessage({ id: "exhibitor" })}
                    onChange={(e) => setSelectedExhibitor(e.value)}
                    required
                  />

                  <SelectWithLabel
                    name="event"
                    options={allEvents}
                    validationBlur={validationBlur}
                    label={intl.formatMessage({ id: "event_edition" })}
                    placeholder={intl.formatMessage({ id: "event_edition" })}
                    onChange={(e) => setEvent(e.value)}
                    required
                  />

                  <SelectWithLabel
                    name="contract"
                    options={opportunities}
                    validationBlur={validationBlur}
                    label={intl.formatMessage({ id: "contract" })}
                    placeholder={intl.formatMessage({ id: "contract" })}
                    onChange={(e) => setContract(e.value)}
                    required
                  />
                </Horizontal>
                <Horizontal>
                  <CpfCnpj
                    name="document"
                    validationBlur={validationBlur}
                    placeholder={intl.formatMessage({ id: "document" })}
                    label={intl.formatMessage({ id: "document" })}
                    hasLabel
                    required
                  />
                  <BadgeContainer>
                    <Badge
                      color="#002244"
                      text={intl.formatMessage({ id: "search" })}
                      onClick={() => formRef?.current?.submitForm()}
                      style={{ cursor: "pointer" }}
                    />
                    <UserNameContainer>
                      {serviceProvider ? (
                        serviceProvider?.name
                      ) : (
                        <div>{intl.formatMessage({ id: "search_user" })}</div>
                      )}
                    </UserNameContainer>
                  </BadgeContainer>
                </Horizontal>
              </Form>

              <Form
                ref={associationFormRef}
                onSubmit={handleSubmitAssociationForm}
              >
                <Horizontal>
                  <SelectWithLabel
                    name="service_type"
                    options={serviceProvider?.services}
                    validationBlur={validationBlurAssociationForm}
                    label={intl.formatMessage({ id: "service_type" })}
                    placeholder={intl.formatMessage({ id: "service_type" })}
                    onChange={(e) => setService(e.value)}
                    disabled={!serviceProvider}
                  />
                  <BadgeContainer>
                    <Badge
                      color="#002244"
                      text={intl.formatMessage({ id: "associate" })}
                      onClick={() => {
                        if (!service) {
                          return;
                        }
                        associationFormRef.current.submitForm();
                      }}
                      style={
                        service ? { cursor: "pointer" } : { cursor: "default" }
                      }
                      disabled={!service}
                    />
                  </BadgeContainer>
                </Horizontal>
              </Form>
            </FieldsContainer>
          </FormContainer>
        </Container>
      </AnimatedMainContainer>
      <ConfirmationModal
        modalIsOpen={associateModalOpen}
        setIsOpen={setAssociateModalOpen}
        confirmationFunction={createAssociation}
        description={intl.formatMessage(
          { id: "confirm_association" },
          {
            providerName: serviceProvider?.name,
            serviceName: serviceProvider?.services.find((x) => x.id === service)
              ?.name,
          }
        )}
        confirmationText={intl.formatMessage({ id: "confirm" })}
        cancelText={intl.formatMessage({ id: "cancel" })}
      />
    </>
  );
};

export default CreateAssociation;
