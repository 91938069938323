import React from "react";
import { Container, Message } from "./style";

import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { useIntl } from "react-intl";

const NotFound = () => {
  const intl = useIntl();

  return (
    <AnimatedMainContainer>
      <Container>
        <Message>{intl.formatMessage({ id: "not_found" })}</Message>
      </Container>
    </AnimatedMainContainer>
  );
};

export default NotFound;
