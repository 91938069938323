import React, { useCallback, useEffect, useRef, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import {
  Container,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  SessionContainer,
  SessionIconContainer,
  SessionTitle,
  Session,
  SessionDivider,
  ClearButtonContainer,
  PeriodCategoryContainer,
  PeriodCategoryTitle,
  PeriodCategoryFieldsContainer,
  CredentialsContainer,
  CredentialsTitle,
  CredentialsFieldsContainer,
  InformationEditionContainer,
  DocumentsContainer,
  DocumentsUploadContainer,
  DocumentUpload,
  DocumentUploadTitle,
  DocumentDragAndDropContainer,
  DocumentDragAndDropText,
  ActionContainer,
  BadgeContainer,
  VIPCategoryContainer,
  DocumentOpenText,
  DocumentOpenImage,
  DocumentVisualization,
  DocumentAction,
  LabelEndContainer,
} from "./styles";
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationErrors";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";
import api from "../../../service";
import { Form } from "@unform/web";
import InputWithLabel from "../../../components/InputWithLabel";
import SelectWithLabel from "../../../components/SelectWithLabel";
import InputWithHorizontalLabel from "../../../components/InputWithHorizontalLabel";
import Toggle from "../../../components/Toggle";
import {
  MdInfo,
  MdTimelapse,
  MdAssignmentInd,
  MdDescription,
  MdCloudUpload,
  MdClose,
} from "react-icons/md";
import { useToast } from "../../../hooks/toast";
import DatePickerWithLabel from "../../../components/DatePickerWithLabel";
import { isBefore, parse, format } from "date-fns";
import Badge from "../../../components/Badge";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { Strings } from "../../../utils/stringSanitize";
import { fillTimepicker } from "../../../utils/fillTimepicker";
import { isTimeBefore } from "../../../utils/timeValidation";
import { RiVipDiamondFill } from "react-icons/ri";
import FormButton from "../../../components/FormButton";
import Dropzone from "../../../components/Dropzone";
import PDFModal from "../../../components/PDFModal";
import { base64ToBlob, downloadBlob } from "../../../utils/file";
import { useLoading } from "../../../hooks/loading";

const Detail = () => {
  const { token } = useSelector((state) => state.auth);
  const { id } = useParams();
  const [key, setKey] = useState();

  const [vipGuestMetrics, setVipGuestMetrics] = useState([]);
  const [loading, setLoading] = useState(false);

  const intervalTimes = [
    {
      label: "5m",
      value: 5,
    },
    {
      label: "10m",
      value: 10,
    },
    {
      label: "15m",
      value: 15,
    },
    {
      label: "20m",
      value: 20,
    },
    {
      label: "30m",
      value: 30,
    },
  ];

  const timeOptions5m = fillTimepicker(5);
  const [savedVipGuestMetrics, setSavedVipGuestMetrics] = useState({});

  const [exhibitorManualPtOpen, setExhibitorManualPtOpen] = useState(false);
  const [exhibitorManualEnOpen, setExhibitorManualEnOpen] = useState(false);
  const [pricingPtOpen, setPricingPtOpen] = useState(false);
  const [pricingEnOpen, setPricingEnOpen] = useState(false);
  const [logoSrc, setLogoSrc] = useState();
  const { handleLoading } = useLoading();

  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();

  const schema = Yup.object().shape(
    {
      feira: Yup.string(),
      feira_ingles: Yup.string(),
      edicaoDaFeira: Yup.string(),
      year: Yup.string(),
      eventEditionNumber: Yup.string(),
      place: Yup.string(),
      pavilion: Yup.string(),
      address: Yup.string(),
      fair_email: Yup.string(),
      video_link: Yup.string().url(
        intl.formatMessage({ id: "error.valid_url" })
      ),
      vip_deadline: Yup.string()
        .nullable()
        .required(
          intl.formatMessage({
            id: "error.field_required",
          })
        ),
      vip_guest_metrics_parameter: Yup.string()
        .nullable()
        .required(
          intl.formatMessage({
            id: "error.field_required",
          })
        ),
      vip_guest_metrics_value: Yup.string()
        .nullable()
        .matches(
          /^(^$|([0-9.]*))$/g,
          intl.formatMessage({ id: "error.need_to_be_number" })
        )
        .required(
          intl.formatMessage({
            id: "error.field_required",
          })
        ),
      mounting_start: Yup.string().nullable(),
      mounting_end: Yup.string()
        .nullable()
        .when("mounting_start", {
          is: (mounting_start) => mounting_start,
          then: Yup.string()
            .nullable()
            .required(
              intl.formatMessage({
                id: "error.field_required",
              })
            ),
        })
        .test(
          "is-mounting_end-incorrect1",
          intl.formatMessage({
            id: "error.initial_date_later",
          }),
          () => {
            const initial_date =
              formRef.current.getFieldValue("mounting_start");
            const final_date = formRef.current.getFieldValue("mounting_end");

            if (initial_date && final_date) {
              return isBefore(new Date(initial_date), new Date(final_date));
            }

            return true;
          }
        )
        .test(
          "is-mounting_start-incorrect2",
          intl.formatMessage({ id: "error.no_initial_date" }),
          () => {
            const initial_date =
              formRef.current.getFieldValue("mounting_start");
            const final_date = formRef.current.getFieldValue("mounting_end");

            if (!initial_date && final_date) {
              return false;
            }

            return true;
          }
        ),
      realization_start: Yup.string().nullable(),
      realization_end: Yup.string()
        .nullable()
        .test(
          "is-realization_end-incorrect1",
          intl.formatMessage({
            id: "error.initial_date_later",
          }),
          () => {
            const initial_date =
              formRef.current.getFieldValue("realization_start");
            const final_date = formRef.current.getFieldValue("realization_end");

            if (initial_date && final_date) {
              return isBefore(new Date(initial_date), new Date(final_date));
            }
            return true;
          }
        )
        .when("realization_start", {
          is: (realization_start) => realization_start,
          then: Yup.string()
            .nullable()
            .required(
              intl.formatMessage({
                id: "error.field_required",
              })
            ),
        })
        .test(
          "is-realization_end-incorrect2",
          intl.formatMessage({ id: "error.no_initial_date" }),
          () => {
            const initial_date =
              formRef.current.getFieldValue("realization_start");
            const final_date = formRef.current.getFieldValue("realization_end");

            if (!initial_date && final_date) {
              return false;
            }

            return true;
          }
        ),
      unmounting_start: Yup.string().nullable(),
      unmounting_end: Yup.string()
        .nullable()
        .test(
          "is-unmounting_end-incorrect1",
          intl.formatMessage({
            id: "error.initial_date_later",
          }),
          () => {
            const initial_date =
              formRef.current.getFieldValue("unmounting_start");
            const final_date = formRef.current.getFieldValue("unmounting_end");

            if (initial_date && final_date) {
              return isBefore(new Date(initial_date), new Date(final_date));
            }
            return true;
          }
        )
        .when("unmounting_start", {
          is: (unmounting_start) => unmounting_start,
          then: Yup.string()
            .nullable()
            .required(
              intl.formatMessage({
                id: "error.field_required",
              })
            ),
        })
        .test(
          "is-unmounting_end-incorrect2",
          intl.formatMessage({ id: "error.no_initial_date" }),
          () => {
            const initial_date =
              formRef.current.getFieldValue("unmounting_start");
            const final_date = formRef.current.getFieldValue("unmounting_end");

            if (!initial_date && final_date) {
              return false;
            }

            return true;
          }
        ),
      payments_start: Yup.string().nullable(),
      payments_end: Yup.string()
        .nullable()
        .test(
          "is-payments_end-incorrect1",
          intl.formatMessage({
            id: "error.initial_date_later",
          }),
          () => {
            const initial_date =
              formRef.current.getFieldValue("payments_start");
            const final_date = formRef.current.getFieldValue("payments_end");

            if (initial_date && final_date) {
              return isBefore(new Date(initial_date), new Date(final_date));
            }
            return true;
          }
        )
        .when("payments_start", {
          is: (payments_start) => payments_start,
          then: Yup.string()
            .nullable()
            .required(
              intl.formatMessage({
                id: "error.field_required",
              })
            ),
        })
        .test(
          "is-payments_end-incorrect2",
          intl.formatMessage({ id: "error.no_initial_date" }),
          () => {
            const initial_date =
              formRef.current.getFieldValue("payments_start");
            const final_date = formRef.current.getFieldValue("payments_end");

            if (!initial_date && final_date) {
              return false;
            }

            return true;
          }
        ),
      payments_limit_end: Yup.string().nullable(),
      appointment_configuration_start: Yup.string()
        .nullable()
        .required(
          intl.formatMessage({
            id: "error.field_required",
          })
        ),
      appointment_configuration_end: Yup.string()
        .nullable()
        .required(
          intl.formatMessage({
            id: "error.field_required",
          })
        )
        .test(
          "is-payments_end-incorrect1",
          intl.formatMessage({
            id: "error.initial_date_later",
          }),
          () => {
            const initial_date = formRef.current.getFieldValue(
              "appointment_configuration_start"
            );
            const final_date = formRef.current.getFieldValue(
              "appointment_configuration_end"
            );

            if (initial_date && final_date) {
              return isBefore(new Date(initial_date), new Date(final_date));
            }
            return true;
          }
        )
        .test(
          "is-appointment_configuration_opening-incorrect2",
          intl.formatMessage({ id: "error.no_initial_date" }),
          () => {
            const appointment_configuration_start =
              formRef.current.getFieldValue("appointment_configuration_start");

            const appointment_configuration_end = formRef.current.getFieldValue(
              "appointment_configuration_end"
            );

            if (
              !appointment_configuration_start &&
              appointment_configuration_end
            ) {
              return false;
            }

            return true;
          }
        ),
      appointment_configuration_opening: Yup.string()
        .nullable()
        .required(
          intl.formatMessage({
            id: "error.field_required",
          })
        ),
      appointment_configuration_closure: Yup.string()
        .nullable()
        .required(
          intl.formatMessage({
            id: "error.field_required",
          })
        )
        .test(
          "is-appointment_configuration_closure-incorrect2",
          intl.formatMessage({ id: "error.no_initial_date" }),
          () => {
            const appointment_configuration_opening =
              formRef.current.getFieldValue(
                "appointment_configuration_opening"
              );

            const appointment_configuration_closure =
              formRef.current.getFieldValue(
                "appointment_configuration_closure"
              );

            if (
              !appointment_configuration_opening &&
              appointment_configuration_closure
            ) {
              return false;
            }

            return true;
          }
        )
        .test(
          "is-payments_end-incorrect1",
          intl.formatMessage({
            id: "error.initial_date_later",
          }),
          () => {
            var appointment_configuration_opening =
              formRef.current.getFieldValue(
                "appointment_configuration_opening"
              );
            var appointment_configuration_closure =
              formRef.current.getFieldValue(
                "appointment_configuration_closure"
              );

            if (
              appointment_configuration_opening &&
              appointment_configuration_closure
            ) {
              return isTimeBefore(
                appointment_configuration_opening,
                appointment_configuration_closure
              );
            }
            return true;
          }
        ),
      interval_between_appointments: Yup.string()
        .nullable()
        .required(
          intl.formatMessage({
            id: "error.field_required",
          })
        ),
      credential_metrics_free_quota: Yup.string()
        .matches(
          /^(^$|([0-9.]*))$/g,
          intl.formatMessage({ id: "error.need_to_be_number" })
        )
        .required(
          intl.formatMessage({
            id: "error.field_required",
          })
        ),
      credential_metrics_considered_area: Yup.string()
        .matches(
          /^(^$|([0-9.]*))$/g,
          intl.formatMessage({ id: "error.need_to_be_number" })
        )
        .required(
          intl.formatMessage({
            id: "error.field_required",
          })
        ),
      exhibitor_manual_pt: Yup.object().nullable(),
      exhibitor_manual_en: Yup.object().nullable(),
      pricing_pt: Yup.object().nullable(),
      pricing_en: Yup.object().nullable(),
      logo: Yup.object().nullable(),
    },
    [
      ["vip_guest_metrics_parameter", "vip_guest_metrics_value"],
      ["vip_deadline", "vip_guest_metrics_value"],
      ["vip_deadline", "vip_guest_metrics_parameter"],
    ]
  );

  const { addToast } = useToast();
  const [activateModalIsOpen, setActivateModalIsOpen] = useState(false);
  const [deactivateModalIsOpen, setDeactivateModalIsOpen] = useState(false);
  const [ecommerceEnableModalIsOpen, setEcommerceEnableModalIsOpen] =
    useState(false);
  const [vipMetricsConfirmationModalOpen, setVipMetricsConfirmationModalOpen] =
    useState(false);

  const [data, setData] = useState({
    feira: "",
    feira_ingles: "",
    edicaoDaFeira: "",
    year: "",
    eventEditionNumber: "",
    place: "",
    pavilion: "",
    address: "",
    fair_email: "",
    video_link: "",
    mounting_start: "",
    mounting_end: "",
    realization_start: "",
    realization_end: "",
    unmounting_start: "",
    unmounting_end: "",
    payments_start: "",
    payments_end: "",
    payments_limit_end: "",
    appointment_configuration_start: "",
    appointment_configuration_end: "",
    appointment_configuration_opening: "",
    appointment_configuration_closure: "",
    interval_between_appointments: "",
    credential_metrics_free_quota: "",
    credential_metrics_considered_area: "",
    opportunities: [
      {
        opportunityContact: {
          firstName: "",
          lastName: "",
        },
        booths: [],
      },
    ],
    vip_deadline: "",
    vip_guest_metrics_parameter: "",
    vip_guest_metrics_value: "",
    exhibitor_manual_pt: [],
    exhibitor_manual_en: [],
    pricing_pt: [],
    pricing_en: [],
    logo: [],
  });

  const getLogoSrcFromHtml = (htmlTag) => {
    var tmp = document.createElement("div");
    tmp.innerHTML = htmlTag;
    var src = tmp.querySelector("img").getAttribute("src");
    return src;
  };

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        await schema.validate(data, { abortEarly: false });

        setLoading(true);
        if (loading) {
          return;
        }

        var formData = new FormData();

        formData.set("link", data.video_link);
        if (data.mounting_start)
          formData.set(
            "initMounting",
            formatDate(data.mounting_start, "dd/MM/yyyy")
          );
        if (data.mounting_end)
          formData.set(
            "finishMounting",
            formatDate(data.mounting_end, "dd/MM/yyyy")
          );
        if (data.realization_start)
          formData.set(
            "initRealization",
            formatDate(data.realization_start, "dd/MM/yyyy")
          );
        if (data.realization_end)
          formData.set(
            "finishRealization",
            formatDate(data.realization_end, "dd/MM/yyyy")
          );
        if (data.unmounting_start)
          formData.set(
            "initUnmounting",
            formatDate(data.unmounting_start, "dd/MM/yyyy")
          );
        if (data.unmounting_end)
          formData.set(
            "finishUnmounting",
            formatDate(data.unmounting_end, "dd/MM/yyyy")
          );
        if (data.payments_start)
          formData.set(
            "initPayment",
            formatDate(data.payments_start, "dd/MM/yyyy")
          );
        if (data.payments_end)
          formData.set(
            "finishPayment",
            formatDate(data.payments_end, "dd/MM/yyyy")
          );
        if (data.payments_limit_end)
          formData.set(
            "limitPaymentDate",
            formatDate(data.payments_limit_end, "dd/MM/yyyy")
          );
        formData.set(
          "startDateForSchedulingCredentials",
          formatDate(data.appointment_configuration_start, "yyyy-MM-dd")
        );
        formData.set(
          "startTimeToScheduleCredentials",
          data.appointment_configuration_opening
        );
        formData.set(
          "endTimeToScheduleCredentials",
          data.appointment_configuration_closure
        );
        formData.set(
          "endDateForSchedulingCredentials",
          formatDate(data.appointment_configuration_end, "yyyy-MM-dd")
        );
        formData.set(
          "answeringTimeInterval",
          data.interval_between_appointments
        );
        formData.set("consideredArea", data.credential_metrics_considered_area);
        formData.set(
          "freeQuotasOnCredentials",
          data.credential_metrics_free_quota
        );
        if (data.exhibitor_manual_pt && data.exhibitor_manual_pt.length > 0)
          formData.set("exhibitorManualPt", data.exhibitor_manual_pt[0]);
        if (data.exhibitor_manual_en && data.exhibitor_manual_en.length > 0)
          formData.set("exhibitorManualEn", data.exhibitor_manual_en[0]);
        if (data.pricing_pt && data.pricing_pt.length > 0)
          formData.set("pricingPt", data.pricing_pt[0] || null);
        if (data.pricing_en && data.pricing_en.length > 0)
          formData.set("pricingEn", data.pricing_en[0] || null);
        formData.set(
          "vipDeadline",
          formatDate(data.vip_deadline, "yyyy-MM-dd")
        );
        formData.set(
          "vipGuestMetricsParameter",
          data.vip_guest_metrics_parameter
        );
        formData.set("vipGuestMetricsValue", data.vip_guest_metrics_value);

        api
          .post(`/admin/events/${id}`, formData, {
            headers: {
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((result) => {
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.event_updated",
              }),
            });
            history.push("/events");
          })
          .catch((err) => {
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [loading, addToast, history, intl, id, schema, token]
  );

  useEffect(() => {
    handleLoading(true);
    api
      .get(`/admin/events/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (result) => {
        const payloadPeriods = result.data.output.periods;
        setSavedVipGuestMetrics({
          vipDeadline: result.data.output.vipDeadline,
          vipGuestMetricsParameter: result.data.output.vipGuestMetricsParameter,
          vipGuestMetricsValue: result.data.output.vipGuestMetricsValue,
        });

        setData({
          ...result.data.output,
          mounting_start: parseStringToDate(
            payloadPeriods?.initMounting,
            "dd/MM/yyyy"
          ),
          mounting_end: parseStringToDate(
            payloadPeriods?.finishMounting,
            "dd/MM/yyyy"
          ),
          realization_start: parseStringToDate(
            payloadPeriods?.initRealization,
            "dd/MM/yyyy"
          ),
          realization_end: parseStringToDate(
            payloadPeriods?.finishRealization,
            "dd/MM/yyyy"
          ),
          unmounting_start: parseStringToDate(
            payloadPeriods?.initUnmounting,
            "dd/MM/yyyy"
          ),
          unmounting_end: parseStringToDate(
            payloadPeriods?.finishUnmounting,
            "dd/MM/yyyy"
          ),
          payments_start: parseStringToDate(
            payloadPeriods?.initPayment,
            "dd/MM/yyyy"
          ),
          payments_end: parseStringToDate(
            payloadPeriods?.finishPayment,
            "dd/MM/yyyy"
          ),
          payments_limit_end: parseStringToDate(
            payloadPeriods?.limitPaymentDate,
            "dd/MM/yyyy"
          ),
          appointment_configuration_start: parseStringToDate(
            result.data.output.startDateForSchedulingCredentials,
            "yyyy-MM-dd"
          ),
          appointment_configuration_end: parseStringToDate(
            result.data.output.endDateForSchedulingCredentials,
            "yyyy-MM-dd"
          ),
          vip_deadline: parseStringToDate(
            result.data.output.vipDeadline,
            "yyyy-MM-dd"
          ),
        });
        setLogoSrc(getLogoSrcFromHtml(result.data.output.logo));
        setKey(new Date());
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
    setVipGuestMetrics([
      {
        label: intl.formatMessage({ id: "quota_by_area" }),
        value: "QUOTA_AREA",
      },
      {
        label: intl.formatMessage({ id: "single_quota" }),
        value: "QUOTA_SINGLE",
      },
    ]);
  }, [addToast, intl, id, token, handleLoading]);

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const parseStringToDate = (dateString, formatString) => {
    if (!dateString) {
      return "";
    }
    return parse(dateString, formatString, new Date());
  };

  const formatDate = (date, formatString) => {
    if (!date || date === "") {
      return null;
    }

    return format(date, formatString);
  };

  const handleActivateEvent = useCallback((_) => {
    const statusValue = formRef.current.getFieldValue("status");
    if (statusValue) {
      setActivateModalIsOpen(true);
    } else {
      setDeactivateModalIsOpen(true);
    }
  }, []);

  const handleEnableEcommerce = useCallback((_) => {
    setEcommerceEnableModalIsOpen(true);
  }, []);

  const changeEventStatus = useCallback(() => {
    const statusValue = formRef.current.getFieldValue("status");

    if (statusValue) {
      setActivateModalIsOpen(false);
    } else {
      setDeactivateModalIsOpen(false);
    }

    api
      .put(
        `/admin/events/${id}`,
        { active: statusValue },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        if (statusValue) {
          addToast({
            type: "success",
            title: intl.formatMessage({
              id: "success",
            }),
            description: intl.formatMessage({
              id: "success.activate_event",
            }),
          });
        } else {
          addToast({
            type: "success",
            title: intl.formatMessage({
              id: "success",
            }),
            description: intl.formatMessage({
              id: "success.deactivate_event",
            }),
          });
        }
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      });
  }, [addToast, id, intl, token]);

  const changeEcommerceStatus = useCallback(() => {
    const statusValue = formRef.current.getFieldValue("is_ecommerce_enable");

    setEcommerceEnableModalIsOpen(false);

    api
      .put(
        `/admin/events/${id}/toggle-ecommerce`,
        { active: statusValue },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        addToast({
          type: "success",
          title: intl.formatMessage({
            id: "success",
          }),
          description: intl.formatMessage({
            id: "success.change_ecommerce_status",
          }),
        });
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      });
  }, [addToast, id, intl, token]);

  const cancelActivation = useCallback(() => {
    const previousValue = formRef.current.getFieldValue("status");
    formRef.current.setFieldValue("status", !previousValue);
  }, []);

  const cancelEcommerceStatusChange = useCallback(() => {
    const previousValue = formRef.current.getFieldValue("is_ecommerce_enable");
    formRef.current.setFieldValue("is_ecommerce_enable", !previousValue);
  }, []);

  const hasVipMetricsChanges = (
    newVipDeadline,
    newVipGuestMetricsParameter,
    newVipGuestMetricsValue
  ) => {
    const { vipDeadline, vipGuestMetricsParameter, vipGuestMetricsValue } =
      savedVipGuestMetrics;

    if (
      !vipDeadline ||
      !vipGuestMetricsParameter ||
      vipGuestMetricsValue === null ||
      vipGuestMetricsValue === undefined
    )
      return false;

    return (
      parseStringToDate(vipDeadline, "yyyy-MM-dd").getTime() !==
        newVipDeadline.getTime() ||
      vipGuestMetricsParameter !== newVipGuestMetricsParameter ||
      vipGuestMetricsValue !== parseFloat(newVipGuestMetricsValue)
    );
  };

  const confirmVipMetricsModal = () => {
    setVipMetricsConfirmationModalOpen(false);
    formRef.current.submitForm();
  };

  const handleSubmitConditional = () => {
    const vip_deadline = formRef?.current?.getFieldValue("vip_deadline"),
      vip_guest_metrics_parameter = formRef?.current?.getFieldValue(
        "vip_guest_metrics_parameter"
      ),
      vip_guest_metrics_value = formRef?.current?.getFieldValue(
        "vip_guest_metrics_value"
      );

    if (
      hasVipMetricsChanges(
        vip_deadline,
        vip_guest_metrics_parameter,
        vip_guest_metrics_value
      )
    ) {
      setVipMetricsConfirmationModalOpen(true);
    } else {
      formRef.current.submitForm();
    }
  };

  const [deleteFileModalIsOpen, setDeleteFileModalIsOpen] = useState(false);
  const [typeAttachment, setTypeFile] = useState();
  const handleDeleteFileModalIsOpen = useCallback((_) => {
    setDeleteFileModalIsOpen(true);
  }, []);

  const handleDeleteFile = (eventFileType) => {
    setDeleteFileModalIsOpen(false);
    handleLoading(true);
    api
      .put(
        `/admin/events/delete-file/${id}`,
        { eventFileType: eventFileType },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        let key;
        switch (eventFileType) {
          case "EXHIBITOR_MANUAL_PT":
            key = "exhibitorManualPt";
            break;
          case "EXHIBITOR_MANUAL_EN":
            key = "exhibitorManualEn";
            break;
          case "PRICING_PT":
            key = "pricingPt";
            break;
          case "PRICING_EN":
            key = "pricingEn";
            break;
          default:
            return;
        }
        setData({ ...data, [key]: undefined });
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  };

  const handleLogFileDownload = (eventFileType) => {
    api
      .post(
        `/users/events/log-file-download/${id}`,
        { fileType: eventFileType },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {})
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      });
  };

  const handleExportGuests = () => {
    api
      .patch(
        `/admin/manage-guests/export`,
        {
          eventEditionCode: data.eventEditionCode,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        const { file, name } = result.data.manageGuestAdminExportOutput;
        var blob = base64ToBlob(file, "application/vnd.ms-excel");
        downloadBlob(blob, `${name}.xlsx`);
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      });
  };

  const clearFieldsMointing = () => {
    formRef.current.setFieldValue("mounting_start").clear();
    formRef.current.setFieldValue("mounting_end").clear();
  };

  const clearFieldsRelization = () => {
    formRef.current.setFieldValue("realization_start").clear();
    formRef.current.setFieldValue("realization_end").clear();
  };

  const clearFieldsUnmounting = () => {
    formRef.current.setFieldValue("unmounting_start").clear();
    formRef.current.setFieldValue("unmounting_end").clear();
  };

  const clearFieldsPayments = () => {
    formRef.current.setFieldValue("payments_start").clear();
    formRef.current.setFieldValue("payments_end").clear();
  };

  const clearFieldsPaymentsLimitEnd = () => {
    formRef.current.setFieldValue("payments_limit_end").clear();
  };

  const validatePrevPath = () => {
    if (typeof history.location.state == "undefined") {
      return false;
    } else {
      if (history.location.state.prevPath.pathname.includes("/account/")) {
        return true;
      }
    }
  };

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "edition_information" })}
    >
      <Container>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          key={key}
          initialData={{
            event: data.eventEditionName,
            edicaoDaFeira: data.eventEditionCode,
            year: "",
            eventEditionNumber: "",
            place: data.eventVenue,
            pavilion: data?.opportunities[0]?.booths[0]?.pavilion,
            fair_email: "",
            prefix: data.eventCode,
            video_link: data.linkParaVideos,
            mounting_start: data.mounting_start,
            mounting_end: data.mounting_end,
            realization_start: data.realization_start,
            realization_end: data.realization_end,
            unmounting_start: data.unmounting_start,
            unmounting_end: data.unmounting_end,
            payments_start: data.payments_start,
            payments_end: data.payments_end,
            payments_limit_end: data.payments_limit_end,
            appointment_configuration_start:
              data?.appointment_configuration_start,
            appointment_configuration_end: data.appointment_configuration_end,
            appointment_configuration_opening: {
              label: timeOptions5m.filter(
                (i) => i.value === data?.startTimeToScheduleCredentials
              )[0]?.label,
              value: data?.startTimeToScheduleCredentials,
            },
            appointment_configuration_closure: {
              label: timeOptions5m.filter(
                (i) => i.value === data?.endTimeToScheduleCredentials
              )[0]?.label,
              value: data?.endTimeToScheduleCredentials,
            },
            interval_between_appointments: {
              label: intervalTimes.filter(
                (i) => i.value === data.answeringTimeInterval
              )[0]?.label,
              value: data.answeringTimeInterval,
            },
            credential_metrics_free_quota: data.freeQuotasOnCredentials,
            credential_metrics_considered_area: data.consideredArea,
            status: data.active,
            is_ecommerce_enable: data.ecommerceEnable,
            vip_deadline: data?.vip_deadline,
            vip_guest_metrics_parameter: {
              label: vipGuestMetrics.filter(
                (i) => i.value === data.vipGuestMetricsParameter
              )[0]?.label,
              value: data.vipGuestMetricsParameter,
            },
            vip_guest_metrics_value: data?.vipGuestMetricsValue,
            exhibitor_manual_pt: data.exhibitor_manual_pt
              ? [data.exhibitor_manual_pt]
              : [],
            exhibitor_manual_en: data.exhibitor_manual_en
              ? [data.exhibitor_manual_en]
              : [],
            pricing_pt: data.pricing_pt ? [data.pricing_pt] : [],
            pricing_en: data.pricing_pt ? [data.pricing_en] : [],
          }}
        >
          <TitleContainer>
            <Title>
              <FormattedMessage id="search_for_event_edition" />
            </Title>
            <ActionContainer>
              <BadgeContainer
                onClick={() =>
                  validatePrevPath()
                    ? history.goBack()
                    : history.push("/events", { prevPath: history.location })
                }
              >
                <Badge
                  color="#C9C9C9"
                  text={intl.formatMessage({ id: "goback" })}
                  fontSize="12px"
                />
              </BadgeContainer>
              <BadgeContainer onClick={() => handleSubmitConditional()}>
                <Badge
                  color="#002244"
                  text={intl.formatMessage({ id: "save" })}
                  fontSize="12px"
                  loading={loading}
                />
              </BadgeContainer>
            </ActionContainer>
          </TitleContainer>

          <SessionDivider nr_items="2">
            <Session>
              <SessionContainer>
                <SessionIconContainer>
                  <MdInfo size="16px" color="#002244" />
                </SessionIconContainer>
                <SessionTitle>
                  <FormattedMessage id="informations" />
                </SessionTitle>
              </SessionContainer>
              <FieldsContainer>
                <InputWithLabel
                  name="event"
                  validationBlur={validationBlur}
                  disabled
                  label={intl.formatMessage({ id: "event_edition" })}
                />
                <InformationEditionContainer>
                  <Horizontal>
                    <InputWithLabel
                      name="edicaoDaFeira"
                      validationBlur={validationBlur}
                      disabled
                      className="edition"
                      label={intl.formatMessage({ id: "edition" })}
                    />
                    <InputWithLabel
                      name="year"
                      validationBlur={validationBlur}
                      disabled
                      label={intl.formatMessage({ id: "year" })}
                    />
                    <InputWithLabel
                      name="prefix"
                      validationBlur={validationBlur}
                      disabled
                      label={intl.formatMessage({ id: "prefix" })}
                    />
                    <Toggle
                      label="Status"
                      name="status"
                      onClick={() => handleActivateEvent()}
                    />
                    <Toggle
                      label={intl.formatMessage({ id: "ecommerce" })}
                      name="is_ecommerce_enable"
                      onClick={() => handleEnableEcommerce()}
                    />
                  </Horizontal>
                </InformationEditionContainer>

                <Horizontal>
                  <InputWithLabel
                    name="place"
                    validationBlur={validationBlur}
                    disabled
                    label={intl.formatMessage({ id: "place" })}
                  />

                  <InputWithLabel
                    name="pavilion"
                    validationBlur={validationBlur}
                    disabled
                    label={intl.formatMessage({ id: "pavilion" })}
                    value={data.opportunities[0]?.booths[0]?.pavilion}
                  />
                </Horizontal>

                <InputWithLabel
                  name="fair_email"
                  validationBlur={validationBlur}
                  disabled
                  label={intl.formatMessage({ id: "fair_email" })}
                />

                <Horizontal>
                  <InputWithLabel
                    name="video_link"
                    validationBlur={validationBlur}
                    label={intl.formatMessage({ id: "video_link" })}
                  />
                </Horizontal>
              </FieldsContainer>
              <SessionContainer>
                <SessionIconContainer>
                  <RiVipDiamondFill size="16px" color="#002244" />
                </SessionIconContainer>
                <SessionTitle>
                  <FormattedMessage id="vip_guests" />
                </SessionTitle>
              </SessionContainer>
              <FieldsContainer>
                <VIPCategoryContainer>
                  <DatePickerWithLabel
                    name="vip_deadline"
                    validationBlur={validationBlur}
                    label={intl.formatMessage({ id: "vip_deadline" })}
                    labelWidth="100px"
                    disabled={data.vipDeadline}
                    required
                  />
                </VIPCategoryContainer>
                <VIPCategoryContainer>
                  <SelectWithLabel
                    name="vip_guest_metrics_parameter"
                    validationBlur={validationBlur}
                    label={intl.formatMessage({
                      id: "vip_guest_metrics",
                    })}
                    placeholder={intl.formatMessage({
                      id: "vip_guest_metrics",
                    })}
                    labelOrientation="horizontal"
                    labelWidth="100px"
                    options={vipGuestMetrics}
                    disabled={data.vipGuestMetricsParameter}
                    required
                  />
                  <InputWithHorizontalLabel
                    name="vip_guest_metrics_value"
                    label={intl.formatMessage({
                      id: "quotas",
                    })}
                    labelWidth="100px"
                    validationBlur={validationBlur}
                    disabled={data.vipGuestMetricsValue}
                    required
                  />
                </VIPCategoryContainer>
                <VIPCategoryContainer>
                  <FormButton
                    size="auto"
                    fontSize="12px"
                    type="button"
                    onClick={() => handleExportGuests()}
                  >
                    <FormattedMessage id="export_guests_to_xls" />
                  </FormButton>
                </VIPCategoryContainer>
              </FieldsContainer>
            </Session>
            <Session>
              <SessionContainer>
                <SessionIconContainer>
                  <MdTimelapse size="16px" color="#002244" />
                </SessionIconContainer>
                <SessionTitle>
                  <FormattedMessage id="periods" />
                </SessionTitle>
              </SessionContainer>
              <FieldsContainer>
                <PeriodCategoryContainer>
                  <PeriodCategoryTitle>
                    <FormattedMessage id="assemble" />
                  </PeriodCategoryTitle>

                  <PeriodCategoryFieldsContainer>
                    <DatePickerWithLabel
                      name="mounting_start"
                      validationBlur={validationBlur}
                      validateEnd={() => validationBlur("mounting_end")}
                      label={intl.formatMessage({ id: "start" })}
                      popperPlacement="bottom-start"
                      labelWidth="unset"
                      maxWidth="200px"
                    />
                    <LabelEndContainer>
                      <DatePickerWithLabel
                        name="mounting_end"
                        validationBlur={validationBlur}
                        label={intl.formatMessage({ id: "end" })}
                        labelWidth="unset"
                        popperPlacement="bottom-end"
                        maxWidth="200px"
                      />
                    </LabelEndContainer>

                    <BadgeContainer onClick={clearFieldsMointing}>
                      <ClearButtonContainer>
                        <Badge
                          color="#C9C9C9"
                          text={intl.formatMessage({ id: "clear_fields" })}
                          fontSize="12px"
                        />
                      </ClearButtonContainer>
                    </BadgeContainer>
                  </PeriodCategoryFieldsContainer>
                </PeriodCategoryContainer>

                <PeriodCategoryContainer>
                  <PeriodCategoryTitle>
                    <FormattedMessage id="realization" />
                  </PeriodCategoryTitle>
                  <PeriodCategoryFieldsContainer>
                    <DatePickerWithLabel
                      name="realization_start"
                      validationBlur={validationBlur}
                      validateEnd={() => validationBlur("realization_end")}
                      label={intl.formatMessage({ id: "start" })}
                      labelWidth="unset"
                      popperPlacement="bottom-start"
                      maxWidth="200px"
                    />
                    <LabelEndContainer>
                      <DatePickerWithLabel
                        name="realization_end"
                        validationBlur={validationBlur}
                        label={intl.formatMessage({ id: "end" })}
                        labelWidth="unset"
                        popperPlacement="bottom-end"
                        maxWidth="200px"
                      />
                    </LabelEndContainer>

                    <BadgeContainer onClick={clearFieldsRelization}>
                      <ClearButtonContainer>
                        <Badge
                          color="#C9C9C9"
                          text={intl.formatMessage({ id: "clear_fields" })}
                          fontSize="12px"
                        />
                      </ClearButtonContainer>
                    </BadgeContainer>
                  </PeriodCategoryFieldsContainer>
                </PeriodCategoryContainer>

                <PeriodCategoryContainer>
                  <PeriodCategoryTitle>
                    <FormattedMessage id="disassemble" />
                  </PeriodCategoryTitle>

                  <PeriodCategoryFieldsContainer>
                    <DatePickerWithLabel
                      name="unmounting_start"
                      validationBlur={validationBlur}
                      validateEnd={() => validationBlur("unmounting_end")}
                      label={intl.formatMessage({ id: "start" })}
                      labelWidth="unset"
                      popperPlacement="bottom-start"
                      maxWidth="200px"
                    />
                    <LabelEndContainer>
                      <DatePickerWithLabel
                        name="unmounting_end"
                        validationBlur={validationBlur}
                        label={intl.formatMessage({ id: "end" })}
                        labelWidth="unset"
                        popperPlacement="bottom-end"
                        maxWidth="200px"
                      />
                    </LabelEndContainer>

                    <BadgeContainer onClick={clearFieldsUnmounting}>
                      <ClearButtonContainer>
                        <Badge
                          color="#C9C9C9"
                          text={intl.formatMessage({ id: "clear_fields" })}
                          fontSize="12px"
                        />
                      </ClearButtonContainer>
                    </BadgeContainer>
                  </PeriodCategoryFieldsContainer>
                </PeriodCategoryContainer>

                <PeriodCategoryContainer>
                  <PeriodCategoryTitle>
                    <FormattedMessage id="payments" />
                  </PeriodCategoryTitle>
                  <PeriodCategoryFieldsContainer>
                    <DatePickerWithLabel
                      name="payments_start"
                      validationBlur={validationBlur}
                      validateEnd={() => validationBlur("payments_end")}
                      label={intl.formatMessage({ id: "start" })}
                      labelWidth="unset"
                      popperPlacement="bottom-start"
                      maxWidth="200px"
                    />
                    <LabelEndContainer>
                      <DatePickerWithLabel
                        name="payments_end"
                        validationBlur={validationBlur}
                        label={intl.formatMessage({ id: "end" })}
                        labelWidth="unset"
                        popperPlacement="bottom-end"
                        maxWidth="200px"
                      />
                    </LabelEndContainer>
                    <BadgeContainer onClick={clearFieldsPayments}>
                      <ClearButtonContainer>
                        <Badge
                          color="#C9C9C9"
                          text={intl.formatMessage({ id: "clear_fields" })}
                          fontSize="12px"
                        />
                      </ClearButtonContainer>
                    </BadgeContainer>
                  </PeriodCategoryFieldsContainer>
                </PeriodCategoryContainer>

                <PeriodCategoryContainer>
                  <PeriodCategoryTitle>
                    <FormattedMessage id="payments_limit" />
                  </PeriodCategoryTitle>
                  <PeriodCategoryFieldsContainer className="payment_limit">
                    <DatePickerWithLabel
                      name="payments_limit_end"
                      validationBlur={validationBlur}
                      label={intl.formatMessage({ id: "end" })}
                      labelWidth="unset"
                      popperPlacement="bottom-start"
                      maxWidth="200px"
                    />

                    <BadgeContainer onClick={clearFieldsPaymentsLimitEnd}>
                      <ClearButtonContainer>
                        <Badge
                          color="#C9C9C9"
                          text={intl.formatMessage({ id: "clear_fields" })}
                          fontSize="12px"
                        />
                      </ClearButtonContainer>
                    </BadgeContainer>
                  </PeriodCategoryFieldsContainer>
                </PeriodCategoryContainer>
              </FieldsContainer>
              <SessionContainer>
                <SessionIconContainer>
                  <MdAssignmentInd size="16px" color="#002244" />
                </SessionIconContainer>
                <SessionTitle>
                  <FormattedMessage id="credentials" />
                </SessionTitle>
              </SessionContainer>
              <FieldsContainer>
                <CredentialsContainer>
                  <CredentialsTitle>
                    <FormattedMessage id="appointment_configuration" />
                  </CredentialsTitle>
                  <CredentialsFieldsContainer>
                    <DatePickerWithLabel
                      name="appointment_configuration_start"
                      validationBlur={validationBlur}
                      label={intl.formatMessage({ id: "start" })}
                      labelWidth="80px"
                      popperPlacement="bottom-start"
                      validateEnd={() =>
                        validationBlur("appointment_configuration_end")
                      }
                      disabled={data.startDateForSchedulingCredentials}
                      required
                    />
                    <DatePickerWithLabel
                      name="appointment_configuration_end"
                      validationBlur={validationBlur}
                      label={intl.formatMessage({ id: "end" })}
                      test={formRef?.current?.getFieldValue(
                        "appointment_configuration_start"
                      )}
                      labelWidth="80px"
                      popperPlacement="bottom-end"
                      disabled={data.endDateForSchedulingCredentials}
                      required
                    />
                  </CredentialsFieldsContainer>
                  <CredentialsFieldsContainer>
                    <SelectWithLabel
                      name="appointment_configuration_opening"
                      validationBlur={validationBlur}
                      label={intl.formatMessage({ id: "opening" })}
                      placeholder={intl.formatMessage({ id: "opening" })}
                      validateEnd={() =>
                        validationBlur("appointment_configuration_closure")
                      }
                      options={timeOptions5m}
                      disabled={data.startTimeToScheduleCredentials}
                      required
                    />
                    <SelectWithLabel
                      name="appointment_configuration_closure"
                      validationBlur={validationBlur}
                      label={intl.formatMessage({ id: "closure" })}
                      placeholder={intl.formatMessage({ id: "closure" })}
                      options={timeOptions5m}
                      disabled={data.endTimeToScheduleCredentials}
                      required
                    />
                    <SelectWithLabel
                      name="interval_between_appointments"
                      validationBlur={validationBlur}
                      label={intl.formatMessage({
                        id: "interval_between_appointments",
                      })}
                      placeholder={intl.formatMessage({
                        id: "interval_between_appointments",
                      })}
                      rootClass="interval"
                      options={intervalTimes}
                      disabled={data.answeringTimeInterval}
                      required
                    />
                  </CredentialsFieldsContainer>
                  <CredentialsTitle>
                    <FormattedMessage id="credential_metrics" />
                  </CredentialsTitle>
                  <Horizontal>
                    <InputWithHorizontalLabel
                      name="credential_metrics_free_quota"
                      validationBlur={validationBlur}
                      label={intl.formatMessage({ id: "free_quota" })}
                      labelWidth="80px"
                      disabled={data.freeQuotasOnCredentials}
                      required
                    />
                    <InputWithHorizontalLabel
                      name="credential_metrics_considered_area"
                      validationBlur={validationBlur}
                      label={intl.formatMessage({ id: "considered_area" })}
                      labelWidth="80px"
                      disabled={data.consideredArea}
                      required
                    />
                  </Horizontal>
                </CredentialsContainer>
              </FieldsContainer>
            </Session>
          </SessionDivider>
          <Session>
            <SessionContainer>
              <SessionIconContainer>
                <MdDescription size="16px" color="#002244" />
              </SessionIconContainer>
              <SessionTitle>
                <FormattedMessage id="document_management" />
              </SessionTitle>
            </SessionContainer>
            <FieldsContainer>
              <DocumentsContainer>
                <DocumentsUploadContainer>
                  <DocumentUpload>
                    <DocumentUploadTitle>
                      Manual do Expositor
                    </DocumentUploadTitle>
                    <DocumentDragAndDropContainer>
                      {data.exhibitorManualPt ? (
                        <DocumentVisualization>
                          <DocumentAction>
                            <MdClose
                              color="#e00000"
                              size={20}
                              onClick={() => {
                                handleDeleteFileModalIsOpen();
                                setTypeFile("EXHIBITOR_MANUAL_PT");
                              }}
                            />
                          </DocumentAction>
                          <DocumentOpenText
                            onClick={() => {
                              handleLogFileDownload("EXHIBITOR_MANUAL_PT");
                              setExhibitorManualPtOpen(true);
                            }}
                          >
                            <FormattedMessage id="view_file" />
                          </DocumentOpenText>
                        </DocumentVisualization>
                      ) : (
                        <>
                          <MdCloudUpload size={32} color="#525a5c" />
                          <DocumentDragAndDropText>
                            <Dropzone
                              name="exhibitor_manual_pt"
                              accept="application/pdf"
                              type="PDF"
                              multiple={false}
                            />
                          </DocumentDragAndDropText>
                        </>
                      )}
                    </DocumentDragAndDropContainer>
                    <PDFModal
                      setIsOpen={setExhibitorManualPtOpen}
                      modalIsOpen={exhibitorManualPtOpen}
                      pdfLocation={data.exhibitorManualPt}
                    />
                  </DocumentUpload>
                  <DocumentUpload>
                    <DocumentUploadTitle>Exhibitor Manual</DocumentUploadTitle>
                    <DocumentDragAndDropContainer>
                      {data.exhibitorManualEn ? (
                        <DocumentVisualization>
                          <DocumentAction>
                            <MdClose
                              color="#e00000"
                              size={20}
                              onClick={() => {
                                handleDeleteFileModalIsOpen();
                                setTypeFile("EXHIBITOR_MANUAL_EN");
                              }}
                            />
                          </DocumentAction>
                          <DocumentOpenText
                            onClick={() => {
                              handleLogFileDownload("EXHIBITOR_MANUAL_EN");
                              setExhibitorManualEnOpen(true);
                            }}
                          >
                            <FormattedMessage id="view_file" />
                          </DocumentOpenText>
                        </DocumentVisualization>
                      ) : (
                        <>
                          <MdCloudUpload size={32} color="#525a5c" />
                          <DocumentDragAndDropText>
                            <Dropzone
                              name="exhibitor_manual_en"
                              accept="application/pdf"
                              type="PDF"
                              multiple={false}
                            />
                          </DocumentDragAndDropText>
                        </>
                      )}
                    </DocumentDragAndDropContainer>
                    <PDFModal
                      setIsOpen={setExhibitorManualEnOpen}
                      modalIsOpen={exhibitorManualEnOpen}
                      pdfLocation={data.exhibitorManualEn}
                    />
                  </DocumentUpload>
                  <DocumentUpload>
                    <DocumentUploadTitle>Tabela de Preços</DocumentUploadTitle>
                    <DocumentDragAndDropContainer>
                      {data.pricingPt ? (
                        <DocumentVisualization>
                          <DocumentAction>
                            <MdClose
                              color="#e00000"
                              size={20}
                              onClick={() => {
                                handleDeleteFileModalIsOpen();
                                setTypeFile("PRICING_PT");
                              }}
                            />
                          </DocumentAction>
                          <DocumentOpenText
                            onClick={() => {
                              handleLogFileDownload("PRICING_PT");
                              setPricingPtOpen(true);
                            }}
                          >
                            <FormattedMessage id="view_file" tagName="test" />
                          </DocumentOpenText>
                        </DocumentVisualization>
                      ) : (
                        <>
                          <MdCloudUpload size={32} color="#525a5c" />
                          <DocumentDragAndDropText>
                            <Dropzone
                              name="pricing_pt"
                              accept="application/pdf"
                              type="PDF"
                              multiple={false}
                            />
                          </DocumentDragAndDropText>
                        </>
                      )}
                    </DocumentDragAndDropContainer>
                    <PDFModal
                      setIsOpen={setPricingPtOpen}
                      modalIsOpen={pricingPtOpen}
                      pdfLocation={data.pricingPt}
                    />
                  </DocumentUpload>
                  <DocumentUpload>
                    <DocumentUploadTitle>Pricing</DocumentUploadTitle>
                    <DocumentDragAndDropContainer>
                      {data.pricingEn ? (
                        <DocumentVisualization>
                          <DocumentAction>
                            <MdClose
                              color="#e00000"
                              size={20}
                              onClick={() => {
                                handleDeleteFileModalIsOpen();
                                setTypeFile("PRICING_EN");
                              }}
                            />
                          </DocumentAction>
                          <DocumentOpenText
                            onClick={() => {
                              handleLogFileDownload("PRICING_EN");
                              setPricingEnOpen(true);
                            }}
                          >
                            <FormattedMessage id="view_file" />
                          </DocumentOpenText>
                        </DocumentVisualization>
                      ) : (
                        <>
                          <MdCloudUpload size={32} color="#525a5c" />
                          <DocumentDragAndDropText>
                            <Dropzone
                              name="pricing_en"
                              accept="application/pdf"
                              type="PDF"
                              multiple={false}
                            />
                          </DocumentDragAndDropText>
                        </>
                      )}
                    </DocumentDragAndDropContainer>
                    <PDFModal
                      setIsOpen={setPricingEnOpen}
                      modalIsOpen={pricingEnOpen}
                      pdfLocation={data.pricingEn}
                    />
                  </DocumentUpload>
                  <DocumentUpload>
                    <DocumentUploadTitle>Logo da Feira</DocumentUploadTitle>
                    <DocumentDragAndDropContainer>
                      {logoSrc ? (
                        <DocumentVisualization>
                          <DocumentOpenText>
                            <DocumentOpenImage src={logoSrc} />
                          </DocumentOpenText>
                        </DocumentVisualization>
                      ) : (
                        <>
                          <MdCloudUpload size={32} color="#525a5c" />
                          <DocumentDragAndDropText>
                            <Dropzone
                              name="logo"
                              accept="image/*"
                              multiple={false}
                            />
                          </DocumentDragAndDropText>
                        </>
                      )}
                    </DocumentDragAndDropContainer>
                  </DocumentUpload>
                </DocumentsUploadContainer>
              </DocumentsContainer>
            </FieldsContainer>
          </Session>
        </Form>
      </Container>
      <ConfirmationModal
        setIsOpen={setActivateModalIsOpen}
        modalIsOpen={activateModalIsOpen}
        confirmationFunction={() => changeEventStatus()}
        cancelationFunction={() => cancelActivation()}
        description={intl.formatMessage({ id: "activate_event_message" })}
        confirmationText={intl.formatMessage({ id: "yes" })}
        cancelText={intl.formatMessage({ id: "no" })}
      />
      <ConfirmationModal
        setIsOpen={setEcommerceEnableModalIsOpen}
        modalIsOpen={ecommerceEnableModalIsOpen}
        confirmationFunction={() => changeEcommerceStatus()}
        cancelationFunction={() => cancelEcommerceStatusChange()}
        description={intl.formatMessage({ id: "change_ecommerce_status" })}
        confirmationText={intl.formatMessage({ id: "yes" })}
        cancelText={intl.formatMessage({ id: "no" })}
      />
      <ConfirmationModal
        setIsOpen={setDeactivateModalIsOpen}
        modalIsOpen={deactivateModalIsOpen}
        confirmationFunction={() => changeEventStatus()}
        cancelationFunction={() => cancelActivation()}
        description={intl.formatMessage({ id: "deactivate_event_message" })}
        confirmationText={intl.formatMessage({ id: "yes" })}
        cancelText={intl.formatMessage({ id: "no" })}
      />
      <ConfirmationModal
        setIsOpen={setVipMetricsConfirmationModalOpen}
        modalIsOpen={vipMetricsConfirmationModalOpen}
        confirmationFunction={() => confirmVipMetricsModal()}
        description={intl.formatMessage({ id: "vip_metrics_change_message" })}
        confirmationText={intl.formatMessage({ id: "yes" })}
        cancelText={intl.formatMessage({ id: "no" })}
      />
      <ConfirmationModal
        setIsOpen={setDeleteFileModalIsOpen}
        modalIsOpen={deleteFileModalIsOpen}
        confirmationFunction={() => handleDeleteFile(typeAttachment)}
        cancelationFunction={() => cancelActivation()}
        description={intl.formatMessage({ id: "delete_file_message" })}
        confirmationText={intl.formatMessage({ id: "yes" })}
        cancelText={intl.formatMessage({ id: "no" })}
      />
    </AnimatedMainContainer>
  );
};

export default Detail;
