import React, { useEffect, useState } from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import api from "../../../service";
import { authSuccess } from "../../../redux/modules/auth/actions";
import { changeApplicationLanguage } from "../../../redux/modules/language/actions";
import { useToast } from "../../../hooks/toast";
import { useIntl } from "react-intl";
import { useLoading } from "../../../hooks/loading";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const StaffOpenID = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const { addToast } = useToast();
  const intl = useIntl();
  const history = useHistory();

  const [code] = useState(query.get("code"));
  const { handleLoading } = useLoading();

  useEffect(() => {
    handleLoading(true);
    api
      .get("/auth/staff-openid-signin", {
        params: {
          code,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
      })
      .then((result) => {
        dispatch(
          authSuccess({
            user: result.data.user,
            token: result.data.token,
            customerCenterToken: result.data.customerCenterToken,
            accountCreated: result.data.accountCreated,
            keepSignedIn: true,
            serviceProviderLoggingAsAnExhibitor:
              result.data.serviceProviderLoggingAsAnExhibitor,
          })
        );
        dispatch(
          changeApplicationLanguage({
            code: result.data.user.language,
          })
        );
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
        history.push("/");
      })
      .finally(() => handleLoading(false));
  }, [code, addToast, dispatch, history, intl, handleLoading]);

  return <div style={{ height: "100vh" }}></div>;
};

export default StaffOpenID;
