import styled, { css } from "styled-components";
import Modal from "react-modal";

Modal.setAppElement(document.getElementById("root"));

export const ModalContainer = styled(Modal)`
  position: absolute;
  padding: 24px;
  background-color: #fff;
  max-height: 100vh;
  z-index: 999999;
  width: 80%;

  ${(props) =>
    props.modalWidth &&
    css`
      width: ${props.modalWidth};
    `}

  overflow-x: ${({ overflowX }) => (overflowX ? overflowX : "hidden")};

  @media (max-width: 1100px) {
    margin: unset;
    margin-right: -50% !important;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;
