import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import { useToast } from "../../../hooks/toast";
import api from "../../../service";
import InputWithLabel from "../../../components/InputWithLabel";
import { Form } from "@unform/web";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  LinkButton,
  GoBackContainer,
  BadgeContainer,
  FilterActionsContainer,
  SubmitContainer,
} from "./styles";
import SelectWithLabel from "../../../components/SelectWithLabel";
import Table from "../../../components/Table";
import Badge from "../../../components/Badge";
import { useLoading } from "../../../hooks/loading";
import FormButton from "../../../components/FormButton";

const ServiceProviderAssociation = () => {
  const { token } = useSelector((state) => state.auth);
  const [filteredData, setFilteredData] = useState([]);
  const [services, setServices] = useState([]);
  const [events, setEvents] = useState([]);
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);

  const [provisionTypes] = useState([
    { label: intl.formatMessage({ id: "landscaping" }), value: 1 },
    { label: intl.formatMessage({ id: "safety" }), value: 2 },
    { label: intl.formatMessage({ id: "receptive" }), value: 3 },
    { label: intl.formatMessage({ id: "cleaning" }), value: 4 },
    { label: intl.formatMessage({ id: "custom_broker" }), value: 5 },
    { label: intl.formatMessage({ id: "catering" }), value: 6 },
    { label: intl.formatMessage({ id: "furniture_and_decoration" }), value: 7 },
    { label: intl.formatMessage({ id: "translation" }), value: 8 },
    { label: intl.formatMessage({ id: "compressed_air" }), value: 9 },
    { label: intl.formatMessage({ id: "travel_agency" }), value: 10 },
    { label: intl.formatMessage({ id: "visual_communication" }), value: 11 },
    {
      label: intl.formatMessage({ id: "electrical_and_hydraulic" }),
      value: 12,
    },
    { label: intl.formatMessage({ id: "data_collectors" }), value: 13 },
    {
      label: intl.formatMessage({ id: "transport_import_and_export" }),
      value: 14,
    },
    {
      label: intl.formatMessage({ id: "transportation_equipment_leasing" }),
      value: 15,
    },
    { label: intl.formatMessage({ id: "telephone_and_internet" }), value: 16 },
    { label: intl.formatMessage({ id: "press_office" }), value: 17 },
    { label: intl.formatMessage({ id: "equipment_leasing" }), value: 18 },
    { label: intl.formatMessage({ id: "carmaker" }), value: 19 },
    { label: intl.formatMessage({ id: "carpet" }), value: 20 },
    { label: intl.formatMessage({ id: "cargo_handling" }), value: 21 },
    { label: intl.formatMessage({ id: "reception" }), value: 22 },
    { label: intl.formatMessage({ id: "insurance" }), value: 23 },
    { label: intl.formatMessage({ id: "agency" }), value: 24 },
    { label: intl.formatMessage({ id: "coexhibitor" }), value: 25 },
    { label: intl.formatMessage({ id: "others" }), value: 26 },
  ]);

  const handleSubmit = (data) => {
    setLoading(true);
    if (loading) {
      return;
    }
    api
      .patch(
        "/serviceprovider/filter",
        {
          eventId: data.event === "" ? null : data.event,
          provisionType: data.serviceType === "" ? null : data.serviceType,
          boothNumber: data.booth === "" ? null : data.booth,
          accountName: data.exhibitor === "" ? null : data.exhibitor,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        const data = result.data.output;
        let translatedData = [];

        if (data && provisionTypes) {
          data.forEach((item) => {
            provisionTypes.forEach((s) => {
              if (s.value === item.provisionType) {
                translatedData.push({
                  ...item,
                  serviceType: s.label,
                });
              }
            });
          });
        }

        setLoading(false);

        setFilteredData(translatedData);
      })
      .catch((err) => {
        setLoading(false);
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      });
  };

  const getServiceProviderAssociations = useCallback(() => {
    handleLoading(true);
    api
      .patch(
        "/serviceprovider/filter",
        {
          eventId: null,
          provisionType: null,
          boothNumber: null,
          accountName: null,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        const data = result.data.output;
        let translatedData = [];

        data.forEach((item) => {
          provisionTypes.forEach((s) => {
            if (s.value === item.provisionType) {
              translatedData.push({
                ...item,
                serviceType: s.label,
              });
            }
          });
        });

        setFilteredData(translatedData);
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, intl, provisionTypes, token, handleLoading]);

  const getServices = useCallback(() => {
    handleLoading(true);
    api
      .get("/serviceprovider/services", {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        let translatedServices = [];

        result.data.provisionTypes.forEach((item) => {
          provisionTypes.forEach((s) => {
            if (s.value === item.provisionType) {
              translatedServices.push({
                value: item.provisionType,
                label: s.label,
              });
            }
          });
        });

        setServices(translatedServices);
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, intl, provisionTypes, token, handleLoading]);

  const getEvents = useCallback(() => {
    handleLoading(true);
    api
      .get("/serviceprovider/events", {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        const data = result.data.events;
        let eventsSort = [...data].sort((a, b) =>
          a.eventName < b.eventName ? -1 : a.eventName > b.eventName ? 1 : 0
        );
        setEvents(eventsSort);
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, intl, token, handleLoading]);

  useEffect(() => {
    getServiceProviderAssociations();
    getServices();
    getEvents();
  }, [getEvents, getServiceProviderAssociations, getServices]);

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "event_edition" }),
        selector: (row) => row.eventEditionName,
      },
      {
        name: intl.formatMessage({ id: "exhibitor" }),
        selector: (row) => row.accountName,
      },
      {
        name: intl.formatMessage({ id: "type" }),
        selector: (row) => row.serviceType,
      },
      {
        name: intl.formatMessage({ id: "booth" }),
        selector: (row) => row.boothNumber,
      },
      {
        cell: (row) => (
          <Badge
            color="#002244"
            text={intl.formatMessage({ id: "see_details" })}
            onClick={() =>
              history.push({
                pathname: "/serviceprovider-association-provider-detail",
                state: {
                  opportunityId: row.opportunityIdNumber,
                  provisionType: row.provisionType,
                },
              })
            }
            style={{ cursor: "pointer" }}
          />
        ),
      },
    ],
    [history, intl]
  );

  const clearAll = () => {
    formRef.current.setFieldValue("event", null);
    formRef.current.setFieldValue("serviceType", null);
    formRef.current.reset();
    getServiceProviderAssociations();
  };

  return (
    <>
      <AnimatedMainContainer
        breadcrumb={intl.formatMessage({ id: "breadcrumb.events" })}
      >
        <Container>
          <FormContainer>
            <TitleContainer>
              <Title>
                <FormattedMessage id="search_associated_service_providers" />
              </Title>
              <GoBackContainer>
                <LinkButton onClick={() => history.push("/dashboard")}>
                  <Badge
                    color="#002244"
                    text={intl.formatMessage({ id: "goback" })}
                    fontSize="12px"
                  />
                </LinkButton>
              </GoBackContainer>
            </TitleContainer>
            <FieldsContainer>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <Horizontal>
                  <SelectWithLabel
                    name="event"
                    options={
                      events &&
                      events.map((value) => {
                        return {
                          label: value.eventName,
                          value: value.id,
                        };
                      })
                    }
                    clearable
                    label={intl.formatMessage({ id: "event_edition" })}
                    placeholder={intl.formatMessage({ id: "event_edition" })}
                  />

                  <SelectWithLabel
                    name="serviceType"
                    options={services}
                    clearable
                    label={intl.formatMessage({ id: "service_type" })}
                    placeholder={intl.formatMessage({ id: "service_type" })}
                  />
                </Horizontal>
                <Horizontal>
                  <InputWithLabel
                    name="booth"
                    label={intl.formatMessage({ id: "booth" })}
                  />
                  <InputWithLabel
                    name="exhibitor"
                    label={intl.formatMessage({ id: "exhibitor" })}
                  />
                </Horizontal>
                <Horizontal>
                  <FilterActionsContainer>
                    <SubmitContainer>
                      <FormButton
                        color="#C9C9C9"
                        type="button"
                        onClick={() => clearAll()}
                      >
                        <FormattedMessage id="clear_fields" />
                      </FormButton>
                    </SubmitContainer>
                    <SubmitContainer>
                      <FormButton type="submit" loading={loading}>
                        <FormattedMessage id="search" />
                      </FormButton>
                    </SubmitContainer>
                  </FilterActionsContainer>
                </Horizontal>
              </Form>
            </FieldsContainer>
          </FormContainer>
          <Table data={filteredData} columns={columns} hasPagination />
        </Container>
      </AnimatedMainContainer>
    </>
  );
};

export default ServiceProviderAssociation;
