import styled, { keyframes } from "styled-components";
import { shade } from "polished";

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: stretch;
  background-color: #dadada;
  padding: 88px 16px 88px 16px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimatedContainer = styled.div`
  display: flex;
  flex-direction: column;
  animation: ${appearFromLeft} 1s;
  /* background-color: white; */
  width: 100%;
  /* padding: 16px; */
  /* overflow: auto; */
`;

export const RouteBreadcrumbs = styled.p`
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 600;
  color: #002244;
`;
