import styled, { css } from "styled-components";
import { shade } from "polished";

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.color
      ? css`
          background: ${props.color};
        `
      : css`
          background: #002244;
        `}

  color: #fff;
  border-radius: 5px;
  border: 0;
  padding: 0 16px;
  width: 100%;
  height: 54px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  font-size: 16px;
  letter-spacing: normal;
  transition: background-color 0.2s;
  text-transform: uppercase;

  ${(props) =>
    props.size === "full" &&
    css`
      width: 100%;
    `}

  ${(props) =>
    props.size === "auto" &&
    css`
      width: auto;
    `}

    ${(props) =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize};
    `}

  &:hover {
    cursor: pointer;
    ${(props) =>
      props.color
        ? css`
            background: ${shade(0.2, props.color)};
          `
        : css`
            background: ${shade(0.2, "#002244")};
          `}
  }
`;
