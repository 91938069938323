import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import { useToast } from "../../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationErrors";
import api from "../../../service";
import { useCallback } from "react";
import InputWithLabel from "../../../components/InputWithLabel";
import FormButton from "../../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  LinkButton,
  ActionContainer,
  BadgeContainer,
} from "./styles";
import Table from "../../../components/Table";
import Badge from "../../../components/Badge";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { useLoading } from "../../../hooks/loading";

const AssociateColladorators = () => {
  const { token } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [collaboratorId, setCollaboratorId] = useState(null);
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);

  const schema = Yup.object().shape({
    document: Yup.string(),
    name: Yup.string(),
  });

  const showConfirmation = () => {
    setModalIsOpen(true);
  };

  const getAllAssociateCollaborators = useCallback(() => {
    handleLoading(true);
    api
      .get("/associate-collaborators/", {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setData(result.data.associateCollaboratorListOutput);
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, intl, token, handleLoading]);

  useEffect(() => {
    getAllAssociateCollaborators();
  }, [getAllAssociateCollaborators]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        await schema.validate(data, { abortEarly: false });

        setLoading(true);
        if (loading) {
          return;
        }

        api
          .patch(
            "/associate-collaborators/",
            {
              name: data.name,
              document: data.document,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            setLoading(false);
            setData(result.data.associateCollaboratorListOutput);
          })
          .catch((err) => {
            setLoading(false);
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, intl, loading, schema, token]
  );

  const handleDeletion = () => {
    handleLoading(true);
    api
      .delete(`/associate-collaborators/${collaboratorId}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((_) => {
        addToast({
          type: "success",
          title: intl.formatMessage({
            id: "success",
          }),
          description: intl.formatMessage({
            id: "success.collaborator_deletion",
          }),
        });
        setData(data.filter((value) => value.id !== collaboratorId));
        setCollaboratorId(null);
        setModalIsOpen(false);
      })
      .catch((err) => {
        setCollaboratorId(null);
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  };

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const clearFields = () => {
    formRef.current.reset();
    getAllAssociateCollaborators();
  };

  const columns = [
    {
      name: intl.formatMessage({ id: "name" }),
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: intl.formatMessage({ id: "document_filter" }),
      selector: (row) => row.document,
    },
    {
      name: intl.formatMessage({ id: "assignment" }),
      selector: (row) => row.jobTitle,
      sortable: true,
    },
    {
      cell: (row) => (
        <>
          <LinkButton to={`/associate-collaborator/${row.id}`}>
            <Badge color="#002244" text={intl.formatMessage({ id: "edit" })} />
          </LinkButton>

          <Badge
            onClick={() => {
              setCollaboratorId(row.id);
              showConfirmation();
            }}
            color="#cc0000"
            text={intl.formatMessage({ id: "delete" })}
          />
        </>
      ),
      right: true,
    },
  ];

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({
        id: "breadcrumb.associate_collaborators",
      })}
    >
      <Container>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <TitleContainer>
              <Title>
                <FormattedMessage id="search_for_collaborators" />
              </Title>
              <ActionContainer>
                <BadgeContainer onClick={() => history.push("/dashboard")}>
                  <Badge
                    color="#C9C9C9"
                    text={intl.formatMessage({ id: "goback" })}
                    fontSize="12px"
                  />
                </BadgeContainer>
                <BadgeContainer
                  onClick={() => history.push("/associate-collaborators/new")}
                >
                  <Badge
                    color="#002244"
                    text={intl.formatMessage({ id: "new" })}
                    fontSize="12px"
                  />
                </BadgeContainer>
              </ActionContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal>
                <InputWithLabel
                  name="name"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "name" })}
                />
                <InputWithLabel
                  name="document"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "document_filter" })}
                />
              </Horizontal>
              <Horizontal>
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="search" />
                    </FormButton>
                  </SubmitContainer>
                </FilterActionsContainer>
              </Horizontal>
            </FieldsContainer>
          </Form>
        </FormContainer>
      </Container>
      <Table
        columns={columns}
        data={data}
        title={intl.formatMessage({ id: "results" })}
      />
      <ConfirmationModal
        setIsOpen={setModalIsOpen}
        modalIsOpen={modalIsOpen}
        confirmationFunction={handleDeletion}
        description={intl.formatMessage({ id: "exclusion_confirmation" })}
        confirmationText={intl.formatMessage({ id: "yes" })}
        cancelText={intl.formatMessage({ id: "no" })}
      />
    </AnimatedMainContainer>
  );
};

export default AssociateColladorators;
