import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 16px;
  background-color: #fff;
`;

export const TableContainer = styled.div`
  width: 100%;
  background-color: #fff;
  margin-top: 32px;
`;

export const Title = styled.h1`
  font-size: 14px;
  font-weight: normal;
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  padding-bottom: 21px;
  padding-left: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  margin-bottom: 16px;

  @media (max-width: 500px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const FieldsContainer = styled.div`
  margin-top: 16px;
`;

export const SubmitContainer = styled.div`
  width: 100%;
  max-width: 150px;

  @media (max-width: 720px) {
    margin-left: 0px;
    max-width: none;
    width: 100%;
  }
`;

export const LinkButton = styled(Link)`
  text-decoration: none;
`;

export const CollapseToggle = styled.div`
  ${(props) =>
    props.oppened &&
    css`
      /* margin-bottom: 16px; */
    `}
  font-size: 14px;
  font-weight: normal;
  padding: 16px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  cursor: pointer;

  font-size: 13px;
`;

export const CollapseContainer = styled.div`
  border: 1px solid #ccc;
  padding: 8px;
  margin-bottom: 16px;
  & > .mandatory-table {
    margin-top: 0px;
  }
`;

export const Session = styled.div``;

export const SessionDivider = styled.div`
  display: flex;
  @media (max-width: 720px) {
    flex-direction: column;
  }
  ${(props) =>
    props.nr_items === "2" &&
    css`
      & > div {
        flex: 0.5;
        @media (max-width: 720px) {
          flex: 1 1 auto;
          margin-bottom: 16px;
        }
      }
      & > div:nth-child(2) {
        margin-left: 32px;
        @media (max-width: 720px) {
          margin-left: 0px;
        }
      }
    `}

  margin-bottom: 16px;
`;

export const SessionContainer = styled.div`
  border-bottom: 1px solid #dadada;
  display: flex;
  align-items: center;
  padding-bottom: 8px;
`;

export const SessionTitle = styled.h3`
  font-size: 14px;
  font-weight: bold;
  color: #002244;
  margin-left: 8px;
  text-transform: uppercase;
`;

export const SessionIconContainer = styled.div`
  display: flex;
`;

export const PeriodCategoryTitle = styled.p`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: #002244;

  @media (max-width: 1440px) {
    margin-left: 16px;
  }

  @media (max-width: 720px) {
    margin-left: 0px;
    margin-bottom: 8px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 100px;
  align-items: center;
  & > button {
    margin-bottom: 16px;
    width: 360px;
    @media (max-width: 720px) {
      width: 100%;
    }
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;

  div {
    margin-left: 16px;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    div {
      margin-left: 0px;
    }
  }
`;

export const BadgeContainer = styled.div`
  cursor: pointer;
  @media (max-width: 500px) {
    margin-top: 16px;
  }
`;

export const ValuesContainer = styled.div`
  min-width: 100%;
  display: flex;
  flex-direction: row;

  &:nth-child {
    border-right: 1px solid black;
  }
`;

export const ParameterContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 60px;

  div {
    align-self: center;
  }
`;

export const ParameterTitle = styled.div`
  text-align: center;
  flex: 1;
`;

export const ParameterValue = styled.div`
  flex: 0 0 10px;
`;
