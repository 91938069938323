import { useState } from "react";
import { Collapse } from "react-collapse";
import { BsSquare } from "react-icons/bs";
import { FaCheckSquare } from "react-icons/fa";
import { BsChevronUp, BsChevronDown } from "react-icons/bs";
import { useIntl } from "react-intl";
import {
  CollapseContainer,
  CollapseToggle,
  PrincipalContainer,
  SubItemsContainer,
  ItemCheckbox,
  ItemTitle,
  ItemArrow,
  SubItemContainer,
  SubItemContainerTitle,
  SubItemContainerValue,
} from "./styles";

const ItemCollapseSelfSufficiency = ({ element }) => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const intl = useIntl();

  const hasAnyManualElement =
    element?.exhibitorsManualTimesVisualized &&
    element?.exhibitorsManualTimesVisualized > 0;
  const hasAnyPricingElement =
    element?.pricingTableTimesVisualized &&
    element?.pricingTableTimesVisualized > 0;
  const hasBothElements = hasAnyManualElement && hasAnyPricingElement;
  const hasAnyElements = hasAnyManualElement || hasAnyPricingElement;

  const toggleCollapse = () => setCollapseOpen(!collapseOpen);

  const handleAccessCount = (accesses) => {
    return intl.formatMessage(
      { id: accesses === 1 ? "access_counter" : "accesses_counter" },
      { value: accesses }
    );
  };

  return (
    <CollapseContainer>
      <CollapseToggle onClick={toggleCollapse} hasElements={hasAnyElements}>
        <PrincipalContainer>
          <ItemCheckbox>
            {hasBothElements ? (
              <FaCheckSquare size={20} />
            ) : (
              <BsSquare size={20} />
            )}
          </ItemCheckbox>
          <ItemTitle>{element?.eventName}</ItemTitle>
          {hasAnyElements && (
            <ItemArrow>
              {collapseOpen ? <BsChevronDown /> : <BsChevronUp />}
            </ItemArrow>
          )}
        </PrincipalContainer>
      </CollapseToggle>
      <Collapse isOpened={collapseOpen}>
        <SubItemsContainer>
          <SubItemContainer>
            <SubItemContainerTitle>
              {intl.formatMessage({ id: "exhibitor_manual" })}
            </SubItemContainerTitle>
            <SubItemContainerValue>
              {handleAccessCount(element?.exhibitorsManualTimesVisualized || 0)}
            </SubItemContainerValue>
          </SubItemContainer>
          <SubItemContainer>
            <SubItemContainerTitle>
              {intl.formatMessage({ id: "price_list" })}
            </SubItemContainerTitle>
            <SubItemContainerValue>
              {handleAccessCount(element?.pricingTableTimesVisualized || 0)}
            </SubItemContainerValue>
          </SubItemContainer>
        </SubItemsContainer>
      </Collapse>
    </CollapseContainer>
  );
};

export default ItemCollapseSelfSufficiency;
