import React from "react";

import { Container, RootContainer, Label, HtmlContent } from "./styles";

const HtmlContainer = ({
  name,
  containerStyle = {},
  label,
  className,
  value,
  ...rest
}) => {
  return (
    <RootContainer className={className}>
      <Label>{label}</Label>
      <Container style={containerStyle} data-testid="input-container">
        <HtmlContent
          dangerouslySetInnerHTML={{ __html: value }}
          {...rest}
        ></HtmlContent>
      </Container>
    </RootContainer>
  );
};

export default HtmlContainer;
