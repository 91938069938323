import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";

import messages_pt from "../../translations/pt.json";
import messages_en from "../../translations/en.json";

const messages = {
  pt: messages_pt,
  en: messages_en,
};

function LanguageProvider({ children }) {
  let language = useSelector((state) => state.language).code;

  if (language !== "pt" && language !== "en") {
    language = navigator.language.split(/[-_]/)[0];
  }

  return (
    <IntlProvider
      key={language}
      locale={language}
      messages={messages[language]}
      defaultLocale="en"
    >
      {children}
    </IntlProvider>
  );
}

export default LanguageProvider;
