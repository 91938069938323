import { all, takeLatest, call, put } from "redux-saga/effects";

import { setNotifications, deductAnUnreadNotification } from "./actions";
import { alertRequest } from "../alerts/actions";
import api from "../../../service";

function* getNotifications({ payload }) {
  const { token } = payload;
  try {
    const notificationsResponse = yield call(() =>
      api.get("/notification", {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
    );

    const { notificationOutputList, unreadNotifications } =
      notificationsResponse.data;

    yield put(
      setNotifications({
        notifications: notificationOutputList,
        unread: unreadNotifications,
      })
    );
  } catch (error) {
    yield put(
      alertRequest({
        title: "error",
        message: "api.error." + String(error.response.data.code),
        messageType: "error",
        isDialog: true,
      })
    );
  }
}

function* setNotificationAsRead({ payload }) {
  const { id, token } = payload;
  try {
    yield call(() =>
      api.put(
        `/notification/${id}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
    );
    yield put(deductAnUnreadNotification());
  } catch (error) {
    yield put(
      alertRequest({
        title: "error",
        message: "api.error." + String(error.response.data.code),
        messageType: "error",
        isDialog: true,
      })
    );
  }
}

export default all([
  takeLatest("GET_NOTIFICATIONS", getNotifications),
  takeLatest("SET_NOTIFICATION_AS_READ", setNotificationAsRead),
]);
