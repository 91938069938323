import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { useToast } from "../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";
import api from "../../service";
import { useCallback } from "react";
import InputWithLabel from "../../components/InputWithLabel";
import FormButton from "../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  LinkButton,
  GoBackContainer,
  ActionContainer,
  BadgeContainer,
  CredentialsActionContainer,
} from "./styles";
import SelectWithLabel from "../../components/SelectWithLabel";
import Table from "../../components/Table";
import Badge from "../../components/Badge";
import Loader from "react-loader-spinner";
import { FaExclamation } from "react-icons/fa";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useLoading } from "../../hooks/loading";

const Credentials = () => {
  const { token } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [selectedEvent, setSelectedEvents] = useState();
  const [freeQuotes, setFreeQuotes] = useState(null);
  const [utilizedQuotes, setUtilizedQuotes] = useState(null);
  const [filtered, setFiltered] = useState(false);
  const { handleLoading } = useLoading();

  const schema = Yup.object().shape({
    event: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    contract: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    name: Yup.string().nullable(),
    document: Yup.string().nullable(),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) {
          return;
        }

        await schema.validate(data, { abortEarly: false });
        setFiltered(true);

        api
          .patch(
            `/accreditation`,
            {
              eventId: data.event === "" ? null : data.event,
              opportunityId: data.contract === "" ? null : data.contract,
              associateCollaboratorName: data.name === "" ? null : data.name,
              associateCollaboratorDocument:
                data.document === "" ? null : data.document,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            const { usageQuotes, freeQuotes, credentialOutputList } =
              result.data;
            setUtilizedQuotes(usageQuotes);
            setFreeQuotes(freeQuotes);
            setData(credentialOutputList);
          })
          .catch((err) =>
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            })
          )
          .finally(() => setLoading(false));
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, intl, token, schema, loading]
  );

  useEffect(() => {
    handleLoading(true);
    api
      .get(`customer/events/by-user`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        const events = result.data.output;
        let eventsSort = [...events].sort((a, b) =>
          a.label < b.label ? -1 : a.label > b.label ? 1 : 0
        );
        setEvents(eventsSort);
      })
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => handleLoading(false));
  }, [addToast, intl, token, handleLoading]);

  useEffect(() => {
    if (selectedEvent) {
      handleLoading(true);
      api
        .get(`customer/opportunity/event/${selectedEvent}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => setContracts(result.data.output))
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    }
  }, [selectedEvent, addToast, intl, token, handleLoading]);

  const handleUnassociation = useCallback(
    (id) => {
      handleLoading(true);
      api
        .delete(`accreditation/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          addToast({
            type: "success",
            title: intl.formatMessage({
              id: "success",
            }),
            description: intl.formatMessage({
              id: "success.delete_association",
            }),
          });
          handleSubmit(formRef.current.getData());
        })
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    },
    [addToast, intl, handleSubmit, token, handleLoading]
  );

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "name" }),
        selector: (row) => row.collaboratorName,
        sortable: true,
        cell: (row) => (
          <div
            title={`${
              !row.collaboratorStatus
                ? intl.formatMessage({ id: "collaborator_inactive" })
                : ""
            }`}
            style={{ display: "flex", justifyContent: "center" }}
          >
            {!row.collaboratorStatus ? (
              <div>
                <FaExclamation color="#cc0000" />
              </div>
            ) : null}
            <div>{row.collaboratorName}</div>
          </div>
        ),
      },
      {
        name: intl.formatMessage({ id: "scheduled" }),
        selector: (row) => row.scheduled,
        cell: (row) =>
          row.credentialScheduleId
            ? intl.formatMessage({ id: "yes" })
            : intl.formatMessage({ id: "no" }),
      },
      {
        name: intl.formatMessage({ id: "assignment" }),
        selector: (row) => row.jobTitle,
      },
      {
        name: intl.formatMessage({ id: "event_edition" }),
        selector: (row) => row.eventName,
      },
      {
        name: intl.formatMessage({ id: "booth" }),
        selector: (row) => row.boothName,
      },
      {
        name: intl.formatMessage({ id: "category" }),
        selector: (row) => row.category,
        cell: (row) => row.category || intl.formatMessage({ id: "contract" }),
      },
      {
        cell: (row) => (
          <>
            {row.collaboratorStatus && !row.credentialScheduleId ? (
              <LinkButton to={`/credential/edit/${row.id}`}>
                <Badge
                  color="#002244"
                  text={intl.formatMessage({ id: "edit" })}
                />
              </LinkButton>
            ) : null}
          </>
        ),
        right: true,
        width: "125px",
      },
      {
        cell: (row) => {
          return row.collaboratorStatus && !row.credentialScheduleId ? (
            <Badge
              color="#002244"
              text={intl.formatMessage({ id: "unassociate" })}
              onClick={() => handleUnassociation(row.id)}
            />
          ) : (
            <div></div>
          );
        },
        right: true,
        width: "125px",
      },
    ],
    [intl, handleUnassociation]
  );

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("event", {
      label: intl.formatMessage({ id: "event_edition" }),
      value: null,
    });
    formRef.current.setFieldValue("contract", {
      label: intl.formatMessage({ id: "all" }),
      value: "ALL",
    });
    formRef.current.setFieldValue("name", "");
    formRef.current.setFieldValue("document_filter", "");
    setFiltered(false);
  };

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "breadcrumb.credentials" })}
    >
      <Container>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <TitleContainer>
              <Title>
                <FormattedMessage id="manage_credentials" />
              </Title>
              <GoBackContainer>
                <ActionContainer>
                  <BadgeContainer onClick={() => history.push("/dashboard")}>
                    <Badge
                      color="#C9C9C9"
                      text={intl.formatMessage({ id: "goback" })}
                      fontSize="12px"
                    />
                  </BadgeContainer>
                  <BadgeContainer
                    onClick={() => history.push("/associate-collaborators/new")}
                  >
                    <Badge
                      color="#002244"
                      text={intl.formatMessage({ id: "new_collaborator" })}
                      fontSize="12px"
                    />
                  </BadgeContainer>
                </ActionContainer>
              </GoBackContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal>
                <SelectWithLabel
                  name="event"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "event_edition" })}
                  placeholder={intl.formatMessage({ id: "event_edition" })}
                  labelOrientation="vertical"
                  options={events}
                  onChange={(e) => {
                    setSelectedEvents(e.value);
                    formRef.current.setFieldValue("contract", {
                      value: null,
                      label: intl.formatMessage({ id: "contract" }),
                    });
                  }}
                  required
                />

                <SelectWithLabel
                  name="contract"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "contract" })}
                  placeholder={intl.formatMessage({ id: "contract" })}
                  labelOrientation="vertical"
                  options={contracts}
                  required
                />

                <InputWithLabel
                  name="name"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "name" })}
                  placeholder={intl.formatMessage({ id: "name" })}
                />
                <InputWithLabel
                  name="document"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "document_filter" })}
                  placeholder={intl.formatMessage({ id: "document_filter" })}
                />
              </Horizontal>
              <Horizontal>
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="search" />
                    </FormButton>
                  </SubmitContainer>
                </FilterActionsContainer>
              </Horizontal>
            </FieldsContainer>
          </Form>
        </FormContainer>
      </Container>
      {filtered ? (
        <CredentialsActionContainer>
          <FormattedMessage id="utilized" /> {utilizedQuotes} / {freeQuotes}
        </CredentialsActionContainer>
      ) : null}
      <CredentialsActionContainer>
        <BadgeContainer onClick={() => history.push("/credential/associate")}>
          <Badge
            color="#33d27e"
            text={intl.formatMessage({ id: "associate_credential" })}
            fontSize="16px"
          />
        </BadgeContainer>
        <BadgeContainer
          onClick={() => history.push("/credential/schedule-withdrawal")}
        >
          <Badge
            color="#33d27e"
            text={intl.formatMessage({ id: "schedule_withdrawal" })}
            fontSize="16px"
          />
        </BadgeContainer>
      </CredentialsActionContainer>

      {!filtered ? (
        <CredentialsActionContainer>
          <FormattedMessage id="need_to_filter_first" />
        </CredentialsActionContainer>
      ) : (
        <Table
          columns={columns}
          data={data}
          title={intl.formatMessage({ id: "results" })}
          progressPending={loading}
          progressComponent={
            <Loader type="Oval" color="#00BFFF" height={100} width={100} />
          }
        />
      )}
    </AnimatedMainContainer>
  );
};

export default Credentials;
