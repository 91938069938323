import produce from "immer";

const INITIAL_STATE = {
  token: "",
  code: navigator.language.split(/[-_]/)[0],
};

const language = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case "CHANGE_APPLICATION_LANGUAGE": {
        const { code } = action.payload;

        draft.code = code || "en";

        localStorage.setItem("InformaMarkets:Language", code);

        break;
      }

      case "LOAD_APPLICATION_LANGUAGE": {
        const storagedValue = localStorage.getItem("InformaMarkets:Language");

        if (storagedValue) {
          draft.code = storagedValue;
        }

        break;
      }

      default: {
        return draft;
      }
    }
  });
};

export default language;
