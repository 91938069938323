import styled, { css } from "styled-components";

export const SessionContainer = styled.div`
  border-bottom: 1px solid #dadada;
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  flex: 1;
  margin: 0px 8px;
`;

export const SessionTitle = styled.h3`
  font-size: 14px;
  font-weight: bold;
  color: #002244;
  text-transform: uppercase;
  ${(props) =>
    props.hasIcon &&
    css`
      margin-left: 8px;
    `}
`;

export const SessionIconContainer = styled.div`
  display: flex;
`;

export const InfoIconContainer = styled.div`
  margin-left: 8px;
`;
