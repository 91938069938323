import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { useToast } from "../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";
import api from "../../service";
import { useCallback } from "react";
import InputWithLabel from "../../components/InputWithLabel";
import FormButton from "../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";
import { format } from "date-fns";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  LinkButton,
  GoBackContainer,
} from "./styles";
import SelectWithLabel from "../../components/SelectWithLabel";
import Table from "../../components/Table";
import Badge from "../../components/Badge";
import UserModal from "./UserModal";
import { useLoading } from "../../hooks/loading";

const Users = () => {
  const { token } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);

  const schema = Yup.object().shape({
    type: Yup.string().nullable(),
    email: Yup.string().nullable(),
    commercial_name: Yup.string().nullable(),
    status: Yup.string().nullable(),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) {
          return;
        }

        await schema.validate(data, { abortEarly: false });

        api
          .patch(
            "/users",
            {
              type: data.type,
              commercialName: data.commercial_name,
              email: data.email,
              active: data.status,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            setLoading(false);
            setData(result.data.users);
          })
          .catch((err) => {
            setLoading(false);
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, intl, schema, token, loading]
  );

  const emptySubmit = useCallback(() => {
    handleLoading(true);
    api
      .patch(
        "/users",
        {
          type: "ALL",
          commercialName: "",
          email: "",
          active: null,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        setData(result.data.users);
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, handleLoading, intl, token]);

  useEffect(() => {
    emptySubmit();
  }, [addToast, intl, token, handleLoading, emptySubmit]);

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("type", {
      label: intl.formatMessage({ id: "all" }),
      value: "ALL",
    });
    formRef.current.setFieldValue("status", {
      label: intl.formatMessage({ id: "both" }),
      value: null,
    });
    emptySubmit();
  };

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const handleShowModal = (user) => {
    setSelectedUser(user);
    setModalIsOpen(true);
  };

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "creation" }),
        selector: (row) => row.creation,
        format: (row) => {
          return format(new Date(row.createdAt), "dd/MM/yyyy");
        },
        maxWidth: "105px",
      },
      {
        name: "E-mail",
        selector: (row) => row.email,
      },
      {
        name: intl.formatMessage({ id: "company" }),
        selector: (row) => row.commercialName,
      },
      {
        name: intl.formatMessage({ id: "user_type" }),
        selector: (row) => row.role,
        format: (row) => {
          return intl.formatMessage({
            id: `${row.role.toLowerCase().replace(/ /g, "_")}`,
          });
        },
      },
      {
        name: intl.formatMessage({ id: "profile" }),
        selector: (row) => row.currentRoleName,
      },
      {
        name: "Status",
        selector: (row) => row.status,
        cell: (row) => {
          if (row.status === "ACTIVATED") {
            return (
              <Badge
                color="#33d27e"
                text={intl.formatMessage({ id: "active" })}
              />
            );
          }
          return (
            <Badge
              color="#C9C9C9"
              text={intl.formatMessage({ id: "inactive" })}
            />
          );
        },
        maxWidth: "200px",
      },
      {
        width: "125px",
        cell: (row) => (
          <>
            {row.role.toLowerCase().replace(/ /g, "_") ===
              "service_provider" && (
              <LinkButton to={`/admin/service-provider-edit/${row.id}`}>
                <Badge
                  color="#002244"
                  text={intl.formatMessage({ id: "edit" })}
                />
              </LinkButton>
            )}
          </>
        ),
        right: true,
      },
      {
        width: "125px",
        cell: (row) => (
          <div
            onClick={() => handleShowModal(row)}
            style={{ cursor: "pointer", margin: "0 8px" }}
          >
            <Badge
              color="#002244"
              text={intl.formatMessage({ id: "manage" })}
            />
          </div>
        ),
        right: true,
      },
    ],
    [intl]
  );

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "breadcrumb.users" })}
    >
      <Container>
        <FormContainer>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{
              type: {
                label: intl.formatMessage({ id: "all" }),
                value: "ALL",
              },
              status: {
                label: intl.formatMessage({ id: "both" }),
                value: null,
              },
            }}
          >
            <TitleContainer>
              <Title>
                <FormattedMessage id="search_for_users" />
              </Title>
              <GoBackContainer>
                <LinkButton onClick={() => history.push("/dashboard")}>
                  <Badge
                    color="#002244"
                    text={intl.formatMessage({ id: "goback" })}
                    fontSize="12px"
                  />
                </LinkButton>
              </GoBackContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal>
                <SelectWithLabel
                  name="type"
                  options={[
                    {
                      label: intl.formatMessage({ id: "all" }),
                      value: "ALL",
                    },
                    {
                      label: intl.formatMessage({ id: "administrator" }),
                      value: "ADMINISTRATOR",
                    },
                    {
                      label: intl.formatMessage({ id: "staff" }),
                      value: "STAFF",
                    },
                    {
                      label: intl.formatMessage({ id: "client" }),
                      value: "CLIENT",
                    },
                    {
                      label: intl.formatMessage({ id: "service_provider" }),
                      value: "SERVICE_PROVIDER",
                    },
                  ]}
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "type" })}
                />
                <InputWithLabel
                  name="commercial_name"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "company" })}
                />
                <InputWithLabel
                  name="email"
                  validationBlur={validationBlur}
                  label="E-mail"
                />

                <SelectWithLabel
                  name="status"
                  options={[
                    {
                      label: intl.formatMessage({ id: "active" }),
                      value: true,
                    },
                    {
                      label: intl.formatMessage({ id: "inactive" }),
                      value: false,
                    },
                    {
                      label: intl.formatMessage({ id: "both" }),
                      value: null,
                    },
                  ]}
                  validationBlur={validationBlur}
                  label="Status"
                />
              </Horizontal>
              <Horizontal>
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="search" />
                    </FormButton>
                  </SubmitContainer>
                </FilterActionsContainer>
              </Horizontal>
            </FieldsContainer>
          </Form>
        </FormContainer>
        <Table data={data} columns={columns} hasPagination />
        <UserModal
          user={selectedUser}
          setIsOpen={setModalIsOpen}
          modalIsOpen={modalIsOpen}
          updateTable={() => formRef.current.submitForm()}
        />
      </Container>
    </AnimatedMainContainer>
  );
};

export default Users;
