import { cpf, cnpj } from "cpf-cnpj-validator";

export default function cnpjCpfFormatter(value, type) {
  if (type === "F") {
    return cpf.format(value);
  } else if (type === "J") {
    return cnpj.format(value);
  } else {
    return value;
  }
}
