import React from "react";

import { ToastProvider } from "./toast";
import { SidebarProvider } from "./sidebar";
import { LoadingProvider } from "./loading";

const AppProvider = ({ children }) => (
  <LoadingProvider>
    <ToastProvider>
      <SidebarProvider>{children}</SidebarProvider>
    </ToastProvider>
  </LoadingProvider>
);

export default AppProvider;
