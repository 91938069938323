const types = [
  {
    label: "Jurídica",
    value: "J",
  },
  {
    label: "Física",
    value: "F",
  },
  {
    label: "Internacional",
    value: "I",
  },
];

export default types;
