import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { useToast } from "../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";
import api from "../../service";
import { useCallback } from "react";
import FormButton from "../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";
import printJS from "print-js";
import { base64ToArrayBuffer, mergePdfs } from "../../utils/file";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  BadgeContainer,
  CredentialsActionContainer,
  LinkButton,
  GoBackContainer,
} from "./styles";
import SelectWithLabel from "../../components/SelectWithLabel";
import Table from "../../components/Table";
import Badge from "../../components/Badge";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useLoading } from "../../hooks/loading";
import PrintModal from "./PrintModal";
import { useHistory } from "react-router";
import axios from "axios";

const ManageCredentials = () => {
  const { token } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const formRef = useRef(null);
  const intl = useIntl();
  const { addToast } = useToast();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [filtered, setFiltered] = useState(false);

  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState();
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState();
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState();
  const [opportunities, setOpportunities] = useState([]);
  const [types, setTypes] = useState([]);
  const { handleLoading } = useLoading();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [toggledClearRows, setToggledClearRows] = useState(false);
  const [credentialsToPrintProtocol, setCredentialsToPrintProtocol] = useState(
    []
  );

  const [selectedRows, setSelectedRows] = useState([]);

  const schema = Yup.object().shape({
    event: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    role: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    account: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    opportunity: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    category: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
  });

  const handleClearRows = useCallback(() => {
    setToggledClearRows(!toggledClearRows);
  }, [toggledClearRows]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        await schema.validate(data, { abortEarly: false });

        setLoading(true);
        if (loading) {
          return;
        }

        handleClearRows();

        api
          .patch(
            `/admin/accreditation`,
            {
              eventEditionCode: data.event,
              role: data.role,
              accountId: data.account,
              opportunityId: data.opportunity,
              categoryId: data.category,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            setFiltered(true);
            setData(result.data);
          })
          .catch((err) =>
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            })
          )
          .finally(() => setLoading(false));
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, intl, schema, token, loading, handleClearRows]
  );

  useEffect(() => {
    handleLoading(true);
    setRoles([
      {
        label: intl.formatMessage({ id: "client" }),
        value: "CLIENT",
      },
      {
        label: intl.formatMessage({ id: "service_provider" }),
        value: "SERVICE_PROVIDER",
      },
    ]);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .all([
        api.get(`admin/events`, config),
        api.patch(
          `/admin/ecommerce/chleba/category`,
          {
            credential: true,
          },
          config
        ),
      ])
      .then(
        axios.spread((eventResponse, categoryResponse) => {
          const events = eventResponse.data.output.map((event) => ({
            label: event.eventEditionName,
            value: event.eventEditionCode,
          }));
          let eventsSort = [...events].sort((a, b) =>
            a.label < b.label ? -1 : a.label > b.label ? 1 : 0
          );
          setEvents(eventsSort);

          const credentialCategories =
            categoryResponse.data.ecommerceChlebaCategoryFilterOutput.reduce(
              (categories, category) => [
                ...categories,
                {
                  value: category.id,
                  label: category.title,
                },
              ],
              [
                {
                  label: intl.formatMessage({ id: "contract" }),
                  value: 0,
                },
                {
                  label: intl.formatMessage({ id: "all" }),
                  value: -1,
                },
              ]
            );
          setTypes(credentialCategories);
        })
      )
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => handleLoading(false));
  }, [addToast, handleLoading, intl, token]);

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const handleOpenProtocolModal = useCallback(
    ({ printAll }) => {
      if (printAll) {
        setCredentialsToPrintProtocol(
          data.credentialAdminOutputList.map((value) => {
            return value.id;
          })
        );
        setModalIsOpen(true);
      } else {
        if (selectedRows.length > 0) {
          setModalIsOpen(true);
          setCredentialsToPrintProtocol(selectedRows);
        }
      }
    },
    [data.credentialAdminOutputList, selectedRows]
  );

  useEffect(() => {
    formRef.current.setFieldValue("account");
    formRef.current.setFieldValue("opportunity");
    if (selectedEvent && selectedRole) {
      handleLoading(true);
      api
        .patch(
          `admin/account/by-event-and-role/`,
          {
            eventEditionCode: selectedEvent,
            roleType: selectedRole,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => setAccounts(result.data.accountDropdownOutputList))
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    }
  }, [addToast, intl, selectedEvent, selectedRole, token, handleLoading]);

  useEffect(() => {
    formRef.current.setFieldValue("opportunity");
    if (selectedEvent && selectedRole && selectedAccount) {
      handleLoading(true);
      api
        .patch(
          `admin/opportunity/by-event-and-account/`,
          {
            eventEditionCode: selectedEvent,
            roleType: selectedRole,
            accountId: selectedAccount,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) =>
          setOpportunities(result.data.opportunityDropdownOutputList)
        )
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    }
  }, [
    addToast,
    intl,
    selectedAccount,
    selectedEvent,
    selectedRole,
    token,
    handleLoading,
  ]);

  const handleReprintProtocol = useCallback(
    (id) => {
      handleLoading(true);
      api
        .get(`credential-reports/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          const output = result.data.reportOutput;

          printJS({
            printable: output.file,
            type: "pdf",
            base64: true,
          });
        })
        .catch((err) => {
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
        })
        .finally(() => {
          handleLoading(false);
        });
    },
    [addToast, handleLoading, intl, token]
  );

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "associated_credential" }),
        selector: "credentialName",
        sortable: true,
        allowOverflow: true,
        wrap: false,
        minWidth: "115px",
      },
      {
        name: intl.formatMessage({ id: "scheduled" }),
        selector: "scheduled",
        cell: (row) =>
          row.credentialScheduleId
            ? intl.formatMessage({ id: "yes" })
            : intl.formatMessage({ id: "no" }),
      },
      {
        name: intl.formatMessage({ id: "assignment" }),
        selector: "assignment",
      },
      {
        name: intl.formatMessage({ id: "protocol" }),
        selector: "protocol",
        cell: (row) => {
          if (row.protocol) {
            return (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => handleReprintProtocol(row.protocol)}
              >
                {intl.formatMessage(
                  { id: "click_here_to_reprint" },
                  {
                    protocolNumber: row.protocol,
                  }
                )}
              </div>
            );
          }
          return;
        },
        allowOverflow: true,
        wrap: false,
        minWidth: "315px",
      },
      {
        name: intl.formatMessage({ id: "printed_credential" }),
        cell: (row) =>
          row.printed
            ? intl.formatMessage({ id: "yes" })
            : intl.formatMessage({ id: "no" }),
      },
    ],
    [intl, handleReprintProtocol]
  );

  const handlePrint = useCallback(
    ({ printAll }) => {
      let printIds = selectedRows;
      if (
        (selectedRows.length > 0 || printAll) &&
        data.credentialAdminOutputList.length > 0
      ) {
        if (printAll) {
          printIds = data.credentialAdminOutputList.map((value) => {
            return value.id;
          });
        }

        handleLoading(true);

        api
          .patch(
            "/credential-reports",
            {
              credentialsId: printIds,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(async (result) => {
            let filteredOutput = result.data.reportOutputList.filter(
              (value) => value
            );

            if (filteredOutput.length <= 0) {
              return;
            }

            let arrayBuffers = filteredOutput.map((apiResult) => {
              return base64ToArrayBuffer(apiResult.file);
            });

            const base64String = btoa(
              [].reduce.call(
                new Uint8Array(await mergePdfs(arrayBuffers)),
                function (p, c) {
                  return p + String.fromCharCode(c);
                },
                ""
              )
            );

            formRef.current.submitForm();

            printJS({
              printable: base64String,
              type: "pdf",
              base64: true,
            });
          })
          .catch((err) => {
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          })
          .finally(() => {
            handleLoading(false);
          });
      }
    },
    [selectedRows, addToast, intl, token, data, handleLoading]
  );

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("event");
    formRef.current.setFieldValue("role");
    formRef.current.setFieldValue("account");
    formRef.current.setFieldValue("opportunity");
    formRef.current.setFieldValue("category");
    setFiltered(false);
  };

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "breadcrumb.manage_credentials" })}
    >
      <Container>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <TitleContainer>
              <Title>
                <FormattedMessage id="view_indicators" />
              </Title>
              <GoBackContainer>
                <LinkButton onClick={() => history.push("/dashboard")}>
                  <Badge
                    color="#002244"
                    text={intl.formatMessage({ id: "goback" })}
                    fontSize="12px"
                  />
                </LinkButton>
              </GoBackContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal>
                <SelectWithLabel
                  name="event"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "event_edition" })}
                  placeholder={intl.formatMessage({ id: "event_edition" })}
                  labelOrientation="vertical"
                  options={events}
                  onChange={(event) => setSelectedEvent(event?.value)}
                  required
                />

                <SelectWithLabel
                  name="role"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "type" })}
                  placeholder={intl.formatMessage({ id: "type" })}
                  labelOrientation="vertical"
                  options={roles}
                  onChange={(role) => setSelectedRole(role?.value)}
                  required
                />

                <SelectWithLabel
                  name="account"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "account" })}
                  placeholder={intl.formatMessage({ id: "account" })}
                  labelOrientation="vertical"
                  options={accounts}
                  onChange={(account) => setSelectedAccount(account?.value)}
                  required
                />
              </Horizontal>
              <Horizontal>
                <SelectWithLabel
                  name="opportunity"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "contract" })}
                  placeholder={intl.formatMessage({ id: "contract" })}
                  labelOrientation="vertical"
                  options={opportunities}
                  required
                />

                <SelectWithLabel
                  name="category"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "credential_types" })}
                  placeholder={intl.formatMessage({ id: "credential_types" })}
                  labelOrientation="vertical"
                  options={types}
                  required
                />
              </Horizontal>
              <Horizontal>
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="search" />
                    </FormButton>
                  </SubmitContainer>
                </FilterActionsContainer>
              </Horizontal>
            </FieldsContainer>
          </Form>
        </FormContainer>
      </Container>
      {filtered ? (
        <>
          <CredentialsActionContainer>
            {intl.formatMessage({ id: "used" })}: {data.usageQuotas}/
            {data.freeQuotas}
          </CredentialsActionContainer>
          <Table
            columns={columns}
            data={data.credentialAdminOutputList}
            selectableRows={true}
            onSelectedRowsChange={(rowsInformation) =>
              setSelectedRows(rowsInformation.selectedRows.map((row) => row.id))
            }
            title={intl.formatMessage({ id: "results" })}
            progressPending={loading}
            clearSelectedRows={toggledClearRows}
            progressComponent={
              <Loader type="Oval" color="#00BFFF" height={100} width={100} />
            }
          />
          <CredentialsActionContainer>
            <BadgeContainer onClick={() => handlePrint({ printAll: false })}>
              <Badge
                color="#33d27e"
                text={intl.formatMessage({ id: "print_selected" })}
                fontSize="16px"
                disabled={selectedRows.length <= 0}
              />
            </BadgeContainer>
            <BadgeContainer
              onClick={() => handleOpenProtocolModal({ printAll: false })}
            >
              <Badge
                color="#33d27e"
                text={intl.formatMessage({ id: "print_selected_protocols" })}
                fontSize="16px"
                disabled={selectedRows.length <= 0}
              />
            </BadgeContainer>
          </CredentialsActionContainer>
        </>
      ) : (
        <CredentialsActionContainer>
          <FormattedMessage id="need_to_filter_first" />
        </CredentialsActionContainer>
      )}
      <PrintModal
        setIsOpen={setModalIsOpen}
        modalIsOpen={modalIsOpen}
        credentialsId={credentialsToPrintProtocol}
        updateCallback={() => formRef.current.submitForm()}
      />
    </AnimatedMainContainer>
  );
};

export default ManageCredentials;
