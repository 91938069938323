import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { FormattedMessage, useIntl } from "react-intl";

import {
  FaqTitle,
  FaqQuestionText,
  FaqContainer,
  FaqAnswerText,
  FaqAnswersContainer,
  FaqAnswerQuestionText,
  VideoWrapper,
  Container,
} from "./styles";

import { useToast } from "../../../hooks/toast";
import api from "../../../service";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";

import { Link, Element } from "react-scroll";
import { useLoading } from "../../../hooks/loading";

const FaqClientVisualization = () => {
  const intl = useIntl();
  const { addToast } = useToast();
  const { token } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const { handleLoading } = useLoading();

  let language = useSelector((state) => state.language).code;

  if (language !== "pt" && language !== "en") {
    language = navigator.language.split(/[-_]/)[0];
  }

  useEffect(() => {
    handleLoading(true);
    api
      .get("faq/list/true", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setData(result.data.faqListOutput);
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, intl, token, handleLoading]);

  return (
    <AnimatedMainContainer>
      <Container>
        <FaqTitle>
          <FormattedMessage id="faq.client.title" />
        </FaqTitle>
        <FaqContainer>
          {data.map((item) => (
            <FaqQuestionText key={item.id}>
              <Link
                to={item.id}
                smooth={true}
                offset={-72}
                duration={500}
                style={{ cursor: "pointer" }}
              >
                {item[`question${language.toUpperCase()}`]}
              </Link>
            </FaqQuestionText>
          ))}
        </FaqContainer>
        <FaqAnswersContainer>
          {data.map((item) => (
            <Element name={item.id} key={item.id}>
              <FaqAnswerQuestionText id={item.id}>
                {item[`question${language.toUpperCase()}`]}
              </FaqAnswerQuestionText>
              <FaqAnswerText>
                {item[`answer${language.toUpperCase()}`]}
              </FaqAnswerText>
              <VideoWrapper>
                <div dangerouslySetInnerHTML={{ __html: item.tag }}></div>
              </VideoWrapper>
            </Element>
          ))}
        </FaqAnswersContainer>
      </Container>
    </AnimatedMainContainer>
  );
};

export default FaqClientVisualization;
