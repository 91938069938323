import React, { useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";

import { MdPerson } from "react-icons/md";

import { Form } from "@unform/web";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";

import {
  Container,
  Content,
  Background,
  AnimatedContainer,
  Logo,
  Title,
  Description,
  DescriptionContainer,
} from "./styles";

import { FormattedMessage, useIntl } from "react-intl";

import Input from "../../components/Input";
import FormButton from "../../components/FormButton";
import ReCaptcha from "../../components/ReCaptcha";
import BackgroundSlogan from "../../components/BackgroundSlogan";

import logoImg from "../../assets/images/logo.png";
import { useToast } from "../../hooks/toast";
import api from "../../service";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";

const ForgotPassword = () => {
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();

  const schema = Yup.object().shape({
    email: Yup.string()
      .required(
        intl.formatMessage({
          defaultMessage: "O e-mail é obrigatório",
          id: "error.email_required",
        })
      )
      .email(
        intl.formatMessage({
          defaultMessage: "Digite um e-mail válido",
          id: "error.valid_email",
        })
      ),
    recaptcha: Yup.string().required(
      intl.formatMessage({
        defaultMessage: "A verificação do captcha é obrigatória",
        id: "error.captcha_required",
      })
    ),
  });

  const handleSubmit = useCallback(async (data) => {
    try {
      formRef.current?.setErrors({});

      await schema.validate(data, { abortEarly: false });

      api
        .post("auth/forgot-password", { email: data.email })
        .then((data) => {
          addToast({
            type: "success",
            title: "Sucesso!",
            description: intl.formatMessage({
              defaultMessage:
                "Em instantes você receberá um e-mail com instruções para recuperar sua senha!",
              id: "success.password_email_sended",
            }),
          });
          history.push("/");
        })
        .catch((err) => {
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
        });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        return;
      }
    }
  }, []);

  const pushBack = () => {
    history.push("/");
  };

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  return (
    <Container>
      <BackgroundSlogan />
      <Content>
        <AnimatedContainer>
          <Logo src={logoImg} alt="InformaMarket" />
          <Form ref={formRef} onSubmit={handleSubmit}>
            <DescriptionContainer>
              <Title>
                <FormattedMessage
                  id="forgot_password"
                  defaultMessage="Forgot your password?"
                  description="Cancelar"
                />
              </Title>
              <Description>
                <FormattedMessage
                  id="forgot_password_description"
                  defaultMessage="Enter your e-mail below and do the verification"
                  description="Cancelar"
                />
              </Description>
            </DescriptionContainer>
            <Input
              name="email"
              icon={MdPerson}
              validationBlur={validationBlur}
              placeholder="E-mail"
            />
            <ReCaptcha name="recaptcha" />
            <FormButton type="submit">
              <FormattedMessage
                id="send"
                defaultMessage="Enviar"
                description="Enviar"
              />
            </FormButton>
            <FormButton color="#C9C9C9" type="button" onClick={pushBack}>
              <FormattedMessage
                id="cancel"
                defaultMessage="Cancelar"
                description="Cancelar"
              />
            </FormButton>
          </Form>
        </AnimatedContainer>
      </Content>
    </Container>
  );
};

export default ForgotPassword;
