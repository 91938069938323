import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 16px;
  background-color: #fff;
`;

export const TableContainer = styled.div`
  width: 100%;
  background-color: #fff;
  margin-top: 32px;
`;

export const FormContainer = styled.div`
  form {
    display: flex;
    flex-direction: column;
  }
`;

export const Title = styled.h1`
  font-size: 14px;
  font-weight: normal;
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  padding-bottom: 21px;
  padding-left: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  margin-bottom: 16px;

  @media (max-width: 500px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const FieldsContainer = styled.div`
  margin-top: 16px;
`;

export const Horizontal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & > div {
    display: flex;
    flex: 1 0 100px;
    margin-left: 16px;

    &:first-child {
      margin-left: 0px;
    }

    @media (max-width: 810px) {
      flex: 0 0 calc(50% - 16px);
      &:nth-child(3) {
        margin-left: 0px;
      }
    }

    @media (max-width: 730px) {
      margin-left: 0px;
      flex: 1 1 auto;
      width: 100%;
    }
  }
`;

export const SubmitContainer = styled.div`
  width: 100%;
  max-width: 150px;

  @media (max-width: 720px) {
    margin-left: 0px;
    max-width: none;
    width: 100%;
  }
`;

export const TableTitleContainer = styled.div`
  padding: 16px 0px 16px 24px;
`;

export const TableTitle = styled.p``;

export const LinkButton = styled(Link)`
  text-decoration: none;
`;

export const Session = styled.div``;

export const SessionDivider = styled.div`
  display: flex;
  @media (max-width: 720px) {
    flex-direction: column;
  }
  ${(props) =>
    props.nr_items === "2" &&
    css`
      & > div {
        flex: 0.5;
        @media (max-width: 720px) {
          flex: 1 1 auto;
          margin-bottom: 16px;
        }
      }
      & > div:nth-child(2) {
        margin-left: 32px;
        @media (max-width: 720px) {
          margin-left: 0px;
        }
      }
    `}
  margin: 16px 0px;
`;

export const SessionContainer = styled.div`
  border-bottom: 1px solid #dadada;
  display: flex;
  align-items: center;
  padding-bottom: 8px;
`;

export const SessionTitle = styled.h3`
  font-size: 14px;
  font-weight: bold;
  color: #002244;
  margin-left: 8px;
  text-transform: uppercase;
`;

export const SessionIconContainer = styled.div`
  display: flex;
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;

  div {
    margin-left: 16px;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    div {
      margin-left: 0px;
    }
  }
`;

export const BadgeContainer = styled.div`
  cursor: pointer;
  @media (max-width: 500px) {
    margin-top: 16px;
  }
`;
