import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 16px;
  background-color: #fff;
`;

export const Message = styled.p`
  text-align: center;
`;
