import styled from "styled-components";
import Modal from "react-modal";

Modal.setAppElement(document.getElementById("root"));

export const ModalContainer = styled(Modal)`
  position: absolute;
  margin: 0 16px !important;

  padding: 24px;
  background-color: #fff;
  overflow: auto;
  max-height: 100vh;
  z-index: 999999;

  @media (max-width: 1100px) {
    margin: unset;
    margin-right: -50% !important;
  }

  @media (max-width: 768px) {
    width: 100vw;
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding: 0px;
  }
`;

export const PaginatorContainer = styled.div`
  display: none;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 16px;
  padding: 4px;
  margin: 0px 4px;
`;

export const DocumentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  overflow: hidden;
  @media (max-width: 768px) {
    margin-left: 0px;
  }

  &:hover {
    ${PaginatorContainer} {
      @media (min-width: 768px) {
        display: flex;
      }
    }
  }

  & > .PDFDocument {
    @media (max-width: 768px) {
      margin: auto;
      overflow: scroll !important;
      width: 100vw;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    height: 841px;
    overflow: hidden;
  }

  & > .PDFDocument > .PDFPage {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 841px;
    & > .annotationLayer {
      display: none;
    }
  }

  & > .PDFDocument > .PDFPage > canvas {
    height: auto !important;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
  align-items: center;
  @media (max-width: 768px) {
    padding: 24px;
  }
  svg {
    cursor: pointer;
  }
`;

export const Paginator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #a6bcc6;
  height: 32px;
  padding: 4px;
  border-radius: 4px;
  svg {
    cursor: pointer;
  }
`;

export const MobilePaginator = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  /* background-color: #a6bcc6; */
  padding: 8px;
  margin: 0px 4px;
  @media (max-width: 768px) {
    display: flex;
  }
`;

export const PageIndicator = styled.p`
  margin: 0px 8px;
`;

export const TopContainerLeft = styled.div`
  display: flex;
  align-items: center;
`;
