import styled, { keyframes } from "styled-components";
import { shade } from "polished";

export const Container = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 16px;
`;

export const FaqTitle = styled.h1`
  color: #002244;
`;

export const FaqQuestionText = styled.li`
  > a {
    color: #002244;
    margin-top: 8px;
    text-decoration: none;
    transition: color 0.2s;
    overflow-wrap: break-word;
    &:hover {
      color: ${shade(0.2, "#002244")};
    }
  }
`;

export const FaqContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

export const FaqAnswersContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

export const FaqAnswerQuestionText = styled.p`
  font-weight: bold;
  margin-bottom: 8px;
  overflow-wrap: break-word;
`;

export const FaqAnswerText = styled.p`
  margin-bottom: 16px;
  white-space: pre-line;
  overflow-wrap: break-word;
  overflow: hidden;
  width: calc(100vw - 153px);
`;

export const VideoWrapper = styled.div`
  position: relative;
  > div > iframe {
    top: 0;
    left: 0;
    @media (max-width: 720px) {
      width: 100%;
      height: 100%;
    }
  }
`;
