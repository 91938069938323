import React, { useEffect, useRef, useState, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useToast } from "../../../hooks/toast";
import * as Yup from "yup";
import api from "../../../service";
import { Form } from "@unform/web";
import getValidationErrors from "../../../utils/getValidationErrors";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";

import {
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  FileDropzone,
} from "./styles";
import SelectWithLabel from "../../../components/SelectWithLabel";
import Modal from "../../../components/Modal";
import FormButton from "../../../components/FormButton";
import Dropzone from "../../../components/Dropzone";
import { useLoading } from "../../../hooks/loading";

const ExportGuest = ({ setIsOpen, modalIsOpen, updatePageCallback }) => {
  const { token } = useSelector((state) => state.auth);
  const formRef = useRef(null);
  const intl = useIntl();
  const { addToast } = useToast();
  const { handleLoading } = useLoading();

  const [events, setEvents] = useState([]);

  const schema = Yup.object().shape({
    event: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    file: Yup.array()
      .nullable()
      .min(1, intl.formatMessage({ id: "error.field_required" })),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        await schema.validate(data, { abortEarly: false });

        var formData = new FormData();
        handleLoading(true);

        data.file[0]
          .slice(0, 1)
          .arrayBuffer()
          .then(() => {
            formData.set("file", data.file[0]);

            api
              .post(`/manage-guests/${data.event}`, formData, {
                headers: {
                  Accept: "*/*",
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((result) => {
                addToast({
                  type: "success",
                  title: intl.formatMessage({
                    id: "success",
                  }),
                  description: intl.formatMessage({
                    id: "success.guest_creation",
                  }),
                });
                setIsOpen(false);
                updatePageCallback();
              })
              .catch((err) => {
                addToast({
                  type: "error",
                  title: intl.formatMessage({
                    id: "error",
                  }),
                  description: intl.formatMessage({
                    id: "api.error." + err.response.data.code,
                  }),
                });
              })
              .finally(() => {
                handleLoading(false);
              });
          })
          .catch(() => {
            handleLoading(false);
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "error.changed_file",
              }),
            });
            formRef.current.clearField("file");
          });
      } catch (err) {
        handleLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [
      addToast,
      handleLoading,
      intl,
      schema,
      setIsOpen,
      token,
      updatePageCallback,
    ]
  );

  useEffect(() => {
    api
      .get(`/customer/events/by-user`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setEvents(result.data.output);
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      });
  }, [addToast, intl, token]);

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  return (
    <Modal setIsOpen={setIsOpen} modalIsOpen={modalIsOpen} modalWidth="50%">
      <FormContainer>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <TitleContainer>
            <Title>
              <FormattedMessage id="upload_via_xls" />
            </Title>
          </TitleContainer>
          <FieldsContainer>
            <SelectWithLabel
              name="event"
              validationBlur={validationBlur}
              label={intl.formatMessage({ id: "event_edition" })}
              placeholder={intl.formatMessage({ id: "event_edition" })}
              labelOrientation="vertical"
              options={events}
              required
            />
            <FileDropzone>
              <Dropzone
                name="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                type="XLSX"
                multiple={false}

              />
            </FileDropzone>
            <Horizontal>
              <FilterActionsContainer>
                <SubmitContainer>
                  <FormButton
                    color="#C9C9C9"
                    type="button"
                    onClick={() => setIsOpen(false)}
                  >
                    <FormattedMessage id="close" />
                  </FormButton>
                </SubmitContainer>
                <SubmitContainer>
                  <FormButton type="submit">
                    <FormattedMessage id="save" />
                  </FormButton>
                </SubmitContainer>
              </FilterActionsContainer>
            </Horizontal>
          </FieldsContainer>
        </Form>
      </FormContainer>
    </Modal>
  );
};

export default ExportGuest;
