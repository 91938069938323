import React, { useCallback, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { Form } from "@unform/web";
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationErrors";

import { useSelector } from "react-redux";

import { FormattedMessage, useIntl } from "react-intl";
import axios from "axios";

import {
  Container,
  TitleContainer,
  Title,
  Session,
  FieldsContainer,
  ActionContainer,
  BadgeContainer,
  Horizontal,
} from "./styles";

import { useToast } from "../../../hooks/toast";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";
import api from "../../../service";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import InputWithHorizontalLabel from "../../../components/InputWithHorizontalLabel";
import SelectWithLabel from "../../../components/SelectWithLabel";
import Badge from "../../../components/Badge";
import Toggle from "../../../components/Toggle";
import { useEffect } from "react";
import { useLoading } from "../../../hooks/loading";

const NewAdditionalProduct = () => {
  const formRef = useRef(null);
  const intl = useIntl();
  const { addToast } = useToast();
  const { token } = useSelector((state) => state.auth);
  var language = useSelector((state) => state.language).code;

  const [products, setProducts] = useState();
  const [categories, setCategories] = useState();
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const schema = Yup.object().shape({
    category: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    dependentProduct: Yup.string(),
    title: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    englishTitle: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    description: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    englishDescription: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    code: Yup.string()
      .matches(
        /^(^$|([0-9]*))$/g,
        intl.formatMessage({ id: "error.need_to_be_number" })
      )
      .max(9, intl.formatMessage({ id: "error.max_length" }, { length: 9 }))
      .required(intl.formatMessage({ id: "error.field_required" })),
  });

  useEffect(() => {
    handleLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .all([
        api.patch(`/admin/ecommerce/chleba/product`, {}, config),
        api.patch(`/admin/ecommerce/chleba/category`, {}, config),
      ])
      .then(
        axios.spread((productsResponse, categoriesResponse) => {
          const products =
            productsResponse.data.ecommerceChlebaProductFilterOutput.map(
              (product) => ({
                label: language === "pt" ? product.title : product.englishTitle,
                value: product.id,
              })
            );
          setProducts(products);

          const categories =
            categoriesResponse.data.ecommerceChlebaCategoryFilterOutput.map(
              (category) => ({
                label: category.title,
                value: category.id,
              })
            );
          setCategories(categories);
        })
      )
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, intl, token, handleLoading, language]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) {
          return;
        }

        await schema.validate(data, { abortEarly: false });

        api
          .post(
            `/admin/ecommerce/chleba/product`,
            {
              categoryId: data.category,
              dependentProduct: data.dependentProduct,
              title: data.title,
              englishTitle: data.englishTitle,
              description: data.description,
              englishDescription: data.englishDescription,
              code: data.code,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.additional_product_creation",
              }),
            });
            history.push("/admin/additional-products");
          })
          .catch((err) =>
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            })
          )
          .finally(() => setLoading(false));
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [addToast, history, intl, loading, schema, token]
  );

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  return (
    <AnimatedMainContainer>
      <Container>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <TitleContainer>
            <Title>
              <FormattedMessage id="new_additional_product" />
            </Title>
            <ActionContainer>
              <BadgeContainer
                onClick={() => history.push("/admin/additional-products")}
              >
                <Badge
                  color="#C9C9C9"
                  text={intl.formatMessage({ id: "goback" })}
                  fontSize="12px"
                />
              </BadgeContainer>
              <BadgeContainer onClick={() => formRef.current.submitForm()}>
                <Badge
                  color="#002244"
                  text={intl.formatMessage({ id: "save" })}
                  fontSize="12px"
                  loading={loading}
                />
              </BadgeContainer>
            </ActionContainer>
          </TitleContainer>
          <Session>
            <FieldsContainer>
              <Horizontal>
                <SelectWithLabel
                  name="category"
                  label={intl.formatMessage({ id: "category" })}
                  placeholder={intl.formatMessage({ id: "category" })}
                  validationBlur={validationBlur}
                  labelOrientation="horizontal"
                  labelWidth="80px"
                  options={categories}
                  required
                />
                <SelectWithLabel
                  name="dependentProduct"
                  label={intl.formatMessage({ id: "dependent_product" })}
                  placeholder={intl.formatMessage({ id: "dependent_product" })}
                  validationBlur={validationBlur}
                  labelOrientation="horizontal"
                  clearable
                  labelWidth="80px"
                  options={products}
                />
              </Horizontal>
              <Horizontal>
                <InputWithHorizontalLabel
                  name="title"
                  placeholder={intl.formatMessage({
                    id: "title",
                  })}
                  label={intl.formatMessage({
                    id: "title",
                  })}
                  validationBlur={validationBlur}
                  labelWidth="80px"
                  required
                />
                <InputWithHorizontalLabel
                  name="englishTitle"
                  placeholder={intl.formatMessage({
                    id: "title_en",
                  })}
                  label={intl.formatMessage({
                    id: "title_en",
                  })}
                  validationBlur={validationBlur}
                  labelWidth="80px"
                  required
                />
              </Horizontal>
              <InputWithHorizontalLabel
                name="description"
                placeholder={intl.formatMessage({
                  id: "description",
                })}
                label={intl.formatMessage({
                  id: "description",
                })}
                validationBlur={validationBlur}
                labelWidth="80px"
                required
              />
              <InputWithHorizontalLabel
                name="englishDescription"
                placeholder={intl.formatMessage({
                  id: "description_en",
                })}
                label={intl.formatMessage({
                  id: "description_en",
                })}
                validationBlur={validationBlur}
                labelWidth="80px"
                required
              />
              <InputWithHorizontalLabel
                name="code"
                placeholder={intl.formatMessage({
                  id: "code",
                })}
                label={intl.formatMessage({
                  id: "code",
                })}
                validationBlur={validationBlur}
                labelWidth="80px"
                required
              />
            </FieldsContainer>
          </Session>
        </Form>
      </Container>
    </AnimatedMainContainer>
  );
};

export default NewAdditionalProduct;
