import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import FormButton from "../../FormButton";
import { ModalContainer } from "./styles";
import TextEN from "./TermsText/en";
import TextPT from "./TermsText/pt";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    zIndex: 999999,
  },
};

function TermsModal({ modalIsOpen, setIsOpen, children }) {
  let language = useSelector((state) => state.language).code;

  if (language !== "pt" && language !== "en") {
    language = navigator.language.split(/[-_]/)[0];
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <ModalContainer
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Termos de uso"
      className="Modal"
    >
      {language === "en" ? <TextEN /> : <TextPT />}
      <FormButton color="#002244" onClick={closeModal}>
        <FormattedMessage id="close" />
      </FormButton>
    </ModalContainer>
  );
}

export default TermsModal;
