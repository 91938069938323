import FormButton from "../FormButton";
import {
  ModalContainer,
  Button,
  XButton,
  XButtonContainer,
  ButtonContainer,
} from "./styles";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    zIndex: 999999,
    backgroundColor: "rgba(201, 201, 201, 0.5)",
  },
};

function ConfirmationModal({
  modalIsOpen,
  setIsOpen,
  confirmationFunction,
  cancelationFunction,
  confirmationText,
  cancelText,
  title,
  description,
  isLoading,
}) {
  function closeModal() {
    if (cancelationFunction) {
      cancelationFunction();
    }
    setIsOpen(false);
  }

  function handleConfirmation() {
    if (isLoading) {
      return;
    }
    confirmationFunction();
  }

  return (
    <ModalContainer
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Confirmação"
      className="Modal"
    >
      <h1>{title}</h1>
      <p>{description}</p>
      <ButtonContainer>
        <Button onClick={() => handleConfirmation()} color="#002244">
          {confirmationText}
        </Button>
        {cancelText && (
          <Button
            onClick={() => {
              closeModal();
            }}
            color="#C9C9C9"
          >
            {cancelText}
          </Button>
        )}
      </ButtonContainer>
    </ModalContainer>
  );
}

export default ConfirmationModal;
