import React from "react";
import {
  SessionContainer,
  SessionIconContainer,
  SessionTitle,
  InfoIconContainer,
} from "./styles";

const SessionDivider = ({ icon, title, infoIcon, ...rest }) => (
  <SessionContainer>
    {icon && <SessionIconContainer>{icon}</SessionIconContainer>}
    <SessionTitle hasIcon={!!icon}>{title}</SessionTitle>{" "}
    <InfoIconContainer>{infoIcon && infoIcon}</InfoIconContainer>
  </SessionContainer>
);

export default SessionDivider;
