import React, { useEffect, useState } from "react";

import { FormattedMessage, useIntl } from "react-intl";

import {
  Container,
  Content,
  AnimatedContainer,
  Logo,
  LoginLink,
  ButtonLink,
  VersionContainer,
} from "./styles";

import FormButton from "../../components/FormButton";

import logoImg from "../../assets/images/logo.png";

import BackgroundSlogan from "../../components/BackgroundSlogan";
import api from "../../service";
import { useToast } from "../../hooks/toast";

const SignIn = () => {
  const [versions, setVersions] = useState();
  const intl = useIntl();
  const { addToast } = useToast();

  useEffect(() => {
    api
      .get("/system/actuator", {
        headers: { "Content-Type": "application/json", Accept: "*/*" },
      })
      .then((result) => {
        setVersions({
          frontend: process.env.REACT_APP_VERSION,
          backend: result.data.backendVersion,
        });
      })
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      );
  }, [addToast, intl]);

  return (
    <Container>
      <BackgroundSlogan />
      <Content>
        <AnimatedContainer>
          <Logo src={logoImg} alt="InformaMarket" />
          <LoginLink
            href={`${process.env.REACT_APP_OPENID_URL}?client_id=${process.env.REACT_APP_OPENID_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_OPENID_EXHIBITOR_REDIRECT_URI}&response_type=code&scope=openid%20email%20profile&state=somestatevalue`}
          >
            <FormButton type="button" height="52px" roundedButton>
              <FormattedMessage id="signin_as_exhibitor" />
            </FormButton>
          </LoginLink>

          <LoginLink
            href={`${process.env.REACT_APP_OPENID_URL}?client_id=${process.env.REACT_APP_OPENID_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_OPENID_SERVICE_PROVIDER_REDIRECT_URI}&response_type=code&scope=openid%20email%20profile&state=somestatevalue`}
          >
            <FormButton
              type="button"
              height="52px"
              color="#445671"
              roundedButton
            >
              <FormattedMessage id="signin_as_service_provider" />
            </FormButton>
          </LoginLink>

          <LoginLink
            href={`${process.env.REACT_APP_OPENID_URL}?client_id=${process.env.REACT_APP_OPENID_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_OPENID_STAFF_REDIRECT_URI}&response_type=code&scope=openid%20email%20profile&state=somestatevalue`}
          >
            <FormButton
              type="button"
              height="52px"
              color="#36ac64"
              roundedButton
            >
              <FormattedMessage id="signin_as_staff" />
            </FormButton>
          </LoginLink>

          <LoginLink
            href={`${process.env.REACT_APP_SERVER_URL}auth/informa-sso/admin-login`}
          >
            <FormButton
              type="button"
              height="52px"
              color="#68c18b"
              roundedButton
            >
              <FormattedMessage id="signin_by_azure" />
            </FormButton>
          </LoginLink>
        </AnimatedContainer>
        <VersionContainer>
          Front: {versions?.frontend} Back: {versions?.backend}
        </VersionContainer>
      </Content>
    </Container>
  );
};

export default SignIn;
