import React from "react";
import DataTable from "react-data-table-component";
import { useIntl } from "react-intl";
import Loader from "react-loader-spinner";
import {
  TableContainer,
  TableTitle,
  TableTitleContainer,
  NoDataText,
} from "./styles";

const Table = ({
  title,
  columns,
  data,
  hasPagination = true,
  className,
  striped = false,
  titleMarginLeft,
  paddingAround,
  ...rest
}) => {
  const intl = useIntl();

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "rgba(218, 218, 218, 0.25)",
      },
    },
    headCells: {
      style: {
        color: "#000",
        fontSize: "14px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        textAlign: "left",
      },
    },
    rows: {
      style: {
        backgroundColor: "#fff",
        borderLeft: "4px solid #fff",
        "&:hover": {
          borderLeft: "4px solid #002244",
          backgroundColor: "rgba(17, 167, 217, 0.05) !important",
        },
      },
    },
  };

  return (
    <TableContainer className={className} paddingAround={paddingAround}>
      {title && (
        <TableTitleContainer titleMarginLeft={titleMarginLeft}>
          <TableTitle>{title}</TableTitle>
        </TableTitleContainer>
      )}

      <DataTable
        columns={columns}
        data={data}
        responsive
        customStyles={customStyles}
        noDataComponent={
          <NoDataText>
            {intl.formatMessage({
              id: "no_records_to_display",
            })}
          </NoDataText>
        }
        pagination={hasPagination ? true : false}
        striped={striped}
        paginationComponentOptions={{
          rowsPerPageText: intl.formatMessage({ id: "rows_per_page" }),
          rangeSeparatorText: intl.formatMessage({ id: "of" }),
          noRowsPerPage: false,
          selectAllRowsItem: false,
          selectAllRowsItemText: intl.formatMessage({ id: "all" }),
        }}
        progressComponent={
          <Loader type="Oval" color="#00BFFF" height={100} width={100} />
        }
        {...rest}
      />
    </TableContainer>
  );
};

export default Table;
