import React from "react";
import {
  Container,
  IconText,
  IconContainer,
  LogoContainer,
  Logo,
  Content,
} from "./styles";

import { MdExitToApp } from "react-icons/md";
import { useState } from "react";
import Menus from "./Menus";
import LogoCircle from "../../assets/images/Informa_Logo_Circle.png";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

function Sidebar({ data, openConfirmation }) {
  const [selectedId, setSelectedId] = useState(0);
  const { permissionCategories } = useSelector((state) => state.auth);
  const history = useHistory();

  const location = useLocation();
  const selectedItemName = location.pathname;

  const handleSelectedId = (item) => {
    if (item.subitem.length) {
      if (selectedId === item.id) {
        setSelectedId(0);
      } else {
        if (selectedId === 0) {
          setSelectedId(item.id);
        } else {
          setSelectedId(0);
          setTimeout(() => {
            setSelectedId(item.id);
          }, 650);
        }
      }
    }
  };

  const finalData = [];

  data.forEach((info) => {
    let infoFilteredByCode = permissionCategories.find(
      (permission) => permission.code === info.code
    );

    if (infoFilteredByCode?.permissions) {
      let infoPermissionsBackend = infoFilteredByCode;

      let allowedSubitems = info.subitem.filter((subitem) => {
        return infoPermissionsBackend.permissions.some(
          (infoPermission) => infoPermission.code === subitem.code
        );
      });

      finalData.push({
        ...info,
        subitem: allowedSubitems,
      });
    }
  });

  const handleActive = (item) => {
    if (selectedId !== 0) return false;
    if (item && item.routeName)
      return item.routeName.some((routeName) =>
        selectedItemName.includes(routeName)
      );
    else return false;
  };

  return (
    <>
      <Container>
        <LogoContainer>
          <Logo onClick={() => history.push("/dashboard")} src={LogoCircle} />
        </LogoContainer>
        <Content>
          {finalData.map((item) => {
            if (item.subitem.length > 0 || item.standalone)
              return (
                <IconContainer
                  key={item.id}
                  onClick={() => {
                    if (item.clickFunction) {
                      setSelectedId(0);
                      return item.clickFunction();
                    }
                    return handleSelectedId(item);
                  }}
                  isSelected={item.id === selectedId}
                  active={handleActive(item)}
                >
                  {item.icon}
                  <IconText>{item.name}</IconText>
                </IconContainer>
              );

            return <React.Fragment key={item.id}></React.Fragment>;
          })}
          <IconContainer onClick={() => openConfirmation()}>
            <MdExitToApp size={46} color="#cccccc" />
            <IconText>
              <FormattedMessage id="logout" />
            </IconText>
          </IconContainer>
        </Content>
      </Container>
      {finalData.map((item) => (
        <Menus
          key={item.id}
          data={item}
          title={item.subitemTitle}
          setSelectedId={setSelectedId}
          selected={item.id === selectedId}
        />
      ))}
    </>
  );
}

export default Sidebar;
