import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { useToast } from "../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";
import api from "../../service";
import { useCallback } from "react";
import FormButton from "../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  GoBackContainer,
  ActionContainer,
  BadgeContainer,
  CredentialsActionContainer,
} from "./styles";
import SelectWithLabel from "../../components/SelectWithLabel";
import Table from "../../components/Table";
import Badge from "../../components/Badge";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useLoading } from "../../hooks/loading";
import axios from "axios";

const CredentialTransfer = () => {
  const { token } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState();
  const [opportunities, setOpportunities] = useState([]);
  const [selectedOpportunity, setSelectedOpportunity] = useState();
  const [categories, setCategories] = useState([]);
  const [operations, setOperations] = useState([]);
  const [users, setUsers] = useState([]);
  const [filtered, setFiltered] = useState(false);
  const { handleLoading } = useLoading();

  const schema = Yup.object().shape({
    event: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    opportunity: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    category: Yup.string().nullable(),
    operation: Yup.string().nullable(),
    user: Yup.string().nullable(),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) {
          return;
        }

        await schema.validate(data, { abortEarly: false });
        setFiltered(true);

        api
          .patch(
            `/credential-transfer`,
            {
              opportunityId: data.opportunity ? data.opportunity : null,
              categoryId: data.category ? data.category : null,
              operation: data.operation ? data.operation : null,
              systemUserId: data.user ? data.user : null,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => setData(result.data.credentialTransferOutputs))
          .catch((err) =>
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            })
          )
          .finally(() => setLoading(false));
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [loading, schema, token, addToast, intl]
  );

  useEffect(() => {
    handleLoading(true);
    api
      .get(`customer/events/by-user`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        const events = result.data.output;
        let eventsSort = [...events].sort((a, b) =>
          a.label < b.label ? -1 : a.label > b.label ? 1 : 0
        );
        setEvents(eventsSort);
      })
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => handleLoading(false));
  }, [addToast, intl, token, handleLoading]);

  useEffect(() => {
    formRef.current.setFieldValue("opportunity");
    if (selectedEvent) {
      handleLoading(true);
      api
        .get(`customer/opportunity/event/${selectedEvent}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => setOpportunities(result.data.output))
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    }
  }, [selectedEvent, addToast, intl, token, handleLoading]);

  useEffect(() => {
    formRef.current.setFieldValue("category");
    formRef.current.setFieldValue("operation");
    formRef.current.setFieldValue("user");
    if (selectedOpportunity) {
      handleLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      };

      setOperations([
        {
          label: intl.formatMessage({ id: "transferred" }),
          value: "TRANSFERRED",
        },
        { label: intl.formatMessage({ id: "received" }), value: "RECEIVED" },
      ]);
      axios
        .all([
          api.get(
            `ecommerce/category/available/opportunity/${selectedOpportunity}`,
            config
          ),
          api.get(`/users/transfer/opportunity/${selectedOpportunity}`, config),
        ])
        .then(
          axios.spread((categoryResult, userResult) => {
            const credentialCategories =
              categoryResult.data.ecommerceChlebaCategoryCredentialAvailableOutputs.map(
                (category) => ({
                  value: category.id,
                  label: category.title,
                })
              );
            setCategories(credentialCategories);
            const users = userResult.data.systemUserByOpportunityOutputs.map(
              (user) => ({
                value: user.id,
                label: user.commercialName,
              })
            );
            setUsers(users);
          })
        )
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    }
  }, [addToast, handleLoading, intl, selectedOpportunity, token]);

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "contract" }),
        selector: (row) => row.opportunity,
      },
      {
        name: intl.formatMessage({ id: "category" }),
        selector: (row) => row.category,
      },
      {
        name: intl.formatMessage({ id: "user" }),
        selector: (row) => row.user,
      },
      {
        name: intl.formatMessage({ id: "quantity" }),
        selector: (row) => row.quantity,
      },
      {
        name: intl.formatMessage({ id: "benefited" }),
        selector: (row) => row.benefited,
      },
    ],
    [intl]
  );

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("event");
    formRef.current.setFieldValue("opportunity");
    formRef.current.setFieldValue("category");
    formRef.current.setFieldValue("operation");
    formRef.current.setFieldValue("user");
    setFiltered(false);
  };

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "breadcrumb.credential_transfer" })}
    >
      <Container>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <TitleContainer>
              <Title>
                <FormattedMessage id="credential_transfer" />
              </Title>
              <GoBackContainer>
                <ActionContainer>
                  <BadgeContainer onClick={() => history.push("/dashboard")}>
                    <Badge
                      color="#C9C9C9"
                      text={intl.formatMessage({ id: "goback" })}
                      fontSize="12px"
                    />
                  </BadgeContainer>
                  <BadgeContainer
                    onClick={() => history.push("/credential-transfer/new")}
                  >
                    <Badge
                      color="#002244"
                      text={intl.formatMessage({ id: "new_transfer" })}
                      fontSize="12px"
                    />
                  </BadgeContainer>
                </ActionContainer>
              </GoBackContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal>
                <SelectWithLabel
                  name="event"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "event_edition" })}
                  placeholder={intl.formatMessage({ id: "event_edition" })}
                  labelOrientation="vertical"
                  options={events}
                  onChange={(e) => setSelectedEvent(e?.value)}
                  required
                />

                <SelectWithLabel
                  name="opportunity"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "contract" })}
                  placeholder={intl.formatMessage({ id: "contract" })}
                  labelOrientation="vertical"
                  options={opportunities}
                  onChange={(e) => setSelectedOpportunity(e?.value)}
                  required
                />
              </Horizontal>
              <Horizontal>
                <SelectWithLabel
                  name="category"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "category" })}
                  placeholder={intl.formatMessage({ id: "category" })}
                  labelOrientation="vertical"
                  options={categories}
                />
                <SelectWithLabel
                  name="operation"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "operation" })}
                  placeholder={intl.formatMessage({ id: "operation" })}
                  labelOrientation="vertical"
                  options={operations}
                />

                <SelectWithLabel
                  name="user"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "user" })}
                  placeholder={intl.formatMessage({ id: "user" })}
                  labelOrientation="vertical"
                  options={users}
                />
              </Horizontal>
              <Horizontal>
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="search" />
                    </FormButton>
                  </SubmitContainer>
                </FilterActionsContainer>
              </Horizontal>
            </FieldsContainer>
          </Form>
        </FormContainer>
      </Container>
      {!filtered ? (
        <CredentialsActionContainer>
          <FormattedMessage id="need_to_filter_first" />
        </CredentialsActionContainer>
      ) : (
        <Table
          columns={columns}
          data={data}
          title={intl.formatMessage({ id: "results" })}
          progressPending={loading}
          progressComponent={
            <Loader type="Oval" color="#00BFFF" height={100} width={100} />
          }
        />
      )}
    </AnimatedMainContainer>
  );
};

export default CredentialTransfer;
