import React, { useEffect, useState } from "react";

import "rc-steps/assets/index.css";
import { ProgressIndicator, TextStep, StepContainer } from "./styles";
import { useWindowSize } from "../../../../hooks/dimensions";
import { CgSandClock } from "react-icons/cg";
import { MdDone } from "react-icons/md";

const Timeline = ({ data = [], currentStep, timelineInfo }) => {
  const [width] = useWindowSize();

  const [directionTimeline, setDirectionTimeline] = useState("vertical");
  const [directionLabelTimeline, setDirectionLabelTimeline] =
    useState("vertical");

  useEffect(() => {
    if (width <= 720) {
      setDirectionTimeline("vertical");
      setDirectionLabelTimeline("horizontal");
    } else {
      setDirectionTimeline("horizontal");
      setDirectionLabelTimeline("vertical");
    }
  }, [width]);

  return (
    <StepContainer
      current={currentStep}
      direction={directionTimeline}
      labelPlacement={directionLabelTimeline}
    >
      {data.map((element, index) => (
        <StepContainer.Step
          key={index}
          stepId={element.id}
          icon={
            <ProgressIndicator done={element.done}>
              {element.done ? (
                <MdDone size={20} color="#fff" />
              ) : (
                <CgSandClock size={20} color="#fff" />
              )}
            </ProgressIndicator>
          }
          title={<TextStep done={element.done}>{element.text}</TextStep>}
        />
      ))}
    </StepContainer>
  );
};

export default Timeline;
