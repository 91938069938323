import React from "react";

import { Container } from "./styles";
import Loader from "react-loader-spinner";

const Button = ({
  children,
  icon,
  loading,
  size = "full",
  fontSize,
  ...rest
}) => (
  <Container size={size} fontSize={fontSize} {...rest}>
    {icon}
    {loading ? <Loader type="Oval" color="white" height="20px" /> : children}
  </Container>
);

export default Button;
