import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Form } from "@unform/web";
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationErrors";

import { useSelector } from "react-redux";

import { FormattedMessage, useIntl } from "react-intl";

import {
  Container,
  TitleContainer,
  Title,
  Session,
  FieldsContainer,
  ActionContainer,
  BadgeContainer,
} from "./styles";

import { useToast } from "../../../hooks/toast";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";
import api from "../../../service";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import InputWithHorizontalLabel from "../../../components/InputWithHorizontalLabel";
import SelectWithLabel from "../../../components/SelectWithLabel";
import Badge from "../../../components/Badge";
import { useLoading } from "../../../hooks/loading";
import axios from "axios";
import {
  InfoContainer,
  NumericalInfoContainer,
  NumericalInfoText,
} from "../CreateAssociation/styles";

const EditAssociation = () => {
  const formRef = useRef(null);
  const intl = useIntl();
  const { addToast } = useToast();
  const { user, token } = useSelector((state) => state.auth);
  const { id } = useParams();
  const [data, setData] = useState();
  const [key, setKey] = useState();
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState();
  const [eventItem, setEventItem] = useState();
  const [contracts, setContracts] = useState([]);
  const [selectedContract, setSelectedContract] = useState();
  const [contractItem, setContractItem] = useState();
  const [collaborators, setCollaborators] = useState([]);
  const [selectedCollaborator, setSelectedCollaborator] = useState([]);
  const [collaboratorItem, setCollaboratorItem] = useState();
  const [categories, setCategories] = useState([]);
  const [categoryInformation, setCategoryInformation] = useState();
  const [categoryItem, setCategoryItem] = useState();
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const schema = Yup.object().shape({
    event: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    contract: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    collaborator: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    category: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    assignment: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    credential_name: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        setLoading(true);
        if (loading) {
          return;
        }

        formRef.current?.setErrors({});

        await schema.validate(data, { abortEarly: false });

        api
          .put(
            `/accreditation/${id}`,
            {
              eventId: data.event,
              opportunityId: data.contract,
              associateCollaboratorId: data.collaborator,
              categoryId: data.category,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.collaborator_creation",
              }),
            });
            history.push("/credentials");
          })
          .catch((err) =>
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            })
          )
          .finally(() => setLoading(false));
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, history, id, intl, loading, schema, token]
  );

  useEffect(() => {
    setLoading(true);
    handleLoading(true);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .all([
        api.get(`customer/events/by-user`, config),
        api.get(`associate-collaborators/by-user`, config),
        api.get(`accreditation/${id}`, config),
      ])
      .then(
        axios.spread(
          async (
            eventsResponse,
            collaboratorsResponse,
            accreditationResponse
          ) => {
            const events = eventsResponse.data.output;
            const collaborators = collaboratorsResponse.data.output;
            const data = accreditationResponse.data.credentialOutput;
            setEvents(events);
            setCollaborators(collaborators);
            setData(data);

            const {
              eventId,
              associateCollaboratorId,
              opportunityId,
              categoryId,
            } = accreditationResponse.data.credentialOutput;
            handleEventItemSelection(events, eventId);
            handleCollaboratorItemSelection(
              collaborators,
              associateCollaboratorId
            );

            return {
              categoryId,
              opportunityId,
              eventId,
              opportunityResponse: await api.get(
                `customer/opportunity/event/${eventId}`,
                config
              ),
            };
          }
        )
      )
      .then(
        async ({ categoryId, opportunityId, eventId, opportunityResponse }) => {
          const contracts = opportunityResponse.data.output;
          setContracts(contracts);
          handleContractItemSelection(contracts, opportunityId);

          return {
            categoryId,
            quotaResponse: await api.patch(
              `accreditation/quota/`,
              { eventId, opportunityId },
              config
            ),
            categoryResponse: await api.get(
              `ecommerce/category/available/opportunity/${opportunityId}`,
              config
            ),
          };
        }
      )
      .then(({ categoryId, quotaResponse, categoryResponse }) => {
        const { total, used } = quotaResponse.data.credentialQuotesOutput;
        const credentialCategories = handleCategoryReduce(
          categoryResponse.data
            .ecommerceChlebaCategoryCredentialAvailableOutputs,
          total,
          used
        );
        setCategories(credentialCategories);
        handleCategoryItemSelection(credentialCategories, categoryId);
      })
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => {
        handleLoading(false);
        setKey(new Date());
        setLoading(false);
      });
  }, [addToast, intl, token, id, handleLoading]);

  const handleEventItemSelection = (events, id) => {
    const item = events.find((event) => event.value === id);
    setEventItem(item);
    setSelectedEvent(id);
  };

  const handleCollaboratorItemSelection = (collaborators, id) => {
    const item = collaborators.find(
      (collaborator) => collaborator.value === id
    );
    setCollaboratorItem(item);
    setSelectedCollaborator(item);
  };

  const handleContractItemSelection = (contracts, id) => {
    const item = contracts.find((contract) => contract.value === id);
    setContractItem(item);
    setSelectedContract(id);
  };

  const handleCategoryItemSelection = (categories, id) => {
    const item = categories.find((category) => category.value === id);
    setCategoryItem(item);
    setCategoryInformation({
      used: item?.information?.used || 0,
      total: item?.information?.total || 0,
    });
  };

  const handleCategoryReduce = (categories, total, used) => {
    return categories.reduce(
      (categories, category) => [
        ...categories,
        {
          value: category.id,
          label: category.title,
          information: {
            total: category.total || 0,
            used: category.used || 0,
          },
        },
      ],
      user?.role?.type === "CLIENT"
        ? [
            {
              label: intl.formatMessage({ id: "contract" }),
              value: 0,
              information: {
                total: total || 0,
                used: used || 0,
              },
            },
          ]
        : []
    );
  };

  useEffect(() => {
    if (!loading) {
      formRef.current.setFieldValue("contract");
      if (selectedEvent) {
        handleLoading(true);
        api
          .get(`customer/opportunity/event/${selectedEvent}`, {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((result) => setContracts(result.data.output))
          .catch((err) =>
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            })
          )
          .finally(() => handleLoading(false));
      }
    }
    //loading shouldn't be in the dependency array
  }, [addToast, data, handleLoading, intl, selectedEvent, token]);

  useEffect(() => {
    if (selectedCollaborator) {
      formRef.current.setFieldValue(
        "assignment",
        selectedCollaborator.jobTitle
      );
      formRef.current.setFieldValue(
        "credential_name",
        selectedCollaborator.credentialName
      );
    }
  }, [selectedCollaborator]);

  useEffect(() => {
    if (!loading) {
      formRef.current.setFieldValue("category");
      if (selectedEvent && selectedContract) {
        handleLoading(true);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        };
        axios
          .all([
            api.patch(
              `accreditation/quota/`,
              { eventId: selectedEvent, opportunityId: selectedContract },
              config
            ),
            api.get(
              `ecommerce/category/available/opportunity/${selectedContract}`,
              config
            ),
          ])
          .then(
            axios.spread((quotaResponse, categoryResponse) => {
              const { total, used } = quotaResponse.data.credentialQuotesOutput;
              const credentialCategories = handleCategoryReduce(
                categoryResponse.data
                  .ecommerceChlebaCategoryCredentialAvailableOutputs,
                total,
                used
              );
              setCategories(credentialCategories);
            })
          )
          .catch((error) =>
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + error.response.data.code,
              }),
            })
          )
          .finally(() => handleLoading(false));
      }
    }
    //loading shouldn't be in the dependency array
  }, [addToast, handleLoading, intl, selectedContract, selectedEvent, token]);

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  return (
    <AnimatedMainContainer>
      <Container>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          key={key}
          initialData={{
            event: eventItem,
            contract: contractItem,
            collaborator: collaboratorItem,
            category: categoryItem,
            assignment: data?.associateCollaboratorJobTitle,
            credential_name: data?.credentialName,
          }}
        >
          <TitleContainer>
            <Title>
              <FormattedMessage id="edit_association" />
            </Title>
            <ActionContainer>
              <BadgeContainer onClick={() => history.push("/credentials")}>
                <Badge
                  color="#C9C9C9"
                  text={intl.formatMessage({ id: "goback" })}
                  fontSize="12px"
                />
              </BadgeContainer>
              <BadgeContainer onClick={() => formRef.current.submitForm()}>
                <Badge
                  color="#002244"
                  text={intl.formatMessage({ id: "save" })}
                  fontSize="12px"
                  loading={loading}
                />
              </BadgeContainer>
            </ActionContainer>
          </TitleContainer>
          <Session>
            <FieldsContainer>
              <SelectWithLabel
                name="event"
                label={intl.formatMessage({ id: "event_edition" })}
                placeholder={intl.formatMessage({ id: "event_edition" })}
                validationBlur={validationBlur}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={events}
                onChange={(e) => setSelectedEvent(e?.value)}
                required
              />
              <SelectWithLabel
                name="contract"
                placeholder={intl.formatMessage({
                  id: "contract",
                })}
                label={intl.formatMessage({
                  id: "contract",
                })}
                validationBlur={validationBlur}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={contracts}
                onChange={(e) => setSelectedContract(e?.value)}
                required
              />
              <SelectWithLabel
                name="collaborator"
                placeholder={intl.formatMessage({
                  id: "collaborator",
                })}
                label={intl.formatMessage({
                  id: "collaborator",
                })}
                validationBlur={validationBlur}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={collaborators}
                onChange={(collab) => setSelectedCollaborator(collab)}
                required
              />
              <SelectWithLabel
                name="category"
                validationBlur={validationBlur}
                label={intl.formatMessage({ id: "credential_types" })}
                placeholder={intl.formatMessage({ id: "credential_types" })}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={categories}
                onChange={(category) =>
                  setCategoryInformation(category?.information)
                }
                required
              />
              <InputWithHorizontalLabel
                name="assignment"
                placeholder={intl.formatMessage({
                  id: "assignment",
                })}
                label={intl.formatMessage({
                  id: "assignment",
                })}
                validationBlur={validationBlur}
                disabled={true}
                labelWidth="150px"
                required
              />
              <InputWithHorizontalLabel
                name="credential_name"
                placeholder={intl.formatMessage({
                  id: "credential_name",
                })}
                label={intl.formatMessage({
                  id: "credential_name",
                })}
                validationBlur={validationBlur}
                disabled={true}
                labelWidth="150px"
                required
              />
            </FieldsContainer>
            {categoryInformation && (
              <InfoContainer>
                <NumericalInfoContainer>
                  <NumericalInfoText>
                    {`${intl.formatMessage({
                      id: "utilized",
                    })}: ${categoryInformation.used}/${
                      categoryInformation.total
                    }`}
                  </NumericalInfoText>
                </NumericalInfoContainer>
              </InfoContainer>
            )}
          </Session>
        </Form>
      </Container>
    </AnimatedMainContainer>
  );
};

export default EditAssociation;
