import styled from "styled-components";

import backgroundImg from "../../assets/sign-in-background.jpg";

export const Background = styled.div`
  flex: 1;
  background: url(${backgroundImg}) no-repeat left;
  /* background-size: cover; */
  background-size: calc(100% - 700px) 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 2.1vw;
  text-align: center;
  background-attachment: fixed;
  min-height: 100vh;
  @media (max-width: 1200px) {
    p {
      display: none;
    }
  }

  @media (max-width: 1100px) {
    display: none;
  }

  @media (min-width: 1400px) {
    p {
      margin-bottom: 24px;
    }
  }
`;

export const BackgroundText = styled.p`
  color: #ffffff;
  align-items: flex-end;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  width: calc(100% - 700px);
`;
