import { all, takeLatest, call, put } from "redux-saga/effects";

import { authSuccess } from "./actions";
import { logoutSuccess } from "./actions";
import { alertRequest, alertSuccess } from "../alerts/actions";
import { changeApplicationLanguage } from "../language/actions";
import api from "../../../service";

function* auth({ payload }) {
  const { email, password, keepSignedIn, callbackLoading } = payload;
  try {
    const authResponse = yield call(() =>
      api.post("auth/signin", { email, password })
    );

    yield put(
      authSuccess({
        user: authResponse.data.user,
        token: authResponse.data.token,
        customerCenterToken: authResponse.data.customerCenterToken,
        accountCreated: authResponse.data.accountCreated,
        keepSignedIn,
      })
    );
    callbackLoading(false);
    yield put(
      changeApplicationLanguage({
        code: authResponse.data.user.language,
      })
    );
  } catch (error) {
    callbackLoading(false);
    yield put(
      alertRequest({
        title: "error",
        message: "api.error." + String(error.response.data.code),
        messageType: "error",
        isDialog: true,
      })
    );
  }
}

function* logout({ payload }) {
  const { token, callbackLoading, tokenExpired, user } = payload;
  try {
    yield call(() =>
      api.post("auth/logout", null, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
    );
    if (!tokenExpired) {
      yield put(
        alertSuccess({
          title: "success",
          message: "success.logged.out",
          messageType: "success",
          isDialog: true,
        })
      );
    } else {
      if (user?.role?.type === "ADMINISTRATOR") {
        window.location.href = process.env.REACT_APP_SERVER_URL + "saml/logout?local=true";
      }
    }

    yield put(logoutSuccess());
  } catch (error) {
    yield put(
      alertRequest({
        title: "error",
        message: "api.error." + String(error.response.data.code),
        messageType: "error",
        isDialog: true,
      })
    );
  } finally {
    callbackLoading(false);
  }
}

export default all([
  takeLatest("AUTH_REQUEST", auth),
  takeLatest("LOGOUT_REQUEST", logout),
]);
