import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 16px;
  background-color: #fff;
`;

export const TableContainer = styled.div`
  width: 100%;
  background-color: #fff;
  margin-top: 32px;
`;

export const FormContainer = styled.div`
  form {
    display: flex;
    flex-direction: column;
  }
`;

export const Title = styled.h1`
  font-size: 14px;
  font-weight: normal;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 21px;
  padding-left: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const FieldsContainer = styled.div`
  margin-top: 16px;
`;

export const Horizontal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & > div {
    display: flex;
    flex: 1 0 100px;
    margin-left: 16px;
    @media (max-width: 768px) {
      margin-left: 0px;
      flex: 1 1 auto;
      width: 100%;
    }
  }

  & > div:first-child {
    margin-left: 0px;
  }
`;

export const FilterActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  flex-wrap: wrap;

  & > div:first-child {
    margin-left: 0px;
  }

  @media (max-width: 500px) {
    width: 100%;
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 16px;
    @media (max-width: 500px) {
      margin-left: 0px;
    }
  }
`;

export const SubmitContainer = styled.div`
  width: 100%;
  max-width: 150px;

  @media (max-width: 500px) {
    margin-left: 0px;
    max-width: none;
    width: 100%;
  }
`;

export const TableTitleContainer = styled.div`
  padding: 16px 0px 16px 24px;
`;

export const TableTitle = styled.p``;

export const LinkButton = styled(Link)`
  text-decoration: none;
  margin: 0px 4px;
`;

export const GoBackContainer = styled.div``;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;

  div {
    margin-left: 4px;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    div {
      margin-left: 0px;
    }
  }
`;

export const BadgeContainer = styled.div`
  cursor: pointer;
  @media (max-width: 500px) {
    margin-top: 16px;
  }
`;

export const GuestsActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 32px;

  div {
    margin-left: 16px;
  }

  div:first-child {
    margin-left: 0px;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    div {
      margin-left: 0px;
    }
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;

  div {
    margin-left: 4px;
    flex: 0.5;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    div {
      flex: 1;
      margin-left: 0px;
      p {
        margin-top: 8px;
      }
    }
  }
`;

export const NumericalInfoContainer = styled.div``;

export const NumericalInfoText = styled.p`
  display: block;
`;
