const taxes = [
  {
    label: "Simples Nacional",
    value: 0,
  },
  {
    label: "Órgão Público",
    value: 1,
  },
  {
    label: "Demais empresas",
    value: 2,
  },
  {
    label: "MEI",
    value: 3,
  },
  {
    label: "CPF - pessoa física",
    value: 4,
  },
  {
    label: "International",
    value: 5,
  },
];

export default taxes;
