import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { useToast } from "../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";
import api from "../../service";
import { useCallback } from "react";
import InputWithLabel from "../../components/InputWithLabel";
import FormButton from "../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  LinkButton,
  GoBackContainer,
  LinkButtomSalesForce,
} from "./styles";
import SelectWithLabel from "../../components/SelectWithLabel";
import Table from "../../components/Table";
import Badge from "../../components/Badge";
import { useLoading } from "../../hooks/loading";
import axios from "axios";

const Contracts = () => {
  const { token } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();
  const { handleLoading } = useLoading();
  const [urlSalesForce, setUrlSalesForce] = useState("");
  const [formWithSearchedValues, setFormWithSearchedValues] = useState();

  const schema = Yup.object().shape({
    event: Yup.string().nullable(),
    customer: Yup.string().nullable(),
    contract_number: Yup.string().nullable(),
    contract_type: Yup.string().nullable(),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        await schema.validate(data, { abortEarly: false });

        setLoading(true);
        if (loading) {
          return;
        }

        api
          .patch(
            "/admin/opportunity",
            {
              contractNumber:
                data.contract_number !== "" ? data.contract_number : null,
              accountName: data.customer !== "" ? data.customer : null,
              type: data.contract_type !== "" ? data.contract_type : null,
              eventEditionName: data.event !== "" ? data.event : null,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            setData(result.data.opportunityInformation);
            setLoading(false);
            sessionStorage.setItem(
              "@informacp/contractNavigation",
              JSON.stringify({
                eventName: data.event,
                accountName: data.customer,
                contractNumber: data.contract_number,
                contractType: data.contract_type,
                contractSearchResult: result.data.opportunityInformation,
              })
            );
          })
          .catch((err) => {
            setLoading(false);
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, intl, schema, token, loading]
  );

  useEffect(() => {
    if (process.env.REACT_APP_ENVIROMENT === "prd") {
      setUrlSalesForce(`https://informage.lightning.force.com/`);
    } else {
      setUrlSalesForce(`https://informage--uat.lightning.force.com/`);
    }
    handleLoading(true);
    if (
      history.location.state != null &&
      history.location.state.prevPath.pathname.includes("/contract/")
    ) {
      if (sessionStorage.getItem("@informacp/contractNavigation") != null) {
        setData(
          JSON.parse(sessionStorage.getItem("@informacp/contractNavigation"))
            .contractSearchResult
        );
        api
          .get("/admin/events/eventEditionNames", {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((result) => {
            const events = result.data.output.map((event) => ({
              label: event.eventEditionName,
              value: event.eventEditionName,
            }));
            let eventsSort = [...events].sort((a, b) =>
              a.label < b.label ? -1 : a.label > b.label ? 1 : 0
            );
            setEvents(eventsSort);
          })
          .catch((err) => {
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          })
          .finally(() => {
            handleLoading(false);
          });
      } else {
        handleLoading(false);
      }
    } else {
      callOpportunitiesApi();
    }
  }, [addToast, handleLoading, intl, token, history]);

  const callOpportunitiesApi = () => {
    clearSessionStorage();
    handleLoading(true);
    axios
      .all([
        api.get("/admin/events/eventEditionNames", {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }),
        api.patch(
          "/admin/opportunity",
          {
            contractNumber: null,
            accountName: null,
            type: null,
            eventEditionName: null,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        ),
      ])
      .then(
        axios.spread((editionNamesRes, opportunityRes) => {
          const events = editionNamesRes.data.output.map((event) => ({
            label: event.eventEditionName,
            value: event.eventEditionName,
          }));
          let eventsSort = [...events].sort((a, b) =>
            a.label < b.label ? -1 : a.label > b.label ? 1 : 0
          );
          setEvents(eventsSort);
          setData(opportunityRes.data.opportunityInformation);
        })
      )
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  };

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("event", {
      label: intl.formatMessage({ id: "event_edition" }),
      value: null,
    });
    formRef.current.setFieldValue("customer", "");
    formRef.current.setFieldValue("contract_number", "");
    formRef.current.setFieldValue("contract_type", "");
    callOpportunitiesApi();
  };

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  useEffect(() => {
    const contractNavigationFromSessionStorage = sessionStorage.getItem(
      "@informacp/contractNavigation"
    );

    if (contractNavigationFromSessionStorage) {
      const { eventName, accountName, contractNumber, contractType } =
        JSON.parse(contractNavigationFromSessionStorage);
      const event = events.find((item) => item.value === eventName);
      setFormWithSearchedValues({
        event: event,
        contract_number: contractNumber,
        contract_type: contractType,
        customer: accountName,
        client_type: {
          label: intl.formatMessage({ id: "all" }),
          value: "ALL",
        },
      });
    }
  }, [intl, events]);

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "contract_number" }),
        selector: (row) => row.contractNumber,
        width: "200px",
      },
      {
        name: intl.formatMessage({ id: "event_edition" }),
        selector: (row) => row.eventEditionName,
        sortable: true,
        allowOverflow: true,
        wrap: true,
        minWidth: "250px",
      },

      {
        name: intl.formatMessage({ id: "customer" }),
        selector: (row) => row.accountName,
        sortable: true,
        allowOverflow: true,
        wrap: true,
        minWidth: "250px",
      },
      {
        name: intl.formatMessage({ id: "type" }),
        selector: (row) => row.type,
        sortable: true,
        width: "150px",
      },
      {
        cell: (row) => (
          <LinkButton to={`/contract/${row.opportunityId}`}>
            <Badge
              color="#002244"
              text={intl.formatMessage({ id: "see_details" })}
            />
          </LinkButton>
        ),
        right: true,
        width: "150px",
      },
      {
        cell: (row) => (
          <LinkButtomSalesForce
            href={`${urlSalesForce}lightning/r/SBQQ__Quote__c/${row.opportunityId}/view`}
            target="_blank"
          >
            <Badge
              color="#002244"
              text={intl.formatMessage({ id: "salesforce" })}
            />
          </LinkButtomSalesForce>
        ),
        right: true,
        width: "150px",
      },
    ],
    [intl, urlSalesForce]
  );

  const clearSessionStorage = () => {
    sessionStorage.removeItem("@informacp/contractNavigation");
  };

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "breadcrumb.contracts" })}
    >
      <Container>
        <FormContainer>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={formWithSearchedValues}
          >
            <TitleContainer>
              <Title>
                <FormattedMessage id="search_for_contracts" />
              </Title>
              <GoBackContainer>
                <LinkButton onClick={() => history.push("/dashboard")}>
                  <Badge
                    color="#002244"
                    text={intl.formatMessage({ id: "goback" })}
                    fontSize="12px"
                  />
                </LinkButton>
              </GoBackContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal>
                <SelectWithLabel
                  name="event"
                  options={events}
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "event_edition" })}
                  placeholder={intl.formatMessage({ id: "event_edition" })}
                />
                <InputWithLabel
                  name="customer"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "customer" })}
                />
                <InputWithLabel
                  name="contract_number"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "contract_number" })}
                />
                <InputWithLabel
                  name="contract_type"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "contract_type" })}
                />
              </Horizontal>
              <Horizontal>
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="search" />
                    </FormButton>
                  </SubmitContainer>
                </FilterActionsContainer>
              </Horizontal>
            </FieldsContainer>
          </Form>
        </FormContainer>
        <Table
          data={data}
          columns={columns}
          hasPagination
          progressPending={loading}
        />
      </Container>
    </AnimatedMainContainer>
  );
};

export default Contracts;
