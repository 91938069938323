import React from "react";
import { useIntl } from "react-intl";
import Table from "../../../../components/Table";

const ExpandableRow = (props) => {
  const intl = useIntl();

  const columns = [
    {
      name: "Item",
      selector: (row) => row.itemNumber,
    },
    {
      name: intl.formatMessage({ id: "description" }),
      selector: (row) => row.description,
    },
    {
      name: intl.formatMessage({ id: "quantity" }),
      selector: (row) => row.quantity,
    },
    {
      name: intl.formatMessage({ id: "unitary_price" }),
      selector: (row) => row.unitValue,
    },
  ];

  return (
    <Table
      data={props.data.chlebaItemOutputs}
      columns={columns}
      paddingAround="24px"
      padding
      titleMarginLeft="0px"
      hasPagination={false}
    />
  );
};

export default ExpandableRow;
