export function alertRequest(alertState) {
  return {
    type: "ALERT_REQUEST",
    payload: {
      title: alertState.title,
      message: alertState.message,
      isDialog: alertState.isDialog,
      messageType: alertState.messageType,
    },
  };
}

export function alertSuccess(alertState) {
  return {
    type: "ALERT_SUCCESS",
    payload: {
      title: alertState.title,
      message: alertState.message,
      isDialog: alertState.isDialog,
      messageType: alertState.messageType,
    },
  };
}

export function alertFailure(alertState) {
  return {
    type: "ALERT_FAILURE",
    payload: {
      title: alertState.title,
      message: alertState.message,
      isDialog: alertState.isDialog,
      messageType: alertState.messageType,
    },
  };
}

export function alertReset() {
  return {
    type: "ALERT_RESET",
  };
}
