import React, { useEffect, useRef, useState, useCallback } from "react";
import { useField } from "@unform/core";
import { MdError } from "react-icons/md";

import {
  Container,
  Error,
  RootContainer,
  Label,
  InputRow,
  LabelContainer,
  AsteriskText,
} from "./styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useWindowSize } from "../../hooks/dimensions";
import "./styles.css";
import format from "date-fns/format";
import { useIntl } from "react-intl";

const YearPickerWithRange = ({
  name,
  containerStyle = {},
  icon: Icon,
  validationBlur,
  validateEnd,
  disabled,
  label,
  labelWidth,
  className,
  maxWidth,
  labelOrientation,
  test,
  defaultStart,
  defaultEnd,
  minRange,
  maxRange,
  required,
  ...rest
}) => {
  const [width] = useWindowSize();
  let language = navigator.language.split(/[-_]/)[0];

  if (language !== "pt") {
    language = "en";
  }

  const intl = useIntl();

  const datepickerRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);
  const [showPortalVisualization, setShowPortalVisualization] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState("");

  const [dateRange, setDateRange] = useState([
    defaultValue || null,
    defaultValue || null,
  ]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    if (width <= 500) {
      setShowPortalVisualization(true);
    } else {
      setShowPortalVisualization(false);
    }
  }, [width]);

  const handleInputBlur = useCallback(async () => {
    if (validationBlur) {
      validationBlur(fieldName);
    }
  }, [fieldName, validationBlur]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      clearValue: (ref) => {
        ref.clear();
      },
      getValue: (ref) => {
        return [ref.props.startDate, ref.props.endDate];
      },
    });
  }, [fieldName, registerField]);

  const handleActualValue = useCallback(() => {
    if (startDate && endDate) {
      const to = intl.formatMessage({ id: "date_separator" });
      setValue(`${format(startDate, "yyyy")} ${to} ${format(endDate, "yyyy")}`);
    } else {
      setValue("");
    }
  }, [endDate, intl, startDate]);

  useEffect(() => {
    if (startDate && endDate) {
      handleActualValue();
      handleInputBlur();
      if (validateEnd && isFocused) {
        validateEnd();
      }
    }
  }, [
    handleInputBlur,
    isFocused,
    validateEnd,
    startDate,
    endDate,
    handleActualValue,
  ]);

  const onChangeDateValue = (update) => {
    if (update != null) {
      setDateRange(update);
      if (update[1]) {
        setIsOpen(false);
      }
    } else {
      setValue("");
      setDateRange([]);
    }
  };

  return (
    <RootContainer className={className} maxWidth={maxWidth}>
      <InputRow labelOrientation={labelOrientation}>
        <LabelContainer labelWidth={labelWidth}>
          <Label labelWidth={labelWidth} labelOrientation={labelOrientation}>
            {label}
          </Label>
          {required && <AsteriskText>*</AsteriskText>}
        </LabelContainer>
        <Container
          style={containerStyle}
          isFocused={isFocused}
          isErrored={!!error}
          disabled={disabled}
        >
          <DatePicker
            onInputClick={() => setIsOpen(true)}
            onClickOutside={() => setIsOpen(false)}
            open={isOpen}
            onBlur={() => setIsFocused(false)}
            ref={datepickerRef}
            onFocus={() => setIsFocused(true)}
            startDate={startDate}
            endDate={endDate}
            onChange={onChangeDateValue}
            popperProps={{
              positionFixed: true, // use this to make the popper position: fixed
            }}
            withPortal={showPortalVisualization}
            disabled={disabled}
            value={value}
            locale={language}
            dateFormat="yyyy"
            showYearPicker
            selectsRange
            minDate={new Date(minRange - 1, 1, 1)}
            maxDate={new Date(maxRange, 1, 1)}
            {...rest}
          />
        </Container>
      </InputRow>

      {error && (
        <Error title={error}>
          <MdError color="#e00000" size={20} />
          {error}
        </Error>
      )}
    </RootContainer>
  );
};

export default YearPickerWithRange;
