import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 16px;
  background-color: #fff;
  display: flex;
  /* flex: 0.2 0 70px; */
  flex: 1 0 150px;
  margin: 16px;
  justify-content: center;
  flex-direction: column;
  border-radius: 16px;
  flex-wrap: wrap;
  max-width: 400px;
`;

export const IconsContainer = styled.div`
  display: flex;
  border-radius: 16px;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
  flex-wrap: wrap;
`;

export const IconInfo = styled.div``;

export const IconCenter = styled.div``;

export const TitleContainer = styled.div`
  color: #525a5c;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
`;

export const ValueContainer = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #002244;
`;

export const CardInformation = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const IconCenterContainer = styled.div`
  display: flex;
  justify-content: center;
`;
