import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    
  }
  body {
    font-family: "Open Sans";
    -webkit-font-smoothing: antialiased !important;
  }
  body html #root {
    height: 100%;
  }
/* 
  .octa-widget-v2{
    width: 72px;
    height: 72px;
    left: 0px;
    right: unset;
  }

  .octadesk-opening-button--bottomRight{
    left: 0;
    right: unset;
  }

  .octadesk-octachat-app.octachatAppClose{
    width: 72px !important;
    height: 72px !important;
  } */

`;
