import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1 1 auto;
  height: 100vh;
  background-color: #bcbcbc;
`;

export const LoaderContainer = styled.p`
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
