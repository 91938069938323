import React, { useCallback, useEffect, useRef, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import {
  Container,
  TitleContainer,
  Title,
  FieldsContainer,
  SessionContainer,
  SessionTitle,
  Session,
  SessionDivider,
  ActionContainer,
  BadgeContainer,
} from "./styles";
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationErrors";
import api from "../../../service";
import { Form } from "@unform/web";
import SelectWithLabel from "../../../components/SelectWithLabel";
import InputWithHorizontalLabel from "../../../components/InputWithHorizontalLabel";
import Toggle from "../../../components/Toggle";
import { useToast } from "../../../hooks/toast";
import Badge from "../../../components/Badge";
import CheckboxInput from "../../../components/CheckboxInput";
import { useLoading } from "../../../hooks/loading";

const NewProfile = () => {
  const { token } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);

  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const [userType, setUserType] = useState(null);
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);

  const userTypeOptions = [
    {
      label: `${intl.formatMessage({ id: "customer" })} - ${intl.formatMessage({
        id: "exhibitor",
      })}`,
      value: "client",
    },
    {
      label: `${intl.formatMessage({ id: "customer" })} - ${intl.formatMessage({
        id: "service_provider",
      })}`,
      value: "service_provider",
    },
    {
      label: intl.formatMessage({ id: "administrator" }),
      value: "administrator",
    },
  ];

  const schema = Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    user_type: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
  });

  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (formData) => {
      try {
        setLoading(true);
        if (loading) {
          return;
        }
        formRef.current?.setErrors({});

        await schema.validate(formData, { abortEarly: false });

        const selectedPermissions = data.reduce(
          (array, item) => [...array.concat(formData[item.code])],
          []
        );

        api
          .post(
            "/role",
            {
              name: formData.name,
              type: formData.user_type.toUpperCase(),
              active: formData.status,
              permissions: selectedPermissions,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            setLoading(false);
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.role_created",
              }),
            });
            history.push("/profiles");
          })
          .catch((err) => {
            setLoading(false);
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [data, addToast, history, intl, schema, token, loading]
  );

  useEffect(() => {
    handleLoading(true);
    api
      .get("/role/permissions", {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setData(result.data.permissionCategories);
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, intl, token, handleLoading]);

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "breadcrumb.profiles" })}
    >
      <Container>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{ status: true }}
        >
          <TitleContainer>
            <Title>
              <FormattedMessage id="permission_creation" />
            </Title>
            <ActionContainer>
              <BadgeContainer onClick={() => history.push("/profiles")}>
                <Badge
                  color="#C9C9C9"
                  text={intl.formatMessage({ id: "goback" })}
                  fontSize="12px"
                />
              </BadgeContainer>
              <BadgeContainer onClick={() => formRef.current.submitForm()}>
                <Badge
                  color="#002244"
                  text={intl.formatMessage({ id: "save" })}
                  fontSize="12px"
                  loading={loading}
                />
              </BadgeContainer>
            </ActionContainer>
          </TitleContainer>
          <InputWithHorizontalLabel
            name="name"
            label={intl.formatMessage({ id: "name" })}
            labelWidth="150px"
            required
          />
          <SelectWithLabel
            name="user_type"
            label={intl.formatMessage({ id: "user_type" })}
            placeholder={intl.formatMessage({ id: "user_type" })}
            labelWidth="150px"
            labelOrientation="horizontal"
            options={userTypeOptions}
            onChange={(e) => {
              data.forEach((value) => {
                formRef.current.clearField(value.code);
              });
              setUserType(e.value);
            }}
            required
          />
          <Toggle label="Status" name="status" labelWidth="150px" />
          <SessionDivider>
            {data.map((value) => {
              return (
                <Session key={value.id}>
                  <SessionContainer>
                    <SessionTitle>
                      <FormattedMessage id={value.code.toLowerCase()} />
                    </SessionTitle>
                  </SessionContainer>
                  <FieldsContainer>
                    <CheckboxInput
                      name={value.code}
                      options={value.permissions.map((permission) => {
                        return {
                          id: permission.code,
                          value: permission.code,
                          label: intl.formatMessage({
                            id: permission.code.toLowerCase(),
                          }),
                          disabled: userType
                            ? !permission?.types?.includes(
                                userType?.toUpperCase()
                              )
                            : false,
                        };
                      })}
                    />
                  </FieldsContainer>
                </Session>
              );
            })}
          </SessionDivider>
        </Form>
      </Container>
    </AnimatedMainContainer>
  );
};

export default NewProfile;
