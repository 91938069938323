import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { useToast } from "../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";
import api from "../../service";
import { useCallback } from "react";
import InputWithLabel from "../../components/InputWithLabel";
import FormButton from "../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";
import GuestModal from "./Import";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  LinkButton,
  GoBackContainer,
  ActionContainer,
  BadgeContainer,
  GuestsActionContainer,
  InfoContainer,
  NumericalInfoContainer,
  NumericalInfoText,

} from "./styles";
import SelectWithLabel from "../../components/SelectWithLabel";
import Table from "../../components/Table";
import Badge from "../../components/Badge";
import Loader from "react-loader-spinner";
import { base64ToBlob, downloadBlob } from "../../utils/file";
import FileSaver from "file-saver";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useLoading } from "../../hooks/loading";

const ManageGuests = () => {
  const { token } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();

  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [filteredData, setFilteredData] = useState();
  const [events, setEvents] = useState([]);
  const [invitationInformation, setInvitationInformation] = useState();
  const [selectedEvent, setSelectedEvent] = useState();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const schema = Yup.object().shape({
    event: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    name: Yup.string().nullable(),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        await schema.validate(data, { abortEarly: false });

        setLoading(true);
        if (loading) {
          return;
        }
        setFiltered(true);
        const requestData = {
          event: data.event,
          name: data.name,
        };
        setFilteredData(requestData);

        api
          .patch(`/manage-guests`, requestData, {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((result) => {
            setData(result.data);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, intl, schema, token, loading]
  );

  useEffect(() => {
    handleLoading(true);
    api
      .get(`customer/events/by-user`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        const events = result.data.output;
        let eventsSort = [...events].sort((a, b) =>
          a.label < b.label ? -1 : a.label > b.label ? 1 : 0
        );
        setEvents(eventsSort);
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, intl, token, handleLoading]);

  useEffect(() => {
    if (selectedEvent) {
      handleLoading(true);
      api
        .get(`manage-guests/invitation-information/${selectedEvent}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          setInvitationInformation(
            result.data.manageGuestInvitationInformationOutput
          );
        })
        .catch((err) => {
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
        })
        .finally(() => {
          handleLoading(false);
        });
    }
  }, [selectedEvent, addToast, intl, token, handleLoading]);

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const handleExportGuests = () => {
    handleLoading(true);
    api
      .patch(`/manage-guests/export`, filteredData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        const { file, name } = result.data.manageGuestExportOutput;
        var blob = base64ToBlob(file, "application/vnd.ms-excel");
        downloadBlob(blob, `${name}.xlsx`);
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  };

  const handleDownloadTemplate = () => {
    FileSaver.saveAs(
      `${process.env.PUBLIC_URL}/guests_template.xlsx`,
      "Guests_Template.xlsx"
    );
  };

  const handleInactivateGuest = useCallback(
    (id) => {
      handleLoading(true);
      api
        .delete(`/manage-guests/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          addToast({
            type: "success",
            title: intl.formatMessage({
              id: "success",
            }),
            description: intl.formatMessage({
              id: "success.guest_delete",
            }),
          });

          setData({
            ...data,
            manageGuestList: data.manageGuestList.filter(
              (element) => element.id !== id
            ),
          });
        })
        .catch((err) => {
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
        })
        .finally(() => {
          handleLoading(false);
        });
    },
    [addToast, handleLoading, intl, token, data]
  );

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "name" }),
        selector: (row) => row.name,
        sortable: true,
      },
      {
        name: "E-mail",
        selector: (row) => row.email,
      },
      {
        name: intl.formatMessage({ id: "telephone" }),
        selector: (row) => row.telephone,
        minWidth: "150px",
      },
      {
        name: "VIP",
        selector: (row) => row.vip,
        cell: (row) => {
          if (row.vip) {
            return intl.formatMessage({ id: "yes" });
          }
          return intl.formatMessage({ id: "no" });
        },
        minWidth: "200px",
      },
      {
        cell: (row) => (
          <>
            <LinkButton to={`/manage-guests/${row.id}`}>
              <Badge
                color="#002244"
                text={intl.formatMessage({ id: "edit" })}
              />
            </LinkButton>
            <Badge
              onClick={() => {
                handleInactivateGuest(row.id);
              }}
              color="#cc0000"
              text={intl.formatMessage({ id: "delete" })}
            />
          </>
        ),
        right: true,
      },
    ],
    [intl, handleInactivateGuest]
  );

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("event");
    formRef.current.setFieldValue("name");
    setFiltered(false);
    setFilteredData();
    setData([]);
    setSelectedEvent();
  };

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "breadcrumb.manage_guests" })}
    >
      <Container>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <TitleContainer>
              <Title>
                <FormattedMessage id="search_guests" />
              </Title>
              <GoBackContainer>
                <ActionContainer>
                  <BadgeContainer onClick={() => history.push("/dashboard")}>
                    <Badge
                      color="#C9C9C9"
                      text={intl.formatMessage({ id: "goback" })}
                      fontSize="12px"
                    />
                  </BadgeContainer>
                  <BadgeContainer
                    onClick={() => history.push("/manage-guests/new")}
                  >
                    <Badge
                      color="#002244"
                      text={intl.formatMessage({ id: "new_guest" })}
                      fontSize="12px"
                    />
                  </BadgeContainer>
                </ActionContainer>
              </GoBackContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal>
                <SelectWithLabel
                  name="event"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "event_edition" })}
                  placeholder={intl.formatMessage({ id: "event_edition" })}
                  labelOrientation="vertical"
                  onChange={(option) =>
                    option != null ? setSelectedEvent(option.value) : null
                  }
                  options={events}
                  required
                />

                <InputWithLabel
                  name="name"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "name" })}
                  placeholder={intl.formatMessage({ id: "name" })}
                />
              </Horizontal>
              <Horizontal>
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="search" />
                    </FormButton>
                  </SubmitContainer>
                </FilterActionsContainer>
              </Horizontal>
            </FieldsContainer>
          </Form>
          {invitationInformation && selectedEvent && (
          <InfoContainer>
            <NumericalInfoContainer>
              <NumericalInfoText>
                {intl.formatMessage({
                  id: "vip_guests_used",
                })}
                : {invitationInformation.used}
              </NumericalInfoText>
              <NumericalInfoText>
                {intl.formatMessage({
                  id: "vip_guests_remaining",
                })}
                : {invitationInformation.remaining}
              </NumericalInfoText>
            </NumericalInfoContainer>
          </InfoContainer>
        )}
        </FormContainer>
      </Container>
      <GuestsActionContainer>
        <BadgeContainer onClick={() => handleDownloadTemplate()}>
          <Badge
            color="#33d27e"
            text={intl.formatMessage({ id: "download_template" })}
            fontSize="16px"
          />
        </BadgeContainer>
        <BadgeContainer onClick={() => setModalIsOpen(true)}>
          <Badge
            color="#33d27e"
            text={intl.formatMessage({ id: "upload_via_xls" })}
            fontSize="16px"
          />
        </BadgeContainer>
      </GuestsActionContainer>
      {filtered ? (
        <>
          <GuestsActionContainer>
            <BadgeContainer onClick={() => handleExportGuests()}>
              <Badge
                color="#33d27e"
                text={intl.formatMessage({ id: "export_guests" })}
                fontSize="16px"
              />
            </BadgeContainer>
          </GuestsActionContainer>
          <GuestsActionContainer />
          <Table
            columns={columns}
            data={data.manageGuestList}
            title={intl.formatMessage({ id: "results" })}
            progressPending={loading}
            progressComponent={
              <Loader type="Oval" color="#00BFFF" height={100} width={100} />
            }
          />
        </>
      ) : (
        <GuestsActionContainer>
          <FormattedMessage id="need_to_filter_first" />
        </GuestsActionContainer>
      )}
      <GuestModal
        setIsOpen={setModalIsOpen}
        modalIsOpen={modalIsOpen}
        updatePageCallback={() => formRef.current.submitForm()}
      />
    </AnimatedMainContainer>
  );
};

export default ManageGuests;
