import styled, { css } from "styled-components";
import Steps from "rc-steps";

export const Container = styled.div`
  display: flex;
  flex: 1;
`;

export const ProgressIndicator = styled.div`
  display: flex;
  border-radius: 100%;
  background-color: #002244;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  ${(props) =>
    !props.done &&
    css`
      opacity: 0.3;
    `}
`;

export const ProgressIndicatorText = styled.p`
  text-align: left;
`;

export const IndicatorContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const IndicatorLine = styled.div`
  display: flex;
  flex: 1;
  height: 10px;
  background-color: #002244;
`;

export const TimelineItem = styled.div`
  flex: 1;
`;

export const TextStep = styled.p`
  color: #002244;
  font-weight: bold;
  font-size: 12px;
  ${(props) =>
    props.isFocused &&
    css`
      color: #5ddb98;
      border-color: #5ddb98;
    `}

  ${(props) =>
    !props.done &&
    css`
      opacity: 0.3;
    `}
`;

export const StepContainer = styled(Steps)`
  .rc-steps-item-tail:after {
    height: 4px;
    display: none;

    @media (max-width: 720px) {
      height: 130%;
    }
  }

  .rc-steps-item-tail {
    top: 14px;
  }

  .rc-steps-item-finish .rc-steps-item-tail:after {
    background-color: #002244;
  }

  @media (max-width: 720px) {
    .rc-steps-item-tail:after {
      width: 4px;
      display: none;
    }
    .rc-steps-item-container {
      height: 80px;
    }
    .rc-steps-item-tail {
      top: 0px;
    }
  }
`;
