import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { useToast } from "../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";
import api from "../../service";
import { useCallback } from "react";
import FormButton from "../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  LinkButton,
  GoBackContainer,
  ActionContainer,
  BadgeContainer,
  NotificationContainer,
} from "./styles";
import SelectWithLabel from "../../components/SelectWithLabel";
import Table from "../../components/Table";
import Badge from "../../components/Badge";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useLoading } from "../../hooks/loading";

const ProductEventAssociation = () => {
  const { token } = useSelector((state) => state.auth);
  var language = useSelector((state) => state.language).code;
  const [data, setData] = useState([]);
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [events, setEvents] = useState([]);
  const { handleLoading } = useLoading();

  const schema = Yup.object().shape({
    event: Yup.string().nullable(),
    name: Yup.string().nullable(),
    status: Yup.string().nullable(),
  });

  const statusOptions = [
    {
      label: intl.formatMessage({ id: "active" }),
      value: true,
    },
    {
      label: intl.formatMessage({ id: "inactive" }),
      value: false,
    },
    {
      label: intl.formatMessage({ id: "both" }),
      value: null,
    },
  ];

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) return;

        await schema.validate(data, { abortEarly: false });

        setFiltered(true);

        api
          .patch(
            `/admin/ecommerce/chleba/product-event`,
            {
              eventEditionCode: data.event,
              status: data.status,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) =>
            setData(result.data.ecommerceChlebaProductEventFilterOutput)
          )
          .catch((err) =>
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            })
          )
          .finally(() => setLoading(false));
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, intl, loading, schema, token]
  );

  useEffect(() => {
    handleLoading(true);
    api
      .get(`admin/events`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        const events = result.data.output.map((event) => ({
          label: event.eventEditionName,
          value: event.eventEditionCode,
        }));
        let eventsSort = [...events].sort((a, b) =>
          a.label < b.label ? -1 : a.label > b.label ? 1 : 0
        );
        setEvents(eventsSort);
      })
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, intl, token, handleLoading]);

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "event_edition" }),
        selector: (row) => row.event,
      },
      {
        name: intl.formatMessage({ id: "product" }),
        selector: (row) =>
          language === "pt" ? row.productTitle : row.productEnglishTitle,
      },
      {
        name: intl.formatMessage({ id: "unity" }),
        selector: (row) =>
          row.unity ? intl.formatMessage({ id: row.unity }) : "-",
      },
      {
        name: intl.formatMessage({ id: "active" }),
        selector: (row) =>
          row.active
            ? intl.formatMessage({ id: "yes" })
            : intl.formatMessage({ id: "no" }),
      },
      {
        name: intl.formatMessage({ id: "registered" }),
        selector: (row) =>
          row.registered
            ? intl.formatMessage({ id: "yes" })
            : intl.formatMessage({ id: "no" }),
      },
      {
        cell: (row) => (
          <LinkButton to={`/admin/product-event-association/${row.id}`}>
            <Badge color="#002244" text={intl.formatMessage({ id: "edit" })} />
          </LinkButton>
        ),
        right: true,
      },
    ],
    [intl, language]
  );

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("event");
    formRef.current.setFieldValue("status");
    setFiltered(false);
    setData([]);
  };

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({
        id: "breadcrumb.product_and_event_association",
      })}
    >
      <Container>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <TitleContainer>
              <Title>
                <FormattedMessage id="search_products_and_events_associations" />
              </Title>
              <GoBackContainer>
                <ActionContainer>
                  <BadgeContainer onClick={() => history.push("/dashboard")}>
                    <Badge
                      color="#C9C9C9"
                      text={intl.formatMessage({ id: "goback" })}
                      fontSize="12px"
                    />
                  </BadgeContainer>
                  <BadgeContainer
                    onClick={() =>
                      history.push("/admin/product-event-association/new")
                    }
                  >
                    <Badge
                      color="#002244"
                      text={intl.formatMessage({
                        id: "add_product_and_event_association",
                      })}
                      fontSize="12px"
                    />
                  </BadgeContainer>
                </ActionContainer>
              </GoBackContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal>
                <SelectWithLabel
                  name="event"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "event_edition" })}
                  placeholder={intl.formatMessage({ id: "event_edition" })}
                  labelOrientation="vertical"
                  options={events}
                />

                <SelectWithLabel
                  name="status"
                  validationBlur={validationBlur}
                  label="Status"
                  placeholder="Status"
                  labelOrientation="vertical"
                  options={statusOptions}
                />
              </Horizontal>
              <Horizontal>
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="search" />
                    </FormButton>
                  </SubmitContainer>
                </FilterActionsContainer>
              </Horizontal>
            </FieldsContainer>
          </Form>
        </FormContainer>
      </Container>
      {filtered ? (
        <>
          <Table
            columns={columns}
            data={data}
            title={intl.formatMessage({ id: "results" })}
            progressPending={loading}
            progressComponent={
              <Loader type="Oval" color="#00BFFF" height={100} width={100} />
            }
          />
        </>
      ) : (
        <NotificationContainer>
          <FormattedMessage id="need_to_filter_first" />
        </NotificationContainer>
      )}
    </AnimatedMainContainer>
  );
};

export default ProductEventAssociation;
