import styled, { css } from "styled-components";

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  padding: 30px;
  overflow: hidden;
  z-index: 9999999;
  ${(props) =>
    props.messages.length === 0 &&
    css`
      padding: 0px;
    `};

  @media (max-width: 500px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;
