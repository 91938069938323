import React from "react";

function TermsTextEN({ modalIsOpen, setIsOpen, children }) {
  return (
    <>
      <h1>Terms & Conditions</h1>

      <p>
        This page (together with any other documents referred to on it) tells
        you the terms of use (<b>“Terms”</b>) on which you may make use of our
        website www.informa.com (<b>our</b> “Site”). Please read these Terms
        carefully before you start to use our Site. By using our Site, you
        indicate that you accept these Terms and that you agree to abide by
        them. If you do not agree to these Terms, please refrain from using our
        Site immediately.
      </p>
      <p>
        We may revise these Terms at any time by amending this page. You are
        expected to check this page from time to time to take notice of any
        changes we make, as they are binding on you. Some of the provisions
        contained in these Terms may also be superseded by provisions or notices
        published elsewhere on our Site.
      </p>
      <p>
        <b>Information about us</b>
      </p>
      <p>
        Our site is operated by Informa Group plc <b>(“we”, “us” or “our”)</b>.
        This is a part of Informa PLC, registered in the United Kingdom under
        company number 8860726. Our registered office address is 5 Howick Place,
        London, SW1P 1WG, UK. All Informa PLC UK trading subsidiaries are
        registered for VAT under one UK VAT group: GB365462636
      </p>
      <p>
        <b>Accessing our site</b>
      </p>
      <p>
        Access to our Site is provided on a temporary basis and we reserve the
        right to withdraw access to our Site or amend the service we provide on
        our Site without notice. We will not be liable if for any reason our
        Site is unavailable at any time or for any period.
      </p>
      <p>
        You are responsible for making all arrangements necessary for you to
        have access to our Site. You are also responsible for ensuring that all
        persons who access our Site through your internet connection are aware
        of these Terms, and that they comply with them. You agree not to damage,
        interfere with or disrupt access to the Site or do anything which may
        interrupt or impair its functionality.
      </p>
      <p>
        Anything on our Site may be out of date at any given time, and we are
        under no obligation to update it. We seek to ensure that information
        published on our Site is accurate when posted, but we cannot be held
        liable for its accuracy or timeliness and we may change the information
        at any time without notice. You must not rely on information on the Site
        and you acknowledge that you must take appropriate steps to verify this
        information before acting upon it.
      </p>
      <p>
        <b>Monitoring</b>
      </p>
      <p>We reserve the right to monitor and track your visits to the Site.</p>
      <p>
        <b>Intellectual property</b>
      </p>
      <p>
        We are the owner or the licensee of all copyright, trade marks, design
        rights, database rights, confidential information or any other
        intellectual property rights (together the <b>Intellectual Property</b>)
        in our Site. The Intellectual Property in our Site is protected by
        copyright and other intellectual property laws and treaties around the
        world. All such rights are reserved.
      </p>
      <p>
        <b>Licence</b>
      </p>
      <p>
        You are permitted to print and download extracts from the Site for your
        own use on the following basis:
      </p>
      <p>
        (a)
        <span>
          no documents or related graphics on the Site are modified in any way;
        </span>
      </p>
      <p>
        (b)
        <span>
          no graphics on the Site are used separately from the corresponding
          text; and
        </span>
      </p>
      <p>
        (c)
        <span>
          our copyright and trade mark notices and this permission notice appear
          in all copies.
        </span>
      </p>
      <p>
        Unless otherwise stated, the copyright and other intellectual property
        rights in all material on the Site (including without limitation
        photographs and graphical images) are owned by us or our licensors. For
        the purposes of these Terms, any use of extracts from the Site other
        than in accordance with this licence for any purpose is prohibited. If
        you breach any of the terms in this legal notice, your permission to use
        the Site automatically terminates and you must immediately destroy any
        downloaded or printed extracts from the Site.
      </p>
      <p>
        Subject to this licence, no part of the Site may be reproduced or stored
        in any other website or included in any public or private electronic
        retrieval system or service without our prior written permission. Any
        rights not expressly granted in these terms are reserved. You agree not
        to adapt, alter or create a derivative work from any of the material
        contained in this Site or use it for any other purpose than for
        personal, non-commercial use.
      </p>
      <p>
        <b>Disclaimer</b>
      </p>
      <p>
        While we endeavour to ensure that the information on the Site is
        correct, we do not warrant the accuracy and completeness of the material
        on the Site. We may make changes to the material on the Site, at any
        time without notice. The material on the Site may be out of date, and we
        make no commitment to update such material.
      </p>
      <p>
        <b>Implied Terms</b>
      </p>
      <p>
        The express provisions of these Terms are in place of all warranties,
        conditions, terms, undertakings and obligations implied by statute,
        common law, trade usage, course of dealing or otherwise, all of which
        are excluded to the fullest extent permitted by law.
      </p>
      <p>
        <b>Liability</b>
      </p>
      <p>
        The material on the Site is provided "as is", without any conditions,
        warranties or other terms of any kind.
      </p>
      <p>
        We, any other party (whether or not involved in creating, producing,
        maintaining or delivering the Site), and any of our group companies and
        the officers, directors, employees, shareholders or agents of any of
        them, exclude all liability and responsibility for any amount or kind of
        loss or damage that may result to you or a third party (including
        without limitation, any direct, indirect, punitive or consequential loss
        or damages, or any loss of income, profits, goodwill, data, contracts,
        use of money, or loss or damages arising from or connected in any way to
        business interruption, and whether in tort (including without limitation
        negligence), contract or otherwise) in connection with the Site in any
        way or in connection with the use, inability to use or the results of
        use of the Site, any websites linked to the Site or the material on such
        websites, including but not limited to loss or damage due to viruses
        that may infect your computer equipment, software, data or other
        property on account of your access to, use of, or browsing the Site or
        your downloading of any material from the Site or any websites linked to
        the Site. Nothing in these Terms shall exclude or limit our liability
        for:
      </p>
      <p>
        (a)
        <span>
          death or personal injury caused by negligence (as such term is defined
          by the Unfair Contract Terms Act 1977); or
        </span>
      </p>
      <p>
        (b) <span>fraud.</span>
      </p>
      <p>
        <b>Investors</b>
      </p>
      <p>
        The information contained on our Site (including, without limitation,
        the financial information concerning us or our corporate group) is not
        an invitation to invest in shares or other securities, or any other
        products or services or otherwise deal in these or enter into a contract
        with us or any other company. The information provided should not be
        relied upon in connection with any investment decision. If you need
        advice, please consult with a professional financial adviser.
      </p>
      <p>
        The past performance of us or any other company referred to on the Site
        cannot be relied upon as a guide to its future performance. The price of
        shares and the income derived from them can go down as well as up and
        investors may not recoup the amount originally invested.
      </p>
      <p>
        Our Site contains certain forward-looking statements that are neither
        reported financial results nor other historical information. Because
        these forward-looking statements are subject to assumptions, risks and
        uncertainties, actual future results may differ materially from those
        expressed in or implied by such statements. Many of these assumptions,
        risks and uncertainties relate to factors that are beyond our ability to
        control or estimate precisely, such as delays in obtaining or adverse
        conditions contained in regulatory approvals, competition and industry
        restructuring, changes in economic conditions, currency fluctuations,
        changes in interest and tax rates, changes in laws, regulations or
        regulatory policies, developments in legal or public policy doctrines,
        technological developments, the availability of new acquisition
        opportunities or the timing and success of future acquisition
        opportunities. These forward-looking statements speak only as of the
        date of their publication on the Site. We do not undertake any
        obligation to publicly release any revisions to these forward-looking
        statements to reflect events or circumstances after the date of their
        publication on the Site.
      </p>
      <p>
        <b>Jurisdiction and applicable law</b>
      </p>
      <p>
        The English courts will have non-exclusive jurisdiction over any claim
        arising from, or related to, a visit to our Site, although we retain the
        right to bring proceedings against you for breach of these Terms in your
        country of residence or any other relevant country.
      </p>
      <p>
        These Terms and any dispute or claim arising out of or in connection
        with them or their subject matter or formation (including, without
        limitation, non-contractual disputes or claims) shall be governed by and
        construed in accordance with the laws of England and Wales.
      </p>
      <p>Thank you for visiting our Site.</p>
      <a href="https://www.informa.com/privacy-policy/" target="_blank">
        Privacy Policy
      </a>
    </>
  );
}

export default TermsTextEN;
