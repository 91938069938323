import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import ConfirmationModal from "../../components/ConfirmationModal";
import FormButton from "../../components/FormButton";
import SelectWithLabel from "../../components/SelectWithLabel";
import {
  Container,
  Content,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  InformationContainer,
  InformationNotification,
  AdditionalInformationContainer,
  InformationLabel,
  InformationValue,
} from "./styles";
import { FormattedMessage, useIntl } from "react-intl";
import Sidebar from "../../components/Sidebar";
import api from "../../service";
import Modal from "../../components/Modal";
import {
  MdAccountCircle,
  MdAssignment,
  MdAttachMoney,
  MdComputer,
  MdEdit,
  MdEvent,
  MdEventNote,
  MdInfo,
  MdModeEdit,
  MdPerson,
  MdDoneAll,
  MdCollectionsBookmark,
  MdDonutSmall,
} from "react-icons/md";
import Topbar from "../../components/Topbar";
import TermsModal from "../../components/AcceptTerms/TermsModal";
import { useHistory } from "react-router-dom";
import {
  FaFileContract,
  FaIdCardAlt,
  FaShoppingBasket,
  FaTachometerAlt,
  FaRegIdCard,
  FaDigitalTachograph,
} from "react-icons/fa";
import {
  RiFileInfoLine,
  RiFileList3Fill,
  RiFileSearchFill,
  RiGroup2Fill,
  RiShieldUserFill,
  RiUserSettingsFill,
  RiVipDiamondFill,
  RiChatOffLine,
  RiBookmark2Line,
  RiSurveyLine,
} from "react-icons/ri";
import { SiOpenaccess } from "react-icons/si";
import { IoMdNotificationsOutline } from "react-icons/io";
import { BiTransfer } from "react-icons/bi";
import {
  CgExtensionAdd,
  CgPlayListSearch,
  CgUserList,
  CgLoadbarDoc,
} from "react-icons/cg";
import { HiDocumentReport } from "react-icons/hi";

import {
  AiFillProject,
  AiOutlineUsergroupAdd,
  AiFillShopping,
} from "react-icons/ai";

import { GoGraph } from "react-icons/go";
import { GrSchedules } from "react-icons/gr";
import { useSidebar } from "../../hooks/sidebar";
import { useLoading } from "../../hooks/loading";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";
import * as Yup from "yup";
import LoadingOverlay from "../LoadingOverlay";
import { useToast } from "../../hooks/toast";
import getValidationErrors from "../../utils/getValidationErrors";
import axios from "axios";
import SelectYourAccountModal from "./SelectYourAccountModal";

const MainContainerWithSidebar = ({ children }) => {
  const { user, token, serviceProviderLoggingAsAnExhibitor } = useSelector(
    (state) => state.auth
  );
  const { isLoading, handleLoading } = useLoading();
  const { addToast } = useToast();
  const { sidebarIsVisible } = useSidebar();
  const intl = useIntl();
  const history = useHistory();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [termsAndConditionsOpen, setTermsAndConditionsOpen] = useState(false);

  const ecommerceFormRef = useRef(null);
  const [ecommerceModalOpen, setEcommerceModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState();
  const [exhibitors, setExhibitors] = useState([]);
  const [selectedExhibitor, setSelectedExhibitor] = useState();
  const [opportunities, setOpportunities] = useState();
  const [chlebaAccessInformation, setChlebaAccessInformation] = useState();

  const [
    serviceProviderInvalidDataModalIsOpen,
    setServiceProviderInvalidDataModalIsOpen,
  ] = useState(false);
  const [modalSelectYourAccountIsOpen, setModalSelectYourAccountIsOpen] =
    useState(false);

  useEffect(() => {
    if (serviceProviderLoggingAsAnExhibitor) {
      setModalSelectYourAccountIsOpen(true);
    } else {
      setModalSelectYourAccountIsOpen(false);
    }
  }, [serviceProviderLoggingAsAnExhibitor]);

  const isAdministrator = user?.role?.type === "ADMINISTRATOR";
  const isServiceProvider = user?.role?.type === "SERVICE_PROVIDER";

  const ecommerceSchema = Yup.object().shape({
    event: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    exhibitor: isAdministrator
      ? Yup.string()
          .nullable()
          .required(intl.formatMessage({ id: "error.field_required" }))
      : Yup.string().nullable(),
    opportunity: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
  });

  const showConfirmation = () => {
    setModalIsOpen(true);
  };

  const handleFinancialSingleSignOn = () => {
    handleLoading(true);
    api
      .get(`/financial/access-information`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        const { token, url } = result.data.financialGenerateTokenOutput;
        const form = document.createElement("form");
        form.method = "post";
        form.action = url;
        form.target = "_blank";

        const tokenField = document.createElement("input");
        tokenField.type = "hidden";
        tokenField.name = "bypass_key";
        tokenField.value = token;

        form.appendChild(tokenField);

        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  };

  const handleLogout = () => {
    if (isAdministrator) {
      window.location.href =
        process.env.REACT_APP_SERVER_URL + "saml/logout?local=true";
    } else {
      window.location.href = process.env.REACT_APP_LOGOUT_URI;
    }
  };

  const handleEcommerceAccess = () => {
    handleLoading(true);
    setEcommerceModalOpen(true);
    setChlebaAccessInformation();
    setSelectedExhibitor();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    };
    if (isAdministrator) {
      api
        .get(`admin/events`, config)
        .then((result) => {
          const events = result.data.output.map((event) => ({
            label: event.eventEditionName,
            value: event.eventEditionCode,
          }));
          let eventsSort = [...events].sort((a, b) =>
          a.label < b.label ? -1 : a.label > b.label ? 1 : 0
        );
        setEvents(eventsSort);
        })
        .catch((err) => {
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
        })
        .finally(() => {
          handleLoading(false);
        });
    } else {
      axios
        .all([
          api.get(`customer/events/by-user`, config),
          api.patch(
            `/users/chleba/access-information/`,
            {
              selectedUserId: selectedExhibitor,
            },
            config
          ),
        ])
        .then(
          axios.spread((eventResponse, accessInformationResponse) => {
            setEvents(eventResponse.data.output);
            setChlebaAccessInformation(
              accessInformationResponse.data
                .systemUserChlebaAccessInformationOutput
            );
          })
        )
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    }
  };

  useEffect(() => {
    if (selectedEvent && isAdministrator) {
      handleLoading(true);
      ecommerceFormRef.current.setFieldValue("exhibitor");
      ecommerceFormRef.current.setFieldValue("opportunity");
      api
        .get(`/users/exhibitor/event/${selectedEvent}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          const exhibitors = result.data.users.map((user) => ({
            label: user.commercialName,
            value: user.id,
          }));
          setExhibitors(exhibitors);
        })
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    }
  }, [selectedEvent, addToast, intl, token, handleLoading, isAdministrator]);

  useEffect(() => {
    if (selectedExhibitor) {
      api
        .patch(
          `/users/chleba/access-information/`,
          {
            selectedUserId: selectedExhibitor,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) =>
          setChlebaAccessInformation(
            response.data.systemUserChlebaAccessInformationOutput
          )
        )
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    } else {
      setChlebaAccessInformation();
    }
  }, [addToast, handleLoading, intl, selectedExhibitor, token]);

  useEffect(() => {
    if (selectedEvent && (!isAdministrator || selectedExhibitor)) {
      handleLoading(true);
      ecommerceFormRef.current.setFieldValue("opportunity");
      const requestHeader = {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      };

      let opportunitiesPromise = isAdministrator
        ? api.patch(
            "/admin/opportunity/event-and-user",
            { eventEditionCode: selectedEvent, userId: selectedExhibitor },
            requestHeader
          )
        : api.get(
            `/customer/opportunity/event/${selectedEvent}`,
            requestHeader
          );

      opportunitiesPromise
        .then((result) => setOpportunities(result.data.output))
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    }
  }, [
    selectedEvent,
    addToast,
    intl,
    token,
    handleLoading,
    isAdministrator,
    selectedExhibitor,
  ]);

  const handleInvalidData = () => {
    setEcommerceModalOpen(false);
    if (isServiceProvider) {
      setServiceProviderInvalidDataModalIsOpen(true);
    } else {
      addToast({
        type: "info",
        title: intl.formatMessage({
          id: "info",
        }),
        description: intl.formatMessage({
          id: "chleba_incorrect_data_confirmation",
        }),
      });
    }
  };

  const handleServiceProviderHandleDataConfirmation = () => {
    setServiceProviderInvalidDataModalIsOpen(false);
    history.push("/update-user");
  };

  const handleEcommerceSubmit = useCallback(
    async (data) => {
      try {
        ecommerceFormRef.current?.setErrors({});

        await ecommerceSchema.validate(data, { abortEarly: false });

        setLoading(true);
        if (loading) return;

        handleLoading(true);

        api
          .post(
            `${isAdministrator ? "/admin/" : "/"}ecommerce/chleba/auth`,
            {
              opportunityId: data.opportunity,
              exhibitorId: data.exhibitor,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) =>
            window.open(
              result.data.ecommerceChlebaAuthenticationOutput.generatedUrl,
              "_blank"
            )
          )
          .catch((err) =>
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            })
          )
          .finally(() => {
            setLoading(false);
            handleLoading(false);
          });
      } catch (err) {
        setLoading(false);
        handleLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          ecommerceFormRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [
      ecommerceSchema,
      loading,
      handleLoading,
      isAdministrator,
      token,
      addToast,
      intl,
    ]
  );

  const validationBlur = (name) => {
    let errors = ecommerceFormRef.current.getErrors();
    validateSingleFieldOnBlur(errors, ecommerceSchema, name, ecommerceFormRef);
  };

  return (
    <LoadingOverlay>
      <Container sidebarIsVisible={sidebarIsVisible}>
        {sidebarIsVisible && (
          <Sidebar
            data={[
              {
                id: "ACCOUNTS_USERS",
                name: intl.formatMessage({ id: "sidebar.accounts_and_users" }),
                icon: <MdPerson size={46} color="#cccccc" />,
                subitemTitle: intl.formatMessage({
                  id: "sidebar.accounts_and_users",
                }),
                code: "ACCOUNTS_USERS",
                routeName: [
                  "users",
                  "profiles",
                  "update-user",
                  "associate-collaborators",
                  "associate-collaborator",
                  "admin/user-journey",
                  "accounts",
                  "service-providers-management",
                ],
                subitem: [
                  {
                    id: "ACCESS_ADMIN_TERMS_OF_USE",
                    name: intl.formatMessage({
                      id: "sidebar.access_terms_of_use",
                    }),
                    code: "ACCESS_ADMIN_TERMS_OF_USE",
                    icon: <MdAssignment size={19} />,
                    clickFunction: () => setTermsAndConditionsOpen(true),
                  },
                  {
                    id: "LIST_USERS",
                    name: intl.formatMessage({
                      id: "sidebar.manage_users",
                    }),
                    code: "LIST_USERS",
                    icon: <RiUserSettingsFill size={19} />,
                    clickFunction: () => history.push("/users"),
                  },
                  {
                    id: "SHOW_ROLES",
                    name: intl.formatMessage({
                      id: "sidebar.manage_user_profile",
                    }),
                    code: "SHOW_ROLES",
                    icon: <RiShieldUserFill size={19} />,
                    clickFunction: () => history.push("/profiles"),
                  },
                  {
                    id: "SHOW_ACCOUNTS_INFORMATIONS",
                    name: intl.formatMessage({
                      id: "sidebar.search_customers",
                    }),
                    code: "SHOW_ACCOUNTS_INFORMATIONS",
                    icon: <CgPlayListSearch size={19} />,
                    clickFunction: () => history.push("/accounts"),
                  },
                  {
                    id: "SHOW_SERVICE_PROVIDERS_INFORMATION",
                    name: intl.formatMessage({
                      id: "sidebar.manage_providers",
                    }),
                    code: "SHOW_SERVICE_PROVIDERS_INFORMATION",
                    icon: <MdModeEdit size={19} />,
                    clickFunction: () => history.push("/service-providers-management"),
                  },
                  {
                    id: "MANAGE_STAFF_ACCESS",
                    name: intl.formatMessage({
                      id: "sidebar.manage_staff_access",
                    }),
                    code: "MANAGE_STAFF_ACCESS",
                    icon: <MdModeEdit size={19} />,
                    clickFunction: () => history.push("/staff-access"),
                  },
                ],
              },
              {
                id: "PROFILE",
                name: intl.formatMessage({
                  id: "sidebar.my_account",
                }),
                icon: <MdPerson size={46} color="#cccccc" />,
                subitemTitle: intl.formatMessage({ id: "sidebar.my_account" }),
                code: "PROFILE",
                routeName: [
                  "update-user",
                  "associate-collaborators",
                  "associate-collaborator",
                  "salesforce/user",
                ],
                subitem: [
                  {
                    id: "ACCESS_TERMS_OF_USE",
                    name: intl.formatMessage({
                      id: "sidebar.access_terms_of_use",
                    }),
                    code: "ACCESS_TERMS_OF_USE",
                    icon: <FaFileContract size={19} />,
                    clickFunction: () => setTermsAndConditionsOpen(true),
                  },
                  {
                    id: "EDIT_PROFILE_INFORMATION",
                    name: intl.formatMessage({
                      id: "sidebar.my_data",
                    }),
                    code: "EDIT_PROFILE_INFORMATION",
                    icon: <MdPerson size={19} />,
                    clickFunction: () => history.push("/update-user"),
                  },
                  {
                    id: "EDIT_CUSTOMER_PROFILE_INFORMATION",
                    name: intl.formatMessage({
                      id: "sidebar.my_data",
                    }),
                    code: "EDIT_CUSTOMER_PROFILE_INFORMATION",
                    icon: <MdAccountCircle size={19} />,
                    clickFunction: () => history.push("/salesforce/user"),
                  },
                  {
                    id: "MANAGE_ASSOCIATE_COLLABORATOR",
                    name: intl.formatMessage({
                      id: "sidebar.register_collaborator",
                    }),
                    code: "MANAGE_ASSOCIATE_COLLABORATOR",
                    icon: <AiOutlineUsergroupAdd size={19} />,
                    clickFunction: () =>
                      history.push("/associate-collaborators"),
                  },
                ],
              },
              {
                id: "EVENTS",
                name: intl.formatMessage({ id: "sidebar.events" }),
                icon: <MdEvent size={46} color="#cccccc" />,
                code: "EVENTS",
                subitemTitle: intl.formatMessage({ id: "sidebar.events" }),
                routeName: [
                  "events",
                  "contracts",
                  "contract",
                  "events-client-visualization",
                  "admin/additional-products",
                  "admin/categories",
                  "admin/product-event-association",
                  "serviceprovider/association-provider-view",
                  "serviceprovider-association",
                ],
                subitem: [
                  {
                    id: "ACCESS_EVENTS_INFORMATION",
                    name: intl.formatMessage({ id: "sidebar.event_select" }),
                    code: "ACCESS_EVENTS_INFORMATION",
                    icon: <MdEventNote size={19} />,
                    clickFunction: () =>
                      history.push("/events-client-visualization"),
                  },
                  {
                    id: "UPDATE_EVENT",
                    name: intl.formatMessage({
                      id: "sidebar.manage_event_editions",
                    }),
                    code: "UPDATE_EVENT",
                    icon: <MdEventNote size={19} />,
                    clickFunction: () => history.push("/events"),
                  },
                  {
                    id: "EDIT_SERVICE_PROVIDER_ASSOCIATION",
                    name: intl.formatMessage({
                      id: "sidebar.associate_service_provider",
                    }),
                    code: "EDIT_SERVICE_PROVIDER_ASSOCIATION",
                    icon: <AiOutlineUsergroupAdd size={19} />,
                    clickFunction: () =>
                      history.push("/serviceprovider-association"),
                  },
                  {
                    id: "SHOW_OPPORTUNITIES_INFORMATIONS",
                    name: intl.formatMessage({
                      id: "sidebar.manage_contracts",
                    }),
                    code: "SHOW_OPPORTUNITIES_INFORMATIONS",
                    icon: <RiFileSearchFill size={19} />,
                    clickFunction: () => history.push("/contracts"),
                  },
                  {
                    id: "CREATE_PRODUCTS_CATEGORIES",
                    name: intl.formatMessage({
                      id: "sidebar.manage_categories",
                    }),
                    code: "CREATE_PRODUCTS_CATEGORIES",
                    icon: <CgLoadbarDoc size={19} />,
                    clickFunction: () => history.push("/admin/categories"),
                  },
                  {
                    id: "CREATE_PRODUCTS",
                    name: intl.formatMessage({
                      id: "sidebar.create_additional_products",
                    }),
                    code: "CREATE_PRODUCTS",
                    icon: <CgExtensionAdd size={19} />,
                    clickFunction: () =>
                      history.push("/admin/additional-products"),
                  },
                  {
                    id: "ASSOCIATE_PRODUCTS_WITH_EVENTS",
                    name: intl.formatMessage({
                      id: "sidebar.associate_products_with_events",
                    }),
                    code: "ASSOCIATE_PRODUCTS_WITH_EVENTS",
                    icon: <HiDocumentReport size={19} />,
                    clickFunction: () =>
                      history.push("/admin/product-event-association"),
                  },
                  {
                    id: "ADMIN_SERVICE_PROVIDER_ASSOCIATION",
                    name: intl.formatMessage({
                      id: "sidebar.associate_service_provider",
                    }),
                    code: "ADMIN_SERVICE_PROVIDER_ASSOCIATION",
                    icon: <CgLoadbarDoc size={19} />,
                    clickFunction: () =>
                      history.push("/admin/serviceprovider-association"),
                  },
                  {
                    id: "SHOW_EVENTS_ASSOCIATE_WITH_PROVIDER",
                    name: intl.formatMessage({ id: "sidebar.event_select" }),
                    code: "SHOW_EVENTS_ASSOCIATE_WITH_PROVIDER",
                    icon: <MdEventNote size={19} />,
                    clickFunction: () =>
                      history.push(
                        "/serviceprovider/association-provider-view"
                      ),
                  },
                ],
              },
              {
                id: "ACCREDITATION",
                name: intl.formatMessage({
                  id: "sidebar.credentials",
                }),
                subitemTitle: intl.formatMessage({
                  id: "sidebar.credentials",
                }),
                icon: <FaIdCardAlt size={46} color="#cccccc" />,
                code: "ACCREDITATION",
                routeName: [
                  "credentials",
                  "manage-guests",
                  "admin/manage-guests",
                  "manage-credentials",
                  "credential/associate",
                  "credential/schedule-withdrawal",
                ],
                subitem: [
                  {
                    id: "MANAGE_GUESTS_ADMIN",
                    code: "MANAGE_GUESTS_ADMIN",
                    name: intl.formatMessage({
                      id: "sidebar.manage_guests",
                    }),
                    icon: <RiGroup2Fill size={19} />,
                    clickFunction: () => history.push("/admin/manage-guests"),
                  },
                  {
                    id: "MANAGE_GUESTS",
                    code: "MANAGE_GUESTS",
                    name: intl.formatMessage({
                      id: "sidebar.manage_guests",
                    }),
                    icon: <RiGroup2Fill size={19} />,
                    clickFunction: () => history.push("/manage-guests"),
                  },
                  {
                    id: "MANAGE_CREDENTIALS_ADMIN",
                    code: "MANAGE_CREDENTIALS_ADMIN",
                    name: intl.formatMessage({
                      id: "sidebar.manage_credentials",
                    }),
                    icon: <SiOpenaccess size={19} />,
                    clickFunction: () => history.push("/manage-credentials"),
                  },
                  {
                    id: "MANAGE_CREDENTIALS",
                    code: "MANAGE_CREDENTIALS",
                    name: intl.formatMessage({
                      id: "sidebar.manage_credentials",
                    }),
                    icon: <SiOpenaccess size={19} />,
                    clickFunction: () => history.push("/credentials"),
                  },
                  {
                    id: "TRANSFER_CREDENTIALS",
                    code: "TRANSFER_CREDENTIALS",
                    name: intl.formatMessage({
                      id: "sidebar.transfer_credentials",
                    }),
                    icon: <BiTransfer size={19} />,
                    clickFunction: () => history.push("/credential-transfer"),
                  },
                ],
              },
              {
                id: "SERVICE_PROVIDER_EVENTS",
                name: intl.formatMessage({ id: "sidebar.events" }),
                subitemTitle: intl.formatMessage({ id: "sidebar.events" }),
                icon: <MdEvent size={46} color="#cccccc" />,
                code: "SERVICE_PROVIDER_EVENTS",
                routeName: ["servicerprovider_association_provider_view"],
                clickFunction: () =>
                  history.push("/serviceprovider/association-provider-view"),
                subitem: [],
              },
              {
                id: "REPORTS",
                name: intl.formatMessage({ id: "sidebar.reports" }),
                subitemTitle: intl.formatMessage({ id: "sidebar.reports" }),
                icon: <GoGraph size={46} color="#cccccc" />,
                code: "REPORTS",
                routeName: [
                  "servicerprovider_association_provider_view",
                  "admin/guests-report",
                  "logs",
                  "admin/operational-report",
                  "admin/credential-schedule-report",
                  "admin/credential-report",
                  "/admin/exhibitors-didnt-access-event-report",
                  "/admin/exhibitors-didnt-create-credential-report",
                  "/admin/providers-associated-report",
                  "/admin/digital-products-report",
                  "/admin/headband-report",
                  "/admin/order-report",
                  "/admin/service-providers-report",
                ],
                subitem: [
                  {
                    id: "SHOW_LOGS",
                    name: intl.formatMessage({ id: "sidebar.access_history" }),
                    code: "SHOW_LOGS",
                    icon: <CgUserList size={19} />,
                    clickFunction: () => history.push("/logs"),
                  },
                  {
                    id: "OPERATIONAL_REPORT",
                    name: intl.formatMessage({
                      id: "sidebar.operational_report",
                    }),
                    code: "OPERATIONAL_REPORT",
                    icon: <RiFileList3Fill size={19} />,
                    clickFunction: () =>
                      history.push("/admin/operational-report"),
                  },
                  {
                    id: "CREDENTIAL_REPORT",
                    name: intl.formatMessage({
                      id: "sidebar.credential_report",
                    }),
                    code: "CREDENTIAL_REPORT",
                    icon: <FaIdCardAlt size={19} />,
                    clickFunction: () =>
                      history.push("/admin/credential-report"),
                  },
                  {
                    id: "SCHEDULING_REPORT",
                    name: intl.formatMessage({
                      id: "sidebar.scheduling_report",
                    }),
                    code: "SCHEDULING_REPORT",
                    icon: <GrSchedules size={19} />,
                    clickFunction: () =>
                      history.push("/admin/credential-schedule-report"),
                  },
                  {
                    id: "ACCESS_GUESTS_REPORT",
                    name: intl.formatMessage({
                      id: "sidebar.guests_report",
                    }),
                    code: "ACCESS_GUESTS_REPORT",
                    icon: <RiVipDiamondFill size={19} />,
                    clickFunction: () => history.push("/admin/guests-report"),
                  },
                  {
                    id: "ACCESS_EXHIBITORS_DID_NOT_ACCESS_EVENT_REPORT",
                    name: intl.formatMessage({
                      id: "sidebar.exhibitors_didnt_access_event_report",
                    }),
                    code: "ACCESS_EXHIBITORS_DID_NOT_ACCESS_EVENT_REPORT",
                    icon: <RiChatOffLine size={26} />,
                    clickFunction: () =>
                      history.push(
                        "/admin/exhibitors-didnt-access-event-report"
                      ),
                  },
                  {
                    id: "ACCESS_EXHIBITORS_DID_NOT_CREATE_CREDENTIAL_REPORT",
                    name: intl.formatMessage({
                      id: "sidebar.exhibitors_didnt_create_credentials_report",
                    }),
                    code: "ACCESS_EXHIBITORS_DID_NOT_CREATE_CREDENTIAL_REPORT",
                    icon: <FaRegIdCard size={32} />,
                    clickFunction: () =>
                      history.push(
                        "/admin/exhibitors-didnt-create-credential-report"
                      ),
                  },
                  {
                    id: "ACCESS_SERVICE_PROVIDERS_ASSOCIATED_REPORT",
                    name: intl.formatMessage({
                      id: "sidebar.service_providers_associated_report",
                    }),
                    code: "ACCESS_SERVICE_PROVIDERS_ASSOCIATED_REPORT",
                    icon: <MdDoneAll size={30} />,
                    clickFunction: () =>
                      history.push("/admin/providers-associated-report"),
                  },
                  {
                    id: "ACCESS_DIGITAL_PRODUCTS_REPORT",
                    name: intl.formatMessage({
                      id: "sidebar.digital_products_report",
                    }),
                    code: "ACCESS_DIGITAL_PRODUCTS_REPORT",
                    icon: <FaDigitalTachograph size={19} />,
                    clickFunction: () =>
                      history.push("/admin/digital-products-report"),
                  },
                  {
                    id: "ACCESS_HEADBAND_REPORT",
                    name: intl.formatMessage({
                      id: "sidebar.headband_report",
                    }),
                    code: "ACCESS_HEADBAND_REPORT",
                    icon: <RiBookmark2Line size={19} />,
                    clickFunction: () => history.push("/admin/headband-report"),
                  },
                  {
                    id: "ACCESS_ORDER_REPORT",
                    name: intl.formatMessage({
                      id: "sidebar.order_report",
                    }),
                    code: "ACCESS_ORDER_REPORT",
                    icon: <AiFillShopping size={19} />,
                    clickFunction: () => history.push("/admin/order-report"),
                  },
                  {
                    id: "ACCESS_SERVICE_PROVIDERS_REPORT",
                    name: intl.formatMessage({
                      id: "sidebar.service_providers_report",
                    }),
                    code: "ACCESS_SERVICE_PROVIDERS_REPORT",
                    icon: <MdDonutSmall size={19} />,
                    clickFunction: () =>
                      history.push("/admin/service-providers-report"),
                  },
                ],
              },
              {
                id: "COCKPIT",
                name: intl.formatMessage({ id: "sidebar.cockpit" }),
                subitemTitle: intl.formatMessage({ id: "sidebar.cockpit" }),
                icon: <FaTachometerAlt size={46} color="#cccccc" />,
                code: "COCKPIT",
                routeName: [
                  "cockpit",
                  "/admin/event-setup",
                  "/admin/event-setup-helpdesk",
                ],
                subitem: [
                  {
                    id: "SHOW_COCKPIT",
                    name: "Dashboard",
                    code: "SHOW_COCKPIT",
                    icon: <FaTachometerAlt size={19} />,
                    clickFunction: () => history.push("/cockpit"),
                  },
                  {
                    id: "MANAGE_EVENT_SETUP",
                    name: intl.formatMessage({
                      id: "sidebar.event_setup",
                    }),
                    code: "MANAGE_EVENT_SETUP",
                    icon: <MdCollectionsBookmark size={19} />,
                    clickFunction: () => history.push("/admin/event-setup"),
                  },
                  {
                    id: "MANAGE_EVENT_SETUP_HELPDESK",
                    name: intl.formatMessage({
                      id: "sidebar.event_setup_helpdesk",
                    }),
                    code: "MANAGE_EVENT_SETUP_HELPDESK",
                    icon: <RiSurveyLine size={19} />,
                    clickFunction: () =>
                      history.push("/admin/event-setup-helpdesk"),
                  },
                ],
                standalone: true,
              },
              {
                id: "SYSTEMS",
                name: intl.formatMessage({ id: "sidebar.systems" }),
                subitemTitle: intl.formatMessage({ id: "sidebar.systems" }),
                icon: <MdComputer size={46} color="#cccccc" />,
                code: "SYSTEMS",
                subitem: [
                  {
                    id: "ACCESS_ECOMMERCE",
                    name: intl.formatMessage({
                      id: "sidebar.access_ecommerce",
                    }),
                    code: "ACCESS_ECOMMERCE",
                    icon: <FaShoppingBasket size={19} />,
                    clickFunction: () => handleEcommerceAccess(),
                  },
                  {
                    id: "ACCESS_FINANCE_SYSTEM",
                    name: intl.formatMessage({
                      id: "sidebar.access_finantial_system",
                    }),
                    code: "ACCESS_FINANCE_SYSTEM",
                    icon: <MdAttachMoney size={19} />,
                    clickFunction: () => handleFinancialSingleSignOn(),
                  },
                ],
              },
              {
                id: "INFORMATIONS",
                name: intl.formatMessage({ id: "informations" }),
                icon: <MdInfo size={46} color="#cccccc" />,
                subitemTitle: intl.formatMessage({ id: "informations" }),
                code: "INFORMATIONS",
                routeName: ["faq", "faq-client-visualization", "contact"],
                subitem: [
                  {
                    id: "EDIT_KNOWLEDGE_BASE",
                    name: intl.formatMessage({
                      id: "sidebar.edit_knowledge_base",
                    }),
                    code: "EDIT_KNOWLEDGE_BASE",
                    icon: <MdEdit size={19} />,
                    clickFunction: () => history.push("/faq"),
                  },
                  {
                    id: "ACCESS_KNOWLEDGE_BASE",
                    name: intl.formatMessage({ id: "sidebar.knowledge_base" }),
                    code: "ACCESS_KNOWLEDGE_BASE",
                    icon: <RiFileInfoLine size={19} />,
                    clickFunction: () =>
                      history.push("/faq-client-visualization"),
                  },
                  {
                    id: "info-item",
                    code: "NOTIFICATIONS_CENTER",
                    clickFunction: () => null,
                  },
                ],
              },
            ]}
            openConfirmation={showConfirmation}
          />
        )}

        <Topbar />
        <Content sidebarIsVisible={sidebarIsVisible}>{children}</Content>
        <ConfirmationModal
          setIsOpen={setModalIsOpen}
          modalIsOpen={modalIsOpen}
          confirmationFunction={handleLogout}
          description={intl.formatMessage({ id: "logout_confirmation" })}
          confirmationText={intl.formatMessage({ id: "yes" })}
          cancelText={intl.formatMessage({ id: "no" })}
          isLoading={isLoading}
        />
        <ConfirmationModal
          setIsOpen={setServiceProviderInvalidDataModalIsOpen}
          modalIsOpen={serviceProviderInvalidDataModalIsOpen}
          confirmationFunction={handleServiceProviderHandleDataConfirmation}
          description={intl.formatMessage({ id: "correct_your_data" })}
          confirmationText={intl.formatMessage({ id: "yes" })}
          isLoading={isLoading}
        />
        <TermsModal
          setIsOpen={setTermsAndConditionsOpen}
          modalIsOpen={termsAndConditionsOpen}
        />
        {serviceProviderLoggingAsAnExhibitor && (
          <SelectYourAccountModal
            setIsOpen={setModalSelectYourAccountIsOpen}
            modalIsOpen={modalSelectYourAccountIsOpen}
          />
        )}

        <Modal
          setIsOpen={setEcommerceModalOpen}
          modalIsOpen={ecommerceModalOpen}
          modalWidth="50%"
          overflowX="auto"
        >
          <FormContainer>
            <Form ref={ecommerceFormRef} onSubmit={handleEcommerceSubmit}>
              <TitleContainer>
                <Title>
                  <FormattedMessage id="access_ecommerce" />
                </Title>
              </TitleContainer>
              <FieldsContainer>
                <SelectWithLabel
                  name="event"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "event_edition" })}
                  placeholder={intl.formatMessage({ id: "event_edition" })}
                  onChange={(option) => setSelectedEvent(option.value)}
                  labelOrientation="vertical"
                  options={events}
                />
                {isAdministrator && (
                  <SelectWithLabel
                    name="exhibitor"
                    validationBlur={validationBlur}
                    label={intl.formatMessage({ id: "exhibitor" })}
                    placeholder={intl.formatMessage({ id: "exhibitor" })}
                    onChange={(option) => setSelectedExhibitor(option?.value)}
                    labelOrientation="vertical"
                    options={exhibitors}
                  />
                )}
                <SelectWithLabel
                  name="opportunity"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "contract" })}
                  placeholder={intl.formatMessage({ id: "contract" })}
                  labelOrientation="vertical"
                  options={opportunities}
                />

                {chlebaAccessInformation && (
                  <>
                    <AdditionalInformationContainer>
                      {!isAdministrator && (
                        <InformationContainer>
                          <InformationLabel>
                            <FormattedMessage id="company_name" />
                          </InformationLabel>
                          <InformationValue>
                            {chlebaAccessInformation.corporateName || "-"}
                          </InformationValue>
                        </InformationContainer>
                      )}
                      <InformationContainer>
                        <InformationLabel>
                          <FormattedMessage id="document" />
                        </InformationLabel>
                        <InformationValue>
                          {chlebaAccessInformation.cnpj || "-"}
                        </InformationValue>
                      </InformationContainer>
                      <InformationContainer>
                        <InformationLabel>
                          <FormattedMessage id="zip_code" />
                        </InformationLabel>
                        <InformationValue>
                          {chlebaAccessInformation.cep || "-"}
                        </InformationValue>
                      </InformationContainer>
                      <InformationContainer>
                        <InformationLabel>
                          <FormattedMessage id="telephone" />
                        </InformationLabel>
                        <InformationValue>
                          {chlebaAccessInformation.telephone || "-"}
                        </InformationValue>
                      </InformationContainer>
                      <InformationContainer>
                        <InformationLabel>
                          <FormattedMessage id="taxes" />
                        </InformationLabel>
                        <InformationValue>
                          {chlebaAccessInformation.taxType || "-"}
                        </InformationValue>
                      </InformationContainer>
                      <InformationNotification>
                        <FormattedMessage id="chleba_authentication_confirmation" />
                      </InformationNotification>
                    </AdditionalInformationContainer>
                    <Horizontal>
                      <FilterActionsContainer>
                        <SubmitContainer>
                          <FormButton
                            color="#C9C9C9"
                            type="button"
                            onClick={() => setEcommerceModalOpen(false)}
                            loading={isLoading}
                          >
                            <FormattedMessage id="close" />
                          </FormButton>
                        </SubmitContainer>
                        <SubmitContainer>
                          <FormButton type="submit" loading={loading}>
                            <FormattedMessage id="yes" />
                          </FormButton>
                        </SubmitContainer>
                        <SubmitContainer>
                          <FormButton
                            loading={loading}
                            onClick={() => handleInvalidData()}
                          >
                            <FormattedMessage id="no" />
                          </FormButton>
                        </SubmitContainer>
                      </FilterActionsContainer>
                    </Horizontal>
                  </>
                )}
              </FieldsContainer>
            </Form>
          </FormContainer>
        </Modal>
      </Container>
    </LoadingOverlay>
  );
};

export default MainContainerWithSidebar;
