export function getNotifications(notificationState) {
  return {
    type: "GET_NOTIFICATIONS",
    payload: {
      token: notificationState.token,
    },
  };
}

export function setNotifications(notificationState) {
  return {
    type: "SET_APP_NOTIFICATIONS",
    payload: {
      notifications: notificationState.notifications,
      unread: notificationState.unread,
    },
  };
}

export function setNotificationAsRead(notificationState) {
  return {
    type: "SET_NOTIFICATION_AS_READ",
    payload: {
      id: notificationState.id,
      token: notificationState.token,
    },
  };
}

export function deductAnUnreadNotification() {
  return {
    type: "DEDUCT_AN_UNREAD_NOTIFICATION",
  };
}
