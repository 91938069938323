import React, { useEffect, useRef, useState, useCallback } from "react";
import { useField } from "@unform/core";
import { MdError } from "react-icons/md";

import {
  Container,
  Error,
  Label,
  LabelContainer,
  RootContainer,
  InputRow,
  AsteriskText,
} from "./styles";

import { cpf, cnpj } from "cpf-cnpj-validator";

const CpfCnpj = ({
  name,
  containerStyle = {},
  icon: Icon,
  validationBlur,
  disabled,
  personType,
  placeholder,
  labelOrientation,
  labelWidth,
  hasLabel,
  required,
  ...rest
}) => {
  const inputRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);

    if (validationBlur) {
      validationBlur(fieldName, inputRef.current?.value);
    }
  }, [fieldName, validationBlur]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  const format = () => {
    if (inputRef.current.value.length === 14 && personType === "J") {
      inputRef.current.value = cnpj.format(inputRef.current.value);
    } else if (inputRef.current.value.length === 11 && personType === "F") {
      inputRef.current.value = cpf.format(inputRef.current.value);
    }
  };

  const resetFormat = () => {
    if (personType === "F") {
      inputRef.current.value = inputRef.current.value.replace(
        /[a-zA-Z&\/\\#,+\-()$~%.'":*?= ^~!|@\`<>;´{}]/g,
        ""
      );
    }
  };

  return (
    <RootContainer labelOrientation={labelOrientation}>
      <InputRow labelOrientation={labelOrientation}>
        {hasLabel && (
          <LabelContainer labelWidth={labelWidth}>
            <Label>{placeholder}</Label>
            {required && <AsteriskText>*</AsteriskText>}
          </LabelContainer>
        )}
        <Container
          style={containerStyle}
          isFocused={isFocused}
          isFilled={isFilled}
          onBlur={format}
          onFocus={resetFormat}
          isErrored={!!error}
          disabled={disabled}
          data-testid="input-container"
        >
          {Icon && <Icon size={24} />}
          <input
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            defaultValue={defaultValue}
            onChange={resetFormat}
            ref={inputRef}
            maxLength={personType === "I" ? 50 : 14}
            disabled={disabled}
            placeholder={placeholder}
            {...rest}
          />
        </Container>
      </InputRow>
      {error && (
        <Error
          labelWidth={labelWidth}
          labelOrientation={labelOrientation}
          title={error}
        >
          <MdError color="#e00000" size={20} />
          {error}
        </Error>
      )}
    </RootContainer>
  );
};

export default CpfCnpj;
