import React from "react";
import { useTransition } from "react-spring";

import { Container } from "./styles";

import Toast from "./Toast";

const ToastContainer = ({ messages }) => {
  const messageWithTransition = useTransition(
    messages,
    (message) => message.id,
    {
      from: { right: "-120%", opacity: 0 },
      enter: { right: "0%", opacity: 1 },
      leave: { right: "-120%", opacity: 0 },
    }
  );

  return (
    <Container messages={messages}>
      {messageWithTransition.map(({ key, item, props }) => (
        <Toast key={key} style={props} message={item} />
      ))}
    </Container>
  );
};

export default ToastContainer;
