import React, { useCallback, useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  MdPerson,
  MdSubtitles,
  MdLocationOn,
  MdLocationCity,
  MdStorage,
  MdSmartphone,
  MdPhone,
  MdEmail,
  MdInfo,
  MdPermContactCalendar,
  MdList,
} from "react-icons/md";

import { Form } from "@unform/web";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";

import { useDispatch, useSelector } from "react-redux";

import cnpjCpfFormatter from "../../utils/cnpjCpfFormatter";

import { FormattedMessage, useIntl } from "react-intl";

import {
  Container,
  TitleContainer,
  Title,
  SessionDivider,
  Session,
  SessionContainer,
  SessionIconContainer,
  SessionTitle,
  FieldsContainer,
  ButtonsContainer,
} from "./styles";

import Input from "../../components/Input";
import CpfCnpj from "../../components/CpfCnpj";

import { useToast } from "../../hooks/toast";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";
import { updateUser } from "../../redux/modules/auth/actions";
import api from "../../service";
import taxes from "../../constants/taxes";
import types from "../../constants/types";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import Button from "../../components/Button";
import SelectWithLabel from "../../components/SelectWithLabel";
import axios from "axios";
import { cnpj, cpf } from "cpf-cnpj-validator";
import { useLoading } from "../../hooks/loading";

const UpdateUser = () => {
  const formRef = useRef(null);
  const intl = useIntl();
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const { user, token, accountCreated } = useSelector((state) => state.auth);

  const [countries, setCountries] = useState([]);
  const [personType, setPersonType] = useState();
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);
  const [taxesByPerson, setTaxesByPerson] = useState();
  const [disableTaxes, setDisableTaxes] = useState(true);
  const [documentLabel, setDocumentLabel] = useState(
    `${intl.formatMessage({ id: "id_number" })}`
  );
  const [selectedTax, setSelectedTax] = useState();
  const [key, setKey] = useState();

  const history = useHistory();

  const [provisionTypes] = useState([
    { label: intl.formatMessage({ id: "landscaping" }), value: 1 },
    { label: intl.formatMessage({ id: "safety" }), value: 2 },
    { label: intl.formatMessage({ id: "receptive" }), value: 3 },
    { label: intl.formatMessage({ id: "cleaning" }), value: 4 },
    { label: intl.formatMessage({ id: "custom_broker" }), value: 5 },
    { label: intl.formatMessage({ id: "catering" }), value: 6 },
    { label: intl.formatMessage({ id: "furniture_and_decoration" }), value: 7 },
    { label: intl.formatMessage({ id: "translation" }), value: 8 },
    { label: intl.formatMessage({ id: "compressed_air" }), value: 9 },
    { label: intl.formatMessage({ id: "travel_agency" }), value: 10 },
    { label: intl.formatMessage({ id: "visual_communication" }), value: 11 },
    {
      label: intl.formatMessage({ id: "electrical_and_hydraulic" }),
      value: 12,
    },
    { label: intl.formatMessage({ id: "data_collectors" }), value: 13 },
    {
      label: intl.formatMessage({ id: "transport_import_and_export" }),
      value: 14,
    },
    {
      label: intl.formatMessage({ id: "transportation_equipment_leasing" }),
      value: 15,
    },
    { label: intl.formatMessage({ id: "telephone_and_internet" }), value: 16 },
    { label: intl.formatMessage({ id: "press_office" }), value: 17 },
    { label: intl.formatMessage({ id: "equipment_leasing" }), value: 18 },
    { label: intl.formatMessage({ id: "carmaker" }), value: 19 },
    { label: intl.formatMessage({ id: "carpet" }), value: 20 },
    { label: intl.formatMessage({ id: "cargo_handling" }), value: 21 },
    { label: intl.formatMessage({ id: "reception" }), value: 22 },
    { label: intl.formatMessage({ id: "insurance" }), value: 23 },
    { label: intl.formatMessage({ id: "agency" }), value: 24 },
    { label: intl.formatMessage({ id: "coexhibitor" }), value: 25 },
    { label: intl.formatMessage({ id: "others" }), value: 26 },
  ]);

  const schema = Yup.object().shape({
    type: Yup.string().required("O tipo é obrigatório"),
    email: Yup.string()
      .required(
        intl.formatMessage({
          defaultMessage: "O e-mail é obrigatório",
          id: "error.email_required",
        })
      )
      .email(
        intl.formatMessage({
          defaultMessage: "Digite um e-mail válido",
          id: "error.valid_email",
        })
      ),
    name: Yup.string().required(
      intl.formatMessage({
        id: "error.company_name_required",
      })
    ),
    state_inscription: Yup.string(),
    city_inscription: Yup.string(),
    address_street: Yup.string(),
    address_number: Yup.string(),
    district: Yup.string(),
    state: Yup.string(),
    city: Yup.string(),
    avenue: Yup.string(),
    address_complement: Yup.string(),
    provision_types: Yup.array().min(
      1,
      intl.formatMessage({ id: "select_at_least_one_of_the_options" })
    ),
    taxes: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    document: Yup.string()
      .required(
        intl.formatMessage({
          defaultMessage: "O CPF/CNPJ é obrigatório",
          id: "error.document_required",
        })
      )
      .test(
        "is-incorrect",
        intl.formatMessage({
          id: "error.document_invalid",
        }),
        function (value) {
          let result;
          const type = this.resolve(Yup.ref("type"));
          if (type === "I") {
            return true;
          }
          if (type === "F") {
            result = cpf.isValid(value);
          } else if (type === "J") {
            result = cnpj.isValid(value);
          }
          return result;
        }
      ),
    country: Yup.string().required(
      intl.formatMessage({
        defaultMessage: "O país é obrigatório",
        id: "error.country_required",
      })
    ),
    phone: Yup.string().matches(
      /^(?:\+)[0-9]{1,3}\s?(?:\()[0-9]{1,3}(?:\))\s?[0-9]{3,13}$/,
      intl.formatMessage({
        id: "error.telephone_match",
      })
    ),
    mobile: Yup.string().matches(
      /^(?:\+)[0-9]{1,3}\s?(?:\()[0-9]{1,3}(?:\))\s?[0-9]{3,13}$/,
      intl.formatMessage({
        id: "error.telephone_match",
      })
    )
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) {
          return;
        }

        await schema.validate(data, { abortEarly: false });

        api
          .put(
            `/serviceprovider`,
            {
              type: data.type,
              name: data.name,
              document: data.document,
              state_inscription:
                data.state_inscription && Number(data.state_inscription),
              city_inscription:
                data.city_inscription && Number(data.city_inscription),
              country: data.country,
              avenue: data.avenue,
              address_number: data.address_number,
              address_complement: data.address_complement,
              district: data.district,
              city: data.city,
              state: data.state,
              phone: data.phone,
              mobile: data.mobile,
              email: data.email,
              taxes: data.taxes,
              current_password: data.current_password,
              password: data.password,
              password_confirmation: data.password_confirmation,
              provisionTypes: data.provision_types,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            dispatch(
              updateUser({
                user: result.data.user,
              })
            );
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.user_update",
              }),
            });
            history.push("/dashboard");
          })
          .catch((err) =>
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            })
          )
          .finally(() => setLoading(false));
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, dispatch, history, intl, schema, token, loading]
  );

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const pushBack = () => {
    history.push("/dashboard");
  };

  const handleTypeChanges = (value) => {
    setPersonType(value);
    let taxeField;
    let taxeValues;
    switch (value) {
      case "F":
        taxeValues = [taxes[4]];
        taxeField = taxes.filter((tax) => tax.value === 4)[0];
        setTaxesByPerson(taxeValues);
        setDisableTaxes(true);
        setDocumentLabel(`${intl.formatMessage({ id: "id_number" })}`);
        formRef.current.setFieldValue("taxes", taxeField);
        break;
      case "J":
        taxeValues = [taxes[0], taxes[1], taxes[2], taxes[3]];
        taxeField = taxes.filter((tax) => tax.value === 0)[0];
        setTaxesByPerson(taxeValues);
        setDisableTaxes(false);
        setDocumentLabel(`${intl.formatMessage({ id: "id_number" })}`);
        formRef.current.setFieldValue("taxes", taxeField);
        break;
      case "I":
        taxeValues = [taxes[5]];
        taxeField = taxes.filter((tax) => tax.value === 5)[0];
        setTaxesByPerson(taxeField);
        setDisableTaxes(true);
        setDocumentLabel(
          `${intl.formatMessage({
            id: "passport",
          })}`
        );
        formRef.current.setFieldValue("taxes", taxeField);
        break;
      default:
        break;
    }
    formRef.current.setFieldValue("document", "");
  };

  useEffect(() => {
    if (user.user_type) {
      setTaxesByPerson(taxes);
      const selectedTax = taxes.find((tax) => tax.value === user.taxes);
      setSelectedTax(selectedTax);
      setKey("TAX");
    }
  },[intl, user]);

  useEffect(() => {
    handleLoading(true);
    axios
      .get(
        "https://raw.githubusercontent.com/apilayer/restcountries/master/src/main/resources/countriesV2.json"
      )
      .then((result) => {
        const countries = result.data.map((country) => ({
          value: country.name,
          label: country.name,
        }));
        setCountries(countries);
      })
      .catch(() =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "error.could_not_load_countries",
          }),
        })
      )
      .finally(() => handleLoading(false));
  }, [addToast, handleLoading, intl]);

  return (
    <AnimatedMainContainer>
      <Container>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          key={key}
          initialData={{
            name: user.name,
            type: user.user_type && {
              value: user.user_type,
              label: types.filter((t) => t.value === user.user_type)[0]?.label,
            },
            document: cnpjCpfFormatter(user.document, user.user_type),
            state_inscription: user.state_inscription,
            city_inscription: user.municipal_inscription,
            country: {
              label: user.country,
              value: user.country,
            },
            avenue: user.avenue,
            address_number: user.number,
            address_complement: user.address_complement,
            district: user.district,
            city: user.city,
            state: user.state,
            phone: user.telephone,
            mobile: user.mobile,
            email: user.email,
            taxes: selectedTax,
            provision_types: user.provisionTypes?.map((value) => {
              return {
                value: value,
                label: provisionTypes.filter((pt) => pt.value === value)[0]
                  ?.label,
              };
            }),
          }}
        >
          <TitleContainer>
            <Title>
              <FormattedMessage id="user_editing" />
            </Title>
          </TitleContainer>
          <SessionDivider nr_items="2">
            <Session>
              <SessionContainer>
                <SessionIconContainer>
                  <MdInfo size="16px" color="#002244" />
                </SessionIconContainer>
                <SessionTitle>
                  <FormattedMessage id="informations" />
                </SessionTitle>
              </SessionContainer>
              <FieldsContainer>
                <SelectWithLabel
                  name="type"
                  icon={MdStorage}
                  label={intl.formatMessage({
                    id: "type",
                  })}
                  placeholder={intl.formatMessage({
                    id: "type",
                  })}
                  hasLabel
                  options={types}
                  onChange={(e) => handleTypeChanges(e.value)}
                  validationBlur={validationBlur}
                  disabled={accountCreated}
                  required
                />
                <Input
                  name="name"
                  icon={MdPerson}
                  placeholder={intl.formatMessage({
                    id: "company_name",
                  })}
                  hasLabel
                  validationBlur={validationBlur}
                  disabled={accountCreated}
                  maxLength="250"
                  required
                />
                <CpfCnpj
                  name="document"
                  icon={MdSubtitles}
                  placeholder={documentLabel}
                  hasLabel
                  validationBlur={validationBlur}
                  personType={personType}
                  disabled={accountCreated || !personType}
                  required
                />
                <Input
                  name="state_inscription"
                  icon={MdSubtitles}
                  placeholder={intl.formatMessage({
                    id: "state_inscription",
                  })}
                  hasLabel
                  validationBlur={validationBlur}
                  disabled={accountCreated}
                  maxLength="10"
                />
                <Input
                  name="city_inscription"
                  icon={MdSubtitles}
                  placeholder={intl.formatMessage({
                    id: "city_inscription",
                  })}
                  hasLabel
                  validationBlur={validationBlur}
                  disabled={accountCreated}
                  maxLength="10"
                />
                <SelectWithLabel
                  name="country"
                  icon={MdLocationOn}
                  label={intl.formatMessage({
                    id: "country",
                  })}
                  placeholder={intl.formatMessage({
                    id: "country",
                  })}
                  options={countries}
                  disabled
                  validationBlur={validationBlur}
                  required
                />
                <Input
                  name="email"
                  icon={MdEmail}
                  placeholder="E-mail"
                  validationBlur={validationBlur}
                  hasLabel
                  disabled
                  required
                />
                <SelectWithLabel
                  name="taxes"
                  icon={MdStorage}
                  label={intl.formatMessage({
                    id: "taxes",
                  })}
                  placeholder={intl.formatMessage({
                    id: "taxes",
                  })}
                  options={taxesByPerson}
                  validationBlur={validationBlur}
                  disabled={accountCreated || disableTaxes}
                  required
                />
              </FieldsContainer>
            </Session>
            <Session>
              <SessionContainer>
                <SessionIconContainer>
                  <MdPermContactCalendar size="16px" color="#002244" />
                </SessionIconContainer>
                <SessionTitle>
                  <FormattedMessage id="contact_data" />
                </SessionTitle>
              </SessionContainer>
              <FieldsContainer>
                <Input
                  name="avenue"
                  icon={MdLocationOn}
                  placeholder={intl.formatMessage({
                    id: "avenue",
                  })}
                  hasLabel
                  validationBlur={validationBlur}
                  maxLength="255"
                />
                <Input
                  name="address_number"
                  icon={MdLocationOn}
                  placeholder={intl.formatMessage({
                    id: "address_number",
                  })}
                  hasLabel
                  validationBlur={validationBlur}
                  maxLength="80"
                />
                <Input
                  name="address_complement"
                  icon={MdLocationOn}
                  placeholder={intl.formatMessage({
                    id: "address_complement",
                  })}
                  hasLabel
                  validationBlur={validationBlur}
                  maxLength="80"
                />
                <Input
                  name="district"
                  icon={MdLocationOn}
                  placeholder={intl.formatMessage({
                    id: "district",
                  })}
                  hasLabel
                  validationBlur={validationBlur}
                  maxLength="255"
                />
                <Input
                  name="city"
                  icon={MdLocationCity}
                  placeholder={intl.formatMessage({
                    id: "city",
                  })}
                  hasLabel
                  validationBlur={validationBlur}
                  maxLength="255"
                />
                <Input
                  name="state"
                  icon={MdLocationCity}
                  placeholder={intl.formatMessage({
                    id: "state",
                  })}
                  hasLabel
                  validationBlur={validationBlur}
                  maxLength="255"
                />
                <Input
                  name="phone"
                  icon={MdPhone}
                  placeholder={intl.formatMessage({
                    id: "phone",
                  })}
                  hasLabel
                  validationBlur={validationBlur}
                  maxLength="20"
                  required
                />
                <Input
                  name="mobile"
                  icon={MdSmartphone}
                  placeholder={intl.formatMessage({
                    id: "mobile",
                  })}
                  hasLabel
                  validationBlur={validationBlur}
                  maxLength="20"
                  required
                />
              </FieldsContainer>
            </Session>
          </SessionDivider>

          <SessionDivider nr_items="2">
            <Session>
              <SessionContainer>
                <SessionIconContainer>
                  <MdList size="16px" color="#002244" />
                </SessionIconContainer>
                <SessionTitle>
                  <FormattedMessage id="service_provision" />
                </SessionTitle>
              </SessionContainer>
              <FieldsContainer>
                <SelectWithLabel
                  label={intl.formatMessage({
                    id: "types",
                  })}
                  placeholder={intl.formatMessage({
                    id: "select_at_least_one_of_the_options",
                  })}
                  isMulti
                  options={provisionTypes}
                  name="provision_types"
                  icon={MdList}
                  disabled={accountCreated}
                  validationBlur={validationBlur}
                  required
                />
              </FieldsContainer>
            </Session>
            <Session></Session>
          </SessionDivider>

          <ButtonsContainer>
            <Button size="100px" type="submit" loading={loading}>
              <FormattedMessage id="update_data" />
            </Button>

            <Button color="#C9C9C9" type="button" onClick={pushBack}>
              <FormattedMessage id="goback" />
            </Button>
          </ButtonsContainer>
        </Form>
      </Container>
    </AnimatedMainContainer>
  );
};

export default UpdateUser;
